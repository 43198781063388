import copy from 'copy-text-to-clipboard';
import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { ToastContext } from '../../services/toast';
import { ActionMenuBadge, IActionMenuBadgeItem } from './action-menu-badge';
import { assembleName, generateLabel } from './util';

export interface IGuarantorBadgeProps {
    className?: string;
    id?: string;
    first?: string;
    last?: string;
    suffix?: string;
    tooltip?: string;
    items?: IActionMenuBadgeItem[];
    openInNewTab?: boolean;
}
export const GuarantorBadge: React.FunctionComponent<IGuarantorBadgeProps> = (
    props: IGuarantorBadgeProps,
): React.ReactElement => {
    const { className, id, first, last, suffix, tooltip, items = [], openInNewTab } = props;
    const name = assembleName(first, last, suffix);
    const context = useContext(ToastContext);
    const history = useHistory();

    const defaultItems: IActionMenuBadgeItem[] = [
        {
            label: 'View Details',
            action: () => {
                if (openInNewTab) {
                    window.open(history.createHref({ pathname: `/guarantors/${id}` }), '_blank');
                    return;
                }

                try {
                    history.push(`/guarantors/${id}`);
                } catch (err) {
                    window.open(history.createHref({ pathname: `/guarantors/${id}` }), '_blank');
                }
            },
        },
        {
            label: 'Copy URL',
            action: () => {
                const url = `${window.location.origin}/guarantors/${id}`;
                copy(url);
                context.notify && context.notify('URL copied to clipboard', 'left');
            },
        },
        ...items,
    ];
    return (
        <ActionMenuBadge
            className={className}
            label={generateLabel('Guarantor', id, name)}
            tooltip={tooltip}
            items={defaultItems}
            id={id}
        />
    );
};
