import { mdiInformationOutline, mdiPlus } from '@mdi/js';
import Icon from '@mdi/react';
import * as React from 'react';
import { TooltipTransitionComponent } from '../base';
import * as S from './form-field.styles';

interface IFormField {
    label?: string;
    info?: JSX.Element;
    required?: boolean;
    children?: React.ReactNode;
    important?: boolean;
}

export const FormField: React.StatelessComponent<IFormField> = ({
    label,
    required,
    children,
    info,
    important
}): React.ReactElement => {
    const displayLabel = required && label ? label + '*' : label;

    return (
        <S.FormField>
            {label && (
                <S.Label important={important}>
                    <div>{displayLabel}</div>
                    {!!info && (
                        <S.StyledTooltip
                            placement="top"
                            TransitionComponent={TooltipTransitionComponent}
                            PopperProps={{ style: { zIndex: 10000 } }}
                            title={info}>
                            <S.InfoWrapper>
                                <Icon path={mdiInformationOutline} size={0.75} />
                            </S.InfoWrapper>
                        </S.StyledTooltip>
                    )}
                </S.Label>
            )}
            {children}
        </S.FormField>
    );
};

interface IFormFieldGroupProps {
    readOnly?: boolean;
    onAdd?: () => void;
    onAddLabel?: string;
}

export class FormFieldGroup extends React.PureComponent<IFormFieldGroupProps> {
    public render(): React.ReactElement {
        const { onAdd, onAddLabel, readOnly } = this.props;

        return (
            <S.FormFieldGroup>
                {this.props.children}
                {onAdd && !readOnly && (
                    <S.AddFieldButton variant="text" onClick={onAdd} tabIndex={-1} type="button">
                        <Icon path={mdiPlus} size={0.75} />
                        <span>{onAddLabel}</span>
                    </S.AddFieldButton>
                )}
            </S.FormFieldGroup>
        );
    }
}
