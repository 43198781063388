import React from 'react';
import * as S from './toggle.styles';

export interface IToggleProps {
    className?: string;
    disabled?: boolean;
    value: boolean;
    onChange: (value: boolean) => void;
}

export const Toggle: React.FunctionComponent<IToggleProps> = (props: IToggleProps): React.ReactElement => {
    const { className, disabled, value, onChange } = props;

    const handleClick = (): void => {
        if (!disabled) {
            onChange && onChange(!value);
        }
    };

    return (
        <S.Toggle className={className} disabled={!!disabled} value={value} onClick={handleClick}>
            <S.Knob />
        </S.Toggle>
    );
};
