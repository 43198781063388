import styled from '@emotion/styled/macro';
import { Grow } from '@mui/material';
import { Variables } from '../variables';

export const StyledGrow = styled(Grow)`
    display: flex;
    opacity: 1;

    margin: 2px;
    padding: 16px;

    color: ${Variables.Colors.n100};
    background-color: ${Variables.Colors.white};

    font-size: 14px;
    letter-spacing: 0.2px;

    border-radius: ${Variables.Rounded.small};
    box-shadow: ${Variables.Shadows.medium}, ${Variables.Shadows.border};
    max-width: 400px;
    max-height: 500px;
`;
