import { css } from '@emotion/react/macro';
import styled from '@emotion/styled/macro';
import { Icon } from '@mdi/react';
import { ColumnInstance } from 'react-table';
import { Variables } from '../base/variables';

const Cell = css`
    padding: 16px;
    line-height: normal;
    font-weight: normal;
    position: relative;
    transition: box-shadow 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    box-shadow: inset 0 0 0 0 transparent;
    color: ${Variables.Colors.n50};

    ${'' /* In this example we use an absolutely position resizer,
       so this is required. */}
    position: relative;

    :last-child {
        border-right: 0;
    }

    .resizer {
        display: inline-block;
        background-color: ${Variables.Colors.n50};
        width: 2px;
        padding: 0 5px;
        height: 100%;
        position: absolute;
        right: 5px;
        top: 0;
        transform: translateX(50%);
        z-index: 1;
        background-clip: content-box;

        ${'' /* prevents from scrolling while dragging on touch devices */}

        touch-action: none;

        &.isResizing {
            background-color: ${Variables.Colors.r50};
            background-clip: content-box;
        }
    }
`;

export const TableHead = styled.div`
    user-select: none;
    text-align: left;
`;

const TableRowHover = css`
    cursor: pointer;
    display: inline-flex !important;

    &:hover {
        background-color: ${Variables.Colors.n5};
    }
`;

export const TableRow = styled.div<{ hoverable?: boolean }>`
    ${props => (!!props.hoverable ? TableRowHover : undefined)}
`;

export const TableCell = styled.div`
    ${Cell}
    align-items: center;
    display: flex;
    overflow-y: auto;
`;

type TableHeaderProps = Pick<ColumnInstance, 'canSort' | 'isSorted' | 'maxWidth' | 'minWidth' | 'width'>;

export const TableHeader = styled.div<TableHeaderProps>`
    ${Cell}
    border-bottom: 1px solid ${Variables.Colors.n5};
    border-right: 1px solid ${Variables.Colors.n5};
    
    ${({ isSorted }) =>
        isSorted &&
        `
        color: ${Variables.Colors.n70};
        font-weight: 500;
    `};

    &:hover {
        color: ${({ canSort }) => canSort && Variables.Colors.p300};
    }
`;

export const Table = styled.div`
    width: 100%;
    overflow-x: auto;
`;

export const TableContainer = styled.div`
    position: relative;
    width: 100%;
    overflow-x: auto;
    box-shadow: 0 -0.5px 0 0 ${Variables.Colors.n15};
`;

export const TableBody = styled.div`
    ${TableRow} {
        box-shadow: 0 -0.5px 0 0 ${Variables.Colors.n15};
        max-width: unset !important;
        /* display: inline-flex !important; */
        min-width: 100% !important;
    }
    ${TableCell} {
        align-items: center;
        letter-spacing: 0.2px;
        color: ${Variables.Colors.n70};
        word-break: break-word;
    }
`;

export const TableWrapper = styled.section`
    position: relative;
`;

export const ControlsPanel = styled.section`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    padding: 16px 16px;
    /* min-height: 80px; */
    width: 100%;
`;

export const ActiveFiltersPanel = styled.section`
    &:not(:empty) {
        padding: 24px;
        padding-bottom: 0;
    }
`;

export const SortIcon = styled(Icon)`
    display: inline-block;
    font-weight: 900;
    position: relative;
    margin-left: 9px;
    vertical-align: middle;
    svg {
        fill: ${Variables.Colors.p75};
    }
`;

export const NavigationContainer = styled.section`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: relative;
    width: 100%;
    flex: 1;
    &:empty {
        padding: 0;
    }
`;

export const EmptyContainer = styled.section`
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const EmptyTitle = styled.section`
    font-size: 18px;
`;
