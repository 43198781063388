import { Location } from 'history';
import _ from 'lodash';
import qs from 'qs';

export const nonNumericKeyCodes = _.difference(
    _.times(256, _.identity),
    _.times(10, i => 48 + i),
);

export const parseQueryParams = <T>(location: Location, initial: T): T => {
    const params = location.search.substring(1);
    const parsed = qs.parse(params);
    const picked = _.pick(parsed, _.keys(initial));
    return _.defaults(picked, initial);
};

export const delayedRedirect = (redirect_uri: string, access_token?: string): void => {
    setTimeout(() => {
        const href = redirect_uri + (access_token ? `#code=${access_token}` : '');
        window.location.href = href;
    }, 500);
};

export const delayedReturnRedirect = (redirect_uri: string, flow?: string, access_token?: string): void => {
    setTimeout(() => {

        let href = redirect_uri + (access_token ? `#code=${access_token}` : '');

        //Special redirect for standard flows
        if(flow && flow === 'STANDARD') {
            const query = new URLSearchParams(location.search);
            const state = query.get('state');

            href = redirect_uri + (access_token ? `?state=${state}&code=${access_token}` : '');
        }

        window.location.href = href;
    }, 500);
};

export const handleFetch = async <T>(
    fetch: () => Promise<T>,
    errorHandler: (msg: string) => void,
): Promise<T | undefined> => {
    try {
        const result = await fetch();
        return result;
    } catch (err) {
        const json = await err.json();
        const { message, errors } = json;
        if (message) {
            errorHandler(message);
        } else if (errors) {
            errorHandler(Object.values(errors).join('\n'));
        } else {
            errorHandler('Error communicating with services. Please try again later.');
            // tslint:disable-next-line:no-console
            console.warn(err);
        }
    }
    return undefined;
};
