import * as React from 'react';
import * as S from './sidebar-item.styles';

export interface ISidebarItemProps {
    /**
     * Used for highlight the element (active link, etc.)
     */
    active?: boolean;
    onClick?: (e: React.MouseEvent) => void;
    className?: string;
}

export class SidebarItem extends React.PureComponent<ISidebarItemProps> {
    public render(): React.ReactElement {
        const { active, children, className, onClick } = this.props;
        const classes = [className];
        if (active) {
            classes.push('active');
        }
        return (
            <S.SidebarItem className={classes.join(' ')} onClick={onClick}>
                {children}
            </S.SidebarItem>
        );
    }
}
