import { mdiArrowBottomRight, mdiArrowTopRight, mdiChevronRight, mdiDotsVertical } from '@mdi/js';
import { Fade, Grow } from '@mui/material';
import * as d3 from 'd3';
import _ from 'lodash';
import * as React from 'react';
import { BlockMath } from 'react-katex';
import { formatDate, formatNumber } from '../../../services';
import { Popover, PopoverItem, Spinner, Tooltip } from '../../base';
import * as S from './metrics-card.styles';

export type Sentiment = 'good' | 'bad' | 'neutral';

export interface IDataset {
    metric: MetricType;
    error?: Error;
    value?: number;
    valueType?: 'Integer' | 'Float' | 'Pct' | 'Financial';
    delta?: number;
    sentiment?: Sentiment;
    formula?: string;
    subtitled?: boolean;
    linkText?: string;
    linkHref?: string;
}

export interface IMetricsCardProps {
    dataset?: IDataset;
    startDate: Date;
    endDate: Date;
    onMetricChange: (m: MetricType) => void;
}

export enum MetricType {
    CLAIMS_SUBMITTED = 'Claims Submitted',
    GROSS_CHARGE = 'Claims Charge',
    PAYER_EXPECTED = 'Payer Expected',
    GROSS_PAYER_PAID = 'Payer Paid',
    GROSS_PATIENT_PAID = 'Patient Paid',
}
export const MetricNames = new Map<MetricType, string>([
    [MetricType.CLAIMS_SUBMITTED, 'Claims Submitted'],
    [MetricType.GROSS_CHARGE, 'Claims Charge'],
    [MetricType.PAYER_EXPECTED, 'Payer Expected'],
    [MetricType.GROSS_PAYER_PAID, 'Payer Paid'],
    [MetricType.GROSS_PATIENT_PAID, 'Patient Paid'],
]);

interface IMetricsCardState {
    metric?: MetricType;
}

export class MetricsCard extends React.PureComponent<IMetricsCardProps, IMetricsCardState> {
    public render(): React.ReactElement {
        const { dataset } = this.props;
        if (!dataset) {
            return this.renderLoading();
        } else if (_.isError(dataset.error)) {
            return this.renderError(dataset);
        } else {
            return this.renderMetrics(dataset);
        }
    }
    public renderLoading(): React.ReactElement {
        return (
            <S.MetricsLoader size="small">
                <Spinner />
            </S.MetricsLoader>
        );
    }
    public renderError(dataset: IDataset): React.ReactElement {
        const { metric } = dataset;
        return (
            <S.MetricsError size="small">
                <S.Header key={`${metric}-header`}>
                    <S.Title>{metric}</S.Title>
                </S.Header>
                <S.Message>No data available</S.Message>
            </S.MetricsError>
        );
    }
    public renderMetrics(dataset: IDataset): React.ReactElement {
        const { delta, sentiment, subtitled, formula, metric } = dataset;
        return (
            <S.MetricsCard size="small">
                <S.Header key={`${metric}-header`}>
                    <S.Title>{metric}</S.Title>
                    <Popover align="right" renderButton={this.renderPopoverButton}>
                        {this.renderPopoverItems()}
                    </Popover>
                </S.Header>
                <Grow in={true} timeout={400}>
                    <S.Primary key={`${metric}-primary`}>
                        <S.Metric title={this.renderValue(dataset)}>{this.renderValue(dataset)}</S.Metric>
                        {formula && (
                            <S.StyledInfoIcon
                                variant="equation"
                                direction="bottom"
                                size="large"
                                tooltip={this.renderPrimaryTooltip(formula)}
                            />
                        )}
                    </S.Primary>
                </Grow>
                <Fade in={true} timeout={400}>
                    <S.Secondary key={`${metric}-secondary`}>
                        <S.Description>
                            {subtitled && (
                                <Tooltip variant="equation" direction="bottom" title={this.renderDescriptionTooltip()}>
                                    {this.priorLabel}
                                </Tooltip>
                            )}
                        </S.Description>
                        <S.Delta>
                            {this.renderDeltaIcon(dataset)}
                            <S.DeltaValue sentiment={sentiment}>{formatNumber('Delta')(delta)}</S.DeltaValue>
                        </S.Delta>
                        {this.renderLink(dataset)}
                    </S.Secondary>
                </Fade>
            </S.MetricsCard>
        );
    }
    private get priorLabel(): string {
        const { startDate, endDate } = this.props;
        const count = d3.utcDay.count(startDate, endDate) + 1;
        return `vs last ${count} days`;
    }
    private get currentPeriod(): string {
        const from = formatDate('MonthDayYear')(this.props.startDate);
        const to = formatDate('MonthDayYear')(this.props.endDate);
        return `${from} – ${to}`;
    }
    private get priorPeriod(): string {
        const { startDate, endDate } = this.props;
        const period = d3.utcDay.count(startDate, endDate) + 1;
        const priorStart = d3.utcDay.offset(startDate, -period);
        const priorEnd = d3.utcDay.offset(endDate, -period);
        const from = formatDate('MonthDayYear')(priorStart);
        const to = formatDate('MonthDayYear')(priorEnd);
        return `${from} – ${to}`;
    }
    private readonly handleLinkClick = (linkHref: string): (() => void) => {
        return (): void => {
            return;
        };
    };
    private readonly renderValue = (dataset: IDataset): string | undefined => {
        const { valueType, value } = dataset;
        if (valueType && !_.isUndefined(value)) {
            return formatNumber(valueType)(value);
        }
    };
    private renderPrimaryTooltip(formula: string): React.ReactElement {
        return (
            <div>
                <S.TooltipHeader>Within {this.currentPeriod}</S.TooltipHeader>
                <S.TooltipFormula>
                    <BlockMath math={formula} />
                </S.TooltipFormula>
            </div>
        );
    }
    private renderDescriptionTooltip(): React.ReactElement {
        return (
            <S.DescriptionTooltip>
                Compared to <S.DescriptionDates>{this.priorPeriod}</S.DescriptionDates>
            </S.DescriptionTooltip>
        );
    }
    private readonly renderDeltaIcon = (dataset: IDataset): React.ReactElement | undefined => {
        const { delta } = dataset;
        if (delta !== undefined && delta !== 0) {
            const deltaIcon = delta > 0 ? mdiArrowTopRight : mdiArrowBottomRight;
            return <S.DeltaIcon sentiment={dataset.sentiment} path={deltaIcon} />;
        }
    };
    private readonly renderLink = (dataset: IDataset): React.ReactElement | undefined => {
        const { linkHref, linkText } = dataset;
        // FIXME: suppress link until reporting page available
        if (false && linkHref && linkText) {
            return (
                <S.Link onClick={this.handleLinkClick(linkHref as string)}>
                    {linkText}
                    <S.LinkIcon path={mdiChevronRight} />
                </S.Link>
            );
        }
        return undefined;
    };
    private renderPopoverButton(): React.ReactElement {
        return <S.OptionsIcon path={mdiDotsVertical} />;
    }
    private readonly renderPopoverItems = (): React.ReactElement[] => {
        const handler = (metric: MetricType): (() => void) => {
            return (): void => {
                this.props.onMetricChange(metric);
                this.setState({
                    metric,
                });
            };
        };
        const currMetric: MetricType | undefined = _.get(this.props, 'dataset.metric', undefined);
        return _.map<MetricType, React.ReactElement>(
            _.values(MetricType) as MetricType[],
            (metric: MetricType): React.ReactElement => (
                <PopoverItem
                    key={metric}
                    onClick={handler(metric)}
                    selected={metric === currMetric}
                    disabled={metric === currMetric}>
                    {MetricNames.get(metric)}
                </PopoverItem>
            ),
        );
    };
}
