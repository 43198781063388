import { PaymentStatusType } from '../../api';

export function getPaymentIndex(status?: PaymentStatusType) {
    if (!status) {
        return;
    }

    const statuses: Partial<Record<PaymentStatusType, number>> = {
        [PaymentStatusType.PROCESSEDASPRIMARY]: 0,
        [PaymentStatusType.PROCESSEDASPRIMARYFORWARDEDTOADDITIONALPAYERS]: 0,
        [PaymentStatusType.PROCESSEDASSECONDARY]: 1,
        [PaymentStatusType.PROCESSEDASSECONDARYFORWARDEDTOADDITIONALPAYERS]: 1,
        [PaymentStatusType.PROCESSEDASTERTIARY]: 2,
        [PaymentStatusType.PROCESSEDASTERTIARYFORWARDEDTOADDITIONALPAYERS]: 2,
    };

    return statuses[status];
}
