import { css } from '@emotion/react/macro';
import styled from '@emotion/styled/macro';
import { Variables } from '../variables';

interface IButtonProps {
    selected?: boolean;
}

export const Container = styled.div`
    display: flex;
    align-items: center;
`;

const UnselectedButton = css`
    cursor: pointer;

    font-weight: 500;
    color: ${Variables.Colors.n50};
    background-color: ${Variables.Colors.white};

    &:hover {
        background-color: ${Variables.Colors.n15_24};
    }

    &:active,
    &:focus {
        color: ${Variables.Colors.n100};
        background-color: ${Variables.Colors.n15_48};
        border: ${Variables.Borders.regular};
        &:not(:last-child) {
            border-right: none;
        }
    }
    &:disabled {
        color: ${Variables.Colors.n15};
        &:hover {
            background-color: ${Variables.Colors.white};
        }
    }
`;
const SelectedButton = css`
    font-weight: 500;
    color: ${Variables.Colors.n100};
    background-color: ${Variables.Colors.n15_32};
    &:disabled {
        color: ${Variables.Colors.n15};
    }
`;
export const Button = styled.button`
    display: flex;
    flex: 1 1;
    justify-content: center;

    font-size: 15px;
    padding: 6px 20px;

    user-select: none;
    outline: none;

    border: 1px solid ${Variables.Colors.n15};

    &:first-of-type {
        border-top-left-radius: ${Variables.Rounded.small};
        border-bottom-left-radius: ${Variables.Rounded.small};
    }
    &:last-child {
        border-top-right-radius: ${Variables.Rounded.small};
        border-bottom-right-radius: ${Variables.Rounded.small};
    }
    &:not(:last-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-right: none;
    }
    &:not(:first-of-type) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }

    ${(props: IButtonProps) => (props.selected ? SelectedButton : UnselectedButton)}

    transition: all 0.1s linear;
`;
