import { CssBaseline, Grid } from '@mui/material';
import React from 'react';
import enterLogo from './enter-logo.svg';
import * as S from './side-image-wrapper.styles';

interface ISideImageWrapperProps {
    children?: React.ReactNode;
}

export const SideImageWrapper: React.FunctionComponent<ISideImageWrapperProps> = (
    props: ISideImageWrapperProps,
): React.ReactElement => {
    const { children } = props;
    const url = 'https://enter.health'; // TODO: consider env
    return (
        <S.SideImageGrid item={true} xs={12} container={true}>
            <CssBaseline />

            <S.SideImage item={true} xs={false} sm={2} md={2} lg={4}>
                <S.UpperLeftLogoHolder>
                    <S.HeaderLink href={url}>
                        <S.UpperLeftLogo alt="logo" src={enterLogo} />
                    </S.HeaderLink>
                </S.UpperLeftLogoHolder>
            </S.SideImage>
            <Grid item={true} xs={12} sm={8} md={8} lg={4}>
                <S.PaperCell>{children}</S.PaperCell>
            </Grid>
            <S.SideImage item={true} xs={false} sm={2} md={2} lg={4} />
        </S.SideImageGrid>
    );
};
