const Colors = {
    sunset500: '#ff7733',
    sunset400: '#ff8a4f',
    sunset300: '#ffaa80',
    sunset200: '#ffcab0',
    sunset100: '#ffeadd',
    sunset75: '#fff3ed',
    sunset50: '#fffbf9',

    b500: '#164dba',
    b400: '#1e57cf',
    b300: '#2e6be6',
    b300_l62: '#5284ea',
    b300_l70: '#769eef',
    b300_l76: '#91b2f2',
    b300_l90: '#d1dffa',
    b300_l96: '#edf2fd',
    b200: '#4888e5',
    b100: '#72b0e8',
    b75: '#acd9ef',
    b50: '#ebf7fa',

    g500: '#033331',
    g400: '#043b38',
    g300: '#064b42',
    g200: '#0f6453',
    g100: '#298c6b',
    g75: '#76bfa0',
    g50: '#e8f7ee',

    p600: '#112d41',
    p600_24: 'rgba(17, 45, 65, 0.24)',
    p600_32: 'rgba(17, 45, 65, 0.32)',
    p600_56: 'rgba(17, 45, 65, 0.56)',
    p500: '#153851',
    p400: '#153b55',
    p300: '#1e4463',
    p200: '#2f5678',
    p100: '#56799a',
    p100_l90: '#dee6ed',
    p75: '#99b0c9',
    p60: '#dbeaff',
    p50: '#f5f9ff',

    n100: '#1d2527',
    n85: '#3f4547',
    n70: '#606667',
    n50: '#8e9293',
    n30: '#bbbdbe',
    n15: '#dddfdf',
    n15_48: 'rgba(221, 223, 223, 0.48)',
    n15_32: 'rgba(221, 223, 223, 0.32)',
    n15_24: 'rgba(221, 223, 223, 0.24)',
    n15_16: 'rgba(221, 223, 223, 0.16)',
    n5: '#f3f4f4',

    nx100: 'rgba(15, 15, 15, 1)',
    nx48: 'rgba(15, 15, 15, 0.48)',
    nx24: 'rgba(15, 15, 15, 0.24)',
    nx16: 'rgba(15, 15, 15, 0.16)',
    nx12: 'rgba(15, 15, 15, 0.12)',
    nx8: 'rgba(15, 15, 15, 0.08)',
    nx4: 'rgba(15, 15, 15, 0.04)',

    amber30: '#ffaa00',
    amber20: '#ec890c',
    amber10: '#fbdfbb',

    subG30: '#3ba542',
    subG20: '#24916d',
    subG10: '#5cd6ad',

    r50: '#e93c3c',
    r40: '#f07575',
    r30: '#f5677c',
    r20: '#f99fad',
    r10: '#fde7e9',

    i20: '#8d9ee7',
    i10: '#c0c9f2',

    subS10: '#ffbb99',

    rose20: '#ba3b79',
    rose10: '#da8bb2',

    royalBlue: 'rgba(46, 107, 230, 1)', // b300
    mistyRose: 'rgba(255, 234, 221, 1)', // r100/sunset100
    blackPearl: 'rgba(29, 37, 39, 1)', // n100
    coral: 'rgba(255, 138, 79, 1)', // r400
    prussianBlue: 'rgba(22, 59, 85, 1)', // b500
    seaGreen: 'rgba(41, 140, 107, 1)', // g100
    platinum: '#e6e7e8', // rgba(230, 231, 232, 1)
    ghostWhite: '#fafbfc', // rgba(250, 251, 252, 1)
    coolGrey: '#8b9599', // rgba(139, 149, 153, 1)

    white: '#ffffff',
    warning: '#f25d66', // rgba(242, 93, 102, 1)
    successful: '#70d4be', // rgba(112, 212, 90, 1)
};

const Gradients = {
    r100_r75: 'linear-gradient(to top, #fff3ed, #ffeadd)',
};

const Shadows = {
    border: `0 0 0 1px ${Colors.nx4}`,
    small: `0 8px 12px 0 ${Colors.nx4}`,
    medium: `0 4px 8px 0 ${Colors.nx8}`,
    large: `0 4px 8px 0 ${Colors.nx4}, 0 12px 24px 0 ${Colors.nx16}`,
    xlarge: `0 4px 8px 0 ${Colors.nx8}, 0 16px 32px 0 ${Colors.nx16}`,
};

const Rounded = {
    xsmall: '2px',
    small: '3px',
    medium: '6px',
    large: '12px',
    xlarge: '24px',
};

const Borders = {
    regular: `1px solid ${Colors.nx8}`,
};

const Fonts = {
    calibre:
        '"Calibre", -apple-system, BlinkMacSystemFont, "San Francisco", "Segoe UI", Roboto, "Helvetica Neue", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
};

const FontSizes = {
    xxxlarge: '48px',
    xxlarge: '36px',
    xlarge: '28px',
    large: '24px',
    medium: '20px',
    small: '18px',
    xsmall: '16px',
    tiny: '14px',
};
const FontWeight = {
    regular: '400',
    medium: '500',
    semibold: '600',
};

export const Variables = {
    Colors,
    Gradients,
    Shadows,
    Rounded,
    Borders,
    Fonts,
    FontSizes,
    FontWeight,

    backgroundColor: `${Colors.ghostWhite}`,
    baseFontSize: '15px',
    fontWeightMedium: '500',
    transition: 'all 0.2s linear',
    sidebarSize: '248px',
    sidebarPadding: '72px',
    topnavSize: '64px',
};
