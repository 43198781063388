import { mdiHelpCircle, mdiKeyboardReturn, mdiMagnify, mdiSwapHorizontal, mdiSwapVertical } from '@mdi/js';
import { PopperPlacementType } from '@mui/material';
import * as React from 'react';
import { Spinner } from '../base';
import * as S from './search-bar-overlay.styles';

export interface ISearchBarOverlayProps {
    anchor: React.RefObject<HTMLDivElement>;
    disabled?: boolean;
    open: boolean;
    text: string;
    loading?: boolean;
    controls?: React.ReactNode;
    recent?: React.ReactNode;
    results?: React.ReactElement[];
    noResults?: React.ReactNode;
    placement?: PopperPlacementType;
    disableEscapeClose?: boolean;
}

export class SearchBarOverlay extends React.Component<ISearchBarOverlayProps> {
    private bodyRef: React.RefObject<HTMLDivElement>;
    constructor(props: ISearchBarOverlayProps) {
        super(props);
        this.bodyRef = React.createRef();
    }
    public render(): React.ReactNode {
        const {
            open,
            anchor,
            loading,
            results,
            text,
            controls,
            recent,
            noResults = this.renderNoResults(),
            placement = 'bottom-start',
            disableEscapeClose,
        } = this.props;

        return (
            <S.WrappedPopper open={open} anchorEl={anchor.current} placement={placement}>
                <div>
                    {controls}
                    <S.Body ref={this.bodyRef}>
                        {loading && <Spinner />}
                        {!loading && !!results && results}
                        {!loading && !results && !text && recent}
                        {!loading && !results && text && noResults}
                    </S.Body>
                    <S.Footer>
                        {controls && (
                            <>
                                <S.FooterText>
                                    Shift + <S.FooterIcon path={mdiSwapHorizontal} size="18px" />
                                </S.FooterText>{' '}
                                to switch modes
                            </>
                        )}
                        <S.FooterIcon path={mdiSwapVertical} size="18px" /> to select
                        <S.FooterIcon path={mdiKeyboardReturn} size="18px" /> to open
                        <S.Spacer />
                        {!disableEscapeClose && (
                            <>
                                <S.FooterText>Esc</S.FooterText> to close
                            </>
                        )}
                    </S.Footer>
                </div>
            </S.WrappedPopper>
        );
    }

    private readonly renderNoResults = (): React.ReactElement => {
        return (
            <>
                <S.Related>
                    <S.RelatedIcon path={mdiMagnify} size={1} />
                    <S.RelatedText>No recent claims or patients that match your search.</S.RelatedText>
                </S.Related>
                <S.Spacer />
                <S.Related>
                    <S.RelatedIcon path={mdiHelpCircle} size={1} />
                    <S.RelatedText>Searching by patient name? Try "firstname lastname".</S.RelatedText>
                </S.Related>
            </>
        );
    };
}
