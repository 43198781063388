import { css } from '@emotion/react/macro';
import styled from '@emotion/styled/macro';
import Icon from '@mdi/react';
import { Variables } from '../variables';

interface ICheckboxStyledProps {
    checked?: boolean;
    disabled?: boolean;
}

export const CheckIcon = styled(Icon)`
    path {
        fill: ${Variables.Colors.white};
    }
`;

// Hide checkbox visually but remain accessible to screen readers.
// Source: https://polished.js.org/docs/#hidevisually
export const HiddenCheckbox = styled.input`
    border: 0;
    clip: rect(0 0 0 0);
    clippath: inset(50%);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    white-space: nowrap;
    width: 1px;
`;

HiddenCheckbox.defaultProps = { type: 'checkbox' };

export const StyledCheckbox = styled.div<{ variant: 'medium' | 'large' }>`
    display: inline-block;
    vertical-align: middle;
    width: ${({ variant }) => (variant === 'medium' ? '18px' : '24px')};
    height: ${({ variant }) => (variant === 'medium' ? '18px' : '24px')};
    background: ${Variables.Colors.white};
    border: 1px solid ${Variables.Colors.n15};
    border-radius: ${Variables.Rounded.small};
    transition: all 150ms;
    user-select: none;
    ${CheckIcon} {
        height: ${({ variant }) => (variant === 'medium' ? '16px' : '22px')};
        width: ${({ variant }) => (variant === 'medium' ? '16px' : '22px')};
    }
`;

export const Label = styled.span<{ variant: 'medium' | 'large' }>`
    margin-left: 8px;
    user-select: none;
    vertical-align: middle;
    font-size: ${({ variant }) => (variant === 'medium' ? '15px' : '18px')};
    color: ${Variables.Colors.n70};
`;

const checkedCheckbox = css`
    ${StyledCheckbox} {
        border: 1px solid ${Variables.Colors.n70};
        background: ${Variables.Colors.n70};
    }
`;

const disabledCheckbox = css`
    cursor: default;
    ${StyledCheckbox} {
        border: ${Variables.Borders.regular};
        background: ${Variables.Colors.n15};
    }
`;

export const CheckboxContainer = styled.label<ICheckboxStyledProps>`
    display: inline-block;
    align-items: center;
    cursor: pointer;
    ${props => (props.checked ? checkedCheckbox : null)}
    ${props => (props.disabled ? disabledCheckbox : null)}
    ${StyledCheckbox} {
        &:hover {
            border: 1px solid ${Variables.Colors.b300};
            box-shadow: ${Variables.Shadows.medium};
        }
    }

    ${CheckIcon} {
        visibility: ${(props): string => (props.checked ? 'visible' : 'hidden')};
    }
`;
