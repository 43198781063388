import styled from '@emotion/styled/macro';
import { BaseButton } from '../button';
import { Variables } from '../variables';

export const DatePicker = styled.span`
    display: inline-block;
`;
export const DatePickerWrapper = styled.div`
    .DayPicker-Caption {
        /* Replace with custom NavbarElement */
        display: none;
    }

    .DayPicker-wrapper {
        &:focus {
            outline: none;
        }
    }

    .DayPicker-wrapper {
        padding: 0;
    }

    .DayPickerInput {
        width: 100%;
    }

    .DayPicker-Month {
        border-spacing: 5px;
        border-collapse: separate;
        margin: 0 16px 16px 16px;
    }

    .DayPicker-Weekday {
        padding: 0;
    }

    .DayPicker-Day {
        width: 24px;
        height: 24px;
        font-size: 16px;
        font-weight: 500;
        color: ${Variables.Colors.n70};
        padding: 0 4px;
        margin: 10px 7px;
        &:focus {
            outline: none;
        }

        &--today {
            border-radius: 50%;
            background-color: #ffe7db;
        }

        &--outside {
            color: ${Variables.Colors.n30};
        }

        &--disabled {
            color: ${Variables.Colors.n30};
        }
    }
`;

export const Navbar = styled.div``;

export const RangeNavbar = styled.div`
    border-bottom: ${Variables.Borders.regular};
    padding: 12px 24px;
    display: flex;
    flex-direction: column;
`;

export const RangeItem = styled.div`
    display: flex;
    align-items: center;

    &:last-of-type {
        margin-top: 8px;
    }
`;

export const RangeLabel = styled.div`
    font-size: 16px;
    font-weight: 500;
    color: ${Variables.Colors.n30};
    width: 32px;
    text-align: right;
`;

const RangeValue = styled.div`
    margin-left: 8px;
    width: 144px;
    height: 24px;
    line-height: 24px;
    border-radius: 12px;
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    color: ${Variables.Colors.n70};
`;

export const RangeValueFrom = styled(RangeValue)`
    background-color: #ffe7db;
`;

export const RangeValueTo = styled(RangeValue)`
    background-color: #dbe5fb;
`;

export const Controls = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 24px;
`;

export const Title = styled.div`
    font-size: 16px;
    font-weight: 500;
    color: ${Variables.Colors.n70};
`;

export const ButtonsWrapper = styled.div``;

export const IconButton = styled(BaseButton)`
    min-width: auto;
    padding: 0;
    fill: ${Variables.Colors.n30};

    &:hover {
        fill: ${Variables.Colors.n70};
    }
`;
