import styled from '@emotion/styled/macro';
import { Icon } from '@mdi/react';
import { BaseButton, Variables } from '../../base';

export const Header = styled.div`
    display: flex;
    align-items: center;
    height: 80px;
    box-shadow: ${Variables.Shadows.border};

    padding: 20px 20px 16px 32px;
`;

export const Title = styled.div`
    display: inline-flex;
    flex-grow: 1;
    font-size: 26px;
    font-weight: 600;
    line-height: 1.19;
    letter-spacing: 0.2px;
    color: ${Variables.Colors.n100};
`;

export const CloseButton = styled(BaseButton)`
    border: 0;
    min-width: 0;
    padding: 0 8px;
`;

export const CloseIcon = styled(Icon)`
    fill: ${Variables.Colors.n30};
`;
