import * as React from 'react';
import * as S from './card.styles';

export type CardSize = 'small' | 'medium' | 'large';

export interface ICardProps {
    size: CardSize;
    children?: React.ReactNode;
    className?: string;
}

export class Card extends React.PureComponent<ICardProps> {
    public render(): React.ReactElement {
        const { children, className, size } = this.props;
        return (
            <S.Card className={className} size={size}>
                {children}
            </S.Card>
        );
    }
}
