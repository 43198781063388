import styled from '@emotion/styled/macro';
import { Icon } from '@mdi/react';
import { Variables } from '../base';
import { InfoIcon } from '../info-icon';
import { Card } from './card';

export const TitleCard = styled(Card)`
    padding: 24px 0;
    display: flex;
    flex-direction: column;
`;

export const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 24px;
`;

export const Title = styled.span`
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-transform: uppercase;
    font-size: 15px;
    font-weight: 500;
    line-height: 1;
    letter-spacing: 0.5px;
    color: ${Variables.Colors.p300};
`;

export const StyledInfoIcon = styled(InfoIcon)`
    margin-left: 4px;
`;

export const InfoTooltip = styled.span``;

export const Caption = styled.span`
    font-size: 15px;
    font-weight: normal;
    line-height: 1;
    letter-spacing: 0.5px;
    text-transform: none;
    color: ${Variables.Colors.n50};
`;

export const OptionsLink = styled.a`
    cursor: pointer;
    &:hover {
        fill: ${Variables.Colors.n50};
    }
    &:active {
        fill: ${Variables.Colors.n30};
    }
`;

export const OptionsIcon = styled(Icon)`
    height: 20px;
    width: 20px;
    margin-top: 2px;
    fill: ${Variables.Colors.nx24};
`;
