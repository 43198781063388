import { css } from '@emotion/react/macro';
import styled from '@emotion/styled/macro';
import Icon from '@mdi/react';
import { Variables } from '../variables';

interface IPopoverItemProps {
    selectable?: boolean;
    selected?: boolean;
    disabled?: boolean;
}

export const SelectedIcon = styled(Icon)`
    display: inline-block;
    position: absolute;
    left: 16px;
    background-size: 18px auto;
    height: 18px;
    width: 18px;
    path {
        fill: ${Variables.Colors.b500};
    }
`;

const popoverItemActive = css`
    &:hover {
        cursor: pointer;
        background-color: ${Variables.Colors.n15_24};
    }
    &:active {
        color: ${Variables.Colors.b500};
    }
`;

const popoverItemDisabled = css`
    color: ${Variables.Colors.n15};
`;

export const PopoverItem = styled.div<IPopoverItemProps>`
    position: relative;
    user-select: none;
    white-space: nowrap;
    line-height: 16px;
    font-size: 16px;
    font-weight: ${(props): string => (props.selected ? '500' : 'normal')};
    color: ${(props): string => (props.selected ? Variables.Colors.b300 : Variables.Colors.n70)};
    padding: ${(props): string => (props.selectable ? '8px 24px 8px 42px' : '8px 16px')};
    ${props => {
        return props.disabled ? popoverItemDisabled : popoverItemActive;
    }}
`;
