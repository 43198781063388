import styled from '@emotion/styled/macro';
import { Variables } from '../variables';

export const PopoverDividerWrapper = styled.div`
    margin: 8px 0 16px 0;
`;

export const PopoverDivider = styled.div`
    border-bottom: ${Variables.Borders.regular};
    position: absolute;
    left: 0;
    width: 100%;
`;
