import React from 'react';
import * as S from './badge.styles';
import { BadgeType } from './badge.styles';

interface IClaimTypeBadge {
    type?: BadgeType;
    size?: 'small';
    title?: string;
    action?: { onClick: () => void; Icon: React.ReactElement };
}
export const Badge: React.FC<IClaimTypeBadge> = props => {
    const { children, type, size, title, action } = props;

    return (
        <S.Badge type={type} size={size} onClick={action?.onClick} title={title}>
            <S.BadgeBody>{children}</S.BadgeBody>
            {action && <S.ActionButton>{action.Icon}</S.ActionButton>}
        </S.Badge>
    );
};
