import _ from 'lodash';
import React from 'react';
import { ClaimType } from '../../../api';
import { AuthContext } from '../../../services';
import { RangeOptionType } from '../../date-range';
import { IDataset, MetricsCard, MetricType } from '../metrics-card';
import * as S from './metrics-bar.styles';

interface IMetricsBarProps {
    dateRangeOption: RangeOptionType;
    dateRange: [Date, Date];
    claimTypes: ClaimType[];
    metrics: MetricType[];
    datasets: IDataset[];
    loadMetricsData: (metrics: MetricType[], startDate: Date, endDate: Date, claimTypes: ClaimType[]) => Promise<void>;
    replaceMetric: (index: number, metric: MetricType) => void;
}

export class MetricsBar extends React.PureComponent<IMetricsBarProps> {
    public static contextType = AuthContext;

    public componentDidMount(): void {
        this.context.setGroup('metrics-group');
        this.fetchMetricsData();
    }

    public componentDidUpdate(prevProps: IMetricsBarProps) {
        if (
            !_.isEqual(prevProps.dateRange, this.props.dateRange) ||
            !_.isEqual(prevProps.claimTypes, this.props.claimTypes)
        ) {
            this.fetchMetricsData();
        }
    }

    public componentWillUnmount(): void {
        this.context.cancelGroup('metrics-group');
    }

    public render(): React.ReactElement {
        return this.renderMetrics();
    }

    private fetchMetricsData(): void {
        const { dateRange, claimTypes, metrics, loadMetricsData } = this.props;
        const [startDate, endDate] = dateRange;
        loadMetricsData(metrics, startDate, endDate, claimTypes);
    }

    private readonly renderWarning = (): React.ReactElement => {
        return (
            <S.MetricsWarning>
                Sorry! We don’t support custom date ranges for metrics cards, please select date-range presets to view
                results.
            </S.MetricsWarning>
        );
    };
    private readonly renderMetrics = (): React.ReactElement => {
        const { dateRange, metrics, datasets } = this.props;
        const [startDate, endDate] = dateRange;
        const cards = _.map(metrics.slice(0, 4), (metric: MetricType, i: number) => {
            const dataset = _.find(datasets, { metric });
            return (
                <MetricsCard
                    startDate={startDate}
                    endDate={endDate}
                    key={`metric-${metric}-${i}`}
                    dataset={this.isLoading(metric) ? undefined : dataset}
                    onMetricChange={this.changeMetric(i)}
                />
            );
        });
        return <S.MetricsBar>{cards}</S.MetricsBar>;
    };
    private readonly changeMetric = (index: number): ((m: MetricType) => void) => {
        return (metric: MetricType): void => {
            this.props.replaceMetric(index, metric);
            this.fetchMetricsData();
        };
    };
    private readonly isLoading = (metric: MetricType): boolean => {
        return !_.some(this.props.datasets, { metric });
    };
}
