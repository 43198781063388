import { Fade } from '@mui/material';
import React, { useEffect, useState } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import { TooltipEntity } from './tooltip-entity';
import * as S from './tooltip.styles';

export type TooltipDirection = 'top' | 'right' | 'bottom' | 'left';
export type TooltipSize = 'large' | 'medium';
export type TooltipTheme = 'light' | 'dark' | 'equation';

export interface ITooltipProps {
    children?: React.ReactNode;
    className?: string;
    direction: TooltipDirection;
    open?: boolean;
    size?: TooltipSize;
    variant?: TooltipTheme;
    title?: React.ReactElement;
}

export interface ITooltipState {
    open: boolean;
}

export const Tooltip: React.FunctionComponent<ITooltipProps> = (props: ITooltipProps): React.ReactElement => {
    const tooltipRef: React.RefObject<HTMLElement> = React.createRef();
    const { children, className, direction, size, variant, title } = props;
    const [open, setOpen] = useState(!!props.open);

    useEffect(() => {
        setOpen(!!props.open);
    }, [props.open]);

    const show = (event: React.MouseEvent | React.FocusEvent): void => {
        event.stopPropagation();
        setOpen(true);
    };
    const hide = (event: React.MouseEvent | React.FocusEvent): void => {
        event.stopPropagation();
        setOpen(false);
    };

    return (
        <>
            <S.TooltipWrapper
                ref={tooltipRef}
                className={className}
                onMouseEnter={show}
                onMouseLeave={hide}
                onFocus={show}
                onBlur={hide}>
                {children}
            </S.TooltipWrapper>
            {!!title && (
                <TooltipEntity open={open} direction={direction} size={size} variant={variant} anchor={tooltipRef}>
                    <OutsideClickHandler onOutsideClick={hide} display="flex">
                        <Fade in={open} timeout={{ enter: 300, exit: 0 }}>
                            {title}
                        </Fade>
                    </OutsideClickHandler>
                </TooltipEntity>
            )}
        </>
    );
};
