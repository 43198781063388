import styled from '@emotion/styled/macro';
import { forTabletPortraitDown } from '@enter/core';

export const ProviderLogo = styled.img`
    width: 26px;
    height: 30px;
    margin-bottom: 22px;
`;

export const SignInHeader = styled.div`
    height: 40px;
    font-size: 40px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 1;
    letter-spacing: 0.2px;
    margin-bottom: 16px;

    ${forTabletPortraitDown(`
        height: auto;
    `)}
`;

export const InstructionText = styled.div`
    margin-bottom: 16px;
`;

export const BoldInstructionText = styled.div`
    font-weight: bold;
    margin-bottom: 16px;
`;
