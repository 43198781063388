import styled from '@emotion/styled/macro';
import { Icon } from '@mdi/react';
import { BaseButton, Variables } from '../../base';
import { SearchBar } from '../../search-bar';

export const Search = styled.div`
    background-color: ${Variables.Colors.white};
`;

export const Caption = styled.div`
    font-size: 18px;
    line-height: 1.22;
    color: ${Variables.Colors.n70};
`;

export const SearchInput = styled(SearchBar)`
    min-width: 100px;
    margin-top: 8px;
`;

export const SearchResult = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 6px;

    cursor: pointer;
    padding: 4px;
    background-color: ${Variables.Colors.white};

    &:hover {
        background-color: ${Variables.Colors.n15_24};
    }
    &:active {
        background-color: ${Variables.Colors.n15_48};
    }
`;
export const SearchResultRow = styled.div`
    display: flex;

    font-size: 14px;
    margin-top: 4px;
    color: ${Variables.Colors.n50};
`;
export const SearchResultCell = styled.div`
    padding: 0px 4px;
`;

export const Controls = styled.div`
    display: flex;
    align-items: baseline;
    position: absolute;
    right: 0;
`;
export const Spacer = styled.span`
    flex-grow: 1;
`;

export const CloseIcon = styled(Icon)`
    fill: ${Variables.Colors.nx48};
`;

export const CloseButton = styled(BaseButton)`
    border: 0;
    min-width: 0;
    padding: 0 8px;
`;
