import { mdiCheck } from '@mdi/js';
import * as React from 'react';
import { Spinner } from '../spinner';
import * as S from './popover-item.styles';

export interface IPopoverItemProps {
    className?: string;
    selected?: boolean;
    disabled?: boolean;
    pinned?: boolean;
    selectable?: boolean;
    title?: string;
    onClick?: (e: React.MouseEvent) => Promise<void> | void;
    closeMenu?: () => void;
}

export const PopoverItem: React.FunctionComponent<IPopoverItemProps> = ({
    selectable,
    title,
    selected,
    disabled,
    className,
    onClick,
    closeMenu,
    pinned,
    children,
}): React.ReactElement => {
    const [isDisabled, setIsDisabled] = React.useState(disabled);
    const [isLoading, setIsLoading] = React.useState(false);

    React.useEffect(() => {
        setIsDisabled(disabled);
    }, [disabled]);

    const handleClick = async (e: React.MouseEvent): Promise<void> => {
        if (onClick && !isDisabled) {
            setIsLoading(true);
            try {
                await onClick(e);
            } finally {
                setIsLoading(false);

                if (closeMenu && !pinned) {
                    closeMenu();
                }
            }
        }
    };

    return (
        <S.PopoverItem
            selectable={selectable}
            selected={selected}
            disabled={isDisabled || isLoading}
            onClick={handleClick}
            title={title}
            className={className}>
            {selectable && selected && <S.SelectedIcon path={mdiCheck} />}
            {isLoading ? <Spinner size="16px" /> : children}
        </S.PopoverItem>
    );
};
