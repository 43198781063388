import styled from '@emotion/styled/macro';
import { BaseInput, Variables } from '@enter/core';

export const Input = styled(BaseInput)`
    padding: 20px 14px;
    font-size: 16px;
    margin-top: 4px;
    border-color: ${Variables.Colors.n30};
`;

export const Label = styled.label<{ align?: string }>`
    color: ${Variables.Colors.n70};
    ${({ align }) => (align === 'right' ? 'float: right;' : '')}
`;

export const Error = styled.div`
    margin-top: 4px;
    color: ${Variables.Colors.warning};
`;
