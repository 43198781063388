import styled from '@emotion/styled/macro';
import { Variables } from '../../base';

interface IWrapperProps {
    disabled?: boolean;
    isNote?: boolean;
}
export const Wrapper = styled.div<IWrapperProps>`
    display: flex;

    border: 1px solid ${Variables.Colors.n85};
    border-radius: 6px;
    ${(props: IWrapperProps) =>
        props.disabled &&
        `
        background-color: ${Variables.Colors.n15_24};
        border-color: ${Variables.Colors.n15};
    `}
    ${(props: IWrapperProps) =>
        props.isNote &&
        `
        background-color: ${Variables.Colors.b300_l96};
        border-color: ${Variables.Colors.b300_l90};
        input {
            color:  ${Variables.Colors.b300_l62};
        }
    `}
`;

export const Input = styled.input`
    flex-grow: 1;

    background-color: transparent;
    border: none;

    outline: none;
    flex-grow: 1;
    height: auto;
    font-size: 15px;

    min-height: 24px;
    resize: none;

    padding: 8px 16px;

    &:disabled {
        border-color: ${Variables.Colors.n15};
    }
`;
