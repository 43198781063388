import { mdiChevronDown, mdiInformationOutline } from '@mdi/js';
import { ClickAwayListener } from '@mui/material';
import copy from 'copy-text-to-clipboard';
import _ from 'lodash';
import React, { useContext, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ToastContext } from '../../services';
import * as S from './action-menu-badge.styles';

export interface IActionMenuBadgeAction {
    label: string;
    action: () => void | Promise<void>;
    tooltip?: string;
}
export type IActionMenuBadgeDivider = 'divider';
export type IActionMenuBadgeItem = IActionMenuBadgeAction | IActionMenuBadgeDivider;

export interface IActionMenuBadgeProps {
    className?: string;

    // id of resource
    id?: string;

    // label for button
    label: string;

    // path is basis for default actions
    path?: string;

    // optional tooltip overrride
    tooltip?: string;

    // props for secondary (popover) button
    items?: IActionMenuBadgeItem[];

    // optional icon symbol
    Symbol?: React.ReactNode;
}

export const ActionMenuBadge: React.FunctionComponent<IActionMenuBadgeProps> = (
    props: IActionMenuBadgeProps,
): React.ReactElement => {
    const { id, className, label, path, tooltip, items, Symbol } = props;

    const context = useContext(ToastContext);
    const anchorRef = useRef<HTMLDivElement | null>(null);
    const [isShowMenu, setShowMenu] = useState(false);
    const history = useHistory();

    const defaultItems: IActionMenuBadgeItem[] = [];
    if (id) {
        defaultItems.push({
            label: 'Copy ID',
            action: () => {
                copy(id);
                context.notify?.(`ID ${id} copied to the clipboard`, 'left');
            },
        });
    }

    if (path) {
        defaultItems.push(
            {
                label: 'Show Details',
                action: () => {
                    history.push(path);
                },
            },
            {
                label: 'Open in a new tab',
                action: () => {
                    window.open(history.createHref({ pathname: path }), '_blank');
                },
            },
            {
                label: 'Copy URL',
                action: () => {
                    const url = `${window.location.origin}${path}`;
                    copy(url);
                    context.notify && context.notify('URL copied to the clipboard', 'left');
                },
            },
        );
    }
    const menuItems = _.compact(_.concat([], items, defaultItems));

    const toggleMenu = () => setShowMenu(!isShowMenu);
    const closeMenu = () => setShowMenu(false);

    const renderTooltipTitle = (): React.ReactElement => <span>{tooltip || label}</span>;
    const renderMenuItems = () => {
        return menuItems.map((action, i) => <MenuAction action={action} key={i} onClose={closeMenu} />);
    };

    return (
        <ClickAwayListener onClickAway={closeMenu}>
            <S.Badge className={className} ref={anchorRef} onClick={toggleMenu}>
                <S.ActionTooltip direction="top" variant="dark" title={renderTooltipTitle()}>
                    <S.ActionMainItem>
                        {Symbol && <S.ActionSymbol>{Symbol}</S.ActionSymbol>}
                        <S.ActionLabel title={label}>{label}</S.ActionLabel>
                    </S.ActionMainItem>
                </S.ActionTooltip>
                {!_.isEmpty(menuItems) && (
                    <>
                        <S.ActionButton>
                            <S.ActionIcon path={mdiChevronDown} size="16px" />
                        </S.ActionButton>
                        <S.WrappedPopper open={isShowMenu} anchorEl={anchorRef.current} placement="bottom-end">
                            {renderMenuItems()}
                        </S.WrappedPopper>
                    </>
                )}
            </S.Badge>
        </ClickAwayListener>
    );
};

export const MenuAction = React.memo<{ action: IActionMenuBadgeItem; onClose: () => void; }>(({ action, onClose }) => {
    const [loading, setLoading] = useState(false);

    if (action === 'divider') {
        return <S.ActionMenuDivider />;
    } else if (action) {
        const onClick = async (e: React.MouseEvent) => {
            setLoading(true);
            e.stopPropagation();

            try {
                await action.action();
                onClose();
            } finally {
                setLoading(false);
            }
        };
        if (action.tooltip) {
            return (
                <S.ActionMenuItem onClick={onClick} disabled={loading}>
                    <S.ActionTooltip direction="right" title={<span>{action.tooltip}</span>}>
                        <S.ActionLabel title={action.label}>{action.label}</S.ActionLabel>
                        <S.ActionIcon path={mdiInformationOutline} size="16px" />
                    </S.ActionTooltip>
                </S.ActionMenuItem>
            );
        } else {
            return (
                <S.ActionMenuItem onClick={onClick} disabled={loading}>
                    <S.ActionLabel title={action.label}>{action.label}</S.ActionLabel>
                </S.ActionMenuItem>
            );
        }
    }

    return null;
});
