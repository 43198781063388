import { css } from '@emotion/react/macro';
import styled from '@emotion/styled/macro';
import { Variables } from '../variables';

const successInputState = css`
    --input-border-color: ${Variables.Colors.successful};
    &:not(:disabled) {
        &:hover {
            --input-border-color: ${Variables.Colors.successful};
        }

        &:focus,
        &:active {
            --input-border-color: ${Variables.Colors.successful};

            box-shadow: 0 0 0 3px rgba(112, 212, 190, 0.24);
        }
    }
`;

const errorInputState = css`
    --input-border-color: ${Variables.Colors.warning};
    &:not(:disabled) {
        &:hover {
            --input-border-color: ${Variables.Colors.warning};
        }

        &:focus,
        &:active {
            --input-border-color: ${Variables.Colors.warning};

            box-shadow: 0 0 0 3px rgba(242, 93, 102, 0.24);
        }
    }
`;

export const AreaContainer = styled.textarea`
    --input-color: ${Variables.Colors.n100};
    --input-border-color: ${Variables.Colors.nx8};
    --input-background-color: ${Variables.Colors.white};

    display: block;
    resize: none;
    border-radius: ${Variables.Rounded.small};
    border: solid 1px;
    border-color: var(--input-border-color);
    width: 100%;
    padding: 8px;
    box-sizing: border-box;
    background-color: var(--input-background-color);
    color: var(--input-color);
    font-size: 16px;
    transition: ${Variables.transition};
    &::placeholder {
        color: ${Variables.Colors.n15};
        font-size: 16px;
    }

    &:not(:disabled) {
        &:hover {
            --input-border-color: ${Variables.Colors.b400};
        }

        &:focus,
        &:active {
            --input-border-color: ${Variables.Colors.b500};

            box-shadow: 0 0 0 3px rgba(30, 87, 207, 0.16);
            outline: 0;
        }
    }
`;
