import styled from '@emotion/styled/macro';
import { Icon } from '@mdi/react';
import { transparentize } from 'polished';
import { ClaimResponsibilityType } from '../../api';
import { Variables } from '../base';
import { BadgeSize } from './transmission-status-badge';

export interface IBadgeProps {
    size: BadgeSize;
    responsibility?: keyof typeof ClaimResponsibilityType;
}

export const Badge = styled.span<IBadgeProps>`
    display: inline-flex;
    align-items: center;
    white-space: nowrap;
    font-weight: 500;
    border-radius: ${Variables.Rounded.xlarge};

    ${(props): string => {
        let color;
        let bgColor;

        switch (props.responsibility) {
            case ClaimResponsibilityType.PAYER:
                color = Variables.Colors.amber20;
                bgColor = Variables.Colors.amber10;
                break;
            case ClaimResponsibilityType.PROVIDER:
                color = Variables.Colors.sunset500;
                bgColor = Variables.Colors.sunset300;
                break;
            case ClaimResponsibilityType.GUARANTOR:
                color = Variables.Colors.subG20;
                bgColor = Variables.Colors.subG10;
                break;

            case ClaimResponsibilityType.NONE:
                color = Variables.Colors.r30;
                bgColor = Variables.Colors.r20;
                break;
            case ClaimResponsibilityType.ENTER:
                color = Variables.Colors.b500;
                bgColor = Variables.Colors.b300;
                break;
            case ClaimResponsibilityType.UNKNOWN:
                color = Variables.Colors.r50;
                bgColor = Variables.Colors.r40;
                break;
            case ClaimResponsibilityType.UNKNOWN:
                color = Variables.Colors.rose20;
                bgColor = Variables.Colors.rose10;
                break;
            default:
                color = Variables.Colors.n85;
                bgColor = Variables.Colors.n30;
        }
        if (props.size === 'large') {
            return `
                background-color: ${transparentize(0.75, bgColor)};
                color: ${color};
                & > svg {
                    fill: ${color};
                }
                padding: 5px 8px 4px 8px;
                font-size: 15px;
                line-height: 15px;
            `;
        } else {
            return `
                background-color: ${transparentize(0.75, bgColor)};
                color: ${color};
                & > svg {
                    fill: ${color};
                }
                padding: 2px 8px;
                font-size: 13px;
                line-height: 13px;
            `;
        }
    }}
`;
export const BadgeLabel = styled.span`
    margin-right: 5px;
`;

export const BadgeIcon = styled(Icon)`
    margin-left: 4px;
    height: 16px;
    width: 16px;
`;
