import { Portal } from '@mui/material';
import * as React from 'react';
import * as S from './popover-menu.styles';

export interface IPopoverMenuProps {
    align?: 'left' | 'right';
    verticalAlign?: 'top' | 'bottom' | 'auto';
    open?: boolean;
    activator: React.RefObject<HTMLElement>;
}

export interface IPopoverMenuState {
    activatorRect?: ClientRect;
    viewportRect?: ClientRect;
    menuRect?: ClientRect;
}

export class PopoverMenu extends React.Component<IPopoverMenuProps, IPopoverMenuState> {
    constructor(props: IPopoverMenuProps) {
        super(props);
        this.state = {
            activatorRect: undefined,
            viewportRect: undefined,
            menuRect: undefined,
        };
    }
    public componentDidMount(): void {
        this.updateBoundaries();
        window.addEventListener('resize', this.updateBoundaries);
    }
    public componentDidUpdate(): void {
        this.updateBoundaries();
    }
    public componentWillUnmount(): void {
        window.removeEventListener('resize', this.updateBoundaries);
    }
    public render(): React.ReactElement {
        return <Portal container={document.body}>{this.renderMenu()}</Portal>;
    }
    private readonly updateBoundaries = (): void => {
        // update the boundaries when ref resolves and when referenced element moves
        if (this.props.activator && this.props.activator.current) {
            const activatorRect = this.props.activator.current.getBoundingClientRect();
            if (
                !this.state.activatorRect ||
                this.state.activatorRect.left !== activatorRect.left ||
                this.state.activatorRect.top !== activatorRect.top
            ) {
                this.setState({
                    activatorRect,
                    viewportRect: document.documentElement.getBoundingClientRect(),
                });
            }
        }
    };

    private readonly refCallback = (element: HTMLDivElement | null): void => {
        if (element) {
            this.setState({
                menuRect: element.getBoundingClientRect(),
            });
        }
    };

    private readonly renderMenu = (): React.ReactElement => {
        if (this.props.open && this.state.activatorRect && this.state.viewportRect) {
            return (
                <S.Menu
                    ref={this.refCallback}
                    align={this.props.align}
                    verticalAlign={this.props.verticalAlign}
                    activatorRect={this.state.activatorRect}
                    viewportRect={this.state.viewportRect}
                    menuRect={this.state.menuRect}>
                    {this.props.children}
                </S.Menu>
            );
        }
        return <></>;
    };
}
