import { mdiCheck, mdiEmailReceiveOutline, mdiPlus, mdiReply } from '@mdi/js';
import Icon from '@mdi/react';
import dateFns from 'date-fns';
import _ from 'lodash';
import React from 'react';
import { Row } from 'react-table';
import { MessagePurposeType } from '../../api';
import { MessagingContext } from '../../services';
import { BaseButton, BaseCheckbox, getOptions } from '../base';
import {
    DataTableCell,
    DataTablePresenter,
    ITableColumn,
    ITableFetchOptions,
    renderDate,
    renderString,
} from '../data-table';
import { FilterMethod, IFilterSchema } from '../data-table/components/filters/filters.models';
import { DoneButton } from './done-button';
import * as S from './message-list.styles';
import { PendingButton } from './pending-button';
import { UndoneButton } from './undone-button';
import { getNickname, IMessagingAuthor, IMessagingMessage, isLastAuthor } from './util';

interface IMessageListProps {
    inboxCount: number;
    inboxMessages: IMessagingMessage[];
    doneCount: number;
    doneMessages: IMessagingMessage[];
    onFetchData: (newTableState: ITableFetchOptions) => Promise<void>;
    onShowCompose: () => void;
    selectedMessageId: string | undefined;
    showFiltering: boolean;
    showNavigation: boolean;
    showSettings?: boolean;
    customColumns?: ITableColumn[];
    unreadCount?: number;
}

interface IMessageListState {
    tableState: ITableFetchOptions | undefined;
    selected: string[];
    showDoneMessages: boolean;
    isPending: boolean;
}

const formatDate = (d: Date): string => dateFns.format(d, 'MMM DD, YYYY, hh:mm');
const parseDate = (d: string | undefined): Date => (d ? dateFns.parse(d) : new Date());

export class MessageList extends React.Component<IMessageListProps, IMessageListState> {
    public static contextType = MessagingContext;
    private presenter: DataTablePresenter<IMessagingMessage>;
    private columns: ITableColumn<IMessagingMessage>[] = [
        {
            id: 'Selected',
            Header: ({ data }): React.ReactElement => {
                return (
                    <BaseCheckbox
                        disabled={this.state.isPending || this.context.isReadOnly}
                        checked={this.isAllSelected(data)}
                        onChange={this.toggleAll(data)}
                    />
                );
            },

            accessor: 'id',
            Cell: ({ cell }): React.ReactElement => (
                <span onClick={this.clickSelected(cell.value)}>
                    <BaseCheckbox
                        disabled={this.state.isPending || this.context.isReadOnly}
                        checked={this.isSelected(cell.value)}
                        onChange={this.toggleSelected(cell.value)}
                    />
                </span>
            ),
            maxWidth: 80,
            disableSortBy: true,
        },
        {
            id: 'Patient',
            Header: 'Patient',
            accessor: 'claim',
            Cell: ({ row }) => {
                const patientName = _.compact([row.original.claimPatientFirst, row.original.claimPatientLast]).join(
                    ' ',
                );
                return renderString(patientName);
            },
            minWidth: 100,
            disableSortBy: true,
        },
        {
            id: 'Guarantor',
            Header: 'Guarantor',
            accessor: 'claim',
            Cell: ({ row }) => {
                const guarantorName = _.compact([
                    row.original.claimGuarantorFirst,
                    row.original.claimGuarantorLast,
                ]).join(' ');
                return renderString(guarantorName);
            },
            disableSortBy: true,
        },
        {
            id: 'DOS',
            Header: 'DOS',
            accessor: 'claim',
            Cell: ({ row }) => renderDate(row.original.claimFromDos),
            disableSortBy: true,
        },
        {
            id: 'From',
            Header: 'From',
            accessor: 'from',
            Cell: ({ row }): React.ReactElement => {
                const { comments, author, lastAuthor } = row.original;
                return (
                    <>
                        {this.renderFromField(row.original, author)}
                        {!!comments && comments > 1 && author && lastAuthor && author.id !== lastAuthor.id && (
                            <>
                                <S.ReplyIcon path={mdiReply} size={0.75} />
                                {this.renderFromField(row.original, lastAuthor)}
                            </>
                        )}
                        {!!comments && comments > 1 && <S.CommentCount>{comments}</S.CommentCount>}
                    </>
                );
            },
            maxWidth: 400,
        },
        {
            id: 'Subject',
            Header: 'Subject',
            Cell: ({ row }): React.ReactElement => {
                const { documents, subject, read } = row.original;
                return (
                    <S.SubjectField unread={!read}>
                        {subject}
                        {!!documents && documents > 0 && <S.AttachmentField count={documents} />}
                    </S.SubjectField>
                );
            },
            accessor: 'subject',
            minWidth: 200,
            maxWidth: 9999,
        },
        {
            id: 'Date',
            Header: 'Date',
            accessor: 'updated',
            Cell: ({ cell }): string => formatDate(parseDate(cell.value)),
            maxWidth: 200,
            defaultSort: { id: 'Date', desc: true },
            show: false,
        },
        {
            id: 'Purpose',
            Header: 'Purpose',
            accessor: 'purposeType',
            Cell: DataTableCell('String', { normalized: true, type: 'String' }),
            minWidth: 180,
            show: false,
        },
        ...(this.props.customColumns || []),
    ];

    private filterSchemas: IFilterSchema[] = [
        {
            label: 'From',
            filterId: 'filterAuthor',
            filterType: 'String',
            columnId: 'From',
        },
        {
            label: 'Subject',
            filterId: 'filterSubject',
            filterType: 'String',
            columnId: 'Subject',
        },
        {
            label: 'Updated',
            filterId: 'filterUpdated',
            filterType: 'Date',
            columnId: 'Date',
        },
        {
            label: 'Read',
            filterId: 'filterRead',
            filterType: 'Select',
            options: [
                {
                    label: 'Yes',
                    value: '1',
                },
                {
                    label: 'No',
                    value: '0',
                },
            ],
            columnId: '',
        },
        {
            label: 'Purpose',
            filterId: 'filterPurpose',
            filterType: 'Select',
            options: getOptions(MessagePurposeType, { normalize: true }),
            columnId: 'purpose',
        },
    ];

    constructor(props: IMessageListProps) {
        super(props);
        this.state = {
            tableState: undefined,
            selected: [],
            showDoneMessages: false,
            isPending: false,
        };
        this.presenter = new DataTablePresenter<IMessagingMessage>({
            name: 'message-list',
            data: [],
            columns: this.columns,
            filterSchemas: this.filterSchemas,
        });
    }

    public componentDidUpdate(): void {
        const { showDoneMessages } = this.state;
        const { doneCount, doneMessages, inboxCount, inboxMessages } = this.props;

        const visibleMessages = showDoneMessages ? doneMessages : inboxMessages;
        const visibleCount = showDoneMessages ? doneCount : inboxCount;
        this.presenter.onDataUpdate(visibleMessages);
        this.presenter.onDataTotalChange(visibleCount);
    }

    public render(): React.ReactElement {
        const { selected, showDoneMessages, isPending } = this.state;
        const {
            doneCount,
            inboxCount,
            unreadCount,
            onShowCompose,
            showFiltering,
            showNavigation,
            showSettings,
        } = this.props;

        return (
            <S.MessageList>
                <S.MessageControls>
                    <S.MessageControl>
                        {!!selected.length && (
                            <S.ControlsWrapper>
                                {isPending && <PendingButton />}
                                {!isPending && !showDoneMessages && (
                                    <DoneButton onClick={this.markSelectedDone} count={selected.length} />
                                )}
                                {!isPending && showDoneMessages && (
                                    <UndoneButton onClick={this.markSelectedUndone} count={selected.length} />
                                )}
                            </S.ControlsWrapper>
                        )}

                        <S.TabsContainer>
                            <BaseButton
                                variant="outlined"
                                disabled={!showDoneMessages}
                                onClick={this.toggleDone(false)}>
                                <Icon path={mdiEmailReceiveOutline} size={0.75} />
                                <span>Inbox</span>
                                <S.ControlCount>{inboxCount}</S.ControlCount>
                            </BaseButton>
                            <BaseButton variant="outlined" disabled={showDoneMessages} onClick={this.toggleDone(true)}>
                                <Icon path={mdiCheck} size={0.75} />
                                <span>Done</span>
                                <S.ControlCount>{doneCount}</S.ControlCount>
                            </BaseButton>
                        </S.TabsContainer>

                        {!!unreadCount && !this.hasFilter && (
                            <S.UnreadButton variant="outlined" onClick={this.showUnread}>
                                Show {unreadCount} unread
                            </S.UnreadButton>
                        )}
                    </S.MessageControl>
                    <S.MessageControl>
                        <S.ComposeButton disabled={this.context.isReadOnly} variant="primary" onClick={onShowCompose}>
                            <S.ComposeIcon path={mdiPlus} size={0.75} />
                            <S.ComposeLabel>New message</S.ComposeLabel>
                        </S.ComposeButton>
                    </S.MessageControl>
                </S.MessageControls>
                <S.MessageTable
                    presenter={this.presenter}
                    isLoading={this.context.isListLoading}
                    onFetch={this.props.onFetchData}
                    onRowClick={this.onRowClick()}
                    rowClass={this.rowClass()}
                    hideFiltering={!showFiltering}
                    hideNavigation={!showNavigation}
                    hideSettings={!showSettings}
                    showExport={true}
                />
                <S.SelectedMessageRow show={!!this.props.selectedMessageId} />
            </S.MessageList>
        );
    }

    private get hasFilter() {
        return !!_.find(this.presenter.filters, { filterId: 'filterRead' });
    }

    private rowClass(): (row: Row<IMessagingMessage>) => string {
        return (row: Row<IMessagingMessage>): string => {
            if (row.original.id === this.props.selectedMessageId) {
                return _.get(S.SelectedMessageRow, 'componentStyle.lastClassName');
            }
            return '';
        };
    }

    private onRowClick(): (row: Row) => Promise<void> {
        const { onMessageClose, onMessageOpen } = this.context;
        const { selectedMessageId } = this.props;
        return async (row: Row<IMessagingMessage>): Promise<void> => {
            const {
                original: { id },
            } = row;
            if (id === selectedMessageId) {
                await onMessageClose();
            } else {
                await (onMessageOpen && onMessageOpen(id));
            }
        };
    }

    private markSelectedDone = async (): Promise<void> => {
        this.setState({ isPending: true });
        if (_.includes(this.state.selected, this.props.selectedMessageId)) {
            await this.context.onMessageClose();
        }
        await this.context.onMarkAsClosed(this.state.selected);
        this.setState({
            isPending: false,
            selected: [],
        });
    };
    private markSelectedUndone = async (): Promise<void> => {
        this.setState({ isPending: true });
        await this.context.onMarkAsOpen(this.state.selected);
        this.setState({
            isPending: false,
            selected: [],
        });
    };
    private clickSelected = (id: string): ((e: React.MouseEvent) => void) => {
        return (e: React.MouseEvent): void => {
            e.stopPropagation();
            e.preventDefault();
            this.toggleSelected(id)();
        };
    };

    private isSelected(id: string): boolean {
        return _.includes(this.state.selected, id);
    }
    private isAllSelected(data: IMessagingMessage[]): boolean {
        return data.length === this.state.selected.length;
    }
    private toggleAll(data: IMessagingMessage[]): () => void {
        return (): void => {
            if (!this.state.selected.length) {
                this.setState({ selected: _.compact(_.map(data, 'id')) });
            } else {
                this.setState({ selected: [] });
            }
        };
    }
    private toggleSelected(id: string): () => void {
        return (): void => {
            if (this.isSelected(id)) {
                this.setState({ selected: _.without(this.state.selected, id) });
            } else {
                this.setState({ selected: _.concat(this.state.selected, id) });
            }
        };
    }
    private toggleDone(newValue: boolean): () => void {
        return (): void => {
            if (newValue !== this.state.showDoneMessages) {
                this.setState({ showDoneMessages: newValue, selected: [] });
            }
        };
    }
    private readonly showUnread = (): void => {
        this.presenter.findAndUpdateFilter(
            { filterId: 'filterRead' },
            {
                filterId: 'filterRead',
                filterType: 'Select',
                filterMethod: FilterMethod.EQ,
                value: {
                    value: '0',
                    label: 'Yes',
                },
            },
        );
    };

    private renderFromField(message: IMessagingMessage, author: IMessagingAuthor | undefined): React.ReactElement {
        return (
            <>
                <S.IndicatorField unread={!message.read && !!author && isLastAuthor(message, author)} author={author} />
                <S.FromField unread={!message.read && !!author && isLastAuthor(message, author)}>
                    {getNickname(author)}
                </S.FromField>
            </>
        );
    }
}
