import styled from '@emotion/styled/macro';
import { BaseButton, Variables } from '../../base';

export const RowLabel = styled.span`
    margin-left: 10px;
    /* text-transform: lowercase; */
`;

export const Row = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;

    &:not(:last-child) {
        margin-bottom: 12px;
    }
`;

export const IconRow = styled(Row)`
    font-size: 16px;
    color: ${Variables.Colors.n85};

    img {
        font-size: 18px;
    }
`;

export const ActionButton = styled(BaseButton)`
    padding: 8px 20px;
    text-align: left;
    justify-content: flex-start;
    width: 100%;

    &:hover {
        cursor: pointer;
        background-color: ${Variables.Colors.n15_24};

        ${RowLabel} {
            color: ${Variables.Colors.nx100};
        }
    }

    &:active {
        ${RowLabel} {
            color: ${Variables.Colors.b500};
        }
    }

    &:disabled {
        cursor: default;
    }
`;
