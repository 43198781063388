import { FilterMethod, FilterType } from './filters.models';

export function getFilterMethods(
    filterType: FilterType,
): {
    value: FilterMethod;
    label: string;
}[] {
    switch (filterType) {
        case 'Phone':
        case 'String':
            return [
                {
                    value: FilterMethod.EQ,
                    label: 'Equal',
                },
                {
                    value: FilterMethod.NQ,
                    label: 'Not equal',
                },
                {
                    value: FilterMethod.LK,
                    label: 'Like',
                },
                {
                    value: FilterMethod.NLK,
                    label: 'Not like',
                },
                {
                    value: FilterMethod.EMPTY,
                    label: 'Empty',
                },
                {
                    value: FilterMethod.NEMPTY,
                    label: 'Not Empty',
                },
            ];
        case 'FullText':
            return [
                {
                    value: FilterMethod.OW,
                    label: 'One of the words',
                },
                {
                    value: FilterMethod.CT,
                    label: 'Contains',
                },
                {
                    value: FilterMethod.NCT,
                    label: 'Not contains',
                },
                {
                    value: FilterMethod.EX,
                    label: 'Exact',
                },
                {
                    value: FilterMethod.FT,
                    label: 'FullText search',
                },
            ];
        case 'Date':
        case 'DateTime':
            return [
                {
                    value: FilterMethod.EQ,
                    label: 'On',
                },
                {
                    value: FilterMethod.NQ,
                    label: 'Not on',
                },
                {
                    value: FilterMethod.GT,
                    label: 'After',
                },
                {
                    value: FilterMethod.LT,
                    label: 'Before',
                },
                {
                    value: FilterMethod.GTE,
                    label: 'On or after',
                },
                {
                    value: FilterMethod.LTE,
                    label: 'On or before',
                },
                {
                    value: FilterMethod.EMPTY,
                    label: 'Empty',
                },
                {
                    value: FilterMethod.NEMPTY,
                    label: 'Not Empty',
                },
            ];
        case 'Number':
            return [
                {
                    value: FilterMethod.EQ,
                    label: 'Equal',
                },
                {
                    value: FilterMethod.NQ,
                    label: 'Not equal',
                },
                {
                    value: FilterMethod.GT,
                    label: 'Greater than',
                },
                {
                    value: FilterMethod.LT,
                    label: 'Less than',
                },
                {
                    value: FilterMethod.GTE,
                    label: 'Greater than or equal to',
                },
                {
                    value: FilterMethod.LTE,
                    label: 'Less than or equal to',
                },
                {
                    value: FilterMethod.EMPTY,
                    label: 'Empty',
                },
                {
                    value: FilterMethod.NEMPTY,
                    label: 'Not Empty',
                },
            ];
        case 'Boolean':
        case 'AsyncSelect':
        case 'Select':
            return [
                {
                    value: FilterMethod.EQ,
                    label: 'Equal',
                },
                {
                    value: FilterMethod.NQ,
                    label: 'Not equal',
                },
                {
                    value: FilterMethod.EMPTY,
                    label: 'Empty',
                },
                {
                    value: FilterMethod.NEMPTY,
                    label: 'Not Empty',
                },
            ];
        default:
            throw new Error(`Unknown filter type ${filterType}`);
    }
}

export function isEmptyValueMethod(method: FilterMethod | undefined) {
    if (!method) {
        return false;
    }

    const emptyMethods = [FilterMethod.EMPTY, FilterMethod.NEMPTY];

    return emptyMethods.includes(method);
}

export function parseFilterFirstLast(value: string): [string | undefined, string | undefined] {
    const match = value.match(/([a-zA-Z]{2,})+/gi);

    if (!match) {
        return [undefined, undefined];
    }

    return [match[0], match[1]];
}

export function parseFilterNumber(value: string): string | undefined {
    const match = value.match(/\d{2,}/gi);

    return match ? match[0] : undefined;
}

// date in ISO
export function parseFilterDate(value: string): Date | undefined {
    // 02/02/2021
    const match = value.match(/(0[1-9]|1[012])[- \.\/](0[1-9]|[12][0-9]|3[01])[- \.\/](19|20)\d{2}/gi);

    if (!match || !match[0]) {
        return;
    }

    return new Date(`${match[0]} UTC`);
}
