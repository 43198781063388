import React from 'react';
import * as S from './pending-button.styles';

export const PendingButton: React.FunctionComponent = (): React.ReactElement => {
    return (
        <S.PendingButton variant="fab-light">
            <S.PendingSpinner size="18px" />
        </S.PendingButton>
    );
};
