import React, { useRef } from 'react';
import { BaseDialog, getErrorMessage } from '../../ui';
import * as S from './error.styles';

export interface IErrorProps {
    error: any;
}

export interface IErrorContextProps {
    handleError: (error: any) => void;
}

export const ErrorContext = React.createContext<IErrorContextProps>({
    handleError: () => {
        return;
    },
});

export const ErrorProvider: React.FC = ({ children }) => {
    const textRef = useRef<HTMLTextAreaElement>(null);
    const [errorMessage, setErrorMessage] = React.useState<{
        error: string;
        message: string;
    } | null>(null);

    const handleError = (error: any): void => {
        (async () => {
            const parsedError = await getErrorMessage(error);
            setErrorMessage(parsedError);
        })();
    };

    const closeModal = (): void => {
        setErrorMessage(null);
    };

    const handleContinue = () => {
        closeModal();
    };

    const renderActions = () => {
        return (
            <S.DialogActions>
                <S.DialogButton variant="primary" onClick={handleContinue}>
                    Close
                </S.DialogButton>
            </S.DialogActions>
        );
    };

    const selectAll = () => {
        if (textRef && textRef.current) {
            textRef.current.select();
        }
    };

    return (
        <ErrorContext.Provider value={{ handleError }}>
            {children}
            {errorMessage && (
                <BaseDialog
                    open={true}
                    disableBackdropClick={true}
                    disableEscapeKeyDown={true}
                    renderTitle="Error encountered"
                    renderActions={renderActions()}>
                    <S.DialogBody>
                        <S.Col>
                            {errorMessage.error}
                            <S.Textarea
                                ref={textRef}
                                rows={11}
                                value={errorMessage.message}
                                onClick={selectAll}
                                readOnly={true}
                            />
                        </S.Col>
                    </S.DialogBody>
                </BaseDialog>
            )}
        </ErrorContext.Provider>
    );
};
