import styled from '@emotion/styled/macro';
import { Icon } from '@mdi/react';
import { Variables } from '../variables';

interface ILoadingProps {
    size?: number | string;
}

export const OutlineIcon = styled(Icon)<ILoadingProps>`
    fill: ${Variables.Colors.b300};
`;
export const LoadingIcon = styled(Icon)<ILoadingProps>`
    fill: ${Variables.Colors.white};
    stroke: ${Variables.Colors.white};
    stroke-width: 2px;
    animation: spin 1500ms linear infinite;
    @keyframes spin {
        from {
            transform: rotateZ(0deg);
        }
        to {
            transform: rotateZ(360deg);
        }
    }
`;
export const Loading = styled.div<ILoadingProps>`
    display: flex;
    flex: 1 1 auto;
    justify-content: center;
    align-items: center;
    align-self: stretch;

    opacity: 0.7;
    background-color: transparent;

    --spinner-size: ${(props: ILoadingProps): string | number => props.size || '40px'};

    // NB: more specific selector needed here
    ${LoadingIcon}, ${OutlineIcon} {
        height: var(--spinner-size);
        width: var(--spinner-size);
    }
    ${LoadingIcon} {
        margin-left: calc(-1 * var(--spinner-size));
    }
`;
