import * as React from 'react';
import * as S from './layout-row.styles';

export interface ILayoutRow {
    className?: string;
}

export class LayoutRow extends React.PureComponent<ILayoutRow> {
    public render(): React.ReactElement {
        return <S.LayoutRow className={this.props.className}>{this.props.children}</S.LayoutRow>;
    }
}
