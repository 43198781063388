import { PopperPlacementType } from '@mui/material';
import { mdiCloseCircle, mdiMagnify } from '@mdi/js';
import * as React from 'react';
import { SearchBarOverlay } from './search-bar-overlay';
import * as S from './search-bar.styles';

export interface ISearchBarProps {
    className?: string;
    placeholder: string;
    disabled?: boolean;
    loading?: boolean;
    onChange?: (t?: string) => void;
    onFocus?: () => void;
    controls?: React.ReactNode;
    recent?: React.ReactNode;
    results?: React.ReactElement[];
    noResults?: React.ReactNode;
    text: string;
    open: boolean;
    placement?: PopperPlacementType;
    disableEscapeClose?: boolean;
}

export class SearchBar extends React.PureComponent<ISearchBarProps> {
    private anchorRef: React.RefObject<HTMLDivElement>;
    constructor(props: ISearchBarProps) {
        super(props);
        this.anchorRef = React.createRef();
    }
    public render(): React.ReactElement {
        const { className, disabled, loading, open, placeholder, text, placement } = this.props;
        return (
            <S.Container>
                <S.InputWrapper ref={this.anchorRef} className={className} open={open}>
                    <S.PrefixIcon path={mdiMagnify} size={1} />
                    <S.Input
                        type="text"
                        placeholder={placeholder}
                        value={text}
                        disabled={disabled}
                        onChange={this.handleChange}
                        onFocus={this.handleFocus}
                    />
                    {text && (
                        <S.Suffix onClick={this.clearText}>
                            <S.SuffixIcon path={mdiCloseCircle} size={1} />
                        </S.Suffix>
                    )}
                </S.InputWrapper>
                <SearchBarOverlay
                    disabled={disabled}
                    open={open}
                    text={text}
                    loading={loading}
                    anchor={this.anchorRef}
                    controls={this.props.controls}
                    recent={this.props.recent}
                    results={this.props.results}
                    noResults={this.props.noResults}
                    placement={placement}
                    disableEscapeClose={this.props.disableEscapeClose}
                />
            </S.Container>
        );
        return <></>;
    }
    public componentDidUpdate(): void {
        if (this.props.open) {
            this.focusSearch();
        } else {
            this.blurSearch();
        }
    }
    private readonly focusSearch = (): void => {
        if (this.anchorRef && this.anchorRef.current) {
            const input = this.anchorRef.current.querySelector('input');
            if (input) {
                input.focus();
            }
        }
    };
    private readonly blurSearch = (): void => {
        if (this.anchorRef && this.anchorRef.current) {
            const input = this.anchorRef.current.querySelector('input');
            if (input) {
                input.blur();
            }
        }
    };
    private readonly clearText = (): void => {
        // Check if there is text that should be changed.
        // It is required to have this condition, otherwise ClickAwayListener will handle close
        // everywhere and 'context.cancelGroup' will be handled.
        if (this.props.text && this.props.onChange) {
            this.props.onChange('');
        }
    };
    private readonly handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        if (this.props.onChange) {
            this.props.onChange(e.target.value);
        }
    };
    private readonly handleFocus = (): void => {
        if (this.props.onFocus) {
            this.props.onFocus();
        }
    };
}
