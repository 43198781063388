import { css } from '@emotion/react/macro';
import styled from '@emotion/styled/macro';
import Icon from '@mdi/react';
import chevronRight from '@mdi/svg/svg/chevron-right.svg';
import { NavLink } from 'react-router-dom';
import { BaseButton, forPhoneOnly, Tooltip, Variables } from '../base';
import { DataTable } from '../data-table';
import { AttachmentCount } from './attachment-count';
import { AuthorIndicator } from './author-indicator';
import { sidePanelWidth } from './side-panel.styles';

interface IUnread {
    unread: boolean;
}

interface IOpen {
    open: boolean;
}

const stylesIfUnread = (props: IUnread) => css`
    font-weight: ${props.unread ? '500' : 'normal'};
`;

interface IControlProps {
    active: boolean;
}

export const MessageList = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1 1 100%;
`;

export const MessageControls = styled.div`
    display: flex;
    width: 100%;
    flex-direction: row;
    flex-grow: 0;
    padding: 0 16px;
    flex-wrap: wrap;
    justify-content: space-between;

    ${forPhoneOnly(`
        flex-direction: column;
    `)};
`;

export const MessageControl = styled.div`
    display: flex;
`;

export const TableContainer = styled.div`
    flex-grow: 1;

    overflow: auto;

    /* for Firefox */
    min-height: 0;
`;

export const MessageTable = styled(DataTable)`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    align-self: stretch;
    max-height: 100%;

    & [role='columnheader'] {
        display: inline-flex;
    }

    & [role='cell'] {
        white-space: nowrap;
        overflow-x: hidden;
        text-overflow: ellipsis;
        padding: 16px;
    }

    & [role='row']:hover {
        background-color: ${Variables.Colors.n15_24};
        cursor: pointer;
    }
`;

export const MessageTooltip = styled(Tooltip)`
    display: inline-flex;
    cursor: pointer;
`;

export const IndicatorField = styled(AuthorIndicator)`
    margin-right: 8px;
    &:not(:first-of-type) {
        margin-left: 8px;
    }
`;

export const FromField = styled.div<IUnread>`
    display: flex;
    align-items: center;
    margin-right: 8px;
    ${stylesIfUnread}
`;

export const AttachmentField = styled(AttachmentCount)`
    margin-left: 8px;
`;

export const SubjectField = styled.div<IUnread>`
    display: flex;
    align-items: center;
    flex-grow: 1;
    ${stylesIfUnread}
`;

export const ActionField = styled.div`
    padding-right: 12px;
    color: ${Variables.Colors.n15};
    svg {
        fill: ${Variables.Colors.n15};
    }
`;

export const Spacer = styled.div`
    flex-grow: 1;
`;

export const ControlCount = styled.span`
    margin-left: 12px;
`;

export const ControlButton = styled(BaseButton)<IControlProps>`
    align-self: center;
    border: none;

    padding: 0 12px;
    height: 28px;

    font-size: 20px;
    font-weight: 500;
    color: ${(props: IControlProps): string => (props.active ? Variables.Colors.n70 : Variables.Colors.n15)};
    background-color: ${(props: IControlProps): string =>
        props.active ? Variables.Colors.ghostWhite : Variables.Colors.n15_24};

    &:not(:first-of-type) {
        margin-left: 32px;
        ${forPhoneOnly(`
            margin: 0;
        `)}
    }

    & ${ControlCount} {
        color: ${(props: IControlProps): string => (props.active ? Variables.Colors.n50 : Variables.Colors.n15)};
    }
`;

export const ComposeButton = styled(BaseButton)`
    align-self: flex-end;
    padding: 0 11px 0 7px;

    ${forPhoneOnly(`
        margin-top: 20px;
    `)};
`;

export const ComposeIcon = styled(Icon)`
    fill: white;
`;
export const ComposeLabel = styled.span``;

export const UnreadButton = styled(BaseButton)`
    align-self: flex-end;
    padding: 0 11px 0 7px;
    margin-left: 16px;

    color: ${Variables.Colors.b300};
    background-color: ${Variables.Colors.ghostWhite};
    border: none;
    &:hover {
        background-color: ${Variables.Colors.b300_l96};
    }
    &:active {
        background-color: ${Variables.Colors.b300_l90};
    }
`;

const openMessage = `
    color: ${Variables.Colors.n70};
`;

const doneMessage = `
    color: ${Variables.Colors.n50};
    font-style: italic;
`;

export const MessageLink = styled(NavLink)<IOpen>`
    display: inline-flex;
    flex-grow: 1;
    align-items: baseline;
    padding: 16px;
    line-height: 22px;

    ${forPhoneOnly(`
        padding: 12px 8px;
    `)};

    ${(props: IOpen): string => (props.open ? openMessage : doneMessage)}
`;

const selectedOffset = '50px';
export const SelectedMessageRow = styled.div<{ show: boolean }>`
    display: ${({ show }) => (show ? 'block' : 'none')};
    position: relative;
    z-index: 10;
    background-color: ${Variables.Colors.white};
    box-shadow: 0 8px 12px 0 ${Variables.Colors.nx4}, 0 -4px 12px 0 ${Variables.Colors.nx4},
        0 -0.5px 0 0 ${Variables.Colors.n15}, 0 0.5px 0 0 ${Variables.Colors.n15} !important;
    clip-path: polygon(
        0 -100%,
        calc(100% - ${sidePanelWidth} + ${selectedOffset}) -100%,
        calc(100% - ${sidePanelWidth} + ${selectedOffset}) 200%,
        0 200%
    );
    animation: 0.6s fadein;

    @keyframes fadein {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }

    &:after {
        animation: 0.6s fadein;
        content: '';
        position: absolute;
        right: calc(${sidePanelWidth} - ${selectedOffset});
        height: 100%;
        width: 48px;
        background: rgb(255, 255, 255);
        background: linear-gradient(90deg, rgba(255, 255, 255, 0.8) 0%, rgba(255, 255, 255, 1) 25%);
    }
    &:before {
        animation: 0.6s fadein;
        z-index: 11;
        content: '';
        height: 24px;
        width: 24px;
        mask: url(${chevronRight});
        mask-size: initial;
        position: absolute;
        right: calc(${sidePanelWidth} - ${selectedOffset});
        margin-top: 15px;
        background-color: ${Variables.Colors.b300};
    }
    &:hover {
        background-color: ${Variables.Colors.white} !important;
    }
`;

export const MessageListPanel = styled(MessageList)`
    padding: 10px;
    max-height: 100%;
    ${ComposeButton} {
        width: 100%;
    }
`;

export const CloseButton = styled(BaseButton)`
    border: 0;
    min-width: 0;
    padding: 0 8px;
    margin-left: 8px;
`;

export const ReplyIcon = styled(Icon)`
    position: relative;
    top: 4px;
    fill: ${Variables.Colors.n30};
`;

export const CommentCount = styled.span`
    color: ${Variables.Colors.n30};
`;

export const CloseIcon = styled(Icon)`
    fill: ${Variables.Colors.n30};
`;

export const Header = styled.section`
    display: flex;
`;

export const TabsContainer = styled.div`
    display: flex;
`;

export const ControlsWrapper = styled.div`
    margin-right: 16px;
`;

export const FilterButton = styled(BaseButton)`
    white-space: nowrap;
    margin: 4px 16px 4px 0;
    /* width: 50%; */
    border: 1px solid ${Variables.Colors.n15};
    & > svg {
        margin-left: 8px;
    }
`;
