import { mdiArrowDown, mdiArrowUp } from '@mdi/js';
import { Tooltip } from '@mui/material';
import _ from 'lodash';
import { observer } from 'mobx-react';
import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { NavLink, Prompt } from 'react-router-dom';
import {
    CellProps,
    HeaderProps,
    Row,
    SortingRule,
    TableInstance,
    TableOptions,
    useAsyncDebounce,
    useExpanded,
    usePagination,
    useResizeColumns,
    useRowState,
    useSortBy,
    useTable,
} from 'react-table';
import { TooltipTransitionComponent } from '../base';
import {
    ActiveFilters,
    Filters,
    ICustomFilter,
    Loader,
    Pagination,
    Placeholder,
    Settings,
    TableExport,
} from './components';
import { RowSelectCell } from './components/cells/row-select';
import { DataTablePresenter } from './data-table.presenter';
import * as S from './data-table.styles';
import { useFlexLayout } from './plugins';

export interface ITableFetchOptions {
    pageIndex: number;
    pageSize: number;
    sortBy: SortingRule<object>[];
    validFilters: ICustomFilter[];
}

export interface IDataTableProps<D extends object = any> {
    className?: string;
    presenter: DataTablePresenter<any>;
    isLoading?: boolean;
    hideSettings?: boolean;
    hideNavigation?: boolean;
    hidePagination?: boolean;
    hideFiltering?: boolean;
    hideSelectAll?: boolean;
    disableSorting?: boolean;
    selectableRows?: boolean;
    pageSizeOptions?: number[];
    showExport?: boolean;
    onFetch?: (options: ITableFetchOptions) => void;
    onRowClick?: (row: Row<D>) => void;
    getRowHref?: (row: Row<D>) => string;
    renderRowSubComponent?: (row: Row<D>) => React.ReactElement | null;
    renderToolbar?: () => React.ReactElement;
    renderRowWrapper?: (row: Row<D>, renderRow: React.ReactElement) => React.ReactElement;
    rowClass?: (row: Row<D>) => string;
    NotFoundComponent?: React.ComponentType;
}

const FETCH_DEBOUNCE = 500;

const defaultColumn = {
    minWidth: 50,
    width: 100,
    maxWidth: 400,
};

export const DataTable: React.FunctionComponent<IDataTableProps> = observer(props => {
    const {
        className,
        presenter,
        isLoading,
        hideSettings,
        hideNavigation,
        hideFiltering,
        hidePagination,
        hideSelectAll,
        disableSorting,
        selectableRows,
        pageSizeOptions,
        onFetch,
        onRowClick,
        getRowHref,
        showExport,
        renderRowSubComponent,
        renderToolbar,
        renderRowWrapper,
        rowClass,
        NotFoundComponent,
    } = props;

    const defaultFetchOptions = React.useMemo(() => presenter.getTableDefaults(), [presenter]);

    const {
        columns,
        hiddenColumnIds,
        data,
        dataTotal,
        controlledPageSize,
        validFilters,
        selectedIds,
        saveTableDefaults,
        saveFilters,
        onColumnToggle,
        onSelectedUpdate,
        onPageSizeUpdate,
    } = presenter;

    const defaultSortColumn = columns.find(column => !!column.defaultSort);
    const defaultSort = defaultSortColumn?.defaultSort ? [defaultSortColumn.defaultSort] : [];

    const defaultSortValue = _.isEmpty(defaultFetchOptions?.sortBy) ? defaultSort : defaultFetchOptions?.sortBy;
    const sortByValue = disableSorting ? [] : defaultSortValue;

    // Memoize tableProps to regenerate rows only when we change values that can invoke rerender
    const tableProps: TableOptions<any> = useMemo(
        () => ({
            getRowId: (row: Row<any>, i: number) => row.id || i.toString(),
            defaultColumn: { ...defaultColumn },
            columns,
            data,
            initialState: {
                pageIndex: defaultFetchOptions?.pageIndex || 0,
                pageSize: controlledPageSize,
                sortBy: sortByValue,
                hiddenColumns: hiddenColumnIds,
            }, // Pass our hoisted table state
            useControlledState: state => {
                return React.useMemo(
                    () => ({
                        ...state,
                        pageSize: controlledPageSize,
                    }),
                    [state, controlledPageSize],
                );
            },
            pageCount: Math.ceil(dataTotal / controlledPageSize),
            manualSortBy: true,
            manualPagination: true,
            disableSortRemove: true,
            disableSortBy: disableSorting,
            autoResetExpanded: false,
            autoResetRowState: false,
        }),
        [
            data,
            dataTotal,
            controlledPageSize,
            columns,
            selectedIds,
            disableSorting,
            defaultFetchOptions,
            hiddenColumnIds,
        ],
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        nextPage,
        previousPage,
        gotoPage,
        setHiddenColumns,
        state: { pageIndex, pageSize, sortBy, columnResizing },
    } = useTable(
        tableProps,
        useSortBy,
        useExpanded,
        usePagination,
        useFlexLayout,
        useResizeColumns,
        useRowState,
        hooks => {
            if (!selectableRows) {
                return;
            }

            hooks.visibleColumns.push(visibleColumns => [
                {
                    id: 'selection',
                    width: 'auto',
                    minWidth: 60,
                    Header: (headerProps: HeaderProps<any>) => {
                        if (hideSelectAll) {
                            return null;
                        }

                        const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
                            const checked = event.target.checked;

                            const columnIds: Record<string, boolean> = headerProps.flatRows.reduce(
                                (acc, row) => ({
                                    ...acc,
                                    [row.id]: checked,
                                }),
                                {},
                            );

                            const updatedSelectedIds = _.pickBy<boolean>({ ...selectedIds, ...columnIds }, val => val);

                            onSelectedUpdate(updatedSelectedIds);
                        };

                        return (
                            <RowSelectCell
                                checked={headerProps.flatRows.every(row => selectedIds[row.id])}
                                onChange={handleChange}
                            />
                        );
                    },
                    Cell: ({ row }: CellProps<any>) => {
                        const id = row.original.id;

                        const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
                            const checked = event.target.checked;
                            const updatedSelectedIds = _.pickBy<boolean>({ ...selectedIds, [id]: checked }, val => val);

                            onSelectedUpdate(updatedSelectedIds);
                        };

                        return <RowSelectCell checked={selectedIds[id]} showBorder={true} onChange={handleChange} />;
                    },
                },
                ...visibleColumns,
            ]);
        },
    );

    const handleChangePageSize = useCallback(
        (size: number) => {
            onPageSizeUpdate(size);
        },
        [onPageSizeUpdate],
    );

    useEffect(() => {
        setHiddenColumns(hiddenColumnIds);
    }, [hiddenColumnIds]);

    // Debounce our onFetchData call for 1000ms
    const handleFetchDebounce = onFetch && useAsyncDebounce(onFetch, FETCH_DEBOUNCE);

    const isFirstFetch = useRef(true);
    const fetchData = useCallback(
        (options: ITableFetchOptions): void => {
            if (!handleFetchDebounce) {
                return;
            }

            // For the first fetch we shouldn't use debounce
            if (isFirstFetch.current) {
                isFirstFetch.current = false;
                onFetch?.(options);
                return;
            }

            handleFetchDebounce(options);
        },
        [handleFetchDebounce, isFirstFetch],
    );

    // Reset the current page if filters were changed
    // to prevent staying out of the pages range.
    useEffect(() => {
        // Avoid handling on the first fetch as page can be stored
        if (isFirstFetch.current) {
            return;
        }
        gotoPage(0);
    }, [validFilters]);

    useEffect(() => {
        fetchData({
            pageIndex,
            pageSize,
            sortBy,
            validFilters,
        });
        saveTableDefaults({ pageIndex, pageSize, sortBy });
        saveFilters(validFilters);
    }, [pageIndex, pageSize, sortBy, validFilters]);

    const placeholder = dataTotal === 0 && !!isLoading;
    const loading = dataTotal > 0 && !!isLoading;
    const emptyState = dataTotal === 0 && !isLoading;

    const isSelected = Object.values(selectedIds).some(id => !!id);

    return (
        <S.TableWrapper className={className}>
            {!hideFiltering && (
                <>
                    <S.ActiveFiltersPanel>
                        <ActiveFilters presenter={presenter} />
                    </S.ActiveFiltersPanel>
                </>
            )}
            {(!hideFiltering || !hideNavigation || !hidePagination || !hideSettings) && (
                <S.ControlsPanel>
                    {!hideFiltering && <Filters presenter={presenter} />}
                    {!hideNavigation && (
                        <S.NavigationContainer>
                            {!hidePagination && (
                                <Pagination
                                    pageIndex={pageIndex}
                                    pageSize={pageSize}
                                    dataTotal={dataTotal}
                                    disabled={isLoading}
                                    pageSizeOptions={pageSizeOptions}
                                    nextPage={nextPage}
                                    previousPage={previousPage}
                                    gotoPage={gotoPage}
                                    onChangePageSize={handleChangePageSize}
                                />
                            )}
                            {!hideSettings && (
                                <Settings
                                    columns={columns}
                                    hiddenColumnIds={hiddenColumnIds}
                                    onColumnToggle={onColumnToggle}
                                    variant="small"
                                />
                            )}
                            {!!showExport && <TableExport rows={rows} prepareRow={prepareRow} />}
                        </S.NavigationContainer>
                    )}
                </S.ControlsPanel>
            )}
            {renderToolbar?.()}
            {placeholder ? (
                <Placeholder />
            ) : (
                <S.TableContainer>
                    {loading && <Loader loading={loading} />}
                    <S.Table {...getTableProps()}>
                        {!emptyState && (
                            <S.TableHead>
                                {headerGroups.map((headerGroup, hi) => (
                                    <S.TableRow {...headerGroup.getHeaderGroupProps()} key={hi}>
                                        {headerGroup.headers.map((column, ci) => {
                                            const description = _.get(column, 'description');

                                            return (
                                                <S.TableHeader
                                                    canSort={column.canSort}
                                                    isSorted={column.isSorted}
                                                    {...column.getHeaderProps()}
                                                    key={ci}>
                                                    <div {...column.getSortByToggleProps({ title: undefined })}>
                                                        <Tooltip
                                                            placement="top"
                                                            TransitionComponent={TooltipTransitionComponent}
                                                            title={<span>{description}</span>}
                                                            open={description ? undefined : false}
                                                            enterTouchDelay={0}>
                                                            <span>{column.render('Header')}</span>
                                                        </Tooltip>
                                                        {column.isSorted && (
                                                            <SortIcon isSortedDesc={!!column.isSortedDesc} />
                                                        )}
                                                    </div>

                                                    <div
                                                        {...column.getResizerProps()}
                                                        className={`resizer ${column.isResizing ? 'isResizing' : ''}`}
                                                    />
                                                </S.TableHeader>
                                            );
                                        })}
                                    </S.TableRow>
                                ))}
                            </S.TableHead>
                        )}
                        <S.TableBody {...getTableBodyProps()}>
                            <TableBody
                                rows={rows}
                                prepareRow={prepareRow}
                                onRowClick={onRowClick}
                                getRowHref={getRowHref}
                                renderRowSubComponent={renderRowSubComponent}
                                renderRowWrapper={renderRowWrapper}
                                rowClass={rowClass}
                                isSelected={isSelected}
                                columnResizing={columnResizing}
                            />
                        </S.TableBody>
                    </S.Table>
                </S.TableContainer>
            )}
            {emptyState && (NotFoundComponent ? <NotFoundComponent /> : <EmptyState />)}
        </S.TableWrapper>
    );
});

type TableProps = Pick<TableInstance, 'rows' | 'prepareRow'> &
    Pick<IDataTableProps, 'onRowClick' | 'getRowHref' | 'renderRowSubComponent' | 'renderRowWrapper' | 'rowClass'> & {
        // Determines if any item in the table is selected
        isSelected?: boolean;
        columnResizing: any;
    };

const TableBody = React.memo<TableProps>(props => {
    const {
        rows,
        onRowClick,
        getRowHref,
        renderRowSubComponent,
        renderRowWrapper,
        rowClass,
        prepareRow,
        isSelected,
    } = props;
    const handleRowClick = (row: Row) => () => onRowClick?.(row);
    const handleRowClass = (row: Row) => (rowClass ? rowClass(row) : undefined);

    function renderRow(row: Row<{}>) {
        const hoverable = !!onRowClick || !!getRowHref;
        return (
            <React.Fragment>
                <S.TableRow
                    className={handleRowClass(row)}
                    onClick={handleRowClick ? handleRowClick(row) : undefined}
                    hoverable={hoverable}
                    {...row.getRowProps()}>
                    {row.cells.map((cell, ci) => {
                        return (
                            <S.TableCell {...cell.getCellProps()} key={ci}>
                                {cell.render('Cell')}
                            </S.TableCell>
                        );
                    })}
                </S.TableRow>
                {row.isExpanded && <S.TableRow>{renderRowSubComponent && renderRowSubComponent(row)}</S.TableRow>}
            </React.Fragment>
        );
    }

    return (
        <div>
            {rows.map((row, ri) => {
                prepareRow(row);
                if (getRowHref) {
                    return (
                        // Use ID as a key to rerender cells for the new entity passed
                        <NavLink to={getRowHref(row)} key={row.id || ri}>
                            {!!renderRowWrapper ? renderRowWrapper(row, renderRow(row)) : renderRow(row)}
                        </NavLink>
                    );
                }

                return (
                    // Use ID as a key to rerender cells for the new entity passed
                    <React.Fragment key={row.id || ri}>
                        {!!renderRowWrapper ? renderRowWrapper(row, renderRow(row)) : renderRow(row)}
                    </React.Fragment>
                );
            })}
            {isSelected && !!getRowHref && (
                <Prompt
                    when={isSelected}
                    message="There are some items in the table selected. Do you still want to navigate to the another page?"
                />
            )}
        </div>
    );
});

const EmptyState = React.memo(() => (
    <S.EmptyContainer>
        <S.EmptyTitle>Nothing to show</S.EmptyTitle>
    </S.EmptyContainer>
));

const SortIcon = React.memo<{ isSortedDesc: boolean }>(({ isSortedDesc }) =>
    isSortedDesc ? <S.SortIcon path={mdiArrowDown} size={0.5} /> : <S.SortIcon path={mdiArrowUp} size={0.5} />,
);
