import { mdiCheckboxBlankCircle, mdiCheckboxBlankCircleOutline } from '@mdi/js';
import React from 'react';
import * as S from './author-indicator.styles';
import { IMessagingAuthor } from './util';

export interface IAuthorIndicatorProps {
    className?: string;
    author?: IMessagingAuthor;
    note?: boolean;
    unread: boolean;
}

export const AuthorIndicator: React.FunctionComponent<IAuthorIndicatorProps> = (
    props: IAuthorIndicatorProps,
): React.ReactElement => {
    const { className, author, unread } = props;

    return (
        <S.AuthorIndicator
            className={className}
            path={unread ? mdiCheckboxBlankCircle : mdiCheckboxBlankCircleOutline}
            type={author && author.type}
        />
    );
};
