import styled from '@emotion/styled/macro';
import { Icon } from '@mdi/react';
import { Tooltip, Variables } from '../base';

export const TextActions = styled.div`
    position: absolute;
    right: 0;
    display: flex;
    margin: 2px;
    opacity: 0;
`;

export const PreservedText = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    width: 100%;
    white-space: nowrap;
    position: relative;
    z-index: 1;
    &:hover {
        ${TextActions} {
            opacity: 1;
        }
    }
`;

export const Textarea = styled.textarea`
    white-space: pre;
    font-family: monospace;
    font-size: smaller;
    border: 0;

    max-height: 180px;
    overflow-x: hidden;
    overflow-y: hidden;

    &:disabled {
        background-color: transparent;
        color: ${Variables.Colors.n100};
    }
`;

export const ActionButton = styled.div`
    margin: 2px;
    background: ${Variables.Colors.white};
    box-shadow: 0 0 2px 1px ${Variables.Colors.n15};
    &:hover {
        background: ${Variables.Colors.n5};
    }
`;

export const ActionTooltip = styled(Tooltip)`
    padding: 4px;
    display: inline-flex;
    align-items: center;
    cursor: pointer;
`;

export const ActionIcon = styled(Icon)`
    height: 18px;
    width: 18px;
    fill: ${Variables.Colors.b300_l62};
`;

export const ViewText = styled.pre`
    padding: 0 12px;
    white-space: pre-wrap;
    word-break: break-all;
    overflow-x: auto;
`;
