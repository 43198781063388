import styled from '@emotion/styled/macro';
import { BaseButton, forPhoneOnly, forTabletLandscapeDown, Variables } from '../base';

interface ISidebarProps {
    visible?: boolean;
    noMobile?: boolean;
}

export const MenuButton = styled(BaseButton)`
    display: none;
    display: inline-flex;
    position: absolute;
    margin: 12px 24px;
    padding: 0 8px;
    min-width: unset;
    cursor: pointer;
    z-index: 100;
    fill: ${Variables.Colors.ghostWhite};
    ${forPhoneOnly(`
        margin: 12px;
    `)};
`;

/**
 * Sidebar
 */
export const Sidebar = styled.section<ISidebarProps>`
    width: ${Variables.sidebarSize};
    height: calc(100vh - ${Variables.topnavSize} + 1px);
    background-color: ${Variables.Colors.p500};
    position: fixed;
    overflow-y: auto;
    user-select: none;

    top: ${Variables.topnavSize};
    height: calc(100vh - ${Variables.topnavSize});

    ${(props: ISidebarProps): string => {
        const visible = `
            display: block;
            z-index: 20;
        `;
        const hidden = `
            display: none;
            width: 0;
        `;
        if (props.noMobile) {
            return visible;
        } else {
            const styles = props.visible ? visible : hidden;
            return forTabletLandscapeDown(styles);
        }
    }}
`;

export const SidebarHeader = styled.div`
    height: ${Variables.topnavSize};
`;
