import styled from '@emotion/styled/macro';
import { Variables } from '../variables';

interface IToggleProps {
    disabled: boolean;
    value: boolean;
}

export const Toggle = styled.div<IToggleProps>`
    --toggle-bgcolor-disabled: ${Variables.Colors.n5};
    --toggle-bgcolor-on: ${Variables.Colors.b300};
    --toggle-bgcolor-off: ${Variables.Colors.n30};
    --toggle-offset: ${(props: IToggleProps): string => (props.value ? '18px' : '0')};
    --toggle-bgcolor: ${(props: IToggleProps): string =>
        props.disabled
            ? 'var(--toggle-bgcolor-disabled)'
            : props.value
            ? 'var(--toggle-bgcolor-on)'
            : 'var(--toggle-bgcolor-off)'};

    display: flex;
    height: 32px;
    width: 48px;
    border-radius: 32px;

    align-items: center;
    background-color: var(--toggle-bgcolor);
    cursor: pointer;
`;

export const Knob = styled.div`
    height: 30px;
    width: 30px;
    border-radius: 30px;
    margin-left: var(--toggle-offset);
    box-shadow: 0 0 0 1px var(--toggle-bgcolor);
    background-color: ${Variables.Colors.white};

    transition: all 0.2s ease-in-out;
`;
