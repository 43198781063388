import _ from 'lodash';

export enum MessagingAuthorType {
    SUPPORT = 'SUPPORT',
    PROVIDER = 'PROVIDER',
    PATIENT = 'PATIENT',
}

export enum MessagingOrderEnum {
    ASCENDING = 'ASCENDING',
    DESCENDING = 'DESCENDING',
}

export enum MessagingSortType {
    CREATED = 'CREATED',
    UPDATED = 'UPDATED',
    OPEN = 'OPEN',
    READ = 'READ',
    SUBJECT = 'SUBJECT',
    AUTHOR = 'AUTHOR',
}

export enum MessagingTagType {
    USER = 'USER',
    SYSTEM = 'SYSTEM',
}

export interface IMessagingAuthor {
    id?: string;
    nickname?: string;
    type?: keyof typeof MessagingAuthorType;
}

export interface IMessagingTag {
    id?: string;
    name?: string;
    type?: keyof typeof MessagingTagType;
    groupId?: string;
    created?: string;
}

export interface IMessagingMessage {
    id?: string;
    subject?: string;
    comments?: number;
    documents?: number;
    open?: boolean;
    read?: boolean;
    author?: IMessagingAuthor;
    lastAuthor?: IMessagingAuthor;
    tags?: IMessagingTag[];
    updated?: string;
    created?: string;
    claimPatientFirst?: string;
    claimPatientLast?: string;
    claimGuarantorFirst?: string;
    claimGuarantorLast?: string;
    claimFromDos?: string;
    claimThroughDos?: string;
}

export interface IMessagingComment {
    id?: string;
    body?: string;
    note?: boolean;
    documents?: string[];
    author?: IMessagingAuthor;
    created?: string;
}

export const authorNickname = (message: IMessagingMessage | IMessagingComment | undefined): string => {
    return getNickname(_.get(message, 'author'));
};

export const getNickname = (author: IMessagingAuthor | undefined): string => {
    let nickname = _.get(author, 'nickname');
    const type = _.get(author, 'type');
    if (nickname && nickname !== 'Enter, Inc.' && type === MessagingAuthorType.SUPPORT) {
        const pair = _.split(nickname, ' ');
        const firstName = _.first(pair);
        const lastInitial = _.head(_.last(pair));
        nickname = `${firstName} ${lastInitial}.`;
    }
    return nickname || '';
};

export const isLastAuthor = (message: IMessagingMessage, author: IMessagingAuthor): boolean => {
    return _.get(message, 'lastAuthor.id') === _.get(author, 'id');
};

export const sortMessages = (
    messages: IMessagingMessage[],
    sorts: MessagingSortType[] = [],
    orders: MessagingOrderEnum[] = [],
): IMessagingMessage[] => {
    // sort results
    let sortCriteria = _.map(sorts, (sort: MessagingSortType) => _.toLower(sort));
    if (_.isEmpty(sortCriteria)) {
        sortCriteria.push('updated');
    }
    // NB: extra magic for sorting by author
    sortCriteria = _.map(sortCriteria, sort => (sort === 'author' ? 'author.nickname' : sort));

    // NB: expect both provider/support enums to have 'ASCENDING'
    const orderCriteria = _.map(orders, order => (String(order) === 'ASCENDING' ? 'asc' : 'desc'));
    if (_.isEmpty(orderCriteria)) {
        orderCriteria.push('desc');
    }
    return _.orderBy(messages, sortCriteria, orderCriteria);
};
