import React from 'react';
import * as S from './page-header.styles';
import symbolEnterProvider from './symbol-enter-provider.svg';

interface IPageHeaderProps {
    title: string;
    boldInstructionText?: string;
    instructionText?: string;
}

export const PageHeader: React.FunctionComponent<IPageHeaderProps> = (props: IPageHeaderProps): React.ReactElement => {
    const { title, boldInstructionText, instructionText } = props;
    return (
        <div>
            <S.ProviderLogo alt="logo" className="ProviderLogo" src={symbolEnterProvider} />
            <S.SignInHeader>{title}</S.SignInHeader>
            {boldInstructionText && <S.BoldInstructionText>{boldInstructionText}</S.BoldInstructionText>}
            {instructionText && <S.InstructionText>{instructionText}</S.InstructionText>}
        </div>
    );
};
