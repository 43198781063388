import _ from 'lodash';
import {
    AuthApi,
    Configuration,
    CoreFetch,
    FetchParams,
    Middleware,
    OAuthApi,
    OAuthStandardTokenResponse,
    RequestContext,
    ResponseContext,
    setCoreConfiguration,
} from '../../api';
import { TableStorage } from '../../ui/data-table';
import { AuthStorage } from './auth.storage';
import { IErrorContextProps } from './error.provider';

/* tslint:disable:no-console */

const URL_BASE = process.env.REACT_APP_AUTH_URL;

export type IAuthFetchOptions = {
    skipErrorHandler?: boolean;
};

export interface IFetchController {
    group: string;
    opts?: IAuthFetchOptions;
    map: Map<string, AbortController[]>;
}

export type ClientID = 'PROVIDER_DASHBOARD' | 'SUPPORT_TOOL' | 'LOGIN' | 'PATIENT';

export async function exchangeToken(code: string, authStorage: AuthStorage): Promise<void> {
    console.log('exchangeToken called');
    await CoreFetch<OAuthApi>(OAuthApi)
        .oauthStandardToken({
            oAuthStandardTokenRequest: {
                grantType: 'authorization_code',
                clientId: authStorage.clientId,
                codeVerifier: authStorage.codeVerifier,
                code,
            },
        })
        .then(handleTokenResponse(authStorage));
}

export async function requestAccountAccess(
    accountId: string,
    authStorage: AuthStorage,
): Promise<OAuthStandardTokenResponse> {
    console.log('requestAccountAccess called');
    clearProviderData();
    return CoreFetch<AuthApi>(AuthApi)
        .authRequestAccountAccess({
            requestAccountAccessRequest: {
                accountId,
            },
        })
        .then(handleTokenResponse(authStorage));
}

export function initCoreConfiguration(
    authStorage: AuthStorage,
    fetchController: IFetchController,
    errorContext?: IErrorContextProps,
): void {
    setCoreConfiguration(
        new Configuration({
            basePath: (window && window.location && window.location.host && window.location.host.indexOf('-web') > -1) ? '/api' : process.env.REACT_APP_API_URL,
            middleware: [
                createAuthMiddleware(authStorage, fetchController, errorContext),
                createAbortMiddleware(fetchController),
            ],
            accessToken: (): string => `Bearer ${authStorage.accessToken}`,
        }),
    );
}

export function removeHashFromUrl(): void {
    window.history.pushState('', window.document.title, window.location.pathname + window.location.search);
}

export function logoutAndRedirect(authStorage: AuthStorage, params?: Map<string, string>): void {
    logout(authStorage);
    const url = buildAuthorizeUrl(authStorage, params);
    window.location.href = url;
}

export function logout(authStorage: AuthStorage): void {
    authStorage.logoutClear();
    clearProviderData();
}

function clearProviderData() {
    TableStorage.clear(new RegExp(/table_.*_defaults/g));
}

function buildAuthorizeUrl(authStorage: AuthStorage, params?: Map<string, string>): string {
    const { codeChallenge, submittedState } = authStorage.loginPayload();
    let retUrl =
        URL_BASE +
        '/authorize?&scope=&response_type=code&client_id=' +
        authStorage.clientId +
        '&code_challenge=' +
        codeChallenge +
        '&code_challenge_method=S256&state=' +
        submittedState;

    const redirectUri = _.get(params, 'redirect_uri', window.location.href.split('?')[0]);
    if (!_.isNil(redirectUri)) {
        retUrl += `&redirect_uri=${encodeURIComponent(redirectUri)}`;
    }
    const invitationCode = _.get(params, 'invitation_code');
    if (!_.isNil(invitationCode)) {
        retUrl += `&invitation_code=${encodeURIComponent(invitationCode)}`;
    }

    return retUrl;
}

async function handleRefreshToken(authStorage: AuthStorage): Promise<OAuthStandardTokenResponse> {
    const refreshToken = authStorage.refreshToken;
    if (!refreshToken) {
        throw new Error('Refresh token is not exist');
    }

    return CoreFetch<OAuthApi>(OAuthApi).oauthStandardToken({
        oAuthStandardTokenRequest: {
            grantType: 'refresh_token',
            clientId: authStorage.clientId,
            refreshToken,
        },
    });
}

/**
 * Middleware checks if refresh token required and repeats requests
 */
function createAuthMiddleware(
    authStorage: AuthStorage,
    fetchController: IFetchController,
    errorContext?: IErrorContextProps,
): Middleware {
    return {
        pre: async (context: RequestContext): Promise<FetchParams | void> => {
            const authorization = _.get(context, 'init.headers.Authorization', '');
            if (authorization === 'Bearer null' || authorization === 'Bearer undefined') {
                const refreshToken = authStorage.refreshToken;
                console.warn('Logout on account of null bearer token. Current refresh token:', refreshToken);
                logoutAndRedirect(authStorage);
            }
        },
        post: async (context: ResponseContext): Promise<Response> => {
            if (context.response.status === 401) {
                try {
                    const { accessToken, refreshToken } = await handleRefreshToken(authStorage);

                    if (accessToken && refreshToken) {
                        console.log('createAuthMiddleware setting tokens');
                        authStorage.accessToken = accessToken;
                        authStorage.refreshToken = refreshToken;

                        // Manually set the token and repeat the request
                        const response = await context.fetch(context.url, {
                            ...context.init,
                            headers: {
                                ...context.init.headers,
                                Authorization: `Bearer ${accessToken}`,
                            },
                        });
                        return response;
                    }
                } catch (err) {
                    console.log('createAuthMiddleware err=', err);
                    if (err.status >= 400 && err.status < 500) {
                        logoutAndRedirect(authStorage);
                    } else {
                        console.log('Unexpected 500 error');
                        throw err;
                    }
                }
            }

            const { opts } = fetchController;

            if (context.response.ok === false && !opts?.skipErrorHandler) {
                errorContext?.handleError(context.response.clone());
            }

            return context.response;
        },
    };
}

function createAbortMiddleware(fetchController: IFetchController): Middleware {
    return {
        pre: async (context: RequestContext): Promise<FetchParams | void> => {
            // Add the abort controller signal to Fetch params
            const { url, init } = context;
            const { group, map } = fetchController;
            const controller = new AbortController();
            if (!map.has(group)) {
                map.set(group, []);
            }
            const controllers = map.get(group);
            if (controllers) {
                controllers.push(controller);
                init.signal = controller.signal;
            }
            return { url, init };
        },
        post: async (context: ResponseContext): Promise<Response> => {
            // always deregister controller
            if (context.response.status === 200) {
                const { group, map } = fetchController;
                if (map.has(group)) {
                    const controllers = map.get(group);
                    if (controllers && context.init.signal) {
                        const controller = _.find(controllers, { signal: context.init.signal });
                        if (controller) {
                            map.set(group, _.without(controllers, controller) as AbortController[]);
                        }
                    }
                }
            }
            return context.response;
        },
    };
}

function handleTokenResponse(authStorage: AuthStorage): (r: OAuthStandardTokenResponse) => OAuthStandardTokenResponse {
    return (response: OAuthStandardTokenResponse): OAuthStandardTokenResponse => {
        console.log('handleTokenResponse response=', response);
        const { accessToken, refreshToken } = response;
        authStorage.accessToken = accessToken;
        authStorage.refreshToken = refreshToken;
        return response;
    };
}
