import styled from '@emotion/styled/macro';
import { Variables } from '../base';

export const Badge = styled.div`
    padding: 6px 12px;
    font-size: 14px;
    color: ${Variables.Colors.b300_l62};
    background-color: ${Variables.Colors.b300_l96};
    border: 1px solid ${Variables.Colors.b300_l90};
    border-radius: ${Variables.Rounded.small};
`;
