import { SideImageWrapper } from 'components';
import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { parseQueryParams } from '../util';
import { ITwoFactorParams } from './authorize.models';
import { InvitationForm } from './invitation-form';
import { LoginForm } from './login-form';
import { TwoFactorForm } from './two-factor-form';

export const Authorize: React.FunctionComponent = (): React.ReactElement => {
    const location = useLocation();

    const authorizeParams = parseQueryParams(location, {
        client_id: '',
        code: '',
        sso_return: '',
        code_challenge: '',
        code_challenge_method: '',
        redirect_uri: '',
        realm: '',
        scope: '',
        provider_code: '',
        invitation_code: '',
        skip_invitation_screen: '',
    });

    const [twoFactorParams, setTwoFactorParams] = useState<ITwoFactorParams>();

    const handleTwoFactorAuth = (params: ITwoFactorParams): void => {
        setTwoFactorParams(params);
    };

    return (
        <SideImageWrapper>
            {authorizeParams.invitation_code && !authorizeParams.skip_invitation_screen && !twoFactorParams && (
                <InvitationForm {...authorizeParams} />
            )}
            {(!authorizeParams.invitation_code || authorizeParams.skip_invitation_screen) && twoFactorParams && (
                <TwoFactorForm {...twoFactorParams} {...authorizeParams} />
            )}
            {(!authorizeParams.invitation_code || authorizeParams.skip_invitation_screen) && !twoFactorParams && (
                <LoginForm onTwoFactorAuth={handleTwoFactorAuth} {...authorizeParams} />
            )}
        </SideImageWrapper>
    );
};
