import { css } from '@emotion/react/macro';
import styled from '@emotion/styled/macro';
import { Icon } from '@mdi/react';
import { BaseButton, Variables } from '../../base';
import { Card } from '../../card';

interface ITab {
    active: boolean;
}

export const PersonalOverviewCard = styled(Card)`
    display: flex;
    flex-direction: row;
    flex: 1 0 auto;
    padding: 0;
`;

export const Header = styled.div`
    display: flex;
    align-items: stretch;
`;

export const Title = styled.div`
    flex-grow: 1;
    padding: 16px 24px;

    font-size: 15px;
    font-weight: 500;
    text-transform: uppercase;
    color: ${Variables.Colors.p500};
    border-bottom: 1px solid ${Variables.Colors.platinum};
`;

export const Tabs = styled.div`
    display: flex;
    flex-grow: 1;
    justify-content: flex-start;
    align-items: stretch;
    border-bottom: 1px solid ${Variables.Colors.platinum};
    padding: 0;
`;

export const TabName = styled.div`
    font-size: 18px;
    font-weight: 500;
`;

export const TabType = styled.div`
    font-size: 12px;
`;

const ActiveTab = css`
    color: ${Variables.Colors.b300};
    border-bottom-color: ${Variables.Colors.b300};

    ${TabType} {
        color: ${Variables.Colors.b200};
    }

    /* &:hover {
        color: ${Variables.Colors.b500};
    } */
`;

const InactiveTab = css`
    color: ${Variables.Colors.n50};
    user-select: none;
    cursor: pointer;

    /* ${TabName} {
        font-size: 16px;
    } */

    ${TabType} {
        color: ${Variables.Colors.n50};
    }

    &:hover {
        color: ${Variables.Colors.n15};
        ${TabType} {
            color: ${Variables.Colors.n15};
        }
    }
`;

export const Tab = styled.div<ITab>`
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: flex-end;
    /* flex-wrap: wrap; */
    /* align-items: flex-end; */
    padding: 16px;

    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0.2px;
    line-height: 1;

    background-color: ${Variables.Colors.white};
    border-bottom: 2px solid transparent;
    color: ${Variables.Colors.n100};

    ${props => {
        return props.active ? ActiveTab : InactiveTab;
    }};
`;

export const Body = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: 16px;
`;

export const Container = styled.div`
    flex: 1;
`;

export const Row = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;

    &:not(:last-child) {
        margin-bottom: 12px;
    }
`;

export const Loader = styled.div`
    margin: 100px 0;
`;

export const Footer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 12px 0;
    font-size: 16px;
    font-weight: 500;
    color: ${Variables.Colors.n100};
    background-color: ${Variables.Colors.ghostWhite};
    cursor: pointer;
    &:hover {
        color: ${Variables.Colors.n50};
    }
`;

export const Link = styled.div``;

export const RecordRow = styled(Row)`
    font-size: 16px;
    color: ${Variables.Colors.n50};
    & > *:not(:first-of-type) {
        margin-left: 4px;
    }
`;
export const RecordLabel = styled.div`
    font-weight: 500;
`;
export const RecordNumber = styled.div`
    letter-spacing: 0.5px;
`;

export const Name = styled.div`
    font-size: 24px;
    color: ${Variables.Colors.n100};
`;

export const MemberLabel = styled.div`
    font-size: 16px;
    font-weight: 500;
    color: ${Variables.Colors.n70};
`;
export const MemberId = styled.div`
    font-size: 16px;
    letter-spacing: 0.5px;
    color: ${Variables.Colors.b300};
`;

export const IconRow = styled(Row)`
    font-size: 16px;
    color: ${Variables.Colors.n85};

    img {
        font-size: 18px;
    }
`;

export const RowLabel = styled.span`
    margin-left: 10px;
    /* text-transform: lowercase; */
`;

export const BaseIcon = styled(Icon)`
    height: 20px;
    width: 20px;
    border-radius: 20px;

    fill: ${Variables.Colors.white};
    background-color: ${Variables.Colors.n85};

    margin-right: 8px;

    &:not(:first-of-type) {
        margin-left: 16px;
    }
`;
export const DobIcon = styled(BaseIcon)`
    padding: 3px 0px;
`;
export const PatientIcon = styled(BaseIcon)`
    padding: 1px 0 2px 0;
`;
export const PhoneIcon = styled(BaseIcon)`
    padding: 1px 0 2px 0;
`;
export const EmailIcon = styled(BaseIcon)`
    padding: 1px 0 2px 0;
`;
export const AddressIcon = styled(BaseIcon)`
    padding: 4px 0;
`;

export const Spacer = styled.div`
    flex-grow: 1;
`;

export const EmptyLabel = styled.span`
    color: ${Variables.Colors.n30};
`;

export const ActionButton = styled(BaseButton)`
    padding: 8px 20px;
    text-align: left;
    justify-content: flex-start;
    width: 100%;

    &:hover {
        cursor: pointer;
        background-color: ${Variables.Colors.n15_24};

        ${RowLabel} {
            color: ${Variables.Colors.nx100};
        }
    }

    &:active {
        ${RowLabel} {
            color: ${Variables.Colors.b500};
        }
    }

    &:disabled {
        cursor: default;
    }
`;

export const Actions = styled.div`
    /* max-width: 100px; */
    background-color: ${Variables.Colors.b300_l96};
    height: 100%;
    padding: 20px 10px;
    border-left: 1px solid ${Variables.Colors.platinum};
    display: flex;
    flex-direction: column;
    width: 200px;
`;

export const Divider = styled.div`
    border-bottom: ${Variables.Borders.regular};
    /* position: absolute; */
    left: 0;
    width: 100%;
    margin: 2px 0;
`;

export const ActionsHeader = styled.div`
    margin-bottom: 10px;
`;

export const ActionsHeaderRow = styled.div`
    display: flex;
`;
export const PayButton = styled(BaseButton)`
    flex: 1;
`;
export const LinkButton = styled(BaseButton)`
    padding: 0;
    min-width: 40px;
    margin-left: 4px;
`;

export const GuarantorAmount = styled.div`
    margin-bottom: 10px;
`;

export const PrimaryAmount = styled.span`
    font-size: 24px;
    font-weight: 600;
    color: ${Variables.Colors.p500};
`;

export const SecondaryAmount = styled.span`
    font-size: 18px;
    font-weight: 500;
    color: ${Variables.Colors.n50};
`;
