import { css } from '@emotion/react/macro';
import styled from '@emotion/styled/macro';
import { Variables } from '../variables';

interface IRadioStyledProps {
    checked?: boolean;
    disabled?: boolean;
}

export const CheckIcon = styled.div`
    height: 10px;
    width: 10px;
    border-radius: 50%;
    background: ${Variables.Colors.n70};
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
`;

// Hide radio visually but remain accessible to screen readers.
// Source: https://polished.js.org/docs/#hidevisually
export const HiddenRadio = styled.input`
    border: 0;
    clip: rect(0 0 0 0);
    clippath: inset(50%);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    white-space: nowrap;
    width: 1px;
`;

HiddenRadio.defaultProps = { type: 'radio' };

export const StyledRadio = styled.div`
    display: inline-block;
    vertical-align: middle;
    width: 18px;
    height: 18px;
    background: ${Variables.Colors.white};
    border: 2px solid ${Variables.Colors.n70};
    border-radius: 50%;
    transition: all 150ms;
    user-select: none;
    position: relative;
`;

export const Label = styled.span`
    margin-left: 8px;
    user-select: none;
    vertical-align: middle;
    font-size: 15px;
    color: ${Variables.Colors.n70};
`;

const disabledRadio = css`
    cursor: default;
    ${StyledRadio} {
        border: 2px solid ${Variables.Colors.n15};
    }

    ${CheckIcon} {
        background: ${Variables.Colors.n15};
    }
`;

export const RadioContainer = styled.label<IRadioStyledProps>`
    display: inline-block;
    align-items: center;
    cursor: pointer;
    ${props => (props.disabled ? disabledRadio : null)}

    ${CheckIcon} {
        visibility: ${(props): string => (props.checked ? 'visible' : 'hidden')};
    }
`;
