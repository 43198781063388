import { css } from '@emotion/react/macro';
import styled from '@emotion/styled/macro';
import Icon from '@mdi/react';
import CreatableSelect from 'react-select/creatable';
import { Variables } from '../variables/variables';

interface IInputProps {
    showSuccess?: boolean;
    showError?: boolean;
    showDelete?: boolean;
}

const successInputState = css`
    --input-border-color: ${Variables.Colors.successful};
    &:not(:disabled) {
        &:hover {
            --input-border-color: ${Variables.Colors.successful};
        }

        &:focus,
        &:active {
            --input-border-color: ${Variables.Colors.successful};

            box-shadow: 0 0 0 3px rgba(112, 212, 190, 0.24);
        }
    }
`;

const errorInputState = css`
    --input-border-color: ${Variables.Colors.warning};
    &:not(:disabled) {
        &:hover {
            --input-border-color: ${Variables.Colors.warning};
        }

        &:focus,
        &:active {
            --input-border-color: ${Variables.Colors.warning};

            box-shadow: 0 0 0 3px rgba(242, 93, 102, 0.24);
        }
    }
`;

const deleteInputState = css`
    padding-right: 40px;
`;

export const Input = styled.input<IInputProps>`
    --input-color: ${Variables.Colors.n100};
    --input-border-color: ${Variables.Colors.nx8};
    --input-background-color: ${Variables.Colors.white};

    display: block;
    border-radius: ${Variables.Rounded.small};
    border: solid 1px;
    border-color: var(--input-border-color);
    height: 40px;
    width: 100%;
    padding: 0 8px;
    box-sizing: border-box;
    background-color: var(--input-background-color);
    color: var(--input-color);
    font-size: 16px;
    transition: ${Variables.transition};
    &::placeholder {
        color: ${Variables.Colors.n15};
        font-size: 14px;
    }

    &:not(:disabled) {
        &:hover {
            --input-border-color: ${Variables.Colors.b400};
        }

        &:focus,
        &:active {
            --input-border-color: ${Variables.Colors.b500};

            box-shadow: 0 0 0 3px rgba(30, 87, 207, 0.16);
            outline: 0;
        }
    }

    ${props => {
        if (props.showSuccess) {
            return successInputState;
        }

        if (props.showError) {
            return errorInputState;
        }

        if (props.showDelete) {
            return deleteInputState;
        }

        return;
    }}

    &:disabled {
        --input-background-color: ${Variables.Colors.nx4};
    }

    &:read-only {
        cursor: pointer;
    }
`;

export const InputContainer = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
`;

export const InputWrapper = styled.div`
    display: flex;
    align-items: center;
    position: relative;
`;

export const InputPostfixButton = styled.button`
    width: 24px;
    height: 24px;
    margin-left: 12px;
    background: ${Variables.Colors.white};
    box-shadow: none;
    border: none;
    outline: none;
    text-align: center;
    padding: 0;
    line-height: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    svg {
        width: 18px;
        height: 18px;
    }
    &:hover,
    &:focus {
        border-radius: ${Variables.Rounded.small};
        background-color: ${Variables.Colors.n15_24};
    }

    &:active {
        box-shadow: ${Variables.Shadows.medium};
    }
`;

export const InputInnerPostfixButton = styled(InputPostfixButton)`
    margin-left: -30px;
    background-color: transparent;

    svg {
        fill: ${Variables.Colors.n15};
        width: 24px;
        height: 24px;
    }

    &:hover {
        background-color: none;
        svg {
            fill: ${Variables.Colors.n30};
        }
    }
    &:active {
        box-shadow: none;
        svg {
            fill: ${Variables.Colors.n50};
        }
    }
`;

export const InputBottom = styled.div`
    font-size: 13px;
    font-weight: 500;
    display: flex;
    align-items: center;
    margin-top: 8px;
`;

export const InputBottomSuccess = styled(InputBottom)`
    color: ${Variables.Colors.successful};
    fill: ${Variables.Colors.successful};
`;

export const InputBottomError = styled(InputBottom)`
    color: ${Variables.Colors.warning};
    fill: ${Variables.Colors.warning};
`;

export const InputBottomIcon = styled(Icon)`
    width: 18px;
    height: 18px;
    vertical-align: middle;
`;

export const InputBottomMessage = styled.div`
    display: inline-block;
    margin-left: 4px;
`;

export const CreatableInput = styled(CreatableSelect)`
    --input-color: ${Variables.Colors.n100};
    --input-border-color: ${Variables.Colors.nx8};
    --input-background-color: ${Variables.Colors.white};

    width: 100%;

    .select {
        &__control {
            cursor: text;
            border-radius: ${Variables.Rounded.small};
            border: solid 1px;
            border-color: var(--input-border-color) !important;
            min-height: 40px;
            width: 100%;
            box-sizing: border-box;
            background-color: var(--input-background-color);
            color: var(--input-color);
            font-size: 16px;
            transition: ${Variables.transition};
            padding: 0 8px;

            &:hover {
                --input-border-color: ${Variables.Colors.b400};
                border-color: inherit;
            }

            &--is-focused,
            &:active {
                --input-border-color: ${Variables.Colors.b500};

                box-shadow: 0 0 0 3px rgba(30, 87, 207, 0.16);
                outline: 0;
            }

            &:disabled {
                --input-background-color: ${Variables.Colors.nx4};
            }
        }

        &__value-container {
            padding: 0;
        }

        &__placeholder {
            color: ${Variables.Colors.n15};
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            width: 100%;
        }

        &__multi-value {
            &__remove {
                cursor: pointer;
            }
        }
    }
`;
