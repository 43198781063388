import dateFns from 'date-fns';
import _ from 'lodash';
import * as React from 'react';
import { PatientBadge } from '../badge';
import * as S from './patient-search-result.styles';

export interface IPatientSearchResultProps {
    id: string;
    first: string;
    last: string;
    suffix: string;
    sex: string;
    dob: string;
}

export class PatientSearchResult extends React.PureComponent<IPatientSearchResultProps> {
    public render(): React.ReactNode {
        const { id, first, last, suffix, sex, dob, children } = this.props;
        const gender = _.toUpper(sex[0]);
        return (
            <S.Result>
                <S.Patient>
                    {id && (
                        <S.PatientTop>
                            <b>Patient</b>
                            <S.Spacer />
                            <b>DOB</b>
                            <span>{dateFns.format(dob, 'YYYY/MM/DD')}</span>
                            <b>{gender}</b>
                        </S.PatientTop>
                    )}
                    <S.PatientBottom>
                        <S.PatientName>
                            {first} {last}
                        </S.PatientName>
                        <S.Spacer />
                        <PatientBadge
                            id={id}
                            first={first}
                            last={last}
                            suffix={suffix}
                            path={`/claims/by-patient/${id}`}
                            tooltip="Find claims for this Patient"
                        />
                    </S.PatientBottom>
                </S.Patient>
                {!!children && (
                    <S.ClaimTable>
                        <tbody>{children}</tbody>
                    </S.ClaimTable>
                )}
            </S.Result>
        );
    }
}
