import React, { useState } from 'react';
import { Toast, ToastAlign } from '../../ui/toast';
import { ToastTheme } from '../../ui/toast/toast.models';

export interface IToastContextProps {
    notify: ((msg: string | React.ReactElement, align?: ToastAlign, variant?: ToastTheme) => void) | undefined;
    dismiss: (() => void) | undefined;
}

export const ToastContext = React.createContext<IToastContextProps>({
    notify: undefined,
    dismiss: undefined,
});

export interface IToastProviderProps {
    children?: React.ReactNode;
    variant?: ToastTheme;
}

export const ToastProvider: React.FC<IToastProviderProps> = (props: IToastProviderProps): React.ReactElement => {
    const { children, variant: defaultTheme } = props;
    const [message, setMessage] = useState<string | React.ReactElement | undefined>();
    const [align, setAlign] = useState<ToastAlign | undefined>();
    const [variant, setVariant] = useState<ToastTheme | undefined>(defaultTheme);

    const notify = (msg: string | React.ReactElement, aln?: ToastAlign, variant?: ToastTheme): void => {
        setMessage(msg);
        setAlign(aln);

        if (variant) {
            setVariant(variant);
        }
    };
    const dismiss = (): void => {
        setMessage(undefined);
    };
    return (
        <ToastContext.Provider
            value={{
                notify,
                dismiss,
            }}>
            {children}
            <Toast message={message!} align={align} onClose={dismiss} variant={variant} />
        </ToastContext.Provider>
    );
};
