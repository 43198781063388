import dateFns from 'date-fns';
import { FieldProps } from 'formik';
import _ from 'lodash';
import * as React from 'react';
import { DatePicker, IBaseInputProps, toUTC } from '../../';

type IDateTimePickerFieldProps = IBaseInputProps & FieldProps;

export class DateTimePickerField extends React.PureComponent<IDateTimePickerFieldProps> {
    public render(): React.ReactElement {
        const { field, ...props } = this.props;
        // Make sure that we set the valid date
        const date = _.isDate(this.props.field.value)
            ? this.props.field.value
            : toUTC(new Date(this.props.field.value));

        // TODO: adds showing error message

        return (
            <DatePicker
                {...field}
                {...props}
                onDateSelected={this.handleChange}
                value={dateFns.isValid(date) ? date : ''}
            />
        );
    }
    private readonly handleChange = (value: Date | undefined): void => {
        const {
            field,
            form: { setFieldValue },
        } = this.props;
        setFieldValue(field.name, value);
    };
}
