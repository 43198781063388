import styled from '@emotion/styled/macro';
import { Icon } from '@mdi/react';
import { AttachmentButton, IAttachmentButtonProps } from '..';
import { BaseButton, Toggle, Tooltip, Variables } from '../../base';

interface INote {
    isNote: boolean;
}

interface ILockable {
    locked: boolean;
}

export const Controls = styled.div<INote>`
    padding: 16px;
    ${(props: INote) => props.isNote && noteControls}
`;

export const InputWrapper = styled.div`
    display: flex;
    align-items: flex-start;
`;

export const InputBox = styled.div<ILockable>`
    display: flex;
    flex-grow: 1;
    align-items: flex-end;

    min-height: 40px;
    padding: 6px 12px;

    border: 1px solid ${(props: ILockable): string => (props.locked ? Variables.Colors.n15 : Variables.Colors.n85)};
    border-radius: 3px;

    & textarea {
        background: inherit;
    }
`;
export const InputAttachments = styled.div`
    padding-bottom: 16px;
    margin-bottom: 6px;
    border-bottom: 1px solid ${Variables.Colors.platinum};
`;
export const AttachInput = styled.input`
    display: none;
`;
export const AttachButton = styled(BaseButton)`
    padding: 0 10px;
    min-width: auto;
    background-color: inherit;
`;
export const AttachIcon = styled(Icon)`
    transform: rotate(45deg);
    vertical-align: middle;
    fill: ${Variables.Colors.n100};
`;
export const AttachTooltip = styled(Tooltip)`
    cursor: pointer;
`;
export const StyledAttachmentButton = styled(AttachmentButton)<IAttachmentButtonProps>`
    min-width: 250px;
    max-width: 275px;
`;

export const Textarea = styled.textarea`
    flex-grow: 1;
    font-size: 15px;

    border: 0;
    padding: 0;

    outline: none;
    resize: none;
`;
export const SendButton = styled(BaseButton)`
    /* font-size: 16px; */
    /* font-weight: 500; */
    /* padding: 2px 8px; */
    /* min-width: auto; */

    /* color: ${Variables.Colors.b300}; */
    /* background-color: ${Variables.Colors.white}; */
    /* border-radius: 15.5px; */
    /* border: 1px solid ${Variables.Colors.b300}; */

    /* &:disabled {
        border-color: ${Variables.Colors.n15};
    } */
`;
export const SendIcon = styled(Icon)``;

export const Col = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    align-self: center;
`;

export const NoteContainer = styled.div`
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    margin-bottom: 16px;
`;

export const Note = styled.div`
    margin-right: 16px;
    font-size: 16px;
    color: ${Variables.Colors.b200};
`;

export const Comment = styled.div`
    margin-right: 16px;
    font-size: 16px;
    color: ${Variables.Colors.nx48};
`;

export const NoteToggle = styled(Toggle)`
    --toggle-bgcolor-on: ${Variables.Colors.b200};
`;

const noteControls = `
    background-color: ${Variables.Colors.b300_l96};
    border-top: 1px solid ${Variables.Colors.b300_l90};
    ${InputBox} {
        border-color:  ${Variables.Colors.b300_l90};
        textarea {
            color:  ${Variables.Colors.b300_l62};
        }
    }
    // ${AttachButton} {
    //     background-color: ${Variables.Colors.b300_l96};
    //     &:hover {
    //         background-color: ${Variables.Colors.b300_l90};
    //     }
    // }
`;

export const InputError = styled.div`
    color: ${Variables.Colors.warning};
    font-size: 14px;
    fill: ${Variables.Colors.warning};
    margin-top: 5px;
`;

export const InputErrorIcon = styled(Icon)`
    width: 18px;
    height: 18px;
    vertical-align: middle;
`;

export const InputErrorMessage = styled.div`
    display: inline-block;
    margin-left: 4px;
`;

export const InputContainer = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    margin-left: 16px;
`;

export const ActionsWrapper = styled.div`
    margin-top: 8px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

export const ActionsItem = styled.div`
    margin-left: 5px;
    /* display: flex;
    flex-direction: row; */
`;

export const ActionsCol = styled.div`
    display: flex;

    ${ActionsItem}:first-of-type {
        margin-left: 0;
    }
`;
