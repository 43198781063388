import { mdiPaperclip } from '@mdi/js';
import React from 'react';
import * as S from './attachment-count.styles';

export interface IAttachmentCountProps {
    className?: string;
    count: number;
}

export const AttachmentCount: React.FunctionComponent<IAttachmentCountProps> = (
    props: IAttachmentCountProps,
): React.ReactElement => {
    const { className, count } = props;
    return (
        <S.AttachmentCount>
            <S.Paperclip className={className} path={mdiPaperclip} /> {count}
        </S.AttachmentCount>
    );
};
