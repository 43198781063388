import styled from '@emotion/styled/macro';
import Icon from '@mdi/react';
import { BaseButton } from '../button';
import { Variables } from '../variables/variables';

interface IFileUploaderProps {
    showSuccess?: boolean;
    showError?: boolean;
    showDelete?: boolean;
}

export const Button = styled(BaseButton)`
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
`;
export const Label = styled.label`
    font-size: 18px;
    color: ${Variables.Colors.n100};
    margin-left: 10px;
`;

export const FileUploader = styled.input<IFileUploaderProps>`
    display: none;
`;

export const FileUploaderContainer = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
`;

export const FileUploaderUnstyledWrapper = styled.div`
    display: flex;
    align-items: center;
    position: relative;
`;

export const FileUploaderWrapper = styled(FileUploaderUnstyledWrapper)`
    border-radius: 3px;
    border: solid 1px ${Variables.Colors.nx8};
    background-color: ${Variables.Colors.nx4};
`;

export const FileUploaderBottom = styled.div`
    font-size: 13px;
    font-weight: 500;
    display: flex;
    align-items: center;
    margin-top: 8px;
`;

export const FileUploaderBottomSuccess = styled(FileUploaderBottom)`
    color: ${Variables.Colors.successful};
    fill: ${Variables.Colors.successful};
`;

export const FileUploaderBottomError = styled(FileUploaderBottom)`
    color: ${Variables.Colors.warning};
    fill: ${Variables.Colors.warning};
`;

export const FileUploaderBottomIcon = styled(Icon)`
    width: 18px;
    height: 18px;
    vertical-align: middle;
`;

export const FileUploaderBottomMessage = styled.div`
    display: inline-block;
    margin-left: 4px;
`;
