import styled from '@emotion/styled/macro';
import { ClaimIdBadge } from '../../badge';
import { Variables } from '../../base';

interface INote {
    note?: boolean;
}

const PanelElement = styled.div`
    padding: 8px 24px;
`;

const noteHeader = `
    padding: 8px 0;
    margin: 8px;
    border: 1px solid ${Variables.Colors.b100};
    border-radius: 8px;
    background-color: ${Variables.Colors.b300_l96};
`;

const commentHeader = `
    padding: 8px 8px;
    color: ${Variables.Colors.n85};
    background-color: ${Variables.Colors.white};
`;

export const Header = styled.div<INote>`
    ${(props: INote): string | undefined => (props.note ? noteHeader : commentHeader)}
`;

export const Row = styled(PanelElement)`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const Timestamp = styled.div`
    font-size: 14px;
    letter-spacing: 0.2px;
    color: ${Variables.Colors.n70};
`;

export const NoteLabel = styled.div`
    background-color: ${Variables.Colors.b100};
    color: ${Variables.Colors.white};
    font-size: smaller;
    text-transform: uppercase;
    padding: 0 4px;
`;

export const Subject = styled(PanelElement)`
    font-size: 20px;
    line-height: 1.25;
    color: ${Variables.Colors.n100};
`;

export const EditableSubject = styled(PanelElement)`
    cursor: pointer;
`;

export const ClaimWrapper = styled.div`
    padding: 12px 24px;
`;

export const ClaimButton = styled(ClaimIdBadge)`
    /* margin: 0 32px 12px 32px; */
    align-self: baseline;
`;

export const Body = styled(PanelElement)`
    font-size: 16px;
    line-height: 1.5;
    letter-spacing: 0.2px;
    color: ${Variables.Colors.n85};
`;

export const Attachments = styled(PanelElement)``;
