import { mdiCalendar, mdiCalendarRemove } from '@mdi/js';
import { Popover } from '@mui/material';
import dateFns from 'date-fns';
import _ from 'lodash';
import * as React from 'react';
import DayPicker, { DayPickerProps, NavbarElementProps } from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import { BaseInput } from '../input';
import { DatePickerNavbar } from './datepicker-navbar';
import * as S from './datepicker.styles';

export function toUTC(date: Date): Date {
    return new Date(date.getTime() + date.getTimezoneOffset() * 60000);
}

export interface IDatePickerComponentProps {
    onBlur: () => void;
    onChange: () => void;
    onClick: () => void;
    onFocus: () => void;
    onKeyDown: () => void;
    onKeyUp: () => void;
    placeholder: string;
    readOnly: boolean;
    value: string;
}

export interface IDatePickerProps {
    disabled?: boolean;
    inputProps?: Partial<IDatePickerComponentProps>;
    customDayPickerProps?: Partial<DayPickerProps>;
    onDateSelected?: (date: Date | undefined) => void;
    format?: string;
    value?: string | Date;
    name?: string;
    errorMessage?: string;
}

const defaultProps = {
    format: 'YYYY-MM-DD',
};

function formatTimezone(date: Date, format: string) {
    return dateFns.format(dateFns.addMinutes(date, date.getTimezoneOffset()), format);
}

export const DatePicker: React.FunctionComponent<IDatePickerProps> = React.memo(
    ({ value: propsVal, format, onDateSelected, disabled, inputProps, customDayPickerProps, errorMessage }) => {
        const [anchorElement, setAnchorElement] = React.useState<Element>();
        const [value, setValue] = React.useState(propsVal);

        const pickDate = React.useCallback(
            (date?: Date | undefined) => {
                onDateSelected?.(date);
            },
            [onDateSelected],
        );

        React.useEffect(() => {
            if (!_.isEqual(value, propsVal)) {
                setValue(propsVal);
            }
        }, [propsVal]);

        React.useEffect(() => {
            if (_.isDate(value)) {
                pickDate(value);
                return;
            }

            const date = parseDate(value);
            if (date) {
                pickDate(date);
                return;
            }

            // In case if we selected empty field
            if (value === '') {
                pickDate();
                return;
            }
        }, [value]);

        const renderDayPicker = (): React.ReactElement => {
            const dayPickerProps: DayPickerProps = {
                selectedDays: valueAsDate,
                month: valueAsMonth(),
                onDayClick: handleDayClick,
                showOutsideDays: true,
                navbarElement: renderNavbarElement,
                captionElement: <></>,
                ...customDayPickerProps,
            };
            return <DayPicker {...dayPickerProps} />;
        };

        const renderNavbarElement = (props: NavbarElementProps): React.ReactElement => {
            const month = dateFns.format(props.month, 'MMMM YYYY');
            return (
                <DatePickerNavbar
                    isShowRange={false}
                    month={month}
                    showPreviousButton={props.showPreviousButton}
                    onPreviousClick={props.onPreviousClick}
                    showNextButton={props.showNextButton}
                    onNextClick={props.onNextClick}
                />
            );
        };

        const valueAsDate = (date: Date): boolean => {
            const utcDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));

            if (_.isDate(value)) {
                return (value as Date).toISOString() === utcDate.toISOString();
            } else {
                return parseDate(value)?.toISOString() === utcDate.toISOString();
            }
        };

        const valueAsString = (): string | undefined => {
            if (_.isDate(value)) {
                return formatDate(value);
            } else {
                return value;
            }
        };

        const valueAsMonth = (): Date => {
            let month: Date | undefined;
            if (_.isString(value)) {
                month = parseDate(value);
            } else if (_.isDate(value)) {
                month = value;
            }
            return month || new Date();
        };

        const parseDate = (str: string | Date | undefined): Date | undefined => {
            if (str && _.size(str) === _.size(dateFormat())) {
                return dateFns.parse(new Date(str));
            }
            return undefined;
        };

        const dateFormat = (): string => {
            return format || defaultProps.format;
        };

        const formatDate = (date: Date): string => {
            return formatTimezone(date, dateFormat());
        };

        const handleChange = (e: any): void => {
            const val = e.target.value;

            setValue(val);
        };

        const handleDayClick = (date: Date): void => {
            const utcDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
            pickDate(utcDate);
        };

        const handleKeyDown = (e: React.KeyboardEvent): void => {
            if (e && e.nativeEvent) {
                if (e.nativeEvent.key === 'ArrowDown') {
                    showDayPicker(e);
                }
            }
        };

        const showDayPicker = (e: React.MouseEvent | React.KeyboardEvent): void => {
            setAnchorElement(e.target as Element);
        };

        const hideDayPicker = (): void => {
            setAnchorElement(undefined);
        };

        return (
            <S.DatePicker>
                <BaseInput
                    disabled={disabled}
                    {...inputProps}
                    errorMessage={errorMessage}
                    name={name}
                    type="text"
                    onChange={handleChange}
                    onKeyDown={handleKeyDown}
                    placeholder={dateFormat()}
                    value={valueAsString()}
                    optionPath={!!anchorElement ? mdiCalendarRemove : mdiCalendar}
                    onOption={showDayPicker}
                />
                <Popover
                    anchorEl={anchorElement}
                    open={!!anchorElement}
                    onClose={hideDayPicker}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}>
                    <S.DatePickerWrapper>{renderDayPicker()}</S.DatePickerWrapper>
                </Popover>
            </S.DatePicker>
        );
    },
);
