import styled from '@emotion/styled/macro';
import { IconButton, IconButtonProps } from '@mui/material';
import { Variables } from '../variables/variables';

interface IStylesProps {
    size?: 'small' | 'medium' | 'large';
    noBorder?: boolean;
}

const Button = styled.button<IStylesProps>`
    display: inline-flex;
    vertical-align: middle;
    align-items: center;
    justify-content: center;
    border: none;
    padding: 0;
    font-weight: ${Variables.fontWeightMedium};
    cursor: pointer;
    transition: ${Variables.transition};
    outline: none;
    user-select: none;
    background-color: unset;

    svg ~ span,
    span + svg {
        margin-left: 4px;
    }

    &:disabled {
        pointer-events: none;
    }
    &:focus {
        outline: 0;
    }

    a {
        color: inherit;
    }
`;

export const Primary = styled(Button)`
    min-width: 80px;
    color: ${Variables.Colors.white};
    font-size: 16px;
    height: 40px;
    border-radius: ${Variables.Rounded.small};
    border: solid 1px transparent;
    background-color: ${Variables.Colors.b300};
    padding: 0 20px;
    svg {
        fill: ${Variables.Colors.white};
    }

    &:hover {
        background-color: ${Variables.Colors.b400};
    }

    &:active {
        background-color: ${Variables.Colors.b500};
    }

    &:disabled {
        border: solid 1px transparent;
        background-color: ${Variables.Colors.n15_24};
        color: ${Variables.Colors.n15};
        svg {
            fill: ${Variables.Colors.n15};
        }
    }
`;

export const Warning = styled(Button)`
    min-width: 80px;
    color: ${Variables.Colors.white};
    font-size: 16px;
    height: 40px;
    border-radius: ${Variables.Rounded.small};
    border: solid 1px transparent;
    background-color: ${Variables.Colors.r30};
    padding: 0 20px;
    svg {
        fill: ${Variables.Colors.white};
    }

    &:hover {
        background-color: ${Variables.Colors.r40};
    }

    &:active {
        background-color: ${Variables.Colors.r50};
    }

    &:disabled {
        border: solid 1px transparent;
        background-color: ${Variables.Colors.n15_24};
        color: ${Variables.Colors.n15};
        svg {
            fill: ${Variables.Colors.n15};
        }
    }
`;

export const Success = styled(Button)`
    min-width: 80px;
    color: ${Variables.Colors.white};
    font-size: 16px;
    height: 40px;
    border-radius: ${Variables.Rounded.small};
    border: solid 1px transparent;
    background-color: ${Variables.Colors.b300};
    padding: 0 20px;
    svg {
        fill: ${Variables.Colors.white};
    }

    &:hover {
        background-color: ${Variables.Colors.b400};
    }

    &:active {
        background-color: ${Variables.Colors.b500};
    }

    &:disabled {
        border: solid 1px transparent;
        background-color: ${Variables.Colors.n15_24};
        color: ${Variables.Colors.n15};
        svg {
            fill: ${Variables.Colors.n15};
        }
    }
`;

export const Outlined = styled(Button)`
    min-width: 80px;
    color: ${Variables.Colors.n70};
    font-size: 16px;
    height: 40px;
    border-radius: ${Variables.Rounded.small};
    border: solid 1px ${Variables.Colors.nx8};
    background-color: ${Variables.Colors.white};
    padding: 0 20px;
    svg {
        fill: ${Variables.Colors.n70};
    }

    &:hover {
        background-color: ${Variables.Colors.n15_24};
    }

    &:active {
        background-color: ${Variables.Colors.n15_48};
    }

    &:disabled {
        color: ${Variables.Colors.n15};
        svg {
            fill: ${Variables.Colors.n15};
        }
    }
`;

export const Text = styled(Button)`
    height: 26px;
    min-width: 60px;
    border-radius: ${Variables.Rounded.small};
    color: ${Variables.Colors.n70};
    font-size: 16px;
    padding: 0 8px;
    font-weight: normal;
    svg {
        fill: ${Variables.Colors.n70};
    }

    &:hover {
        background-color: ${Variables.Colors.n15_24};
    }

    &:active {
        background-color: ${Variables.Colors.n15_48};
    }

    &:disabled {
        color: ${Variables.Colors.n15};
        svg {
            fill: ${Variables.Colors.n15};
        }
    }
`;

const Fab = styled(Button)`
    border-radius: 50%;
    height: ${(props): string => {
        switch (props.size) {
            case 'medium':
                return '40px';
            case 'small':
                return '24px';
            default:
                return '40px';
        }
    }};
    width: ${(props): string => {
        switch (props.size) {
            case 'medium':
                return '40px';
            case 'small':
                return '24px';
            default:
                return '40px';
        }
    }};
    color: var(--fab-color);
    background-color: var(--fab-background-color);
    border: ${({ noBorder }) => (noBorder ? `none` : `var(--fab-border)`)};
    fill: var(--fab-fill);
    svg {
        fill: var(--fab-fill);
    }

    > span {
        display: inline-flex;
    }
`;

export const FabDark = styled(Fab)`
    --fab-color: ${Variables.Colors.n70};
    --fab-border: none;
    --fab-fill: ${Variables.Colors.white};
    --fab-background-color: ${Variables.Colors.b300};

    &:hover {
        --fab-background-color: ${Variables.Colors.b400};
    }

    &:active {
        --fab-background-color: ${Variables.Colors.b500};
    }
`;

export const FabLight = styled(Fab)`
    --fab-color: ${Variables.Colors.n70};
    --fab-border: ${Variables.Borders.regular};
    --fab-fill: ${Variables.Colors.n70};
    --fab-background-color: ${Variables.Colors.white};

    &:hover {
        --fab-background-color: ${Variables.Colors.n15_24};
        --fab-color: ${Variables.Colors.n70};
        --fab-fill: ${Variables.Colors.n70};
    }

    &:active {
        --fab-background-color: ${Variables.Colors.n15_48};
        --fab-color: ${Variables.Colors.n70};
        --fab-fill: ${Variables.Colors.n70};
    }

    &:disabled {
        --fab-border: solid 1px ${Variables.Colors.nx8};
        --fab-background-color: ${Variables.Colors.white};
        --fab-color: ${Variables.Colors.n15};
        --fab-fill: ${Variables.Colors.n15};
    }
`;

export const Unstyled = styled(Button)``;

const MaterialIcon = styled(IconButton)`
    color: var(--fab-color);
    fill: var(--fab-fill);
    background-color: var(--fab-background-color);
    /* border: var(--fab-border); */
    height: ${(props): string => {
        switch (props.size) {
            case 'medium':
                return '40px';
            case 'small':
                return '24px';
            default:
                return '48px';
        }
    }};
    width: ${(props): string => {
        switch (props.size) {
            case 'medium':
                return '40px';
            case 'small':
                return '24px';
            default:
                return '48px';
        }
    }};
    padding: ${(props): string => {
        switch (props.size) {
            case 'medium':
                return '10px';
            case 'small':
                return '8px';
            default:
                return '8px';
        }
    }};
` as React.ComponentType<IconButtonProps>;

export const FabIconLight = styled(MaterialIcon)`
    --fab-color: ${Variables.Colors.n70};
    --fab-fill: ${Variables.Colors.n70};
    /* --fab-background-color: ${Variables.Colors.white}; */
    --fab-border: ${Variables.Borders.regular};

    &:hover {
        --fab-color: ${Variables.Colors.n70};
        --fab-fill: ${Variables.Colors.n70};
    }

    &:active {
        --fab-color: ${Variables.Colors.n70};
        --fab-fill: ${Variables.Colors.n70};
    }

    &:disabled {
        --fab-border: solid 1px ${Variables.Colors.nx8};
        --fab-color: ${Variables.Colors.n15};
        --fab-fill: ${Variables.Colors.n15};
    }
`;

export const FabIconDark = styled(MaterialIcon)`
    --fab-color: ${Variables.Colors.n70};
    --fab-border: none;
    --fab-fill: ${Variables.Colors.white};
    --fab-background-color: ${Variables.Colors.b300};

    &:hover {
        --fab-background-color: ${Variables.Colors.b400};
    }

    &:active {
        --fab-background-color: ${Variables.Colors.b500};
    }
`;
