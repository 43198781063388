import styled from '@emotion/styled/macro';
import { lighten, transparentize } from 'polished';
import { Variables } from '../../base/variables/variables';

const borderWidth = '5px';

export const SidebarItem = styled.div`
    padding-left: calc(${Variables.sidebarPadding} - ${borderWidth});
    height: 50px;
    display: flex;
    align-items: center;
    margin: 12px 0;
    cursor: pointer;
    border-left: ${borderWidth} solid transparent;

    text-decoration: none;
    color: ${Variables.Colors.p75};
    font-weight: 500;

    &.active {
        color: ${Variables.Colors.p50};
        background-color: ${transparentize(0.9, Variables.Colors.b300)};
        border-left: 5px solid ${Variables.Colors.b300};
    }

    &:not(.active):hover {
        color: ${Variables.Colors.p75};
        background-color: ${lighten(0.1, transparentize(0.76, Variables.Colors.p600))};
    }
    &:focus,
    &:active {
        color: ${Variables.Colors.p50};
        background-color: ${lighten(0.1, transparentize(0.68, Variables.Colors.p600))};
    }
`;
