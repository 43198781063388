import styled from '@emotion/styled/macro';

export const Container = styled.div`
    display: flex;
    align-items: flex-start;
    width: 100%;
`;

export const InputContainer = styled.div`
    flex: 1;
`;

export const PostfixContainer = styled.div`
    min-height: 100%;
`;
