import styled from '@emotion/styled/macro';
import { Icon } from '@mdi/react';
import { Variables } from '../../base';

export const Error = styled.div`
    display: flex;
    align-items: center;

    font-size: 13px;
    font-weight: 500;
    line-height: 1;
    letter-spacing: 0.2px;
    color: ${Variables.Colors.warning};

    margin: 8px 0;
`;

export const ErrorIcon = styled(Icon)`
    fill: ${Variables.Colors.warning};
    margin-right: 8px;
`;
