import { SHA256, WordArray } from 'crypto-js';
import Base64 from 'crypto-js/enc-base64';
import _ from 'lodash';

interface IAuthStorageFingerprint {
    id: string;
    access: string;
    refresh: string;
}

interface ILoginPayload {
    codeChallenge: string;
    submittedState: string;
}

function generateRandomString(length: number): string {
    const possible: string = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    return _.times(length, () => _.sample(possible)).join('');
}

function generateCodeVerifier(): string {
    return generateRandomString(32);
}

function generateCodeChallenge(codeVerifier: string): string {
    return base64URL(SHA256(codeVerifier));
}

function base64URL(str: WordArray): string {
    return str.toString(Base64).replace(/=/g, '').replace(/\+/g, '-').replace(/\//g, '_');
}

/**
 * Facade for persistent storage. Handles decision making for localStorage
 * vs sessionStorage persistence automatically.
 */
export class AuthStorage {
    public clientId: string;
    private prefix: string;

    constructor(clientId: string) {
        this.clientId = clientId;
        this.prefix = _.toLower(_.first(_.split(clientId, '_')) || clientId);
    }

    /* ------------- public utilities ------------- */

    public loginPayload(): ILoginPayload {
        this.codeVerifier = generateCodeVerifier();
        return {
            codeChallenge: generateCodeChallenge(this.codeVerifier),
            submittedState: generateRandomString(32),
        };
    }

    public logoutClear(): void {
        this.removeItem('access_token');
        this.removeItem('refresh_token');
        this.removeItem('code_verifier');
    }

    /* ------------- getters ------------- */

    public get fingerprint(): IAuthStorageFingerprint {
        const abbr = (s: string | undefined): string => _.join(_.take(s, 6), '');
        return {
            id: abbr(this.accountId),
            access: abbr(this.accessToken),
            refresh: abbr(this.refreshToken),
        };
    }

    public get accountId(): string | undefined {
        return this.getItem('account_id');
    }
    public set accountId(accountId: string | undefined) {
        this.setItem('account_id', accountId);
    }

    public get accessToken(): string | undefined {
        return this.getItem('access_token');
    }
    public set accessToken(accessToken: string | undefined) {
        this.setItem('access_token', accessToken);
    }

    public get refreshToken(): string | undefined {
        return this.getItem('refresh_token');
    }
    public set refreshToken(refreshToken: string | undefined) {
        this.setItem('refresh_token', refreshToken);
    }

    public get codeVerifier(): string | undefined {
        return this.getItem('code_verifier');
    }
    public set codeVerifier(codeVerifier: string | undefined) {
        this.setItem('code_verifier', codeVerifier);
    }

    private prefixKey(key: string): string {
        return `${this.prefix}_${key}`;
    }

    private getStringOrUndefined(value: string | null): string | undefined {
        return _.isNull(value) ? undefined : value;
    }

    private getItem(key: string): string | undefined {
        const pk = this.prefixKey(key);
        // (Dmitry) Don't use session storage
        // const session = this.getStringOrUndefined(window.sessionStorage.getItem(pk));
        const local = this.getStringOrUndefined(window.localStorage.getItem(pk));

        return local;
    }

    // NB: always write BOTH sessionStorage and localStorage
    // other tabs continue using session values even if local changes
    private setItem(key: string, value: string | undefined): void {
        if (!_.isNil(value)) {
            const pk = this.prefixKey(key);
            window.sessionStorage.setItem(pk, value);
            window.localStorage.setItem(pk, value);
        }
    }
    private removeItem(key: string): void {
        const pk = this.prefixKey(key);
        window.sessionStorage.removeItem(pk);
        window.localStorage.removeItem(pk);
    }
}
