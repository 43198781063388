import styled from '@emotion/styled/macro';
import { darken, lighten, transparentize } from 'polished';
import { Variables } from '../base';

export enum BadgeType {
    ORANGE = 1,
    YELLOW,
    GREEN,
    BLUE,
    BLUE_LIGHT,
    PINK,
    RED,
    PURPLE,
}

export const BadgeBody = styled.div`
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    /* padding: 3px; */
`;

export const ActionButton = styled.div`
    display: inline-flex;
    border-left: 1px solid;
    min-width: 20px;
    justify-content: center;
    padding: 5px;
    cursor: pointer;
`;

export const Badge = styled.div<{ type?: BadgeType; size?: 'small' }>`
    ${(props): string => {
        let color;
        let bgColor;
        let border;
        switch (props.type) {
            case BadgeType.ORANGE:
                color = Variables.Colors.sunset400;
                bgColor = transparentize(0.75, Variables.Colors.subS10);
                break;
            case BadgeType.YELLOW:
                color = Variables.Colors.amber20;
                bgColor = transparentize(0.75, Variables.Colors.amber10);
                break;
            case BadgeType.GREEN:
                color = Variables.Colors.subG20;
                bgColor = transparentize(0.75, Variables.Colors.subG10);
                break;
            case BadgeType.BLUE:
                color = Variables.Colors.i20;
                bgColor = transparentize(0.75, Variables.Colors.i10);
                break;
            case BadgeType.BLUE_LIGHT:
                color = Variables.Colors.b300_l62;
                bgColor = Variables.Colors.b300_l96;
                border = Variables.Colors.b300_l90;
                break;
            case BadgeType.PINK:
                color = Variables.Colors.r30;
                bgColor = transparentize(0.75, Variables.Colors.r20);
                break;
            case BadgeType.RED:
                color = Variables.Colors.r50;
                bgColor = transparentize(0.75, Variables.Colors.r40);
                break;
            case BadgeType.PURPLE:
                color = Variables.Colors.rose20;
                bgColor = transparentize(0.75, Variables.Colors.rose10);
                break;
            default:
                color = Variables.Colors.n85;
                bgColor = transparentize(0.75, Variables.Colors.n30);
        }

        return `
            color: ${color};
            background-color: ${bgColor};
            border: 1px solid ${border};
            svg {
                fill: ${color};
            }
            ${ActionButton} {
                border-color: ${border};
                svg {
                    fill: ${color};
                }
            }
            &:hover {
                ${
                    props.onClick
                        ? `
                            cursor: pointer;
                            color: ${lighten(0.1, color)};
                            border-color: ${border ? darken(0.2, border) : undefined};
                        `
                        : undefined
                }

                ${ActionButton} {
                    border-color: ${border ? darken(0.2, border) : undefined};
                    svg {
                        fill: ${darken(0.2, color)};
                    }
                }
            }
        `;
    }}

    display: flex;
    align-items: center;
    font-size: 14px;
    border-radius: ${({ size }) => (size === 'small' ? Variables.Rounded.xsmall : Variables.Rounded.xlarge)};

    ${BadgeBody} {
        padding: ${({ size }) => (size === 'small' ? '3px 6px' : '6px 12px')};
    }
`;

export const Container = styled.div`
    display: flex;
    align-items: center;
`;

export const PostfixContainer = styled.div`
    min-height: 100%;
`;
