import { mdiDotsHorizontal, mdiDotsVertical, mdiInformationOutline } from '@mdi/js';
import Icon from '@mdi/react';
import { Tooltip } from '@mui/material';
import _ from 'lodash';
import React from 'react';
import { BaseButton, Popover, PopoverHeader, PopoverItem, TooltipTransitionComponent } from '../../../base';
import { ITableColumn } from '../../data-table.presenter';
import * as S from './settings.styles';

interface ISettingsProps<D extends object> {
    columns?: ITableColumn<D>[];
    hiddenColumnIds: string[];
    onColumnToggle: (columnId: string) => void;
    variant?: 'small';
}

export class Settings<D extends object = {}> extends React.PureComponent<ISettingsProps<D>> {
    public render(): React.ReactElement {
        return (
            <S.SettingsContainer>
                <Popover renderButton={this.renderButton} align="right" selectable={true} pinned={true}>
                    <PopoverHeader>Select visible columns</PopoverHeader>
                    {this.renderItems()}
                </Popover>
            </S.SettingsContainer>
        );
    }

    private renderItems = (): React.ReactElement[] => {
        const { columns, hiddenColumnIds, onColumnToggle } = this.props;
        if (!columns) {
            return [];
        }

        return columns
            .filter(column => column.Header)
            .map(column => {
                if (!column.id) {
                    throw new Error('Column must have an id!');
                }

                const columnId: string = column.id;
                const header = _.isFunction(column.Header) ? _.startCase(columnId) : column.Header;

                const handleSelectedChange = (): void => onColumnToggle(columnId);
                return (
                    <PopoverItem
                        selected={!hiddenColumnIds.includes(columnId)}
                        key={columnId}
                        onClick={handleSelectedChange}>
                        <S.ItemWrapper>
                            <span>{header}</span>
                            {column.description ? (
                                <Tooltip
                                    placement="top"
                                    TransitionComponent={TooltipTransitionComponent}
                                    title={<span>{column.description}</span>}
                                    enterTouchDelay={0}
                                    disableInteractive={true}>
                                    <S.IconWrapper>
                                        <Icon path={mdiInformationOutline} size={0.75} />
                                    </S.IconWrapper>
                                </Tooltip>
                            ) : null}
                        </S.ItemWrapper>
                    </PopoverItem>
                );
            });
    };

    private renderButton = (): React.ReactElement => {
        if (this.props.variant === 'small') {
            return (
                <S.ActionButton variant="outlined" size="small" type="button">
                    <Icon path={mdiDotsHorizontal} size={1} />
                </S.ActionButton>
            );
        }
        return (
            <BaseButton variant="fab-light">
                <Icon path={mdiDotsVertical} size={1} />
            </BaseButton>
        );
    };
}
