import React, { useEffect, useRef } from 'react';
import { RouteComponentProps, useHistory, withRouter } from 'react-router-dom';
import { ApiErrorResponse, ApiErrorResponseFromJSON, ApiValidationErrorResponse, ApiValidationErrorResponseFromJSON } from '../../api';
import { BaseDialog } from '../base';
import * as S from './error-boundary.styles';

interface IErrorBoundaryProps extends Partial<RouteComponentProps> {
    children: React.ReactElement | React.ReactElement[] | (() => React.ReactElement);
}

interface IErrorBoundaryState {
    error?: Error;
    info?: React.ErrorInfo;
}

@(withRouter as any)
export class ErrorBoundary extends React.Component<IErrorBoundaryProps, IErrorBoundaryState> {
    public static getDerivedStateFromError(error: Error, info: React.ErrorInfo): IErrorBoundaryState {
        return {
            error,
            info,
        };
    }

    constructor(props: IErrorBoundaryProps) {
        super(props);
        this.state = {};
    }

    public render() {
        const { error, info } = this.state;
        const { children } = this.props;
        if (!error) {
            return children;
        }

        return <ErrorMessage error={error} info={info} onReset={this.reset} />;
    }

    private readonly reset = (): void => {
        this.setState({
            error: undefined,
            info: undefined,
        });
    };
}

const ErrorMessage: React.FC<{
    error: Error,
    onReset: () => void;
    info?: React.ErrorInfo,
}> = ({ error, info, onReset }) => {
    const textRef = useRef<HTMLTextAreaElement>(null);
    const [errorMessage, setErrorMessage] = React.useState<string>();

    useEffect(() => {
        (async () => {
            const parsedError = await getErrorMessage(error);
            setErrorMessage(parsedError.message);
        })();
    }, [error]);

    const history = useHistory();

    const handleGoBack = () => {
        history.push('/');

        onReset();
    };

    const handleContinue = () => {
        onReset();
    };

    const renderActions = () => {
        return (<S.DialogActions>
            <S.DialogButton variant="outlined" onClick={handleGoBack}>
                Go back
            </S.DialogButton>
            <S.DialogButton variant="primary" onClick={handleContinue}>
                OK, continue
            </S.DialogButton>
        </S.DialogActions>)
    }

    const selectAll = () => {
        if (textRef && textRef.current) {
            textRef.current.select();
        }
    };

    return <BaseDialog
        open={true}
        disableBackdropClick={true}
        disableEscapeKeyDown={true}
        renderTitle="Error encountered"
        renderActions={renderActions()}>
        <S.DialogBody>
            <S.Col>
                {errorMessage}
                <S.Textarea ref={textRef} rows={11} value={errorMessage} onClick={selectAll} />
            </S.Col>
        </S.DialogBody>
    </BaseDialog>
}
export async function getErrorMessage(
    err: Error,
    includeSystemErrors?: boolean,
): Promise<{ error: string; message: string }> {
    if (includeSystemErrors && err instanceof Error) {
        return { error: '', message: err.message };
    }

    if (!(err instanceof Response)) {
        if (typeof err.message === 'string') {
            return { error: '', message: err.message };
        }

        // TODO: Add handling not api errors
        return { error: 'Unrecognized error', message: JSON.stringify(err) };
    }

    if (err.status === 400) {
        const error: ApiValidationErrorResponse = await err.json();
        return handleValidationError(ApiValidationErrorResponseFromJSON(error));
    }

    if (err.status === 403) {
        const error: ApiValidationErrorResponse = await err.json();
        return handleAccessDeniedError(ApiValidationErrorResponseFromJSON(error));
    }

    if (err.status === 504) {
        return handleServerError(
            {
                message:
                    'We added your request to the queue, it will be processed shortly. You can close this page and check the result later.',
            },
            '',
        );
    }

    if (err.status >= 500 && err.status < 600) {
        const error: ApiErrorResponse = await err.json();
        return handleServerError(ApiErrorResponseFromJSON(error));
    }

    return handleUnknownError(await err.json());
}

function handleValidationError(err: ApiValidationErrorResponse) {
    const errors: string[] = [];
    if (err.errors) {
        for (const key of Object.keys(err.errors)) {
            errors.push(`${key}: ${err.errors[key]}`);
        }
    }

    return { error: errors.join('\n'), message: `Trace Id: ${err.traceId}` };
}

function handleAccessDeniedError(err: any) {
    return {
        error: 'The current user does not have permission to view this resource.',
        message: JSON.stringify(err),
    };
}

function handleServerError(err: ApiErrorResponse, customMessage?: string) {
    const errors: string[] = [];
    if (err.message) {
        errors.push(err.message);
    }
    return { error: errors.join('\n'), message: customMessage ?? `Trace Id: ${err.traceId}` };
}

function handleUnknownError(err: any) {
    // TODO: Find a way to handle such errors
    return { error: 'Unknown error', message: JSON.stringify(err) };
}