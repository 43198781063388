import _ from 'lodash';
import { formatDate, parseDate } from '../../../services';

interface IAmount {
    amount?: string;
}

interface IChargemasterItem {
    id?: string;
    allowed?: IAmount;
}

type GroupType =
    | 'CONTRACTUAL_OBLIGATIONS'
    | 'OTHER_ADJUSTMENTS'
    | 'PAYER_INITIATED_REDUCTIONS'
    | 'PATIENT_RESPONSIBILITY'
    | 'CORRECTIONS_AND_REVERSALS';

interface IPaymentItemAdjustment {
    amount?: IAmount;
    group?: GroupType;
    reasonCode?: string;
    units?: string;
}

interface IPaymentItem {
    chargeAmount?: IAmount;
    allowedAmount?: IAmount;
    paidAmount?: IAmount;
    adjustments?: IPaymentItemAdjustment[];
    remarkCodes?: string[];
}

interface IPayment {
    items?: IPaymentItem[];
}

interface IProvider {
    id?: string;
    first?: string;
    last?: string;
}

interface IInstitutionalServiceLine {
    f44ProcedureCode?: string;
    f44ModifierCodes?: string[];
    f45ServiceDate?: string;
    f46ServiceUnits?: string;
    f47TotalChargeAmount?: IAmount;
}

interface IInstitutionalClaimTemplate {
    f42ClaimLines?: IInstitutionalServiceLine[];
}

interface INynf3ServiceLine {
    f15aFromDateOfService?: string;
    f15aThroughDateOfService?: string;
    f15dProcedureCode?: string;
    f15dModifierCodes?: string[];
    f15dServiceUnit?: string;
    f15eChargeAmount?: IAmount;
}

interface INynf3ClaimTemplate {
    f15ServiceLines?: INynf3ServiceLine[];
}

interface IProfessionalServiceLine {
    f24aFromDateOfService?: string;
    f24aThroughDateOfService?: string;
    f24bPlaceOfServiceCode?: string;
    f24dProcedureCode?: string;
    f24dProcedureModifiers?: string[];
    f24fChargeAmount?: IAmount;
    f24gUnits?: number;
    x12ControlNumber?: string;
    x12ProviderIds?: string[];
    chargemasterItemId?: string;
}

interface IProfessionalClaimTemplate {
    f24ServiceLine?: IProfessionalServiceLine[];
}

export interface IClaimPayerSummaryRecordReason {
    reason?: string;
    group?: string;
    code?: string;
    units?: string;
    amount?: number;
}

export interface IClaimPayerSummaryRecord {
    dos?: string;
    units?: number;
    service?: string;
    provider?: string;
    placeOfService?: string;
    modifiers?: string[];
    billed?: number;
    goal?: number;
    allowed?: number;
    payerPaid?: number;
    disallow?: number;
    reasons?: IClaimPayerSummaryRecordReason[];
}

const reformatDates = (date1?: string, date2?: string): string | undefined => {
    const parse = parseDate('Date');
    const d1 = date1 ? parse(date1) : undefined;
    const d2 = date2 ? parse(date2) : undefined;
    if (d1 && d2) {
        return `${formatDate('MonthDay')(d1)} - ${formatDate('MonthDayYear')(d2)}`;
    }
};

const reformatAmount = (a: IAmount | undefined): number => {
    return reformatNumber(_.get(a, 'amount', '0'));
};

const reformatNumber = (n: string | undefined): number => {
    return n === undefined ? 0 : Number.parseFloat(n);
};

const isInstitutionalTemplate = (template: object): template is IInstitutionalClaimTemplate => {
    return 'f42ClaimLines' in template;
};
const parseInstitutionalTemplate = (template: IInstitutionalClaimTemplate): IClaimPayerSummaryRecord[] => {
    const serviceLines = _.get(template, 'f42ClaimLines', []);
    return _.map(serviceLines, line => ({
        dos: reformatDates(line.f45ServiceDate),
        service: line.f44ProcedureCode,
        modifiers: line.f44ModifierCodes,
        units: reformatNumber(line.f46ServiceUnits),
        billed: reformatNumber(_.get(line.f47TotalChargeAmount, 'amount', '0')),
    }));
};
const isNynf3Template = (template: object): template is INynf3ClaimTemplate => {
    return 'f15ServiceLines' in template;
};
const parseNynf3Template = (template: INynf3ClaimTemplate): IClaimPayerSummaryRecord[] => {
    const serviceLines = _.get(template, 'f15ServiceLines', []);
    return _.map(serviceLines, line => ({
        dos: reformatDates(line.f15aFromDateOfService, line.f15aThroughDateOfService),
        service: line.f15dProcedureCode,
        modifiers: line.f15dModifierCodes,
        units: reformatNumber(line.f15dServiceUnit),
        billed: reformatNumber(_.get(line.f15eChargeAmount, 'amount', '0')),
    }));
};
const isProfessionalTemplate = (template: object): template is IProfessionalClaimTemplate => {
    return 'f24ServiceLine' in template;
};
const parseAdjustmentCodes = (paymentAdjustments: IPaymentItemAdjustment[]) => {
    return _.map(paymentAdjustments, adjustment => parseAdjustmentCode(adjustment));
};
const parseAdjustmentCode = (adjustment: IPaymentItemAdjustment): IClaimPayerSummaryRecordReason | undefined => {
    if (!adjustment) {
        return undefined;
    }
    let prefix = '';
    switch (adjustment.group) {
        case 'CONTRACTUAL_OBLIGATIONS':
            prefix = 'CO';
            break;
        case 'OTHER_ADJUSTMENTS':
            prefix = 'OA';
            break;
        case 'PAYER_INITIATED_REDUCTIONS':
            prefix = 'PIR';
            break;
        case 'PATIENT_RESPONSIBILITY':
            prefix = 'PR';
            break;
        case 'CORRECTIONS_AND_REVERSALS':
            prefix = 'CR';
            break;
        default:
            throw new Error(`Unknown group type: ${adjustment.group}`);
    }
    return {
        reason: `${prefix}-${adjustment.reasonCode}`,
        group: adjustment.group,
        code: adjustment.reasonCode,
        units: adjustment.units,
        amount: reformatAmount(adjustment.amount),
    };
};
const parseRemarkCodes = (codes: string[]): IClaimPayerSummaryRecordReason[] => {
    return _.map(codes, code => ({
        reason: code,
    }));
};
const parseProfessionalServiceLine = (
    line: IProfessionalServiceLine,
    chargemasterItems: IChargemasterItem[],
    payments: IPayment[],
    providers: IProvider[],
): IClaimPayerSummaryRecord => {
    // Fields directly from ProfessionalClaimTemplate
    const dos = reformatDates(line.f24aFromDateOfService, line.f24aThroughDateOfService);
    const units = line.f24gUnits;
    const service = line.f24dProcedureCode;
    const modifiers = line.f24dProcedureModifiers;
    const placeOfService = line.f24bPlaceOfServiceCode;

    const firstProvider = _.find(providers, { id: _.first(line.x12ProviderIds) });
    const provider = firstProvider ? `${firstProvider.first} ${firstProvider.last}` : undefined;

    // Fields based on ChargemasterItem
    const chargemasterItem = _.find(chargemasterItems, { id: line.chargemasterItemId });
    const goal = reformatAmount(chargemasterItem?.allowed);

    // Find IPaymentItem by mapping x12ControlNumber to controlNumber (NB: data is case sensitive)
    const paymentItem = _.find(
        _.flatMap(payments, 'items'),
        item => _.toLower(item.controlNumber) === _.toLower(line.x12ControlNumber),
    );

    // Fields based on IPaymentItem
    const paymentChargeAmount = reformatAmount(paymentItem?.chargeAmount);
    const templateChargeAmount = reformatAmount(line.f24fChargeAmount);
    const billed = paymentChargeAmount || templateChargeAmount;
    const allowed = reformatAmount(paymentItem?.allowedAmount);
    const payerPaid = reformatAmount(paymentItem?.paidAmount);

    // disallowed: total of IPaymentItemAdjustment.amount from IPaymentItem.adjustments
    const paymentAdjustments = paymentItem?.adjustments;
    const disallow = _.sum(_.map(paymentAdjustments, adjustment => reformatAmount(adjustment?.amount)));

    // reasons: from PaymentItem.adjustments & PaymentItem.remarkCodes
    const reasons = _.compact([
        ...parseAdjustmentCodes(paymentAdjustments),
        ...parseRemarkCodes(paymentItem?.remarkCodes),
    ]);
    return {
        dos,
        units,
        service,
        provider,
        placeOfService,
        modifiers,
        billed,
        goal,
        allowed,
        payerPaid,
        disallow,
        reasons,
    };
};
const parseProfessionalTemplate = (
    template: IProfessionalClaimTemplate,
    chargemasterItems: IChargemasterItem[],
    payments: IPayment[],
    providers: IProvider[],
): IClaimPayerSummaryRecord[] => {
    return _.map(template.f24ServiceLine, (line, i) =>
        parseProfessionalServiceLine(line, chargemasterItems, payments, providers),
    );
};

export const parseRecords = (
    template: IProfessionalClaimTemplate | IInstitutionalClaimTemplate | INynf3ClaimTemplate,
    chargemasterItems: IChargemasterItem[],
    payments: IPayment[],
    providers: IProvider[],
): IClaimPayerSummaryRecord[] => {
    let records: IClaimPayerSummaryRecord[] = [];
    if (isInstitutionalTemplate(template)) {
        records = parseInstitutionalTemplate(template);
    } else if (isNynf3Template(template)) {
        records = parseNynf3Template(template);
    } else if (isProfessionalTemplate(template)) {
        records = parseProfessionalTemplate(template, chargemasterItems, payments, providers);
    }
    const sorted = _.reverse(_.sortBy(records, 'dateOfService'));
    // append summary after sorting
    sorted.push({
        billed: _.sum(_.map(sorted, 'billed')),
        goal: _.sum(_.map(sorted, 'goal')),
        allowed: _.sum(_.map(sorted, 'allowed')),
        payerPaid: _.sum(_.map(sorted, 'payerPaid')),
        disallow: _.sum(_.map(sorted, 'disallowed')),
    });
    return sorted;
};
