import {CoreFetch, OAuthApi, ToastContext, TwoFactorType} from '@enter/core';
import {Button, CircularProgress} from '@mui/material';
import {PageHeader} from 'components';
import * as S from 'components/styles';
import {Form, Formik, FormikProps} from 'formik';
import React, {useContext, useState} from 'react';
import ReactCodeInput from 'react-code-input';
import * as Yup from 'yup';
import {delayedReturnRedirect, handleFetch, nonNumericKeyCodes} from '../util';
import {IAuthorizeParams} from './authorize.models';

const FieldsCount = 6;

const schema = Yup.object().shape({
    code: Yup.string()
        .matches(/^\d{6}$/, 'Code is invalid')
        .required('Code is required'),
});

interface ITwoFactor {
    code: string;
}

interface ITwoFactorFormProps extends IAuthorizeParams {
    email: string;
    responseId: string;
    responseType: TwoFactorType;
}
export const TwoFactorForm: React.FunctionComponent<ITwoFactorFormProps> = (
    props: ITwoFactorFormProps,
): React.ReactElement => {
    const { client_id, code_challenge, redirect_uri, scope, email, responseId, invitation_code, responseType } = props;
    const toastContext = useContext(ToastContext);

    const [isLoading, setLoading] = useState(false);

    const initial: ITwoFactor = {
        code: '',
    };

    const showError = (msg: string): void => {
        toastContext.notify && toastContext.notify(msg, 'left');
    };
    const determineFlow = (): string => {
        switch (client_id) {
            case 'PROVIDER_DASHBOARD':
                return 'PROVIDER';
            case 'SUPPORT_TOOL':
                return 'SUPPORT';
            default:
                return 'OTHER';
        }
    };
    const getTitle = () => {
        const flow = determineFlow();
        return flow === 'SUPPORT' ? 'Support Sign In' : 'Sign In';
    };

    const onSubmit = (values: ITwoFactor) => {
        const { code } = values;
        window.grecaptcha.ready(async () => {
            const recaptcha: string = await window.grecaptcha.execute(process.env.REACT_APP_RECAPTCHA_KEY, {
                action: 'login',
            });
            setLoading(true);
            const token = await handleFetch(
                async () =>
                    CoreFetch<OAuthApi>(OAuthApi).oauthLoginToken({
                        grantType: 'password',
                        clientId: client_id,
                        clientSecret: code_challenge + ';' + recaptcha,
                        username: email,
                        password: 'TWOFACTOR;' + responseId + ';' + code,
                        invitationCode: !!invitation_code ? invitation_code : undefined,
                        scope,
                        redirectUri: redirect_uri
                    }),
                showError,
            );
            if (token) {
                const { accessToken, flow } = token;
                if (accessToken) {
                    delayedReturnRedirect(redirect_uri, flow, accessToken);
                } else {
                    showError('Error retrieving access token');
                    // tslint:disable-next-line:no-console
                    console.warn(token);
                }
            }
            setLoading(false);
        });
    };

    const onChange = (formikProps: FormikProps<ITwoFactor>): ((code: string) => void) => {
        return (code: string): void => {
            formikProps.setFieldValue('code', code);
            formikProps.validateForm();

            if (code.length === FieldsCount) {
                onSubmit({ ...formikProps.values, code });
            }
        };
    };

    return (
        <Formik
            initialValues={initial}
            validationSchema={schema}
            onSubmit={onSubmit}
            validateOnBlur={true}
            validateOnChange={true}
            validateOnMount={true}>
            {(formikProps: FormikProps<ITwoFactor>) => (
                <Form>
                    <PageHeader
                        title={getTitle()}
                        boldInstructionText={responseType === TwoFactorType.SMS ? 'We just sent a text message to your phone.' : 'Your one-time code should be available in your 2-factor app.'}
                        instructionText="Please enter the code below:"
                    />
                    <S.Centered>
                        <ReactCodeInput
                            type="text"
                            onChange={onChange(formikProps)}
                            fields={FieldsCount}
                        />
                    </S.Centered>
                    <S.Spacer />
                    <S.ButtonWrapper>
                        <Button
                            variant="contained"
                            color="primary"
                            disabled={isLoading || !formikProps.isValid}
                            fullWidth={true}
                            tabIndex={1}
                            type="submit">
                            {isLoading ? (
                                <CircularProgress size={24} className="LoginButtonProgress" />
                            ) : (
                                'Submit Code To Login'
                            )}
                        </Button>
                        {/* {isLoading && } */}
                    </S.ButtonWrapper>
                </Form>
            )}
        </Formik>
    );
};
