import { mdiAlert, mdiCircle, mdiInformationOutline } from '@mdi/js';
import { Tooltip } from '@mui/material';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { formatNumber } from '../../../services';
import { LoadingMeter, TooltipTransitionComponent } from '../../base';
import * as S from './financial-overview.styles';

export type OverviewItem = {
    name: string;
    shortLabel?: string;
    amount: number | undefined;
    showRefundWarning?: boolean;
    infoMessage?: string;
};

interface IFinancialOverviewProps {
    loading: boolean;
    total: OverviewItem;
    segments: OverviewItem[];
    goal: OverviewItem;
}

const formatAmount = formatNumber('Currency');
export const FinancialOverview: React.FC<IFinancialOverviewProps> = (props: IFinancialOverviewProps) => {
    const { loading, segments, total, goal } = props;

    const [isShowDetails, setShowDetails] = useState(true);
    const [name, setName] = useState(segments[0].shortLabel || segments[0].name);
    const [featuredSegment, setFeaturedSegment] = useState<OverviewItem>(segments[0]);

    useEffect(() => {
        if (segments[0]) {
            setFeaturedSegment(segments[0]);
        }
    }, [segments[0]]);

    const mouseOver = (i: number) => {
        return () => {
            const item = _.nth(segments, i);
            if (item) {
                setName(item.shortLabel || item.name);
                setFeaturedSegment(item);
            }
        };
    };

    const mouseOut = (i: number) => {
        return () => {
            const item = _.first(segments);
            if (item) {
                setName(item.shortLabel || item.name);
                setFeaturedSegment(item);
            }
        };
    };

    const handleShowMore = () => {
        setShowDetails(!isShowDetails);
    };

    const renderLoader = (): React.ReactElement => {
        return (
            <S.Loader>
                <LoadingMeter />
            </S.Loader>
        );
    };

    const renderBody = (): React.ReactElement => {
        const segmentsItems = _.map([...segments], (item, i) => (
            <S.Segment
                key={i}
                ord={i}
                pct={(100 * (item.amount || 0)) / (total.amount || 1)}
                onMouseOver={mouseOver(i)}
                onMouseOut={mouseOut(i)}
            />
        ));

        const details = _.map([...segments], (item, i) => {
            const warning = (item.amount || 0) < 0;
            const showWarning = warning && item.showRefundWarning;
            const openTooltip = showWarning || item.infoMessage ? undefined : false;
            const message = showWarning
                ? `The patient is due a refund of $${Math.abs(item.amount || 0)}`
                : item.infoMessage || '';

            return (
                <S.Detail key={i} warning={showWarning}>
                    <S.Dot path={mdiCircle} ord={i} />
                    {showWarning && <S.WarningIcon path={mdiAlert} size={0.75} />}
                    {item.name}
                    <S.Spacer />
                    <S.DetailAmount>{formatAmount(item.amount)}</S.DetailAmount>
                    {!!message && (
                        <Tooltip
                            key={i}
                            placement="top"
                            open={openTooltip}
                            title={message}
                            enterTouchDelay={0}
                            TransitionComponent={TooltipTransitionComponent}>
                            <div>
                                <S.InfoIcon path={mdiInformationOutline} size={0.5} />
                            </div>
                        </Tooltip>
                    )}
                </S.Detail>
            );
        });

        return (
            <>
                <S.Body>
                    <S.Feature>
                        <S.FeatureAmount>{formatAmount(featuredSegment.amount)}</S.FeatureAmount>
                        <S.FeatureName>{name}</S.FeatureName>
                    </S.Feature>
                    <S.Segments>
                        {segmentsItems}
                        {!_.isUndefined(goal.amount) && goal.amount >= 0 ? (
                            <>
                                <S.Goal pct={(100 * (goal.amount || 0)) / (total.amount || 1)}>
                                    {goal.name}: <S.GoalAmount>{formatAmount(goal.amount)}</S.GoalAmount>
                                </S.Goal>
                                <S.GoalLine pct={(100 * (goal.amount || 0)) / (total.amount || 1)} />
                            </>
                        ) : (
                            <></>
                        )}
                    </S.Segments>
                    <S.Feature>
                        <S.FeatureAmount>{formatAmount(total.amount)}</S.FeatureAmount>
                        <S.FeatureName>{total.shortLabel || total.name}</S.FeatureName>
                    </S.Feature>
                </S.Body>
                {isShowDetails && <S.Details>{details}</S.Details>}
                <S.Footer onClick={handleShowMore}>{isShowDetails ? 'Hide details' : 'Show details'}</S.Footer>
            </>
        );
    };

    return (
        <S.FinancialOverviewCard size="large">
            {loading && renderLoader()}
            {!loading && renderBody()}
        </S.FinancialOverviewCard>
    );
};
