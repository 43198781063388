import React, {useContext, useEffect, useState} from "react";
import {useHistory, useLocation} from 'react-router-dom';
import {handleFetch, parseQueryParams} from '../util';
import {PageHeader, SideImageWrapper} from "../../components";
import {InvitationForm} from "./invitation-form";
import {TwoFactorForm} from "./two-factor-form";
import {LoginForm} from "./login-form";
import {AuthApi, CoreFetch, ToastContext} from "@enter/core";
import * as S from "../../components/styles";

export const SingleSignOn: React.FunctionComponent = (): React.ReactElement => {
    const [isLoading, setLoading] = useState(true);

    const history = useHistory();
    const location = useLocation();

    useEffect(() => {
        handleLogin();
    }, []);

    const handleLogin = () => {
        console.log("SSO href = " + window.location.href);

        const ssoParams = window.localStorage.getItem('sso_params');
        let appendedLocationSearch = ssoParams ? ssoParams + '&' + location.search.substring(1) : location.search;
        if (appendedLocationSearch.indexOf('sso_return') === -1) {
            appendedLocationSearch = appendedLocationSearch + '&sso_return=true';
        }

        window.localStorage.removeItem('sso_params');

        const ssoRegistrationRequired = "true" === window.localStorage.getItem('sso_registration_required');
        const invitationCodePresent = (appendedLocationSearch.indexOf('invitation_code=') > -1);

        window.localStorage.removeItem('sso_registration_required');

        let urlRoot: string = '/authorize';

        if(invitationCodePresent && ssoRegistrationRequired) {
            urlRoot = '/signup_invitation';
        } else if(invitationCodePresent) {
            if (appendedLocationSearch.indexOf('skip_invitation_screen') === -1) {
                appendedLocationSearch = appendedLocationSearch + '&skip_invitation_screen=true';
            }
        }

        history.push(urlRoot + appendedLocationSearch);
    };

    return (
        <SideImageWrapper>
            <div>
                <PageHeader
                    title="Single Sign On"
                />
                {isLoading && (
                    <S.Centered>
                        <S.Progress />
                    </S.Centered>
                )}
            </div>
        </SideImageWrapper>
    );
}
