import styled from '@emotion/styled/macro';
import { Icon } from '@mdi/react';
import { BaseButton, Spinner, Tooltip, Variables } from '../base';

export const AttachmentButton = styled.div`
    display: inline-flex;
    align-items: center;
    padding: 12px 16px;

    min-width: 275px;
    max-width: 365px;

    border-radius: ${Variables.Rounded.small};
    border: ${Variables.Borders.regular};
    border-color: ${Variables.Colors.n15};

    transition: all 0.2s ease-in;

    &:not(:first-of-type) {
        margin-top: 16px;
    }

    &:hover {
        border-color: ${Variables.Colors.n30};
        cursor: pointer;
    }
`;

export const AttachmentImage = styled.div`
    display: inline-flex;
    max-width: 365px;

    border-radius: ${Variables.Rounded.medium};
    border: ${Variables.Borders.regular};
    border-color: ${Variables.Colors.n15};

    &:not(:first-of-type) {
        margin-top: 16px;
    }

    &:hover {
        border-color: ${Variables.Colors.n30};
        cursor: pointer;
    }
`;

export const AttachmentThumbnail = styled.img``;

export const PaperclipIcon = styled(Icon)`
    fill: ${Variables.Colors.n30};
    transform: rotate(45deg);
`;

export const AttachmentLabels = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow: hidden;

    font-size: 15px;
    letter-spacing: 0.2px;

    margin-left: 12px;
`;

export const AttachmentFilename = styled.div`
    color: ${Variables.Colors.n100};
    margin-bottom: 2px;

    text-overflow: ellipsis;
    overflow: hidden;
`;

export const AttachmentSize = styled.div`
    color: ${Variables.Colors.n30};
`;

export const CloseIcon = styled(Icon)`
    fill: ${Variables.Colors.n50};
`;

export const IconButton = styled(BaseButton)`
    margin-left: 12px;
    padding: 0;
    background: none;
    min-width: 0;
    width: 24px;
    height: 24px;
    border: none;
    &:hover {
        svg {
            fill: ${Variables.Colors.n30};
        }
    }
`;

export const DownloadSpinner = styled(Spinner)`
    svg {
        fill: ${Variables.Colors.n30};
    }
`;

export const StyledTooltip = styled(Tooltip)`
    cursor: pointer;
`;
