import { mdiCheck } from '@mdi/js';
import * as React from 'react';
import * as S from './checkbox.styles';

export interface IBaseCheckboxProps extends React.PropsWithoutRef<JSX.IntrinsicElements['input']> {
    label?: string;
    type?: 'checkbox';
    variant?: 'medium' | 'large';
}

export class BaseCheckbox extends React.Component<IBaseCheckboxProps> {
    public render(): React.ReactElement {
        const { className, checked, label, variant = 'medium', disabled, ...props } = this.props;

        return (
            <S.CheckboxContainer className={className} checked={checked} disabled={disabled}>
                <S.HiddenCheckbox checked={checked} disabled={disabled} {...props} />
                <S.StyledCheckbox variant={variant}>
                    <S.CheckIcon path={mdiCheck} />
                </S.StyledCheckbox>
                {label && <S.Label variant={variant}>{label}</S.Label>}
            </S.CheckboxContainer>
        );
    }
}
