import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import * as S from './loading-meter.styles';

interface ILoadingMeterProps {
    className?: string;
}

const padding = 3;
const loaderSize = 48;
const barCount = 3;
const barWidth = 12;
const barHeight = 42;
const offset = 24;
const maxChange = 8;

function renderLoadingMeter(props: ILoadingMeterProps): React.ReactElement {
    const [barSizes, setBarSizes] = useState<number[]>(
        _.times(barCount, i => Math.floor(_.random(0.2 * barHeight, 0.8 * barHeight))),
    );
    useEffect(() => {
        const t = setInterval(() => {
            // NB: preserve total volume by redistribution between bars
            let fill = _.sum(barSizes);
            const sizes = _.map(barSizes, (oldSize, i) => {
                if (i === barCount - 1) {
                    return fill;
                } else {
                    const tooSmall = oldSize - maxChange <= 0;
                    const tooBig = oldSize + maxChange >= barHeight;
                    const lowerBound = tooSmall ? 0 : -maxChange;
                    const upperBound = tooBig ? 0 : maxChange;
                    const sizeChange = _.random(lowerBound, upperBound);
                    fill -= oldSize + sizeChange;
                    return oldSize + sizeChange;
                }
            });
            setBarSizes(sizes);
        }, 1000);
        return () => {
            clearInterval(t);
        };
    }, []);

    const bars = _.times(3, i => (
        <S.LoadingRect
            key={i}
            x={-offset + i * (padding + barWidth)}
            y={-offset}
            width={barWidth}
            height={barSizes[i]}
            className={props.className}
        />
    ));
    return (
        <S.LoadingMeter
            width={`${loaderSize}px`}
            height={`${loaderSize}px`}
            viewBox={`0 0 ${loaderSize} ${loaderSize}`}
            version="1.1"
            className={props.className}>
            <S.LoadingContainer>{bars}</S.LoadingContainer>
        </S.LoadingMeter>
    );
}
export const LoadingMeter = React.memo(renderLoadingMeter);
