import { Dialog, DialogActions, DialogProps, DialogTitle, Slide } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import React from 'react';
import * as S from './dialog.styles';

// Transition component
const Transition = React.forwardRef(
    (props: TransitionProps & { children: React.ReactElement<any, any> }, ref: React.Ref<unknown>) => {
        return <Slide direction="up" ref={ref} {...props} />;
    },
);

export interface IBaseDialogProps extends Omit<DialogProps, 'ref'> {
    renderTitle?: string;
    renderActions?: React.ReactElement | React.ReactElement[];
    showCloseButton?: boolean;
    disableBackdropClick?: boolean;
}

export class BaseDialog extends React.PureComponent<IBaseDialogProps> {
    private onClose = (event: {}, reason: 'backdropClick' | 'escapeKeyDown') => {
        if (this.props.disableBackdropClick && reason === 'backdropClick') {
            return false;
        }

        if (this.props.disableBackdropClick && reason === 'escapeKeyDown') {
            return false;
        }

        if (typeof this.props.onClose === 'function') {
            this.props.onClose(event, reason);
        }
    };

    public render(): React.ReactElement {
        const { renderTitle, children, renderActions, showCloseButton, disableBackdropClick, ...props } = this.props;
        return (
            <Dialog TransitionComponent={Transition} maxWidth={false} {...props} onClose={this.onClose}>
                {renderTitle && (
                    <DialogTitle>
                        <S.DialogTitle>{renderTitle}</S.DialogTitle>
                    </DialogTitle>
                )}
                <S.StyledDialogContent>{children}</S.StyledDialogContent>
                {renderActions && (
                    <DialogActions>
                        <S.ActionsContainer>{renderActions}</S.ActionsContainer>
                    </DialogActions>
                )}
            </Dialog>
        );
    }
}
