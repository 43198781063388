import dateFns from 'date-fns';
import _ from 'lodash';
import { SortingRule } from 'react-table';
import {
    FilterMethod,
    ICustomFilter,
    ICustomFilterAsyncSelect,
    ICustomFilterBoolean,
    ICustomFilterDate,
    ICustomFilterDateRange,
    ICustomFilterDateTime,
    ICustomFilterFullText,
    ICustomFilterNumber,
    ICustomFilterPhone,
    ICustomFilterSelect,
    ICustomFilterString,
    isEmptyValueMethod,
} from './components';
import { ITableColumn } from './data-table.presenter';

export interface ISortRequest {
    sort: string[];
    order: string[];
}

enum ISortOrderEnum {
    ASCENDING = 'ASCENDING',
    DESCENDING = 'DESCENDING',
}

/**
 * Filter mapper
 * Please refer Filter.id to filterData that supports on the server
 */
export function createRequestFilters(filters?: ICustomFilter[], omitActive: boolean = false): Record<string, string[]> {
    if (!filters) {
        return {};
    }

    // Add default active filter if it is manually not specified, unless omitActive is true
    if (!omitActive) {
        filters = withActiveFilter(filters);
    }

    return filters.reduce((acc: Record<string, string[]>, filter) => {
        const emptyValueMethod = filter.filterMethod && isEmptyValueMethod(filter.filterMethod);

        if (!filter.filterId || !filter.filterMethod || (!filter.value && !emptyValueMethod)) {
            return acc;
        }

        // If we already have filter with this id, add more to array
        if (acc[filter.filterId]) {
            acc[filter.filterId].push(parseFilter(filter));
        } else {
            acc[filter.filterId] = [parseFilter(filter)];
        }

        return acc;
    }, {});
}

/**
 * Filter mapper
 * Please refer Filter.id to filterData that supports on the server
 */
export function createGroupRequestFilters(
    filterGroups?: ICustomFilter[][],
    omitActive: boolean = false,
): Record<string, string[]> {
    if (!filterGroups) {
        return {};
    }

    // Add default active filter if it is manually not specified, unless omitActive is true
    if (!omitActive) {
        filterGroups = withGroupActiveFilter(filterGroups);
    }

    return filterGroups
        .map<Record<string, string[]>>(group => {
            return group.reduce((acc, filter) => {
                if (!filter.filterId || !filter.filterMethod || !filter.value) {
                    return acc;
                }
                if (acc[filter.filterId]) {
                    acc[filter.filterId][0] += `,${parseFilter(filter)}`;
                } else {
                    acc[filter.filterId] = [parseFilter(filter)];
                }
                return acc;
            }, {});
        })
        .reduce<Record<string, string[]>>((acc, el) => {
            return _.merge(el, acc);
        }, {});
}

function withActiveFilter(filters: ICustomFilter[]): ICustomFilter[] {
    const isActiveExists = filters.find(filter => filter.filterId === 'filterActive');

    if (!isActiveExists) {
        filters.push({
            filterId: 'filterActive',
            filterMethod: FilterMethod.EQ,
            filterType: 'String',
            value: '1',
        });
    }

    return filters;
}

function withGroupActiveFilter(filters: ICustomFilter[][]): ICustomFilter[][] {
    const flatten = _.flatten(filters);
    const isActiveExists = flatten.find(filter => filter.filterId === 'filterActive');

    if (!isActiveExists) {
        filters.push([
            {
                filterId: 'filterActive',
                filterMethod: FilterMethod.EQ,
                filterType: 'String',
                value: '1',
            },
        ]);
    }

    return filters;
}

/**
 * Flatten specified keys in filters map (as returned by createRequestFilters) by using first array value.
 */
export function flattenRequestFilters(filters: Record<string, string[] | string> | undefined, keys: string[]): void {
    if (filters) {
        _.forEach(keys, key => {
            const f = _.get(filters, key);
            if (f) {
                _.set(filters, key, _.first(f));
            }
        });
    }
}

export function parseFilter(filter: ICustomFilter): string {
    switch (filter.filterType) {
        case 'Number':
            return parseFilterNumber(filter);
        case 'String':
            return parseFilterString(filter);
        case 'FullText':
            return parseFilterFullText(filter);
        case 'Phone':
            return parseFilterPhone(filter);
        case 'Date':
            return parseFilterDate(filter);
        case 'DateTime':
            return parseFilterDateTime(filter);
        case 'DateRange':
            return parseFilterDateRange(filter);
        case 'Boolean':
            return parseFilterBoolean(filter);
        case 'AsyncSelect':
        case 'Select':
        default:
            return parseFilterSelect(filter);
        // NB: TS error for <never> type since switch covers all values
        // default:
        //      throw new Error(`No rule for parsing ${filter.filterType} type!`);
    }
}

function parseFilterString(filter: ICustomFilterString): string {
    return `${filter.filterMethod}:${filter.value}`;
}

function parseFilterFullText(filter: ICustomFilterFullText): string {
    if (!filter.value) {
        return '';
    }

    let value = '';

    switch (filter.filterMethod) {
        case 'OW':
            // 'apple'
            value = filter.value;
            break;
        case 'CT':
            // '+apple +orange'
            value = filter.value
                .split(' ')
                .map(el => `+${el}`)
                .join(' ');
            break;
        case 'NCT':
            // '-apple -orange'
            value = filter.value
                .split(' ')
                .map(el => `-${el}`)
                .join(' ');
            break;
        case 'EX':
            // '"apple orange"'
            value = `"${filter.value}"`;
            break;
        case 'FT':
        default:
            // unformatted fulltext
            value = filter.value;
            break;
    }

    return `FTS:${value}`;
}

function parseFilterPhone(filter: ICustomFilterPhone): string {
    return `${filter.filterMethod}:${filter.value}`;
}

function parseFilterBoolean(filter: ICustomFilterBoolean): string {
    const value: boolean = _.get(filter, 'value.value', ''); // Get select option value
    return `${filter.filterMethod}:${Number(value).toString()}`; // true = '1', false = '0'
}

function parseFilterNumber(filter: ICustomFilterNumber): string {
    return `${filter.filterMethod}:${filter.value}`;
}

function parseFilterDate(filter: ICustomFilterDate): string {
    const format = 'YYYY-MM-DD';
    const value = filter.value ? dateFns.format(filter.value, format) : '';

    return `${filter.filterMethod}:${value}`; // i.e. EQ:2019-08-07
}

function parseFilterDateTime(filter: ICustomFilterDateTime): string {
    const value = filter.value ? filter.value.toISOString() : '';

    return `${filter.filterMethod}:${value}`; // i.e. EQ:2019-08-07T02:00:00.000Z
}

function parseFilterDateRange(filter: ICustomFilterDateRange): string {
    if (!Array.isArray(filter.value)) {
        // In case of undefined
        return '';
    }

    const value = filter.value.map(d => d.toISOString()).join(':');

    return `${filter.filterMethod}:${value}`; // i.e. BT:2018-01-01:2019-01-01
}

function parseFilterSelect(filter: ICustomFilterSelect | ICustomFilterAsyncSelect | ICustomFilterBoolean): string {
    const value = _.get(filter, 'value.value', ''); // Get select option value
    return `${filter.filterMethod}:${value}`;
}

export function parseSorting2<D extends object = {}>(
    sorted: SortingRule<object>[],
    columns: ITableColumn<D>[] | undefined,
): ISortRequest {
    const emptySorting: ISortRequest = {
        sort: [],
        order: [],
    };

    if (!columns) {
        // We should never pass undefined instead of columns array, but return nothing in case if we accidentaly did that.
        return emptySorting;
    }

    const sortRequest = sorted.reduce((acc: ISortRequest, sort) => {
        // Find column associated to the sorting.
        const column = _.find(columns, col => col.id === sort.id);
        if (!column) {
            return acc;
        }

        // Select sortType if it is exists.
        const sortType = column.sortType || column.id;
        if (!sortType) {
            return acc;
        }

        const order = sort.desc ? ISortOrderEnum.DESCENDING : ISortOrderEnum.ASCENDING;

        acc.sort.push(sortType);
        acc.order.push(order);

        return acc;
    }, emptySorting);

    if (sortRequest.sort.length !== sortRequest.order.length) {
        throw new Error('There should be the same amount of sort and orders');
    }

    return sortRequest;
}

// TODO(dmitry): move it to independent StorageService.
export class TableStorage {
    public static saveItem<T extends object = {}>(bucket: string, name: string, item: T) {
        const storageName = `table_${bucket}_${name}`;
        window.localStorage.setItem(storageName, JSON.stringify(item));
    }

    public static getItem<T extends object = {}>(bucket: string, name: string): T | undefined {
        const storageName = `table_${bucket}_${name}`;
        const storageFilters = window.localStorage.getItem(storageName);
        if (storageFilters) {
            return JSON.parse(storageFilters);
        }
    }

    public static clear(pattern: RegExp) {
        const keys = Object.keys(window.localStorage);
        keys.forEach(key => {
            if (pattern.test(key)) {
                window.localStorage.removeItem(key);
            }
        });
    }
}

export function normalizeStorageFilters(filters: ICustomFilter[]): ICustomFilter[] {
    return filters.map(filter => {
        switch (filter.filterType) {
            case 'DateRange':
                return { ...filter, value: _.map(filter.value, el => new Date(el)) };
            case 'Date':
            case 'DateTime':
                return { ...filter, value: filter.value ? new Date(filter.value) : filter.value };
            default:
                return filter;
        }
    }) as ICustomFilter[];
}
