import styled from '@emotion/styled/macro';
import { Icon } from '@mdi/react';
import { Variables } from '../base/variables/variables';
import { ToastTheme } from './toast.models';

interface IToast {
    variant: ToastTheme | undefined;
}

export const CloseIcon = styled(Icon)`
    height: 24px;
    width: 24px;
    margin-left: 24px;
    cursor: pointer;
`;

const defaultTheme = `
    color: ${Variables.Colors.b500};
    background-color: ${Variables.Colors.b300_l96};
    ${CloseIcon} {
        fill: ${Variables.Colors.b500};
    }
`;

const darkTheme = `
    color: ${Variables.Colors.white};
    background-color: ${Variables.Colors.n100};
    ${CloseIcon} {
        fill: ${Variables.Colors.white};
    }
`;

const warningTheme = `
    color: ${Variables.Colors.white};
    background-color: ${Variables.Colors.warning};
    ${CloseIcon} {
        fill: ${Variables.Colors.white};
    }
`;

export const Toast = styled.div<IToast>`
    display: inline-flex;
    align-items: center;
    justify-content: space-between;

    font-size: 14px;
    font-weight: 500;

    border-radius: ${Variables.Rounded.small};
    padding: 16px 16px;
    min-width: 200px;

    white-space: pre-line;

    ${({ variant }) => {
        switch (variant) {
            case 'dark':
                return darkTheme;
            case 'warning':
                return warningTheme;
            case 'light':
            default:
                return defaultTheme;
        }
    }}
`;

export const Close = styled.div`
    display: inline-flex;
    align-items: center;
`;
