import { mdiChevronLeft, mdiChevronRight } from '@mdi/js';
import { Icon } from '@mdi/react';
import * as React from 'react';
import * as S from './datepicker.styles';

interface IDatePickerNavbarProps {
    to?: string;
    from?: string;
    isShowRange?: boolean;
    month: string;
    showPreviousButton: boolean;
    onPreviousClick: () => void;
    showNextButton: boolean;
    onNextClick: () => void;
}

export function DatePickerNavbar(props: IDatePickerNavbarProps): React.ReactElement {
    const { isShowRange, from, to, month, showPreviousButton, onPreviousClick, showNextButton, onNextClick } = props;

    const handlePreviousClick = (): void => onPreviousClick();
    const handleNextClick = (): void => onNextClick();

    return (
        <S.Navbar>
            {isShowRange && (
                <S.RangeNavbar>
                    <S.RangeItem>
                        <S.RangeLabel>From</S.RangeLabel>
                        <S.RangeValueFrom>{from}</S.RangeValueFrom>
                    </S.RangeItem>

                    <S.RangeItem>
                        <S.RangeLabel>To</S.RangeLabel>
                        <S.RangeValueTo>{to}</S.RangeValueTo>
                    </S.RangeItem>
                </S.RangeNavbar>
            )}
            <S.Controls>
                <S.Title>{month}</S.Title>
                <S.ButtonsWrapper>
                    {showPreviousButton && (
                        <S.IconButton variant="text" onClick={handlePreviousClick} type="button">
                            <Icon path={mdiChevronLeft} size={1} />
                        </S.IconButton>
                    )}
                    {showNextButton && (
                        <S.IconButton variant="text" onClick={handleNextClick} type="button">
                            <Icon path={mdiChevronRight} size={1} />
                        </S.IconButton>
                    )}
                </S.ButtonsWrapper>
            </S.Controls>
        </S.Navbar>
    );
}
