import * as React from 'react';
import * as S from './layout.styles';

export interface ILayout {
    children?: React.ReactNode;
    className?: string;
}

export class Layout extends React.PureComponent<ILayout> {
    public ref: React.Ref<HTMLDivElement>;
    constructor(props: any) {
        super(props);
        this.ref = React.createRef();
    }
    public render(): React.ReactElement {
        return (
            <S.Layout ref={this.ref} className={this.props.className}>
                {this.props.children}
            </S.Layout>
        );
    }
}
