import { mdiDotsVertical } from '@mdi/js';
import * as React from 'react';
import { ICardProps } from './card';
import * as S from './title-card.styles';

export interface ITitleCardProps extends ICardProps {
    title: string;
    info?: string;
    caption?: React.ReactNode;
    optionsHandler?: () => void;
}

export class TitleCard extends React.PureComponent<ITitleCardProps> {
    public render(): React.ReactElement {
        return (
            <S.TitleCard size={this.props.size} className={this.props.className}>
                <S.Header>
                    {this.renderTitle()}
                    {this.renderCaption()}
                    {this.renderOptions()}
                </S.Header>
                {this.props.children}
            </S.TitleCard>
        );
    }
    private readonly renderTitle = (): React.ReactElement => {
        return (
            <S.Title>
                {this.props.title}
                {this.props.info && <S.StyledInfoIcon direction="bottom" tooltip={this.renderTooltip()} />}
            </S.Title>
        );
    };
    private readonly renderTooltip = (): React.ReactElement => {
        return <S.InfoTooltip>{this.props.info}</S.InfoTooltip>;
    };
    private readonly renderCaption = (): React.ReactElement | undefined => {
        if (this.props.caption) {
            return <S.Caption>{this.props.caption}</S.Caption>;
        }
    };
    private readonly renderOptions = (): React.ReactElement | undefined => {
        if (this.props.optionsHandler) {
            return (
                <S.OptionsLink onClick={this.props.optionsHandler}>
                    <S.OptionsIcon path={mdiDotsVertical} />
                </S.OptionsLink>
            );
        }
    };
}
