import _ from 'lodash';

export enum BadgeClaimType {
    PROFESSIONAL = 'PROFESSIONAL',
    INSTITUTIONAL = 'INSTITUTIONAL',
    DENTAL = 'DENTAL',
    NYNF3 = 'NYNF3',
}

export enum TransmissionStatusType {
    SUBMITTED = 'SUBMITTED',
    ACKNOWLEDGED = 'ACKNOWLEDGED',
    ADJUDICATED = 'ADJUDICATED',
    COMPLETED = 'COMPLETED',
    REJECTED = 'REJECTED',
    DENIED = 'DENIED',
    FORWARDED = 'FORWARDED',
}

export const assembleName = (first?: string, last?: string, suffix?: string): string => {
    return _.compact([suffix, first, last]).join(' ');
};

export const generateLabel = (prefix: string, id?: string, name?: string) => {
    if (name) {
        return `${prefix}: ${name}`;
    } else if (id) {
        return `${prefix} ID – ${truncateUuid(id)}`;
    } else {
        return prefix;
    }
};

export const truncateUuid = (uuid: string): string => _.split(uuid, '-')[0];
