import { mdiClose } from '@mdi/js';
import Icon from '@mdi/react';
import dateFns from 'date-fns';
import _ from 'lodash';
import { observer } from 'mobx-react';
import React from 'react';
import { DataTablePresenter } from '../../data-table.presenter';
import { FilterMethod, ICustomFilter, ICustomFilterDate, ICustomFilterDateRange } from './filters.models';
import * as S from './filters.styles';

interface IActiveFiltersProps {
    presenter: DataTablePresenter<any>;
}

export const ActiveFilters: React.FunctionComponent<IActiveFiltersProps> = observer(({ presenter }) => {
    const { validFilters, onFilterRemove } = presenter;

    const handleFilterRemove = (filter: ICustomFilter) => () => onFilterRemove(filter);
    return (
        <>
            {validFilters.map((filter, i) => (
                <S.FilterButton
                    key={`active-filter-${i}`}
                    variant="text"
                    onClick={handleFilterRemove(filter)}
                    disabled={!!filter.locked}>
                    <ActiveFilterItem filter={filter} />
                    {!filter.locked && <Icon path={mdiClose} size={0.75} />}
                </S.FilterButton>
            ))}
        </>
    );
});

export const ActiveFilterItem = React.memo<{ filter: ICustomFilter }>(({ filter }) => {
    const dateOperator = (): string => {
        switch (filter.filterMethod) {
            case FilterMethod.EQ:
                return 'on';
            case FilterMethod.NQ:
                return 'not on';
            case FilterMethod.GT:
                return 'after';
            case FilterMethod.GTE:
                return 'on or after';
            case FilterMethod.LT:
                return 'before';
            case FilterMethod.LTE:
                return 'on or before';
            case FilterMethod.BT:
                return 'between';
            default:
                return '';
        }
    };

    const numberOperator = (): string => {
        switch (filter.filterMethod) {
            case FilterMethod.EQ:
                return '=';
            case FilterMethod.NQ:
                return '!=';
            case FilterMethod.GT:
                return '>';
            case FilterMethod.GTE:
                return '≥';
            case FilterMethod.LT:
                return '<';
            case FilterMethod.LTE:
                return '≤';
            case FilterMethod.BT:
                return '<>';
            default:
                return '';
        }
    };

    const labelStart = filter.label || _.startCase(filter.filterId);

    const stringfilter = (): string => {
        const separators = { [FilterMethod.EMPTY]: ' is empty', [FilterMethod.NEMPTY]: ' is not empty' };

        const label = labelStart;
        const separator = (filter.filterMethod && separators[filter.filterMethod]) || ':';
        const value = filter.filterMethod && separators[filter.filterMethod] ? '' : ` "${filter.value}"`;

        return `${label}${separator}${value}`;
    };

    switch (filter.filterType) {
        case 'Select':
        case 'AsyncSelect':
        case 'Boolean':
            return (
                <S.FilterText>
                    {labelStart} <S.FilterOperator>{numberOperator()}</S.FilterOperator> {_.get(filter, 'value.label')}
                </S.FilterText>
            );
        case 'FullText':
        case 'String':
            return <S.FilterText>{stringfilter()}</S.FilterText>;
        case 'Date':
        case 'DateTime':
            const dateFilter = filter as ICustomFilterDate;
            const selectedDate = _.isDate(dateFilter.value) ? new Date(dateFilter.value) : '';
            return (
                <S.FilterText>
                    {labelStart} {dateOperator()} {dateFns.format(selectedDate, 'YYYY-MM-DD')}
                </S.FilterText>
            );
        case 'DateRange':
            // TODO(dmitry): remove it after support 'BETWEEN' filters
            if (filter.filterMethod === FilterMethod.BT) {
                const dateRange = filter as ICustomFilterDateRange;
                if (!dateRange.value) {
                    return null;
                }

                const [from, to] = dateRange.value;
                return (
                    <S.FilterText>
                        {labelStart}{' '}
                        {dateFns.format(new Date(from), 'YYYY-MM-DD') +
                            ' - ' +
                            dateFns.format(new Date(to), 'YYYY-MM-DD')}
                    </S.FilterText>
                );
            }
            break;
        case 'Number':
            return (
                <S.FilterText>
                    {labelStart} <S.FilterOperator>{numberOperator()}</S.FilterOperator> {filter.value}
                </S.FilterText>
            );
        default:
            return (
                <S.FilterText>
                    {labelStart}: {filter.value}
                </S.FilterText>
            );
    }

    return null;
});
