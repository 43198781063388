import { mdiCheck, mdiChevronDown, mdiChevronRight, mdiClose, mdiPencilOutline } from '@mdi/js';
import Icon from '@mdi/react';
import { Tooltip } from '@mui/material';
import _ from 'lodash';
import { observer } from 'mobx-react';
import React from 'react';
import { Row } from 'react-table';
import {
    Amount,
    PayerAdjustmentGroupType,
    PaymentStatusType,
    ProviderChargemasterItem,
    ProviderClaimPayment,
    ProviderClaimPaymentItem,
    ProviderInstitutionalClaimTemplate,
    ProviderNynf3ClaimTemplate,
    ProviderPayer,
    ProviderPayerSubscriber,
    ProviderProfessionalClaimTemplate,
    ProviderProvider,
    SupportChargemasterItem,
    SupportClaimPayment,
    SupportClaimPaymentItem,
    SupportInstitutionalClaimTemplate,
    SupportNynf3ClaimTemplate,
    SupportPayer,
    SupportPayerSubscriber,
    SupportProfessionalClaimTemplate,
    SupportProfessionalTemplateServiceLine,
    SupportProvider,
} from '../../../api';
import { formatDate, formatNumber, parseAmount, parseDate } from '../../../services';
import { TooltipTransitionComponent } from '../../base';
import { DataTablePresenter, ITableColumn, renderString, renderStringGroup, Settings } from '../../data-table';
import { getPaymentIndex } from '../../services';
import { PaymentItems } from '../payment-items/payment-items';
import * as S from './record-of-services.styles';

type PaymentItemWithStatus = (ProviderClaimPaymentItem | SupportClaimPaymentItem) & {
    status?: PaymentStatusType;
    paymentId?: string;
};

interface IRecordOfServicesProps {
    loading: boolean;
    template?:
        | ProviderProfessionalClaimTemplate
        | ProviderInstitutionalClaimTemplate
        | ProviderNynf3ClaimTemplate
        | SupportProfessionalClaimTemplate
        | SupportInstitutionalClaimTemplate
        | SupportNynf3ClaimTemplate;
    providers?: ProviderProvider[] | SupportProvider[];
    chargemasterItems?: ProviderChargemasterItem[] | SupportChargemasterItem[];
    payments?: ProviderClaimPayment[] | SupportClaimPayment[];
    claimPatientPaid?: Amount;
    payerSubscribers?: SupportPayerSubscriber[] | ProviderPayerSubscriber[];
    onEdit?: (line: SupportProfessionalTemplateServiceLine) => void;
}

interface IRecord {
    rvu?: string;
    dateOfService?: string;
    procedureCode?: string;
    modifiers?: string[];
    description?: string;
    units?: number;
    paid?: number;
    patientPaid?: number;
    patientResponsibility?: number;
    allowed?: number;
    accepted?: number;
    medicare?: number;
    totalCharges?: number;
    patientMaxAmount?: number;
    hasExtendedX12Data?: boolean;
    line?: SupportProfessionalTemplateServiceLine;
    chargemasterItem?: ProviderChargemasterItem | SupportChargemasterItem;
    isSummaryRow?: boolean;
    items?: PaymentItemWithStatus[];
}

@observer
export class RecordOfServices extends React.Component<IRecordOfServicesProps> {
    private presenter: DataTablePresenter<IRecord>;
    private columns: ITableColumn<IRecord>[] = _.compact([
        {
            id: 'count',
            minWidth: 50,
            maxWidth: 50,
            Cell: ({ cell }) => {
                if (cell.row.original.isSummaryRow) {
                    return null;
                }

                return <span>{(cell.row.index || 0) + 1}</span>;
            },
        },
        {
            id: 'dateOfService',
            Header: 'Dates of Service',
            accessor: 'dateOfService',
        },
        {
            id: 'procedureCode',
            Header: 'CPT/HCPCS',
            accessor: 'procedureCode',
            Cell: ({ cell, row }) => {
                if (!cell.value || row.original.isSummaryRow) {
                    return null;
                }

                const procedureCode = cell.value;
                const modifiers = _.compact(row.original.modifiers).join(', ');
                const value = _.compact([procedureCode, modifiers]).join('-');

                return (
                    <Tooltip
                        placement="top"
                        title={<>{row.original.description} </>}
                        open={row.original.description ? undefined : false}
                        TransitionComponent={TooltipTransitionComponent}>
                        <div>
                            <S.StyledTag>{value}</S.StyledTag>{' '}
                            {(row.original.units || 0) > 0 ? `x ${row.original.units}` : null}
                        </div>
                    </Tooltip>
                );
            },
            minWidth: 150,
        },
        {
            id: 'paperwork',
            accessor: 'chargemasterItem.paperwork',
            Header: () => <S.StyledCharges>Paperwork</S.StyledCharges>,
            Cell: ({ cell, row }) => {
                if (row.original.isSummaryRow) {
                    return null;
                }

                if (!cell.value) {
                    return (
                        <Tooltip
                            placement="top"
                            title="Chargemaster doesn't require paperwork"
                            TransitionComponent={TooltipTransitionComponent}>
                            <S.AlignCell>-</S.AlignCell>
                        </Tooltip>
                    );
                }

                let claimPaperwork = false;

                if (this.props.template && this.isProfessionalTemplate(this.props.template)) {
                    const professionalTemplate = this.props.template as SupportProfessionalClaimTemplate;

                    claimPaperwork =
                        !_.isEmpty(professionalTemplate.x12Paperwork) ||
                        !!professionalTemplate.f23PrimaryPayerPriorAuthorizationNumber ||
                        !!professionalTemplate.f23SecondaryPayerPriorAuthorizationNumber ||
                        !!professionalTemplate.f23TertiaryPayerPriorAuthorizationNumber;
                }

                return (
                    <S.AlignCell>
                        {claimPaperwork ? (
                            <S.SuccessIcon path={mdiCheck} />
                        ) : (
                            <Tooltip
                                placement="top"
                                title="Chargemaster requires paperwork and claim doesn't satisfy the requirement"
                                TransitionComponent={TooltipTransitionComponent}>
                                <div>
                                    <S.WarningIcon path={mdiClose} />
                                </div>
                            </Tooltip>
                        )}
                    </S.AlignCell>
                );
            },
        },
        {
            id: 'authorization',
            accessor: 'chargemasterItem.authorization',
            Header: () => <S.StyledCharges>Authorization</S.StyledCharges>,
            Cell: ({ cell, row }) => {
                if (row.original.isSummaryRow) {
                    return null;
                }

                if (!cell.value) {
                    return (
                        <Tooltip
                            placement="top"
                            title="Chargemaster doesn't require authorization"
                            TransitionComponent={TooltipTransitionComponent}>
                            <S.AlignCell>-</S.AlignCell>
                        </Tooltip>
                    );
                }

                let claimAuthorization = false;

                if (this.props.template && this.isProfessionalTemplate(this.props.template)) {
                    const professionalTemplate = this.props.template as SupportProfessionalClaimTemplate;

                    claimAuthorization = !_.isEmpty(professionalTemplate.x12Paperwork);
                }

                return (
                    <S.AlignCell>
                        {claimAuthorization ? (
                            <S.SuccessIcon path={mdiCheck} />
                        ) : (
                            <Tooltip
                                placement="top"
                                title="Chargemaster requires authorization and claim doesn't satisfy the requirement"
                                TransitionComponent={TooltipTransitionComponent}>
                                <div>
                                    <S.WarningIcon path={mdiClose} />
                                </div>
                            </Tooltip>
                        )}
                    </S.AlignCell>
                );
            },
            minWidth: 150,
        },
        {
            id: 'description',
            accessor: 'description',
            Header: () => <S.StyledCharges>Description</S.StyledCharges>,
            Cell: ({ cell }) => <S.StyledDescription>{cell.value}</S.StyledDescription>,
            minWidth: 200,
            show: false,
        },
        {
            id: 'totalCharges',
            accessor: 'totalCharges',
            Header: () => <S.StyledCharges>Charges</S.StyledCharges>,
            Cell: ({ cell }) => <S.StyledCharges>{formatNumber('Currency')(cell.value)}</S.StyledCharges>,
        },
        {
            id: 'accepted',
            accessor: 'accepted',
            Header: () => <S.StyledCharges>Accepted</S.StyledCharges>,
            Cell: ({ cell }) => <S.StyledCharges>{formatNumber('Currency')(cell.value)}</S.StyledCharges>,
        },
        {
            id: 'allowed',
            accessor: 'allowed',
            Header: () => <S.StyledCharges>Allowed</S.StyledCharges>,
            Cell: ({ cell }) => <S.StyledCharges>{formatNumber('Currency')(cell.value)}</S.StyledCharges>,
        },
        {
            id: 'medicare',
            accessor: 'medicare',
            Header: () => <S.StyledCharges>Medicare</S.StyledCharges>,
            Cell: ({ cell }) => <S.StyledCharges>{formatNumber('Currency')(cell.value)}</S.StyledCharges>,
            show: false,
        },
        {
            id: 'paid',
            accessor: 'paid',
            Header: () => <S.StyledCharges>Payer Paid</S.StyledCharges>,
            Cell: ({ cell }) => <S.StyledCharges>{formatNumber('Currency')(cell.value)}</S.StyledCharges>,
        },
        {
            id: 'patientResponsibility',
            accessor: 'patientResponsibility',
            Header: () => <S.StyledCharges>Patient Responsibility</S.StyledCharges>,
            Cell: ({ cell }) => <S.StyledCharges>{formatNumber('Currency')(cell.value)}</S.StyledCharges>,
            minWidth: 150,
        },
        {
            id: 'patientPaid',
            accessor: 'patientPaid',
            Header: () => <S.StyledCharges>Patient Paid</S.StyledCharges>,
            Cell: ({ cell }) => <S.StyledCharges>{formatNumber('Currency')(cell.value)}</S.StyledCharges>,
            minWidth: 120,
        },
        {
            id: 'rvu',
            Header: 'RVU',
            accessor: 'chargemasterItem.rvu',
            show: false,
        },
        {
            id: 'patientMaxAmount',
            accessor: 'patientMaxAmount',
            Header: () => <S.StyledCharges>Patient Max</S.StyledCharges>,
            Cell: ({ cell, row }) => {
                if (row.original.isSummaryRow) {
                    return null;
                }
                return <S.StyledCharges>{formatNumber('Currency')(cell.value)}</S.StyledCharges>
            },
            minWidth: 120,
            show: false
        },
        this.props.onEdit
            ? {
                  id: 'edit',
                  minWidth: 40,
                  Cell: ({ row }) => {
                      const line = row.original.line;
                      if (row.original.isSummaryRow || !line) {
                          return null;
                      }

                      const handleClick = (e: React.MouseEvent) => {
                          e.stopPropagation();
                          this.props.onEdit?.(line);
                      };

                      return (
                          <S.ActionButton variant="outlined" size="small" type="button" onClick={handleClick}>
                              <Icon path={mdiPencilOutline} size={0.75} />
                          </S.ActionButton>
                      );
                  },
              }
            : undefined,
        {
            id: 'expandable',
            minWidth: 40,
            maxWidth: 40,
            Cell: ({ row }) => {
                if (row.original.isSummaryRow) {
                    return null;
                }

                const path = row.isExpanded ? mdiChevronDown : mdiChevronRight;
                return (
                    <S.ExpandableDiv>
                        <S.ExpandIcon path={path} />
                    </S.ExpandableDiv>
                );
            },
        },
    ]);

    get payments() {
        const sortedPayments = _(this.props.payments)
            .slice()
            .sort((a, b) => (new Date(a.created || '') < new Date(b.created || '') ? 1 : -1))
            .value();

        const paymentReversals: ProviderClaimPayment[] = _(sortedPayments)
            .filter(payment => payment.status === PaymentStatusType.REVERSALOFPREVIOUSPAYMENT)
            .value();

        const payments = _.filter(
            sortedPayments,
            payment => payment.status !== PaymentStatusType.REVERSALOFPREVIOUSPAYMENT,
        );

        paymentReversals.forEach(el => {
            const reversedPayment = getReversedPayment(el, payments);
            _.pull(payments, reversedPayment);
        });

        return payments;
    }

    constructor(props: IRecordOfServicesProps) {
        super(props);
        this.presenter = new DataTablePresenter({
            name: 'record-services',
            data: [],
            columns: this.columns,
            filterSchemas: [],
        });
    }

    public componentDidMount(): void {
        // NB: load data if possible (e.g., back button)
        this.loadData();
    }

    public componentDidUpdate(): void {
        const { loading } = this.props;
        if (!loading) {
            // NB: load data after loading
            this.loadData();
        }
    }

    public render(): React.ReactElement {
        return (
            <S.StyledTitleCard
                size="large"
                title="Record of Services Provided"
                caption={
                    <Settings
                        columns={this.presenter.columns}
                        hiddenColumnIds={this.presenter.hiddenColumnIds}
                        onColumnToggle={this.presenter.onColumnToggle}
                        variant="small"
                    />
                }>
                <S.CardBody>
                    <S.StyledDataTable
                        presenter={this.presenter}
                        isLoading={this.props.loading}
                        onRowClick={this.onRowClick}
                        renderRowWrapper={this.renderRowWrapper}
                        renderRowSubComponent={this.renderExpanded}
                        disableSorting={true}
                        hideFiltering={true}
                        hideNavigation={true}
                        hidePagination={true}
                        hideSettings={true}
                    />
                </S.CardBody>
            </S.StyledTitleCard>
        );
    }

    private readonly renderRowWrapper = (row: Row<IRecord>, renderRow: JSX.Element) => {
        if (row.original.isSummaryRow) {
            return <S.SummaryRow>{renderRow}</S.SummaryRow>;
        }

        return (
            <Tooltip
                placement="top"
                title="We weren't able to match this payment item with the service line. Please check the control number, modifiers, procedure codes and the date of service."
                TransitionComponent={TooltipTransitionComponent}
                open={!row.original.line ? undefined : false}>
                <S.RowWrapper warning={!row.original.line}>{renderRow}</S.RowWrapper>
            </Tooltip>
        );
    };

    private readonly loadData = (): void => {
        const { template } = this.props;
        if (template) {
            let records: IRecord[] = [];
            if (this.isInstitutionalTemplate(template)) {
                records = this.parseInstitutionalTemplate(template as ProviderInstitutionalClaimTemplate);
            } else if (this.isNynf3Template(template)) {
                records = this.parseNynf3Template(template as ProviderNynf3ClaimTemplate);
            } else if (this.isProfessionalTemplate(template)) {
                records = this.parseProfessionalTemplate(template as ProviderProfessionalClaimTemplate);
            }
            // const sorted = _.reverse(_.sortBy(records, 'dateOfService'));
            if (!_.isEmpty(records) && !_.isEqual(records, this.presenter.data)) {
                this.presenter.onDataUpdate(this.withSummaryData(records));
                this.presenter.onDataTotalChange(records.length);
            }
        }
    };

    private readonly withSummaryData = (data: IRecord[]): IRecord[] => {
        const patientResponsibility = _.reduce(
            this.getLatestPayments(this.payments),
            (acc, el) => {
                acc += this.reformatNumber(_.get(el, 'patientAmount.amount', '0'));
                return acc;
            },
            0,
        );

        const totals = data.reduce<{
            paid: number;
            allowed: number;
            medicare: number;
            accepted: number;
            totalCharges: number;
        }>(
            (acc, el) => {
                return {
                    paid: acc.paid + (el.paid || 0),
                    allowed: acc.allowed + (el.allowed || 0),
                    medicare: acc.medicare + (el.medicare || 0),
                    accepted: acc.accepted + (el.accepted || 0),
                    // Calculate only matched line items
                    totalCharges: el.line ? acc.totalCharges + (el.totalCharges || 0) : acc.totalCharges,
                };
            },
            {
                paid: 0,
                allowed: 0,
                medicare: 0,
                accepted: 0,
                totalCharges: 0,
            },
        );
        return [
            ...data,
            {
                paid: totals.paid,
                patientResponsibility,
                patientPaid: this.reformatNumber(this.props.claimPatientPaid?.amount || '0'),
                allowed: totals.allowed,
                medicare: totals.medicare,
                accepted: totals.accepted,
                totalCharges: totals.totalCharges,
                isSummaryRow: true,
            },
        ];
    };

    private readonly isInstitutionalTemplate = (template: object): boolean => {
        return _.has(template, 'f42ClaimLines');
    };
    private readonly parseInstitutionalTemplate = (template: ProviderInstitutionalClaimTemplate): IRecord[] => {
        const serviceLines = _.get(template, 'f42ClaimLines', []);
        return _.map(serviceLines, line => ({
            dateOfService: this.reformatDates(line.f45ServiceDate),
            procedureCode: line.f44ProcedureCode,
            modifiers: line.f44ModifierCodes,
            description: line.f43RevenueCodeDescription,
            units: this.reformatNumber(line.f46ServiceUnits),
            totalCharges: this.reformatNumber(_.get(line.f47TotalChargeAmount, 'amount', '0')),
        }));
    };
    private readonly isNynf3Template = (template: object): boolean => {
        return _.has(template, 'f15ServiceLines');
    };
    private readonly parseNynf3Template = (template: ProviderNynf3ClaimTemplate): IRecord[] => {
        const serviceLines = _.get(template, 'f15ServiceLines', []);
        return _.map(serviceLines, line => ({
            dateOfService: this.reformatDates(line.f15aFromDateOfService, line.f15aThroughDateOfService),
            procedureCode: line.f15dProcedureCode,
            modifiers: line.f15dModifierCodes,
            description: line.f15cProcedureCodeDescription,
            units: this.reformatNumber(line.f15dServiceUnit),
            totalCharges: this.reformatNumber(_.get(line.f15eChargeAmount, 'amount', '0')),
        }));
    };
    private readonly isProfessionalTemplate = (template: object): boolean => {
        return _.has(template, 'f24ServiceLine');
    };
    private readonly parseProfessionalTemplate = (template: ProviderProfessionalClaimTemplate): IRecord[] => {
        const { f24ServiceLine } = template;

        const paymentItems: PaymentItemWithStatus[] = _.map(this.payments, p => {
            return (p.items || []).map((el: PaymentItemWithStatus) => {
                el.status = p.status;
                el.paymentId = p.id;
                return el;
            });
        }).flat();

        const latestPayments = this.getLatestPayments(this.payments || []);

        const itemsMap = new Map<string | undefined, PaymentItemWithStatus[]>();

        paymentItems.forEach(item => {
            const key = getPaymentItemKey(item) || 'NOT_FOUND';
            const items = itemsMap.get(key) || [];
            items.push(item);
            itemsMap.set(key, items);
        });

        const parsedRecords: IRecord[] = _.chain(f24ServiceLine)
            .map(line => {
                const chargemasterItem = _.find([...(this.props.chargemasterItems || [])], {
                    id: line.chargemasterItemId,
                });

                let foundPaymentItems: PaymentItemWithStatus[] = [];

                const lineKey = getServiceLineKey(line);
                const items = itemsMap.get(lineKey);
                if (items) {
                    foundPaymentItems = [...items];
                    itemsMap.delete(lineKey);
                }

                const paid = foundPaymentItems.reduce((acc, el) => {
                    const amount = this.reformatNumber(_.get(el, 'paidAmount.amount', '0'));
                    return acc + amount;
                }, 0);

                // find the largest amount of unique payment items
                const allowed = Math.max(
                    ...foundPaymentItems.map(el => this.reformatNumber(_.get(el, 'allowedAmount.amount', '0'))),
                );

                const patientResponsibility = foundPaymentItems.reduce((acc, el) => {
                    const adjustments = _.filter(
                        el.adjustments,
                        el => el.group === PayerAdjustmentGroupType.PATIENTRESPONSIBILITY,
                    );
                    const amount = adjustments.reduce((acc2, adj) => {
                        if (el.status === PaymentStatusType.REVERSALOFPREVIOUSPAYMENT) {
                            acc2 -= this.reformatNumber(_.get(adj, 'amount.amount', '0'));
                            return acc2;
                        }

                        const isLatestPayment = latestPayments.some(payment => payment.id === el.paymentId);

                        if (!isLatestPayment) {
                            return acc2;
                        }

                        acc2 += this.reformatNumber(_.get(adj, 'amount.amount', '0'));

                        return acc2;
                    }, 0);

                    return acc + amount;
                }, 0);

                return {
                    controlNumber: line.x12ControlNumber,
                    dateOfService: this.reformatDates(line.f24aFromDateOfService, line.f24aThroughDateOfService),
                    procedureCode: line.f24dProcedureCode,
                    modifiers: line.f24dProcedureModifiers,
                    description: line.x12Narrative,
                    units: line.f24gUnits,
                    paid,
                    allowed: isFinite(allowed) ? allowed : 0,
                    accepted: this.reformatNumber(_.get(chargemasterItem, 'accepted.amount', '0')) * line.f24gUnits,
                    medicare: this.reformatNumber(_.get(chargemasterItem, 'medicare.amount', '0')),
                    patientMaxAmount: _.get(chargemasterItem, 'patient.amount') ? this.reformatNumber(_.get(chargemasterItem, 'patient.amount', '0')) * line.f24gUnits : undefined,
                    totalCharges: this.reformatNumber(_.get(line.f24fChargeAmount, 'amount', '0')),
                    hasExtendedX12Data: this.hasExtendedX12Data(line),
                    chargemasterItem,
                    patientResponsibility,
                    line,
                    items,
                };
            })
            .value();

        const notFoundRecords: IRecord[] = [];

        [...itemsMap.values()].forEach(items => {
            const el = _.first(items);
            if (!el) {
                return;
            }

            const paid = items.reduce((acc, el) => {
                const amount = this.reformatNumber(_.get(el, 'paidAmount.amount', '0'));
                return acc + amount;
            }, 0);

            const allowed = items.reduce((acc, el) => {
                const amount = this.reformatNumber(_.get(el, 'allowedAmount.amount', '0'));
                return acc + amount;
            }, 0);

            const totalCharges = this.reformatNumber(_.get(el, 'chargeAmount.amount', '0'));

            const adjustments = _.filter(
                items.map(item => item.adjustments || []).flat(),
                el => el.group === PayerAdjustmentGroupType.PATIENTRESPONSIBILITY,
            );
            const patientResponsibility = adjustments.reduce((acc2, adj) => {
                acc2 += this.reformatNumber(_.get(adj, 'amount.amount', '0'));

                return acc2;
            }, 0);

            const units = items.reduce((acc, el) => {
                const count = _.get(el, 'paidCount', 0);
                return acc + count;
            }, 0);

            notFoundRecords.push({
                dateOfService: this.reformatDates(el.fromDos, el.throughDos),
                procedureCode: el.procedureCode,
                modifiers: el.modifierCodes,
                description: '',
                units,
                patientResponsibility,
                paid,
                allowed,
                medicare: undefined,
                accepted: undefined,
                totalCharges,
                hasExtendedX12Data: false,
                line: undefined,
                items,
            });
        });

        return [...parsedRecords, ...notFoundRecords];
    };

    private readonly hasExtendedX12Data = (line: SupportProfessionalTemplateServiceLine): boolean => {
        // Test for x12 fields in the service line
        const x12Fields = [
            'x12Note',
            'x12ProviderIds',
            'x12FacilityId',
            'x12DurableMedicalEquipment',
            'x12TestResults',
            'x12Paperwork',
            'x12Ambulance',
            'x12Anesthesia',
            'x12Drug',
            'x12HospiceEmployee',
            'x12Forms',
        ];
        const matched = _.pick(line, x12Fields);
        return !_.isEmpty(matched);
    };

    private readonly onRowClick = (row: Row<IRecord>) => {
        row.toggleRowExpanded();
    };

    private readonly renderExpanded = (row: Row<IRecord>) => {
        if (!row.isExpanded) {
            return null;
        }

        const payerStatusMap: Map<number, SupportPayer | ProviderPayer> = new Map();

        _.forEach([0, 1, 2], i => {
            const payer = _.nth(this.props.payerSubscribers, i)?.payer;

            if (payer) {
                payerStatusMap.set(i, payer);
            }
        });

        return (
            <S.Expand>
                {row.original.chargemasterItem && (
                    <S.DetailsTable>{this.renderDetailsData(row.original)}</S.DetailsTable>
                )}
                {!!row.original.items?.length && (
                    <S.ResourceTable>
                        <PaymentItems items={row.original.items} payerStatusMap={payerStatusMap} />
                    </S.ResourceTable>
                )}
            </S.Expand>
        );
    };

    private readonly renderDetailsData = ({ chargemasterItem, line, description }: IRecord) => {
        const {
            id,
            authorization,
            coverage,
            facility,
            paperwork,
            payer,
            provider,
            placeOfServiceType,
            procedure,
            contract,
            modifiers,
        } = chargemasterItem || {};

        let diagnosis = '';

        const template = this.props.template;
        if (template) {
            template;
            if (this.isProfessionalTemplate(template)) {
                const professionalTemplate = template as SupportProfessionalClaimTemplate;

                const pointersMap = {
                    A: professionalTemplate.f21aDiagnosisCode,
                    B: professionalTemplate.f21bDiagnosisCode,
                    C: professionalTemplate.f21cDiagnosisCode,
                    D: professionalTemplate.f21dDiagnosisCode,
                    E: professionalTemplate.f21eDiagnosisCode,
                    F: professionalTemplate.f21fDiagnosisCode,
                    G: professionalTemplate.f21gDiagnosisCode,
                    H: professionalTemplate.f21hDiagnosisCode,
                    I: professionalTemplate.f21iDiagnosisCode,
                    J: professionalTemplate.f21jDiagnosisCode,
                    K: professionalTemplate.f21kDiagnosisCode,
                    L: professionalTemplate.f21lDiagnosisCode,
                };
                diagnosis = this.getCodesByPointers(line?.f24eDiagnosisPointers || [], pointersMap).join(', ');
            } else if (this.isInstitutionalTemplate(template)) {
                // TODO: support Institutional
                diagnosis = '';
            } else if (this.isNynf3Template(template)) {
                // TODO: support Nynf3
                diagnosis = '';
            }
        }

        function getProviderName(provider: SupportProvider | ProviderProvider): string {
            if (provider.first || provider.last) {
                return _.chain([provider.first, provider.last]).compact().join(' ').value();
            }

            if (provider.organization) {
                return provider.organization;
            }

            return provider.id || ''; // Provider has to always have name of organization
        }

        return (
            <tbody key="details-data">
                <tr>
                    <th>Description</th>
                    <td>{description ? description : <S.EmptyLabel>Empty</S.EmptyLabel>}</td>
                </tr>
                <tr>
                    <th>Service Line Item ID</th>
                    <td>{line?.id ? line.id : <S.EmptyLabel>Empty</S.EmptyLabel>}</td>
                </tr>
                <tr>
                    <th>Chargemaster Item ID</th>
                    <td>{id ? id : <S.EmptyLabel>Empty</S.EmptyLabel>}</td>
                </tr>
                <tr>
                    <th>Facility</th>
                    <td>{facility?.name ? facility.name : <S.EmptyLabel>Empty</S.EmptyLabel>}</td>
                </tr>
                <tr>
                    <th>Payer</th>
                    <td>{payer?.name ? payer.name : <S.EmptyLabel>Empty</S.EmptyLabel>}</td>
                </tr>
                <tr>
                    <th>Provider</th>
                    <td>{provider ? getProviderName(provider) : <S.EmptyLabel>Empty</S.EmptyLabel>}</td>
                </tr>
                <tr>
                    <th>POS Type</th>
                    <td>{placeOfServiceType ? placeOfServiceType : <S.EmptyLabel>Empty</S.EmptyLabel>}</td>
                </tr>
                <tr>
                    <th>Procedure</th>
                    <td>{procedure ? procedure : <S.EmptyLabel>Empty</S.EmptyLabel>}</td>
                </tr>
                <tr>
                    <th>Modifiers</th>
                    <td>{_.isEmpty(modifiers) ? <S.EmptyLabel>Empty</S.EmptyLabel> : renderStringGroup(modifiers)}</td>
                </tr>
                <tr>
                    <th>Diagnosis</th>
                    <td>{diagnosis ? diagnosis : <S.EmptyLabel>Empty</S.EmptyLabel>}</td>
                </tr>
                <tr>
                    <th>Authorization</th>
                    <td>
                        {_.isUndefined(authorization) ? (
                            <S.EmptyLabel>Empty</S.EmptyLabel>
                        ) : authorization ? (
                            'Yes'
                        ) : (
                            'No'
                        )}
                    </td>
                </tr>
                <tr>
                    <th>Coverage</th>
                    <td>{coverage ? coverage : <S.EmptyLabel>Empty</S.EmptyLabel>}</td>
                </tr>
                <tr>
                    <th>Paperwork</th>
                    <td>{_.isUndefined(paperwork) ? <S.EmptyLabel>Empty</S.EmptyLabel> : paperwork ? 'Yes' : 'No'}</td>
                </tr>
                <tr>
                    <th>Contract</th>
                    <td>
                        {contract ? (
                            renderString(contract, { normalized: true, type: 'String' })
                        ) : (
                            <S.EmptyLabel>Empty</S.EmptyLabel>
                        )}
                    </td>
                </tr>
            </tbody>
        );
    };

    private readonly reformatDates = (date1?: string, date2?: string): string | undefined => {
        const parse = parseDate('Date');
        const d1 = date1 ? parse(date1) : undefined;
        const d2 = date2 ? parse(date2) : undefined;
        if (d1 && d2) {
            return `${formatDate('MonthDay')(d1)} - ${formatDate('MonthDayYear')(d2)}`;
        } else if (d1) {
            return formatDate('MonthDayYear')(d1);
        }
    };

    private readonly reformatNumber = (n: string | undefined): number => {
        return n === undefined ? 0 : Number.parseFloat(n);
    };

    private readonly getCodesByPointers = (
        pointers: string[],
        pointersMap: Record<string, string | undefined>,
    ): string[] => {
        return pointers.reduce<string[]>((acc, el) => {
            const code = pointersMap[el];
            if (code) {
                acc.push(code);
            }

            return acc;
        }, []);
    };

    private getLatestPayments(payments: ProviderClaimPayment[]): ProviderClaimPayment[] {
        const primaries = _.filter(payments, p => getPaymentIndex(p.status) === 0);
        const secondaries = _.filter(payments, p => getPaymentIndex(p.status) === 1);
        const tertiaries = _.filter(payments, p => getPaymentIndex(p.status) === 2);

        return tertiaries.length ? tertiaries : secondaries.length ? secondaries : primaries;
    }
}

function getPaymentItemKey(item: PaymentItemWithStatus): string | undefined {
    if (item.controlNumber) {
        return _.toUpper(item.controlNumber);
    }

    const checkProcedures = item.procedureCode;
    const checkModifiers = _.join(item.modifierCodes, '');
    const checkDos = item.fromDos;

    // Some fallbacks if controlNumber doesn't exist
    if (checkProcedures && checkModifiers && checkDos) {
        return _.compact([checkProcedures, checkModifiers, checkDos]).join('-');
    }

    return;
}
function getServiceLineKey(line: SupportProfessionalTemplateServiceLine): string | undefined {
    if (line.x12ControlNumber) {
        return _.toUpper(line.x12ControlNumber);
    }

    const checkProcedures = line.f24dProcedureCode;
    const checkModifiers = _.join(line.f24dProcedureModifiers, '');
    const checkDos = line.f24aFromDateOfService;

    // Some fallbacks if controlNumber doesn't exist
    if (checkProcedures && checkModifiers && checkDos) {
        return _.compact([checkProcedures, checkModifiers, checkDos]).join('-');
    }

    return;
}

// Chris: All the control numbers are the same, so you have to go down to the payer, patient, charge, and allowed amounts at the payment level and take the absolute value of the values and compare them. At that point, you can see that the match is actual the other payment. So, I typically will do a string compare with in this case being "628.88|69.87|2273.17|698.75" ... taking the absolute values of the reversal and payment would match.
function getReversedPayment(reversal: ProviderClaimPayment, payments: ProviderClaimPayment[]) {
    let reversedPayments = payments.filter(el => el.payerControlNumber === reversal.payerControlNumber);

    // If there are more than one payment with the same control number as reversed
    // we need to find one with matched amounts;
    if (reversedPayments.length > 1) {
        reversedPayments = reversedPayments.filter(el => {
            const comparingAmounts: (keyof ProviderClaimPayment)[] = [
                'allowedAmount',
                'payerAmount',
                'chargeAmount',
                'patientAmount',
            ];

            return comparingAmounts.every(
                key => Math.abs(parseAmount(reversal[key] as Amount)) === Math.abs(parseAmount(el[key] as Amount)),
            );
        });
    }

    return _.first(reversedPayments);
}
