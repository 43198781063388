import { mdiChevronLeft, mdiChevronRight, mdiPageFirst, mdiPageLast } from '@mdi/js';
import Icon from '@mdi/react';
import React from 'react';
import { BaseButton, Overlay, PopoverHeader, PopoverItem } from '../../../base';
import * as S from './pagination.styles';

const PAGE_SIZE_OPTIONS: number[] = [5, 10, 20, 30, 50, 100];

// tslint:disable-next-line:no-empty-interface
interface INavigationProps {
    pageIndex: number;
    pageSize: number;
    dataTotal: number;
    disabled?: boolean;
    pageSizeOptions?: number[];
    nextPage: () => void;
    previousPage: () => void;
    gotoPage: (page: number) => void;
    onChangePageSize: (pageSize: number) => void;
}

export const Pagination: React.FunctionComponent<INavigationProps> = ({
    pageIndex,
    pageSize,
    dataTotal,
    disabled,
    pageSizeOptions = PAGE_SIZE_OPTIONS,
    nextPage,
    previousPage,
    gotoPage,
    onChangePageSize,
}) => {
    if (dataTotal === 0) {
        return null;
    }

    const startCount = pageIndex * pageSize + 1;
    const endCount = Math.min((pageIndex + 1) * pageSize, dataTotal);

    const canNextPage = dataTotal > (pageIndex + 1) * pageSize;
    const canPreviousPage = pageIndex !== 0;

    const renderButton = (onOpen: (target: EventTarget) => void) => {
        const handleClick = (e: React.MouseEvent): void => onOpen(e.currentTarget);
        return (
            <S.CurrentPageLabel onClick={handleClick}>
                {startCount} - {endCount}
            </S.CurrentPageLabel>
        );
    };

    const handleChangePageSize = (n: number) => {
        return () => onChangePageSize(n);
    };

    const goToFirstPage = () => gotoPage(0);

    const goToLastPage = () => gotoPage(Math.floor(dataTotal / pageSize));

    return (
        <S.PaginationContainer>
            {!!canPreviousPage && (
                <S.FirstPageContainer>
                    <BaseButton variant="fab-light" size="small" onClick={goToFirstPage}>
                        <Icon path={mdiPageFirst} size={0.75} />
                    </BaseButton>
                </S.FirstPageContainer>
            )}
            <S.LabelsWrapper>
                <Overlay renderButton={renderButton}>
                    <PopoverHeader>Rows per page</PopoverHeader>
                    {pageSizeOptions.map(num => {
                        return (
                            <PopoverItem
                                key={'pagesize-option-' + num}
                                onClick={handleChangePageSize(num)}
                                selected={num === pageSize}>
                                {num}
                            </PopoverItem>
                        );
                    })}
                </Overlay>
                <S.TotalPagesLabel>of {dataTotal}</S.TotalPagesLabel>
            </S.LabelsWrapper>
            {!!canNextPage && (
                <S.LastPageContainer>
                    <BaseButton variant="fab-light" size="small" onClick={goToLastPage}>
                        <Icon path={mdiPageLast} size={0.75} />
                    </BaseButton>
                </S.LastPageContainer>
            )}
            <S.ButtonsWrapper>
                <BaseButton variant="fab-light" onClick={previousPage} disabled={!canPreviousPage || disabled}>
                    <Icon path={mdiChevronLeft} size={1} />
                </BaseButton>
                <BaseButton variant="fab-light" onClick={nextPage} disabled={!canNextPage || disabled}>
                    <Icon path={mdiChevronRight} size={1} />
                </BaseButton>
            </S.ButtonsWrapper>
        </S.PaginationContainer>
    );
};
