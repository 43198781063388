import { mdiClockOutline, mdiCloseCircle } from '@mdi/js';
import * as React from 'react';
import * as S from './recent-search-result.styles';

export interface IRecentSearchResultProps {
    selected?: string;
    terms: string[];
    onClick: (t: string) => void;
    onDelete: (t: string) => void;
}

export class RecentSearchResult extends React.Component<IRecentSearchResultProps> {
    public render(): React.ReactNode {
        const { selected, terms } = this.props;
        let results = null;
        if (terms && terms.length) {
            results = terms.reverse().map(
                (term: string): React.ReactElement => (
                    <S.RecentItem key={term} onClick={this.handleRecentClick(term)} selected={selected === term}>
                        <S.RecentIcon path={mdiClockOutline} size={1} />
                        <S.RecentText>{term}</S.RecentText>
                        <S.RecentClose onClick={this.handleDeleteClick(term)}>
                            <S.RecentCloseIcon path={mdiCloseCircle} size={1} />
                        </S.RecentClose>
                    </S.RecentItem>
                ),
            );
        } else {
            results = (
                <S.RecentItem>
                    <S.RecentIcon path={mdiClockOutline} size={1} />
                    <S.RecentText>No recent queries.</S.RecentText>
                </S.RecentItem>
            );
        }
        return (
            <S.Recent>
                <S.RecentTitle>Recent Searches</S.RecentTitle>
                {results}
            </S.Recent>
        );
    }
    private readonly handleRecentClick = (term: string): (() => void) => {
        return (): void => {
            this.props.onClick(term);
        };
    };
    private readonly handleDeleteClick = (term: string): ((e: React.MouseEvent) => void) => {
        return (e: React.MouseEvent): void => {
            e.stopPropagation();
            this.props.onDelete(term);
        };
    };
}
