import styled from '@emotion/styled/macro';
import React from 'react';
import alarmClock from './emojis/alarm-clock.png';
import astronaut from './emojis/astronaut.png';
import bookmarkTabs from './emojis/bookmark-tabs.png';
import bookmark from './emojis/bookmark.png';
import briefcase from './emojis/briefcase.png';
import cake from './emojis/cake.png';
import cardIndex from './emojis/card-index.png';
import child from './emojis/child.png';
import coupleHeart from './emojis/couple-heart.png';
import email from './emojis/email.png';
import faceWithMonocle from './emojis/face-with-monocle.png';
import faceWithThermometer from './emojis/face-with-thermometer.png';
import healthWorker from './emojis/health-worker.png';
import hospital from './emojis/hospital.png';
import houseGarden from './emojis/house-garden.png';
import lightning from './emojis/lightning.png';
import link from './emojis/link.png';
import male from './emojis/male.png';
import memo from './emojis/memo.png';
import mendingHeart from './emojis/mending-heart.png';
import moneyBag from './emojis/money-bag.png';
import moneyWings from './emojis/money-wings.png';
import pageFacingUp from './emojis/page-facing-up.png';
import page from './emojis/page.png';
import paperclip from './emojis/paperclip.png';
import pencil from './emojis/pencil.png';
import peopleHoldingHands from './emojis/people-holding-hands.png';
import phoneReceiver from './emojis/phone-receiver.png';
import receipt from './emojis/receipt.png';
import repeat from './emojis/repeat.png';
import shield from './emojis/shield.png';
import thumbsUp from './emojis/thumbs-up.png';
import whiteQuestion from './emojis/white-question.png';
import womanBeard from './emojis/woman-beard.png';
import woman from './emojis/woman.png';
import writingHand from './emojis/writing-hand.png';

type Emoji =
    | 'lightning'
    | 'faceWithThermometer'
    | 'thumbsUp'
    | 'hospital'
    | 'healthWorker'
    | 'faceWithMonocle'
    | 'pageFacingUp'
    | 'phoneReceiver'
    | 'email'
    | 'houseGarden'
    | 'shield'
    | 'cake'
    | 'male'
    | 'womanBeard'
    | 'woman'
    | 'whiteQuestion'
    | 'coupleHeart'
    | 'astronaut'
    | 'child'
    | 'briefcase'
    | 'mendingHeart'
    | 'peopleHoldingHands'
    | 'page'
    | 'bookmarkTabs'
    | 'paperclip'
    | 'cardIndex'
    | 'alarmClock'
    | 'repeat'
    | 'writingHand'
    | 'moneyWings'
    | 'receipt'
    | 'memo'
    | 'pencil'
    | 'bookmark'
    | 'moneyBag'
    | 'link';

const Img = styled.img`
    height: 1em;
`;

const emojis: Record<Emoji, string> = {
    lightning,
    faceWithThermometer,
    thumbsUp,
    hospital,
    healthWorker,
    faceWithMonocle,
    pageFacingUp,
    phoneReceiver,
    email,
    houseGarden,
    shield,
    cake,
    male,
    womanBeard,
    woman,
    whiteQuestion,
    coupleHeart,
    astronaut,
    child,
    briefcase,
    mendingHeart,
    peopleHoldingHands,
    page,
    bookmarkTabs,
    paperclip,
    cardIndex,
    alarmClock,
    repeat,
    writingHand,
    moneyWings,
    receipt,
    memo,
    pencil,
    bookmark,
    moneyBag,
    link,
};

export const Emoji = ({ type, title }: { type: Emoji; title?: string }) => {
    return <Img src={emojis[type]} title={title} />;
};
