import { mdiCheckCircle, mdiChevronDown, mdiChevronRight, mdiCloseCircle } from '@mdi/js';
import Icon from '@mdi/react';
import dateFns from 'date-fns';
import _ from 'lodash';
import numeral from 'numeral';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { CellProps } from 'react-table';
import { Address, Amount } from '../../../../api';
import { Badge } from '../../../badge';
import { BaseButton } from '../../../base';
import { AsyncListCell } from './async-list';
import * as S from './cells.styles';

export const normalizeCell = (str: string | undefined) => _.startCase(_.toLower(str));

type CellType =
    | 'String'
    | 'StringGroup'
    | 'AddressGroup'
    | 'Boolean'
    | 'Amount'
    | 'Date'
    | 'DateTime'
    | 'AsyncList'
    | 'Link'
    | 'Expand';

interface ICellBase {
    type: CellType;
}

interface ICellAsyncList extends ICellBase {
    type: 'AsyncList';
    loadMethod: (id: string) => Promise<React.ReactElement>;
}

interface ICellLink extends ICellBase {
    type: 'Link';
    label: string;
}

interface ICellString extends ICellBase {
    type: 'String';
    formatted?: boolean;
    normalized?: boolean;
    emptyString?: string;
}

type CellOptions = ICellAsyncList | ICellLink | ICellString;

export function DataTableCell(
    type: CellType,
    opts?: CellOptions,
): (props: CellProps<any>) => React.ReactElement | string {
    if (opts && opts.type !== type) {
        throw new Error('Type and options type should be the same.');
    }

    switch (type) {
        case 'String':
            return ({ cell: { value } }) => renderString(value, opts as ICellString);
        case 'StringGroup':
            return ({ cell: { value } }) => renderStringGroup(value);
        case 'AddressGroup':
            return ({ cell: { value } }) => renderAddressGroup(value);
        case 'Boolean':
            return ({ cell: { value } }) => renderBoolean(value);
        case 'Amount':
            return ({ cell: { value } }) => renderAmount(value);
        case 'Date':
            return ({ cell: { value } }) => renderDate(value);
        case 'DateTime':
            return ({ cell: { value } }) => renderDateTime(value);
        case 'Link':
            return ({ cell: { value } }) => renderLink(opts as ICellLink)(value);
        case 'AsyncList':
            return ({ cell: { value } }) => renderAsyncList(opts as ICellAsyncList)(value);
        default:
            throw new Error(`There is no type ${type} for the table cell!`);
    }
}

export function renderString(value: string | number | undefined, opts?: ICellString): React.ReactElement | string {
    let result = value ? String(value) : renderEmpty(opts?.emptyString);
    result = opts?.normalized ? normalizeCell(result) : result;

    return opts?.formatted ? <Badge title={result}>{result}</Badge> : <span title={result}>{result}</span>;
}

export function renderStringGroup(value: (string | undefined)[] | undefined): React.ReactElement | string {
    if (_.isEmpty(value) || !_.isArray(value)) {
        return renderEmpty();
    }

    return _.compact(value).join('\n');
}

export function renderAddressGroup(value: Address[] | undefined): React.ReactElement | string {
    if (!value || _.isEmpty(value)) {
        return renderEmpty();
    }

    return (
        <S.List>
            {value.map((address, i) => (
                <S.Item key={i}>
                    {address.name} <br />
                    {_.compact(address.lines).join(' ')} <br />
                    {_.compact([address.city, address.state]).join(', ')} {address.postal} {address.country}
                </S.Item>
            ))}
        </S.List>
    );
}

export function renderBoolean(value: boolean): React.ReactElement {
    return value ? <S.CheckIcon path={mdiCheckCircle} size={1} /> : <S.CrossIcon path={mdiCloseCircle} size={1} />;
}

export function renderAmount(value: Amount | undefined): string {
    if (!value?.amount) {
        return renderEmpty();
    }

    return numeral(value.amount).format('$0,0.00');
}

export function renderPercentage(value: string | undefined): string {
    if (!value) {
        return renderEmpty();
    }

    return numeral(value).format('0.00%');
}

export function renderInt(value: string): string {
    if (!value) {
        return renderEmpty();
    }

    return numeral(value).format('0');
}

export function renderDate(value: string | undefined): string {
    if (!value) {
        return renderEmpty();
    }

    return dateFns.format(value, 'MMM DD, YYYY');
}

export function renderPhone(value: string | undefined): string {
    if (!value) {
        return renderEmpty();
    }

    const cleaned = value.replace(/\+1|\D/g, '');
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
        return '(' + match[1] + ') ' + match[2] + '-' + match[3];
    }

    return renderEmpty();
}

export function renderDateTime(value: string | undefined): string {
    if (!value) {
        return renderEmpty();
    }

    return dateFns.format(value, 'MMM DD, YYYY hh:mm');
}

export function renderAsyncList(opts?: ICellAsyncList) {
    return (value: string): React.ReactElement | string => {
        if (!value) {
            return renderEmpty();
        }

        if (!opts) {
            throw new Error('Load method should exist in the arguments');
        }

        return <AsyncListCell id={value} loadMethod={opts.loadMethod} />;
    };
}

export function renderLink(opts: ICellLink) {
    return (value: string): React.ReactElement | string => {
        const { label } = opts;

        const handleClick = (): void => {
            window.open(value, '_blank');
        };

        return (
            <BaseButton variant="outlined" onClick={handleClick} type="button">
                {label}
            </BaseButton>
        );
    };
}

export const CellLink: React.FC<{ value: string; label: string }> = ({ value, label }) => {
    const history = useHistory();

    const handleClick = (): void => {
        window.open(history.createHref({ pathname: value }), '_blank');
    };

    return (
        <BaseButton variant="outlined" onClick={handleClick} type="button">
            {label}
        </BaseButton>
    );
};

export function renderExpand({ row, column }: CellProps<any>) {
    return (value: string): React.ReactElement | string => {
        const isRowExpanded = row.isExpanded && row.state.expandedId === column.id;
        const handleExpand = () => {
            row.toggleRowExpanded(!isRowExpanded);
            row.setState({ expandedId: column.id });
        };

        return (
            <BaseButton variant="outlined" size="medium" onClick={handleExpand} type="button">
                {value}{' '}
                {isRowExpanded ? <Icon path={mdiChevronDown} size={1} /> : <Icon path={mdiChevronRight} size={1} />}
            </BaseButton>
        );
    };
}

export function renderEmpty(symbol?: string): string {
    return symbol || '-';
}
