import * as React from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import { PopoverMenu } from './popover-menu';
import * as S from './popover.styles';

export interface IPopoverProps {
    renderButton: () => React.ReactElement;
    open?: boolean;
    pinned?: boolean;
    selectable?: boolean;
    items?: React.ReactNode;
    align?: 'left' | 'right';
    verticalAlign?: 'top' | 'bottom' | 'auto';
    className?: string;
}

export interface IPopoverState {
    menuOpen: boolean;
    submenuOpen: boolean;
}

export class Popover extends React.PureComponent<IPopoverProps, IPopoverState> {
    private activatorRef: React.RefObject<HTMLDivElement>;
    constructor(props: IPopoverProps) {
        super(props);
        this.state = {
            menuOpen: !!this.props.open,
            submenuOpen: false,
        };
        this.activatorRef = React.createRef();
    }
    public render(): React.ReactElement {
        return (
            <>
                <S.PopoverWrapper ref={this.activatorRef} onClick={this.toggleMenu} className={this.props.className}>
                    {this.props.renderButton()}
                </S.PopoverWrapper>
                <PopoverMenu
                    activator={this.activatorRef}
                    align={this.props.align}
                    verticalAlign={this.props.verticalAlign}
                    open={this.state.menuOpen}>
                    <OutsideClickHandler disabled={this.state.submenuOpen} onOutsideClick={this.handleOutsideClick}>
                        {this.renderChildren()}
                    </OutsideClickHandler>
                </PopoverMenu>
            </>
        );
    }
    private readonly renderChildren = () => {
        const { children } = this.props;
        if (children) {
            return React.Children.map(
                children as React.ReactElement,
                (child: React.ReactElement, i: number): React.ReactElement | null => {
                    if (!child) {
                        return null;
                    }

                    return React.cloneElement(child, {
                        pinned: this.props.pinned,
                        selectable: this.props.selectable,
                        openSubmenu: this.openSubmenu,
                        closeSubmenu: this.closeSubmenu,
                        closeMenu: this.closeMenu,
                        key: i,
                    });
                },
            );
        }
        return null;
    };
    private readonly openSubmenu = (): void => {
        this.setState({
            submenuOpen: true,
        });
    };
    private readonly closeSubmenu = (): void => {
        this.setState({
            submenuOpen: false,
        });
    };
    private readonly toggleMenu = (e: React.MouseEvent): void => {
        this.setState({
            menuOpen: !this.state.menuOpen,
        });
        e.preventDefault();
        e.stopPropagation();
    };
    private readonly closeMenu = (): void => {
        this.setState({
            menuOpen: false,
        });
    };
    private readonly handleOutsideClick = (e: React.MouseEvent<HTMLElement, MouseEvent>): void => {
        // NB: since OutsideClickHandler is not in the DOM tree of the
        // activator, explicitly check event target so the menu isn't
        // closed then immediately toggled open
        if (!this.activatorRef.current || !this.activatorRef.current.contains(e.target as HTMLElement)) {
            this.closeMenu();
        }
    };
}
