import { AuthApi, CoreFetch, ToastContext } from '@enter/core';
import { FormInput, PageHeader, SideImageWrapper } from 'components';
import * as S from 'components/styles';
import { Form, Formik } from 'formik';
import React, { useContext, useState } from 'react';
import { useLocation } from 'react-router-dom';
import * as Yup from 'yup';
import { handleFetch, parseQueryParams } from './util';

const schema = Yup.object().shape({
    email: Yup.string().required('Email is required'),
});

interface IForgotPassword {
    email: string;
}

export const ForgotPassword: React.FunctionComponent = (): React.ReactElement => {
    document.title = 'Enter Login - Reset Password';

    const toastContext = useContext(ToastContext);
    const location = useLocation();
    const queryParams = parseQueryParams(location, { client_id: '' });

    const [isEmailSent, setEmailSent] = useState(false);
    const [isLoading, setLoading] = useState(false);

    const loginLink = '/authorize' + location.search;
    const initial: IForgotPassword = {
        email: '',
    };

    const onSubmit = (values: IForgotPassword): void => {
        window.grecaptcha.ready(async () => {
            await window.grecaptcha.execute(process.env.REACT_APP_RECAPTCHA_KEY, {
                action: 'forgot_password',
            });
            setLoading(true);
            await handleFetch(
                async () =>
                    CoreFetch<AuthApi>(AuthApi).authRequestForgotPassword({
                        forgotPasswordRequest: {
                            email: values.email,
                            clientId: queryParams.client_id,
                        },
                    }),
                (msg: string): void => {
                    toastContext.notify && toastContext.notify(msg, 'left');
                },
            );
            setEmailSent(true);
            setLoading(false);
        });
    };
    return (
        <SideImageWrapper>
            <Formik initialValues={initial} validationSchema={schema} onSubmit={onSubmit}>
                <Form>
                    <PageHeader title={'Forgot Password'} />
                    {isEmailSent && (
                        <div>An email has been sent to your email address, if it exists in our system.</div>
                    )}
                    {!isEmailSent && (
                        <>
                            <FormInput
                                label="Email"
                                id="email"
                                name="email"
                                type="text"
                                autoComplete="email"
                                placeholder="Enter your email address"
                            />
                            <S.Spacer />
                            <S.Spacer />
                            <S.ButtonWrapper>
                                <S.Button
                                    variant="primary"
                                    disabled={isLoading}
                                    size="large"
                                    tabIndex={1}
                                    type="submit">
                                    {isLoading ? <S.Progress size="24px" /> : 'Recover Password'}
                                </S.Button>
                            </S.ButtonWrapper>
                        </>
                    )}
                    <S.Footer>
                        <S.Link to={loginLink}>Back to Login</S.Link>
                    </S.Footer>
                </Form>
            </Formik>
        </SideImageWrapper>
    );
};
