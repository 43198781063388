import * as d3 from 'd3';
import _ from 'lodash';

type LocaleType = 'UTC' | 'local';
const formatDateOrFail = (
    format: string | undefined,
    locale: LocaleType,
): ((d: string | Date | null | undefined) => string) => {
    return (d: string | Date | null | undefined): string => {
        try {
            if (_.isString(d)) {
                d = new Date(d);
            }
            if (_.isDate(d)) {
                if (format) {
                    if (locale === 'UTC') {
                        return d3.utcFormat(format)(d);
                    } else {
                        return d3.timeFormat(format)(d);
                    }
                } else {
                    return d.toISOString();
                }
            }
        } catch (err) {
            // ignore error
        }
        return '–';
    };
};

const parseDateOrFail = (format: string | undefined, locale: LocaleType): ((d: string | undefined) => Date | null) => {
    return (d: string | undefined): Date | null => {
        try {
            if (d) {
                if (format) {
                    if (locale === 'UTC') {
                        return d3.utcParse(format)(d);
                    } else {
                        return d3.timeParse(format)(d);
                    }
                } else {
                    return d3.isoParse(d);
                }
            }
        } catch (err) {
            // ignore error
        }
        return null;
    };
};

type DateType = 'Date' | 'DateTime' | 'MonthDayYear' | 'MonthDay' | 'MonthDayYearTime' | 'Time' | 'Year' | 'ISO';

export function formatDate(
    type: DateType,
    locale: LocaleType = 'UTC',
): (n: string | Date | null | undefined) => string {
    switch (type) {
        case 'Date':
            // Date => 2019-10-31
            return formatDateOrFail('%Y-%m-%d', locale);
        case 'DateTime':
            // Date => 2019-10-31T11:11:11.000Z
            return formatDateOrFail('%Y-%m-%dT%H:%M:%S.%LZ', locale);
        case 'MonthDay':
            // Date => Aug 1
            return formatDateOrFail('%b %e', locale);
        case 'MonthDayYear':
            // Date => Aug 1, 2019
            return formatDateOrFail('%b %e, %Y', locale);
        case 'MonthDayYearTime':
            // Date => Thursday, Aug 1, 2019 at 8:20pm
            return formatDateOrFail('%A, %b %e, %Y at %I:%M%p', locale);
        case 'Time':
            // Date => 11:30AM
            return formatDateOrFail('%I:%M%p', locale);
        case 'Year':
            // Date => 2019
            return formatDateOrFail('%Y', locale);
        default:
            throw new Error(`Unknown date type: ${type}`);
    }
}
export function parseDate(type: DateType, locale: LocaleType = 'UTC'): (n: string | undefined) => Date | null {
    switch (type) {
        case 'Date':
            // Date => 2019-10-31
            return parseDateOrFail('%Y-%m-%d', locale);
        case 'DateTime':
            // Date => 2019-10-31T11:11:11.000Z
            return parseDateOrFail('%Y-%m-%dT%H:%M:%S.%LZ', locale);
        case 'MonthDay':
            // Date => Aug 1
            return parseDateOrFail('%b %e', locale);
        case 'MonthDayYear':
            // Date => Aug 1, 2019
            return parseDateOrFail('%b %e, %Y', locale);
        case 'MonthDayYearTime':
            // Date => Aug 1, 2019 at 8:20pm
            return parseDateOrFail('%A, %b %e, %Y at %I:%M%p', locale);
        case 'Time':
            // Date => 11:30AM
            return parseDateOrFail('%I:%M%p', locale);
        case 'Year':
            // Date => 2019
            return parseDateOrFail('%Y', locale);
        case 'ISO':
            // NB: uses Date constructor, accepts many forms
            return parseDateOrFail(undefined, locale);
        default:
            throw new Error(`Unknown date type: ${type}`);
    }
}
