import styled from '@emotion/styled/macro';
import { BaseButton, Spinner, Variables } from '../base';

export const PendingButton = styled(BaseButton)`
    height: 40px;
    width: 40px;
    min-width: 40px;

    border-color: ${Variables.Colors.n85};
    fill: ${Variables.Colors.white};
`;

export const PendingSpinner = styled(Spinner)`
    background-color: transparent;
    svg {
        fill: ${Variables.Colors.n85};
    }
`;
