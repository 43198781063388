import _ from 'lodash';
import React from 'react';
import {
    ProviderChargemasterItem,
    ProviderClaimPayment,
    ProviderInstitutionalClaimTemplate,
    ProviderNynf3ClaimTemplate,
    ProviderProfessionalClaimTemplate,
    ProviderProvider,
    SupportChargemasterItem,
    SupportClaimPayment,
    SupportInstitutionalClaimTemplate,
    SupportNynf3ClaimTemplate,
    SupportProfessionalClaimTemplate,
    SupportProvider,
} from '../../../api';
import { formatNumber } from '../../../services';
import { Tooltip } from '../../base';
import { DataTablePresenter, ITableColumn } from '../../data-table';
import { IClaimPayerSummaryRecord, IClaimPayerSummaryRecordReason, parseRecords } from './claim-payer-summary.service';
import * as S from './claim-payer-summary.styles';

interface IClaimPayerSummaryProps {
    loading: boolean;
    template?:
        | ProviderProfessionalClaimTemplate
        | ProviderInstitutionalClaimTemplate
        | ProviderNynf3ClaimTemplate
        | SupportProfessionalClaimTemplate
        | SupportInstitutionalClaimTemplate
        | SupportNynf3ClaimTemplate;
    payments?: ProviderClaimPayment[] | SupportClaimPayment[];
    chargemasterItems?: ProviderChargemasterItem[] | SupportChargemasterItem[];
    providers?: ProviderProvider[] | SupportProvider[];
}

const Header = (header: string, align?: 'right', tooltip?: string) => {
    if (tooltip) {
        return (
            <S.Header align={align}>
                <Tooltip direction="top" title={<span>{tooltip}</span>}>
                    {header}
                </Tooltip>
            </S.Header>
        );
    }
    return <S.Header align={align}>{header}</S.Header>;
};
const Cell = (value: string) => <S.Cell title={value}>{value}</S.Cell>;
const Currency = (value: string) => (
    <S.Cell title={value} align="right">
        {formatNumber('Currency')(value)}
    </S.Cell>
);
const Reasons = (value: IClaimPayerSummaryRecordReason[]) => {
    const reasons = _.map(value, (reason, i) => {
        return (
            <S.ReasonTooltip
                key={`reason-${i}`}
                direction="bottom"
                title={
                    <S.Reason>
                        <S.ReasonCode>Group: {reason.group}</S.ReasonCode>
                        <S.ReasonCode>Reason Code: {reason.code}</S.ReasonCode>
                        <S.ReasonCode>Units: {reason.units}</S.ReasonCode>
                        <S.ReasonCode>Amount: {reason.amount}</S.ReasonCode>
                    </S.Reason>
                }>
                {reason.reason};
            </S.ReasonTooltip>
        );
    });
    return <S.Reasons align="right">{reasons}</S.Reasons>;
};
const columns: ITableColumn<IClaimPayerSummaryRecord>[] = [
    {
        id: 'dos',
        Header: () => Header('DOS', undefined, 'Dates of Service'),
        accessor: 'dos',
        Cell: ({ cell }) => Cell(cell.value),
        minWidth: 100,
    },
    {
        id: 'units',
        Header: () => Header('Unit'),
        accessor: 'units',
        Cell: ({ cell }) => Cell(cell.value),
        maxWidth: 60,
    },
    {
        id: 'service',
        Header: () => Header('Service'),
        accessor: 'service',
        Cell: ({ cell }) => Cell(cell.value),
        maxWidth: 80,
    },
    {
        id: 'provider',
        Header: () => Header('Provider'),
        accessor: 'provider',
        Cell: ({ cell }) => Cell(cell.value),
        show: false,
    },
    {
        id: 'place',
        Header: () => Header('POS', undefined, 'Place of Service'),
        accessor: 'placeOfService',
        Cell: ({ cell }) => Cell(cell.value),
        show: false,
    },
    {
        id: 'modifiers',
        Header: () => Header('Modifiers'),
        accessor: 'modifiers',
        Cell: ({ cell }) => Cell(cell.value),
    },

    {
        id: 'billed',
        Header: () => Header('Billed', 'right'),
        accessor: 'billed',
        Cell: ({ cell }) => Currency(cell.value),
        maxWidth: 80,
    },

    {
        id: 'goal',
        Header: () => Header('Goal', 'right'),
        accessor: 'goal',
        Cell: ({ cell }) => Currency(cell.value),
        maxWidth: 80,
    },

    {
        id: 'allowed',
        Header: () => Header('Allowed', 'right'),
        accessor: 'allowed',
        Cell: ({ cell }) => Currency(cell.value),
        maxWidth: 80,
    },

    {
        id: 'payerPaid',
        Header: () => Header('Payer Paid', 'right'),
        accessor: 'payerPaid',
        Cell: ({ cell }) => Currency(cell.value),
        maxWidth: 80,
    },
    {
        id: 'disallow',
        Header: () => Header('Disallow', 'right'),
        accessor: 'disallow',
        Cell: ({ cell }) => Currency(cell.value),
        maxWidth: 80,
    },
    {
        id: 'reasons',
        Header: () => Header('Reasons', 'right'),
        accessor: 'reasons',
        Cell: ({ cell }) => Reasons(cell.value),
    },
];

export class ClaimPayerSummary extends React.Component<IClaimPayerSummaryProps> {
    private presenter: DataTablePresenter<IClaimPayerSummaryRecord>;

    constructor(props: IClaimPayerSummaryProps) {
        super(props);
        this.presenter = new DataTablePresenter({
            name: 'claim-payer-summary',
            data: [],
            columns,
            filterSchemas: [],
        });
    }

    public componentDidMount(): void {
        // NB: load data if possible (e.g., back button)
        this.updateData();
    }

    public componentDidUpdate(): void {
        const { loading } = this.props;
        if (!loading) {
            // NB: load data after loading
            this.updateData();
        }
    }

    public render(): React.ReactElement {
        return (
            <S.StyledCard size="large">
                <S.CardHeader>
                    <S.CardTitle>Claim Payer Summary</S.CardTitle>
                    <S.Spacer />
                    {this.paymentDate && <S.CardDate>{this.paymentDate}</S.CardDate>}
                </S.CardHeader>
                <S.CardBody>
                    <S.StyledDataTable
                        presenter={this.presenter}
                        isLoading={this.props.loading}
                        disableSorting={true}
                        hideFiltering={true}
                        hidePagination={true}
                    />
                </S.CardBody>
            </S.StyledCard>
        );
    }

    private get paymentDate(): string | undefined {
        const oldestFirst = _.sortBy(_.map(this.props.payments, 'paymentDate'));
        return _.last(oldestFirst);
    }

    private readonly updateData = (): void => {
        const { template, chargemasterItems, payments, providers } = this.props;
        if (template && chargemasterItems && payments && providers) {
            const data = parseRecords(template, chargemasterItems, payments, providers);
            if (!_.isEmpty(data) && !_.isEqual(data, this.presenter.data)) {
                this.presenter.onDataUpdate(data);
                this.presenter.onDataTotalChange(data.length);
            }
        }
    };
}
