import { mdiAlertCircle, mdiCheckCircle } from '@mdi/js';
import { Tooltip } from '@mui/material';
import _ from 'lodash';
import * as React from 'react';
import { components, GroupBase, MenuProps, OptionProps, OptionsOrGroups } from 'react-select';
import { AsyncProps } from 'react-select/async';
import { TooltipTransitionComponent } from '../tooltip';
import { DropdownIndicator, ISelectOption } from './select';
import * as S from './select.styles';

export type IAsyncSelectProps = AsyncProps<ISelectOption, false, GroupBase<ISelectOption>> & {
    className?: string;
    successMessage?: string;
    errorMessage?: string;
    required?: boolean;
    disabled?: boolean;
    loadOptions: (inputValue: string) => Promise<ISelectOption[]>;
};

export const AsyncSelect: React.FunctionComponent<IAsyncSelectProps> = (
    props: IAsyncSelectProps,
): React.ReactElement => {
    const { className, successMessage, errorMessage, required, disabled, ...otherProps } = props;

    const loadOptions = (
        inputValue: string,
        callback: (options: OptionsOrGroups<ISelectOption, GroupBase<ISelectOption>>) => void,
    ): void => {
        props.loadOptions(inputValue).then(callback);
        return;
    };

    const debouncedLoadOptions = _.debounce(loadOptions, 1000, {
        leading: false,
        trailing: true,
    });

    const selectProps: IAsyncSelectProps = {
        ...otherProps,
        styles: {
            menuPortal: base => ({
                ...base,
                zIndex: 9999,
            }),
            ...otherProps.styles,
        },
        components: {
            DropdownIndicator,
            Menu: (menuProps: MenuProps<ISelectOption>): React.ReactElement => (
                <S.MenuWrapper showSuccess={true} showError={true}>
                    <components.Menu {...(menuProps as any)} />
                </S.MenuWrapper>
            ),
            Option: (optionProps: OptionProps<ISelectOption>): React.ReactElement => {
                if (optionProps.data.title) {
                    return (
                        <components.Option {...(optionProps as any)}>
                            <Tooltip
                                placement="top-start"
                                title={optionProps.data.title}
                                TransitionComponent={TooltipTransitionComponent}>
                                <div>
                                    {optionProps.data.label}
                                    {optionProps.data.info}
                                </div>
                            </Tooltip>
                        </components.Option>
                    );
                }

                return (
                    <components.Option {...(optionProps as any)}>
                        {optionProps.data.label}
                        {optionProps.data.info}
                    </components.Option>
                );
            },
        },
        classNamePrefix: 'select',
        openMenuOnFocus: true,
        isClearable: !required,
        isDisabled: disabled,
    };

    const handleClick = (e: React.MouseEvent): void => {
        e.stopPropagation();
        e.preventDefault();
    };

    return (
        <S.SelectContainer className={className} onClick={handleClick}>
            <S.StyledAsyncSelect {...(selectProps as any)} loadOptions={debouncedLoadOptions} />
            {successMessage && (
                <S.SelectBottomSuccess>
                    <S.SelectBottomIcon path={mdiCheckCircle} />
                    <S.SelectBottomMessage>{successMessage}</S.SelectBottomMessage>
                </S.SelectBottomSuccess>
            )}

            {errorMessage && (
                <S.SelectBottomError>
                    <S.SelectBottomIcon path={mdiAlertCircle} />
                    <S.SelectBottomMessage>{errorMessage}</S.SelectBottomMessage>
                </S.SelectBottomError>
            )}
        </S.SelectContainer>
    );
};
