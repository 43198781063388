import { Portal } from '@mui/material';
import * as React from 'react';
import { TooltipDirection, TooltipSize, TooltipTheme } from './tooltip';
import * as S from './tooltip-entity.styles';

export interface ITooltipEntityProps {
    open: boolean;
    anchor: React.RefObject<HTMLElement | SVGElement>;
    direction: TooltipDirection;
    size?: TooltipSize;
    variant?: TooltipTheme;
}
export interface ITooltipEntityState {
    anchorRect?: ClientRect;
    viewportRect?: ClientRect;
}

export class TooltipEntity extends React.Component<ITooltipEntityProps, ITooltipEntityState> {
    constructor(props: ITooltipEntityProps) {
        super(props);
        this.state = {
            anchorRect: undefined,
            viewportRect: undefined,
        };
    }
    public render(): React.ReactNode {
        return <Portal container={document.body}>{this.renderEntity()}</Portal>;
    }
    public componentDidMount(): void {
        this.updateRect();
        window.addEventListener('resize', this.updateRect);
    }
    public componentWillUnmount(): void {
        window.removeEventListener('resize', this.updateRect);
    }
    public componentDidUpdate(): void {
        this.updateRect();
    }
    private updateRect = (): void => {
        if (this.props.anchor && this.props.anchor.current) {
            const viewportRect = document.body.getBoundingClientRect();
            const anchorRect = this.props.anchor.current.getBoundingClientRect();
            if (
                !this.state.anchorRect ||
                this.state.anchorRect.left !== anchorRect.left ||
                this.state.anchorRect.top !== anchorRect.top
            ) {
                this.setState({
                    anchorRect,
                    viewportRect,
                });
            }
        }
    };
    private renderEntity = (): React.ReactElement => {
        if (this.props.open && this.state.anchorRect && this.state.viewportRect) {
            return (
                <S.Entity
                    direction={this.props.direction}
                    size={this.props.size}
                    variant={this.props.variant}
                    anchorRect={this.state.anchorRect}
                    viewportRect={this.state.viewportRect}>
                    {this.props.children}
                </S.Entity>
            );
        }
        return <></>;
    };
}
