import styled from '@emotion/styled/macro';
import { Icon } from '@mdi/react';
import { transparentize } from 'polished';
import { Variables } from '../base';
import { BadgeSize } from './transmission-status-badge';
import { TransmissionStatusType } from './util';

export interface IBadgeProps {
    size: BadgeSize;
    claimStatus?: keyof typeof TransmissionStatusType;
}

export const Badge = styled.span<IBadgeProps>`
    display: inline-flex;
    align-items: center;
    white-space: nowrap;
    font-weight: 500;
    border-radius: ${Variables.Rounded.xlarge};

    ${(props): string => {
        let color;
        let bgColor;
        switch (props.claimStatus) {
            case TransmissionStatusType.SUBMITTED:
                color = Variables.Colors.sunset400;
                bgColor = Variables.Colors.subS10;
                break;
            case TransmissionStatusType.ACKNOWLEDGED:
                color = Variables.Colors.amber20;
                bgColor = Variables.Colors.amber10;
                break;
            case TransmissionStatusType.ADJUDICATED:
                color = Variables.Colors.subG20;
                bgColor = Variables.Colors.subG10;
                break;
            case TransmissionStatusType.COMPLETED:
                color = Variables.Colors.i20;
                bgColor = Variables.Colors.i10;
                break;
            case TransmissionStatusType.REJECTED:
                color = Variables.Colors.r30;
                bgColor = Variables.Colors.r20;
                break;
            case TransmissionStatusType.DENIED:
                color = Variables.Colors.r50;
                bgColor = Variables.Colors.r40;
                break;
            case TransmissionStatusType.FORWARDED:
                color = Variables.Colors.rose20;
                bgColor = Variables.Colors.rose10;
                break;
            default:
                color = Variables.Colors.n85;
                bgColor = Variables.Colors.n30;
        }
        if (props.size === 'large') {
            return `
                background-color: ${transparentize(0.75, bgColor)};
                color: ${color};
                & > svg {
                    fill: ${color};
                }
                padding: 5px 8px 4px 8px;
                font-size: 15px;
                line-height: 15px;
            `;
        } else {
            return `
                background-color: ${transparentize(0.75, bgColor)};
                color: ${color};
                & > svg {
                    fill: ${color};
                }
                padding: 2px 8px;
                font-size: 13px;
                line-height: 13px;
            `;
        }
    }}
`;
export const BadgeLabel = styled.span`
    margin-right: 5px;
`;

export const BadgeIcon = styled(Icon)`
    margin-left: 4px;
    height: 16px;
    width: 16px;
`;
