import styled from '@emotion/styled/macro';
import { Icon } from '@mdi/react';
import { BaseButton, Tooltip, Variables } from '../../base';

interface IWrapperProps {
    disabled?: boolean;
    isNote?: boolean;
}
export const Wrapper = styled.div<IWrapperProps>`
    display: flex;
    flex-grow: 1;

    min-height: 40px;
    padding: 6px 12px;

    border: 1px solid ${Variables.Colors.n85};
    border-radius: 6px;
    ${(props: IWrapperProps) =>
        props.disabled &&
        `
        border-color: ${Variables.Colors.n15};
        background-color: ${Variables.Colors.n15_24};
    `}
    ${(props: IWrapperProps) =>
        props.isNote &&
        `
        border-color: ${Variables.Colors.b300_l90};
        background-color: ${Variables.Colors.b300_l96};
        textarea {
            color:  ${Variables.Colors.b300_l62};
        }
    `}
`;
export const InnerCol = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
`;
export const Textarea = styled.textarea`
    background-color: transparent;
    border: 0;

    outline: none;
    flex-grow: 1;
    height: auto;
    font-size: 15px;

    min-height: 24px;
    resize: none;
`;
export const InputAttachments = styled.div`
    padding-top: 16px;
    margin-top: 8px;
    margin-bottom: 8px;
    border-top: 1px solid ${Variables.Colors.platinum};
`;
export const AttachInput = styled.input`
    display: none;
`;
export const AttachButton = styled(BaseButton)`
    padding: 0 4px;
    min-width: auto;
    background-color: transparent;
`;
export const AttachIcon = styled(Icon)`
    transform: rotate(45deg);
    vertical-align: middle;
    fill: ${Variables.Colors.n100};
`;
export const AttachTooltip = styled(Tooltip)`
    cursor: pointer;
`;
