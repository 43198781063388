import { mdiClose, mdiFilterVariant } from '@mdi/js';
import Icon from '@mdi/react';
import { observer } from 'mobx-react';
import React from 'react';
import { Overlay } from '../../../base';
import { FormFieldGroup } from '../../../form-field';
import { DataTablePresenter } from '../../data-table.presenter';
import { FilterItem } from './filter-item';
import { CustomFilterFieldName, ICustomFilter } from './filters.models';
import * as S from './filters.styles';

interface IFiltersProps {
    presenter: DataTablePresenter<any>;
}

export const Filters: React.FunctionComponent<IFiltersProps> = observer(({ presenter }) => {
    const {
        smartFilterValue,
        enableSmartFilter,
        filters,
        filterSchemas,
        onFilterAdd,
        onFilterRemove,
        onFiltersChange,
        onSmartFilterChange,
    } = presenter;

    const renderButton = (onOpen: (target: EventTarget) => void) => {
        const handleClick = (e: React.MouseEvent): void => onOpen(e.currentTarget);
        const handleChange = (e: React.FormEvent<HTMLInputElement>) => onSmartFilterChange(e.currentTarget.value);
        return (
            <S.ActionsWrapper>
                <S.ActionButton variant="outlined" onClick={handleClick}>
                    <Icon path={mdiFilterVariant} size={0.75} />
                    <span>Filter</span>
                </S.ActionButton>
                {!!enableSmartFilter && (
                    <S.FilterInput placeholder="Search..." value={smartFilterValue} onChange={handleChange} />
                )}
            </S.ActionsWrapper>
        );
    };

    const handleAddFilter = () => {
        onFilterAdd({
            filterType: 'String',
            value: '',
        });
    };

    const handleRemoveFilter = (filter: ICustomFilter) => {
        return () => onFilterRemove(filter);
    };

    const handleChange = (filter: ICustomFilter, name: CustomFilterFieldName, value: any) => {
        const changedFilter: ICustomFilter = {
            ...filter,
            [name]: value,
        };

        // Remove value if we changed Filter ID
        if (name === 'filterId') {
            changedFilter.value = undefined;
        }
        const filterSchema = filterSchemas.find(schema => schema.filterId === filter.filterId);
        if (filterSchema) {
            changedFilter.filterType = filterSchema.filterType;
        }

        onFiltersChange(filter, changedFilter);
    };

    return (
        <S.FiltersContainer>
            <Overlay
                renderButton={renderButton}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                transformOrigin={{ vertical: 'top', horizontal: 'left' }}>
                <S.FilterWrapper>
                    <FormFieldGroup onAdd={handleAddFilter} onAddLabel="Add a Filter">
                        {filters.map((filter, i) => {
                            const onChange = (name: CustomFilterFieldName, value: any) => {
                                handleChange(filter, name, value);
                            };
                            return (
                                <S.FilterGroup key={`${i}-${filter.filterId}`}>
                                    <FilterItem
                                        filterId={filter.filterId}
                                        filterMethod={filter.filterMethod}
                                        filterValue={filter.value}
                                        index={i}
                                        filterSchemas={filterSchemas}
                                        onChange={onChange}
                                    />

                                    {!filter.locked && (
                                        <S.DeleteButton
                                            variant="text"
                                            type="button"
                                            onClick={handleRemoveFilter(filter)}>
                                            <Icon path={mdiClose} size={0.75} />
                                        </S.DeleteButton>
                                    )}
                                </S.FilterGroup>
                            );
                        })}
                    </FormFieldGroup>
                </S.FilterWrapper>
            </Overlay>
        </S.FiltersContainer>
    );
});
