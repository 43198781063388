import * as React from 'react';
import * as S from './area.styles';

export interface IBaseAreaProps extends React.PropsWithoutRef<JSX.IntrinsicElements['textarea']> {}

export class BaseArea extends React.Component<IBaseAreaProps> {
    public render(): React.ReactElement {
        return <S.AreaContainer {...this.props}></S.AreaContainer>;
    }
}
