import styled from '@emotion/styled/macro';
import Icon from '@mdi/react';
import { Variables } from '../../../base';

export const CrossIcon = styled(Icon)`
    fill: ${Variables.Colors.warning};
`;

export const CheckIcon = styled(Icon)`
    fill: ${Variables.Colors.successful};
`;

export const CheckboxWrapper = styled.div`
    display: flex;
    justify-content: center;
`;

export const Item = styled.div``;

export const List = styled.div`
    display: flex;
    flex-direction: column;
    ${Item}:not(:first-of-type) {
        margin-top: 10px;
    }
`;

export const Border = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    width: 1px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    box-shadow: 0.5px 0 0 0 ${Variables.Colors.n15};
`;
