import { Configuration } from './server';

export let CoreConfiguration: Configuration;

export function setCoreConfiguration(configuration: Configuration): Configuration {
  CoreConfiguration = configuration;

  return CoreConfiguration;
}

export function CoreFetch<T>(Api: new (configuration: Configuration) => T): T {
  if (!CoreConfiguration) {
    throw new Error(
      'You have to provide Configuration to do any requests.'
    );
  }

  return new Api(CoreConfiguration);
}

export * from './server';