import styled from '@emotion/styled/macro';
import { Variables } from '../variables';

export const PopoverHeader = styled.div`
    padding: 8px 16px;
    font-size: 12px;
    font-weight: 500;
    text-transform: uppercase;
    color: ${Variables.Colors.n70};
    cursor: default;
`;
