import _ from 'lodash';
function parsePart(str: string): number[] {
    // just a number
    if (/^\d+$/.test(str)) {
        return [parseInt(str, 10)];
    }

    const matched = str.match(/^(\d+)(-|\.\.\.?|\u2025|\u2026|\u22EF)(\d+)$/);
    // 1-5 or 1..5 (equivilant) or 1...5 (doesn't include 5)
    if (matched) {
        const [, from, separator, to] = matched;
        if (from && to) {
            const fromNum = parseInt(from, 10);
            let toNum = parseInt(to, 10);

            const res: number[] = [];
            const incr = fromNum < toNum ? 1 : -1;

            // Make it inclusive by moving the right 'stop-point' away by one.
            if (separator === '-' || separator === '..' || separator === '\u2025') {
                toNum += incr;
            }

            for (let i = fromNum; i !== toNum; i += incr) {
                res.push(i);
            }
            return res;
        }
    }
    return [];
}

// Method for parse string to the numeric ranges
// i.e. 1-5, 8, 11-13 -> [1, 2, 3, 4, 5, 8, 11, 12, 13]
export const parseRange = (str: string): number[] | undefined => {
    // Remove spaces and split by comma
    const parts = str.replace(/\s/g, '').split(',');

    // Parse each part separately
    const toFlatten = parts.map(parsePart);
    const result = _.flatten(toFlatten);

    return result.length > 0 ? result : undefined;
};
