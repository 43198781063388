import dateFns from 'date-fns';
import { FieldProps, getIn } from 'formik';
import _ from 'lodash';
import * as React from 'react';
import { DatePicker, IBaseInputProps } from '../../';

type IDatePickerFieldProps = IBaseInputProps & FieldProps;

export class DatePickerField extends React.PureComponent<IDatePickerFieldProps> {
    public render(): React.ReactElement {
        const {
            field,
            form: { errors, dirty },
            ...props
        } = this.props;
        // Make sure that we set the valid date
        const date = _.isDate(this.props.field.value) ? this.props.field.value : new Date(this.props.field.value);

        const error = getIn(errors, field.name);

        const errorMessage: string | undefined = dirty && typeof error === 'string' ? error : undefined;

        return (
            <DatePicker
                {...field}
                {...props}
                errorMessage={errorMessage}
                onDateSelected={this.handleChange}
                value={dateFns.isValid(date) ? date : ''}
            />
        );
    }

    private readonly handleChange = (value: Date | undefined): void => {
        const {
            field,
            form: { setFieldValue },
        } = this.props;
        // In case if we removed the field
        if (value === undefined) {
            setFieldValue(field.name, undefined);
            return;
        }
        // NB: date field values are always YYYY-MM-DD formatted strings
        const dateOnly = value.toISOString().split('T')[0];
        setFieldValue(field.name, dateOnly);
    };
}
