import { AuthApi, CoreFetch, ToastContext } from '@enter/core';
import { PageHeader, SideImageWrapper } from 'components';
import * as S from 'components/styles';
import { Form, Formik, FormikProps } from 'formik';
import React, { useContext, useState } from 'react';
import ReactCodeInput from 'react-code-input';
import { useLocation } from 'react-router-dom';
import * as Yup from 'yup';
import {delayedReturnRedirect, handleFetch, nonNumericKeyCodes, parseQueryParams} from './util';

const FieldsCount = 6;

const schema = Yup.object().shape({
    code: Yup.string()
        .matches(/^\d{6}$/, 'Code is invalid')
        .required('Code is required'),
});

interface IVerification {
    code: string;
}

export const SignupVerification: React.FunctionComponent = (): React.ReactElement => {
    const location = useLocation();
    const toastContext = useContext(ToastContext);

    const [isLoading, setLoading] = useState(false);

    const initial: IVerification = {
        code: '',
    };

    const queryParams = parseQueryParams(location, {
        client_id: '',
        code_challenge: '',
        phone_verification_id: '',
        redirect_uri: '',
    });
    const showError = (msg: string): void => {
        toastContext.notify && toastContext.notify(msg, 'left');
    };

    const onSubmit = async (values: IVerification): Promise<void> => {
        window.grecaptcha.ready(async () => {
            setLoading(true);
            await window.grecaptcha.execute(process.env.REACT_APP_RECAPTCHA_KEY, {
                action: 'login',
            });
            const { client_id, code_challenge, phone_verification_id, redirect_uri } = queryParams;

            const token = await handleFetch(
                async () =>
                    CoreFetch<AuthApi>(AuthApi).authRegisterPatientVerify({
                        registrationVerifyRequest: {
                            clientId: client_id,
                            code: values.code,
                            codeChallenge: code_challenge,
                            phoneVerificationId: phone_verification_id,
                        },
                    }),
                showError,
            );
            if (token) {
                const { accessToken, flow } = token;
                if (accessToken) {
                    delayedReturnRedirect(redirect_uri, flow, accessToken);
                } else {
                    showError('Error retrieving access token');
                }
            }
            setLoading(false);
        });
    };

    const onChange = (formikProps: FormikProps<IVerification>): ((code: string) => void) => {
        return (code: string): void => {
            formikProps.setFieldValue('code', code);
            formikProps.validateForm();

            if (code.length === FieldsCount) {
                onSubmit({ ...formikProps.values, code });
            }
        };
    };

    return (
        <SideImageWrapper>
            <PageHeader
                title="Registration"
                instructionText="We just sent a text message to your phone. Please enter the code below:"
            />
            <Formik
                initialValues={initial}
                validationSchema={schema}
                onSubmit={onSubmit}
                validateOnBlur={true}
                validateOnChange={true}
                validateOnMount={true}>
                {(formikProps: FormikProps<IVerification>) => (
                    <Form>
                        <S.Centered>
                            <ReactCodeInput
                                type="text"
                                onChange={onChange(formikProps)}
                                filterKeyCodes={nonNumericKeyCodes}
                                fields={FieldsCount}
                            />
                        </S.Centered>
                        <S.Spacer />
                        <S.ButtonWrapper>
                            <S.Button
                                variant="primary"
                                disabled={isLoading || !formikProps.isValid}
                                size="large"
                                tabIndex={1}
                                type="submit">
                                {isLoading ? <S.Progress size="24px" /> : 'Complete Sign Up'}
                            </S.Button>
                        </S.ButtonWrapper>
                    </Form>
                )}
            </Formik>
        </SideImageWrapper>
    );
};
