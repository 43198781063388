import { AuthApi, CoreFetch, ToastContext } from '@enter/core';
import { FormInput, PageHeader, SideImageWrapper } from 'components';
import * as S from 'components/styles';
import { Form, Formik } from 'formik';
import _ from 'lodash';
import React, { useContext, useState } from 'react';
import { formatPhoneNumber, formatPhoneNumberIntl, isValidPhoneNumber } from 'react-phone-number-input';
import { useLocation } from 'react-router-dom';
import * as Yup from 'yup';
import { delayedRedirect, handleFetch } from './util';

const schema = Yup.object().shape({
    businessName: Yup.string().required('Business name is required'),
    firstName: Yup.string().required('First name is required'),
    lastName: Yup.string().required('Last name is required'),
    email: Yup.string().required('Email is required'),
    password: Yup.string().required('Password is required'),
    phone: Yup.string().required('Phone is required'),
});

interface ISignupProvider {
    businessName: string;
    firstName: string;
    lastName: string;
    email: string;
    password: string;
    phone: string;
}

export const SignupProvider: React.FunctionComponent = (): React.ReactElement => {
    const location = useLocation();
    const toastContext = useContext(ToastContext);

    const [isLoading, setLoading] = useState(false);

    const loginLink = '/authorize' + location.search;
    const initial: ISignupProvider = {
        businessName: '',
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        phone: '',
    };

    const onValidate = (values: ISignupProvider): any => {
        const errors = {};
        const { phone } = values;
        if (!_.isEmpty(phone)) {
            const prefixed = phone.indexOf('+') === -1 ? `+1${phone}` : phone;
            const valid = isValidPhoneNumber(prefixed);
            if (!valid) {
                _.set(errors, 'phone', 'Phone number is not valid');
            } else {
                const formattedValue = formatPhoneNumber(prefixed);
                const formattedInternationalValue = formatPhoneNumberIntl(prefixed);
                if (formattedValue.length > 0 && prefixed.indexOf('+1') === 0) {
                    _.set(values, 'phone', formattedValue);
                } else if (prefixed.indexOf('+1') !== 0 && formattedInternationalValue.length > 0) {
                    _.set(values, 'phone', formattedInternationalValue);
                }
            }
        }
        return errors;
    };

    const onSubmit = async (values: ISignupProvider): Promise<void> => {
        window.grecaptcha.ready(async () => {
            setLoading(true);
            await window.grecaptcha.execute(process.env.REACT_APP_RECAPTCHA_KEY, {
                action: 'signup_patient',
            });

            let erroredDisplayingMessage = false;
            const json = await handleFetch(
                async () =>
                    CoreFetch<AuthApi>(AuthApi).authRegisterProvider({
                        providerRegistrationRequest: values,
                    }),
                (msg: string): void => {
                    toastContext.notify && toastContext.notify(msg, 'left');
                    if(msg) {
                        erroredDisplayingMessage = true;
                    }
                },
            );
            if (json && json.phoneVerificationId) {
                const qs = `${location.search.substring(1)}&phone_verification_id=${json.phoneVerificationId}`;
                const uri = `/signup_verification?${qs}`;
                delayedRedirect(uri);
            } else if(!erroredDisplayingMessage) {
                toastContext.notify && toastContext.notify('Service is unavailable. Please try again later.', 'left');
            }
            setLoading(false);
        });
    };

    return (
        <SideImageWrapper>
            <Formik initialValues={initial} validationSchema={schema} onSubmit={onSubmit} validate={onValidate}>
                <Form>
                    <PageHeader title="Sign Up" />
                    <FormInput
                        label="Business Name"
                        id="businessName"
                        name="businessName"
                        type="text"
                        autoComplete="businessName"
                    />
                    <S.Spacer />
                    <FormInput
                        label="Representative First Name"
                        id="firstName"
                        name="firstName"
                        type="text"
                        autoComplete="firstName"
                    />
                    <S.Spacer />
                    <FormInput
                        label="Representative Last Name"
                        id="lastName"
                        name="lastName"
                        type="text"
                        autoComplete="lastName"
                    />
                    <S.Spacer />
                    <FormInput label="Email" id="email" name="email" type="text" autoComplete="email" />
                    <S.Spacer />
                    <FormInput
                        label="Password"
                        id="password"
                        name="password"
                        type="password"
                        autoComplete="new-password"
                    />
                    <S.Spacer />
                    <FormInput
                        label="Phone"
                        id="phone"
                        name="phone"
                        type="text"
                        autoComplete="phone"
                        x-autocompletetype="tel"
                    />
                    <S.Spacer />
                    <S.ButtonWrapper>
                        <S.Button variant="primary" disabled={isLoading} size="large" tabIndex={1} type="submit">
                            Sign Up
                        </S.Button>
                        {isLoading && <S.Progress size="24px" />}
                    </S.ButtonWrapper>
                    <S.Footer>
                        <div>
                            By clicking the SIGN UP button, you agree to our{' '}
                            <S.A
                                href="https://www.enter.health/legal/terms-of-service"
                                target="_blank"
                                rel="noopener noreferrer">
                                Terms of Service
                            </S.A>
                            , our{' '}
                            <S.A
                                href="https://www.enter.health/legal/privacy-policy"
                                target="_blank"
                                rel="noopener noreferrer">
                                Privacy Policy
                            </S.A>
                            , and the{' '}
                            <S.A
                                href="https://stripe.com/us/connect-account/legal"
                                target="_blank"
                                rel="noopener noreferrer">
                                Stripe Connected Account Agreement
                            </S.A>
                            .
                        </div>
                        <S.Spacer />
                        <S.Link to={loginLink}>Back to Login</S.Link>
                    </S.Footer>
                </Form>
            </Formik>
        </SideImageWrapper>
    );
};
