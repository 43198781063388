import styled from '@emotion/styled/macro';
import { Variables } from '../variables';

export const LoadingMeter = styled.svg`
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    width: 100%;
    background-color: ${Variables.Colors.white};
`;

export const LoadingContainer = styled.g`
    transform: rotate(180deg) translate(-21px, -21px);
`;

export const LoadingRect = styled.rect`
    fill: ${Variables.Colors.n15_16};
    stroke: ${Variables.Colors.n30};
    stroke-width: 0.5px;
    transition: all 0.8s ease-in-out;
`;
