import _ from 'lodash';
import * as mobx from 'mobx';
import { Column, SortingRule } from 'react-table';
import { ICustomFilter, IFilterSchema, isEmptyValueMethod } from './components';
import { TableStorage } from './data-table.service';

interface ITableDefaults {
    pageIndex?: number;
    pageSize?: number;
    sortBy?: SortingRule<object>[];
}

// Kepp it as a method to avoid reference updates
const DEFAULT_FILTER = (): ICustomFilter => ({
    filterType: 'String',
    value: '',
});

/**
 * IColumn interface is created to patch react-table column with new features.
 */
export type ITableColumn<D extends object = any, S = string> = Column<D> & {
    show?: boolean;
    sortType?: S;
    defaultSort?: SortingRule<object>;
    disableSortBy?: boolean;
    description?: string;
};

export class DataTablePresenter<D extends object> {
    @mobx.observable.deep public data: D[] = [];
    // Total number of data. This value can be higher than data.length.
    @mobx.observable.deep public dataTotal: number = 0;
    @mobx.observable.ref public controlledPageSize: number;
    // validFilters has to be modified only within mobx.reaction
    @mobx.observable.deep public validFilters: ICustomFilter[] = [];
    @mobx.observable.deep public filters: ICustomFilter[] = [];
    @mobx.observable.deep public filterSchemas: IFilterSchema[] = [];
    @mobx.observable.deep public columns: ITableColumn<D>[] = [];
    @mobx.observable.deep public hiddenColumnIds: string[] = [];

    // List of selected entity ids
    @mobx.observable.deep public selectedIds: Record<string, boolean> = {};

    @mobx.observable.ref public smartFilterValue: string = '';

    // Unique name used to save table-specific data to the storage
    public readonly name: string;

    public readonly storageKey: string;

    public readonly enableSmartFilter: boolean = false;

    private readonly onSelected: ((selectedIds: Record<string, boolean>) => void) | undefined;

    private readonly filterParser: ((value: string) => ICustomFilter[][]) | undefined;

    constructor({
        name,
        data = [],
        columns = [],
        filterSchemas = [],
        filters = [DEFAULT_FILTER()],
        selectedIds = {},
        onSelected,
        filterParser,
    }: {
        readonly name: string;
        // Initial data can be passed to the presenter
        data: D[];
        columns: ITableColumn<D>[];
        filterSchemas: IFilterSchema[];
        selectedIds?: Record<string, boolean>;
        // Default filters that should be applied after table is mounted
        filters?: ICustomFilter[];
        onSelected?: (selectedIds: Record<string, boolean>) => void;
        filterParser?: (value: string) => ICustomFilter[][];
    }) {
        this.name = name;
        this.storageKey = this.name;
        this.data = data;
        this.columns = columns;
        this.filterSchemas = filterSchemas;
        this.filters = this.getDefaultFilters(filters);
        this.onSelected = onSelected;
        this.filterParser = filterParser;

        if (filterParser) {
            this.enableSmartFilter = true;
        }

        this.dataTotal = data.length;
        this.hiddenColumnIds = this.getHiddenColumnIds();
        this.selectedIds = selectedIds;
        this.validFilters = this.filters.filter(this.isValidFilter);

        // Determines page size
        this.controlledPageSize = this.getTableDefaults()?.pageSize || 10;

        // React on change filters and if valid filters are changed, update them
        mobx.reaction(
            () => this.filters.filter(this.isValidFilter),
            f => {
                if (!_.isEqual(f, this.validFilters)) {
                    this.validFilters = f;
                }
                return;
            },
            {
                // Fire immediately to get validFilters for default filters passed to the constructor
                fireImmediately: true,
            },
        );
    }

    @mobx.action
    public addItem = (item: any) => {
        this.data = [...this.data, item];
        this.dataTotal = this.data.length;
    };

    @mobx.action
    public addItems = (items: any[]) => {
        this.data = [...this.data, ...items];
        this.dataTotal = this.data.length;
    };

    @mobx.action
    public onSmartFilterChange = (value: string) => {
        this.smartFilterValue = value;

        if (!this.filterParser) {
            return;
        }

        const filterGroups = this.filterParser(value);

        filterGroups.forEach(filterGroup => {
            const firstFilter = _.first(filterGroup);

            if (!firstFilter) {
                return;
            }

            this.findAndUpdateFilters(
                {
                    filterId: firstFilter.filterId,
                },
                filterGroup,
            );
        });
    };

    @mobx.action
    public onFilterAdd = (filter: ICustomFilter) => {
        this.filters.push(filter);
    };

    @mobx.action
    public onFilterRemove = (filter: ICustomFilter) => {
        const idx = _.findIndex(this.filters, filter);

        if (idx !== -1) {
            this.filters.splice(idx, 1);
        }
    };

    @mobx.action
    public onFiltersChange = (filter: ICustomFilter, changedFilter: ICustomFilter) => {
        const idx = _.findIndex(this.filters, filter);
        if (idx !== -1) {
            this.filters[idx] = changedFilter;
        }
    };

    @mobx.action
    public findAndUpdateFilter = (query: Partial<ICustomFilter>, changedFilter: ICustomFilter) => {
        const idx = _.findIndex(this.filters, query);

        if (idx !== -1) {
            this.filters[idx] = changedFilter;
        } else {
            this.filters.push(changedFilter);
        }
    };

    @mobx.action
    public findAndUpdateFilters = (query: Partial<ICustomFilter>, changedFilters: ICustomFilter[]) => {
        const filteredFilters = this.filters.filter(el => !_.isMatch(el, query));

        this.filters = [...filteredFilters, ...changedFilters];
    };

    @mobx.action
    public updateFilters = (newFilters: ICustomFilter[]) => {
        this.filters = [...newFilters];
    };

    @mobx.action
    public findAndRemoveFilter = (query: Partial<ICustomFilter>) => {
        this.filters = this.filters.filter(filter => !_.isMatch(filter, query));
    };

    @mobx.action
    public onDataTotalChange = (n: number) => {
        this.dataTotal = n;
    };

    @mobx.action
    public onDataUpdate = (data: D[]) => {
        this.data = data;
    };

    @mobx.action
    public onFiltersUpdate = (filterSchemas: IFilterSchema[]) => {
        this.filterSchemas = filterSchemas;
    };

    @mobx.action
    public onColumnsUpdate = (columns: ITableColumn<D>[]) => {
        this.columns = columns;
    };

    @mobx.action
    public onColumnToggle = (columnId: string) => {
        this.hiddenColumnIds = this.hiddenColumnIds.includes(columnId)
            ? this.hiddenColumnIds.filter(hiddenColumn => hiddenColumn !== columnId)
            : [...this.hiddenColumnIds, columnId];

        TableStorage.saveItem(this.storageKey, 'hidden_columns', this.hiddenColumnIds);
    };

    @mobx.action
    public onSelectedUpdate = (selectedIds: Record<string, boolean>) => {
        // Custom controlled onSelected to manage selectedState out of presenter
        if (this.onSelected) {
            this.onSelected(selectedIds);

            return;
        }

        this.selectedIds = selectedIds;
    };

    @mobx.action
    public onPageSizeUpdate = (pageSize: number) => {
        this.controlledPageSize = pageSize;
    };

    public getTableDefaults = (): ITableDefaults | undefined => {
        return TableStorage.getItem(this.storageKey, 'defaults');
    };

    public saveTableDefaults = (tableDefaults: ITableDefaults) => {
        TableStorage.saveItem(this.storageKey, 'defaults', tableDefaults);
    };

    public saveFilters = (filters: ICustomFilter[]) => {
        TableStorage.saveItem(this.storageKey, 'filters', filters);
    };

    private getDefaultFilters = (filters: ICustomFilter[]): ICustomFilter[] => {
        if (filters.length !== 0) {
            return filters;
        }

        return TableStorage.getItem(this.storageKey, 'hidden_filters') || [];
    };

    private isValidFilter = (f: ICustomFilter): boolean => {
        return (
            !_.isUndefined(f.filterId) &&
            !_.isUndefined(f.filterMethod) &&
            (!_.isUndefined(f.value) || isEmptyValueMethod(f.filterMethod))
        );
    };

    private getHiddenColumnIds(): string[] {
        const hiddenColumns = TableStorage.getItem<string[]>(this.storageKey, 'hidden_columns');
        if (hiddenColumns) {
            return hiddenColumns;
        }

        // NB: if show:true or show:undefined, column is visible
        const hidden = _.filter(this.columns, column => column.show === false);
        if (hidden) {
            return _.compact(_.map(hidden, 'id'));
        } else {
            return [];
        }
    }
}
