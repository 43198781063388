import * as React from 'react';
import { BaseCheckbox, IBaseCheckboxProps } from '../../../base';
import * as S from './cells.styles';

export const RowSelectCell = React.memo(
    (
        props: IBaseCheckboxProps & {
            showBorder?: boolean;
        },
    ) => {
        const { showBorder = false } = props;
        // Stop propagation to avoid click event on the row
        const handleClick = (e: React.MouseEvent<HTMLElement>) => {
            e.stopPropagation();
        };

        return (
            <>
                <S.CheckboxWrapper onClick={handleClick}>
                    <BaseCheckbox variant="large" {...props} />
                </S.CheckboxWrapper>
                {showBorder && <S.Border />}
            </>
        );
    },
);
