import styled from '@emotion/styled/macro';
import { Variables } from '../../base';

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: -1px;
    flex-grow: 1;
`;
export const CellHeader = styled.th`
    font-size: 15px;
    font-weight: 500;
    color: ${Variables.Colors.n100};
    white-space: nowrap;
    text-align: right;
    line-height: 1.2;
    padding: 4px 8px;
`;
export const CellValue = styled.td`
    width: 100%;
    font-size: 15px;
    line-height: 18px;
    padding-left: 8px;
    color: ${Variables.Colors.n70};
`;
export const CellBlock = styled.td`
    width: 100%;
    font-size: 18px;
    color: ${Variables.Colors.n70};
    text-align: center;
    padding-top: 12px;
`;

export const Row = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;

    &:not(:last-child) {
        margin-bottom: 12px;
    }
`;

export const Relationship = styled.span`
    color: ${Variables.Colors.b300_l62};
    font-size: 14px;
    background-color: ${Variables.Colors.b300_l96};
    border-radius: ${Variables.Rounded.small};
    padding: 6px 8px;
    white-space: nowrap;
    float: right;
`;

export const Insured = styled.span`
    &:not(:last-of-type):after {
        content: '·';
        margin: 0 8px;
        font-weight: 600;
        color: ${Variables.Colors.n50};
    }
`;
export const InsuredName = styled(Insured)`
    font-weight: 500;
`;

export const Address = styled.span`
    white-space: pre;
`;

export const CityStatePostal = styled.span``;

export const Country = styled.span`
    &:before {
        content: ', ';
    }
`;

export const FooterLink = styled.a`
    flex-grow: 1;
    text-align: right;
    font-size: 16px;
    line-height: 1;
    letter-spacing: 0.2px;
    color: ${Variables.Colors.p200};
    cursor: pointer;
`;

export const EmptyLabel = styled.span`
    color: ${Variables.Colors.n30};
`;

export const IconRow = styled(Row)`
    font-size: 16px;
    color: ${Variables.Colors.n85};

    img {
        font-size: 18px;
        margin-right: 10px;
    }
`;

export const RowLabel = styled.span``;

export const TitleRow = styled.div``;
export const Title = styled.div`
    font-size: 18px;
    font-weight: 500;
    color: ${Variables.Colors.b300};
`;

export const SubTitle = styled.div`
    font-size: 14px;
    color: ${Variables.Colors.n50};
`;

export const Body = styled.div`
    margin-top: 8px;
`;

export const LabelInfo = styled.span`
    color: ${Variables.Colors.n50};
`;

export const Col = styled.div`
    display: flex;
    flex-direction: row;
    /* justify-content: space-between; */

    ${IconRow} {
        &:not(:last-child) {
            margin-bottom: 0;
        }

        &:not(:first-of-type) {
            margin-left: 16px;
        }
    }
`;

export const Actions = styled.div`
    /* max-width: 100px; */
    background-color: ${Variables.Colors.b300_l96};
    height: 100%;
    padding: 20px 10px;
    border-left: 1px solid ${Variables.Colors.platinum};
    display: flex;
    flex-direction: column;
    width: 220px;
`;

export const Divider = styled.div`
    border-bottom: ${Variables.Borders.regular};
    /* position: absolute; */
    left: 0;
    width: 100%;
    margin: 2px 0;
`;
