import styled from '@emotion/styled/macro';

export const Wrapper = styled.section`
    width: 100%;
    height: 100vh;
`;

export const LoadingContainer = styled.section`
    position: absolute;
    z-index: 1;
    bottom: 10px;
    left: 10px;
    display: flex;
    align-items: center;
    justify-content: center;

    transition: all 0.3s ease;
`;

export const Container = styled.section`
    position: relative;
`;

export const IFrame = styled.iframe`
    max-width: 100%;
    max-height: 100%;
    width: 100%;
    min-height: 700px;
    border: none;
`;

export const OverlayWrapper = styled.div`
    border: 1px solid black;
    color: red;
    background: green;
    width: 100px;
    height: 100px;
`;
