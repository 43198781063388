import styled from '@emotion/styled/macro';
import { Icon } from '@mdi/react';
import { BaseButton, Variables } from '../../base';
import { MessagingAuthorType } from '../util';

interface IAuthorType {
    type?: keyof typeof MessagingAuthorType;
}

export const Header = styled.div`
    display: flex;
    align-items: center;
    box-shadow: ${Variables.Shadows.border};

    padding: 20px 32px 16px 32px;
`;

export const HeaderLabels = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
`;

export const AuthorType = styled.div<IAuthorType>`
    font-size: 15px;
    line-height: 1.33;
    margin-bottom: 4px;

    color: ${(props: IAuthorType): string => {
        switch (props.type) {
            case MessagingAuthorType.SUPPORT:
                return Variables.Colors.b300;
            case MessagingAuthorType.PATIENT:
                return Variables.Colors.r30;
            case MessagingAuthorType.PROVIDER:
                return Variables.Colors.subG30;
            default:
                throw new Error(`Unknown AuthorType ${props.type}`);
        }
    }};
`;

export const Author = styled.div`
    font-size: 16px;
    font-weight: 500;
    line-height: 1.11;
    letter-spacing: 0.2px;
    color: ${Variables.Colors.n100};
`;

export const Value = styled.span`
    font-size: 16px;
    font-weight: 500;
    line-height: 1.11;
    letter-spacing: 0.2px;
    color: ${Variables.Colors.n100};
`;

export const BackButton = styled(BaseButton)`
    border: 0;
    min-width: 0;
    padding: 0 8px;
    margin-right: 8px;
`;

export const CloseButton = styled(BaseButton)`
    border: 0;
    min-width: 0;
    padding: 0 8px;
`;

export const CloseIcon = styled(Icon)`
    fill: ${Variables.Colors.n30};
`;

export const BackIcon = styled(Icon)`
    fill: ${Variables.Colors.n30};
`;
