import styled from '@emotion/styled/macro';
import { Variables } from '../../variables';

export const Postfix = styled.div`
    height: 24px;
    width: 24px;
    right: 10px;
    position: absolute;
    cursor: pointer;
    svg {
        width: 100%;
        height: 100%;
    }
    path {
        fill: ${Variables.Colors.n30};
    }
    &:hover {
        path {
            fill: ${Variables.Colors.n50};
        }
    }
`;
