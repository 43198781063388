import * as React from 'react';
import * as S from './file-uploader.styles';

type RenderProps = {
    onClick(): void;
};

type IBaseFileUploaderProps = {
    children: (renderProps: RenderProps) => React.ReactElement;
    onChange(file: File | null): void;
} & Pick<React.PropsWithoutRef<JSX.IntrinsicElements['input']>, 'accept'>;

export class BaseFileUploaderContainer extends React.Component<IBaseFileUploaderProps> {
    private realInputRef: React.RefObject<HTMLInputElement>;
    constructor(props: IBaseFileUploaderProps) {
        super(props);
        this.realInputRef = React.createRef<HTMLInputElement>();
    }

    public render(): React.ReactElement {
        const { children, ...otherProps } = this.props;
        return (
            <S.FileUploaderContainer>
                <S.FileUploaderUnstyledWrapper>
                    {children({ onClick: this.handleClick })}
                    <S.FileUploader
                        // Keep otherProps on top of the props to let `this.handleChange` override the input handler
                        {...otherProps}
                        ref={this.realInputRef}
                        onChange={this.handleChange}
                        // Prevent set null or undefined
                        type="file"
                        value=""
                    />
                </S.FileUploaderUnstyledWrapper>
            </S.FileUploaderContainer>
        );
    }

    private handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        const file = e.target.files ? e.target.files[0] : null;

        this.props.onChange(file);
    };

    private handleClick = (): void => {
        this.realInputRef.current?.click();
    };
}
