const Responsive = {
    mobile: 600,
    tabletPortrait: 900,
    tabletLandscape: 1200,
    desktop: 1800,
};
export const isSupport = /support\./.test(window.location.host);

export const isPhoneOnly = (): boolean => {
    return !isSupport && window.outerWidth < Responsive.mobile;
};

export const isTabletPortraitDown = (): boolean => {
    return !isSupport && window.outerWidth < Responsive.tabletPortrait;
};

export const isTabletPortraitOnly = (): boolean => {
    return !isSupport && window.outerWidth > Responsive.mobile && window.outerWidth < Responsive.tabletPortrait;
};

export const isTabletLandscapeDown = (): boolean => {
    return !isSupport && window.outerWidth < Responsive.tabletLandscape;
};

export const isDesktopDown = (): boolean => {
    return !isSupport && window.outerWidth < Responsive.desktop;
};

export const forPhoneOnly = (content: string): string => {
    return isSupport ? '' : `@media screen and (max-width: ${Responsive.mobile - 1}px) { ${content} };`;
};

export const forTabletPortraitDown = (content: string): string => {
    return isSupport ? '' : `@media screen and (max-width: ${Responsive.tabletPortrait}px) { ${content} };`;
};

export const forTabletPortraitOnly = (content: string): string => {
    return isSupport
        ? ''
        : `@media screen and (min-width: ${Responsive.mobile}px) and (max-width: ${Responsive.tabletPortrait}px) { ${content} };`;
};

export const forTabletLandscapeDown = (content: string): string => {
    return isSupport ? '' : `@media screen and (max-width: ${Responsive.tabletLandscape}px) { ${content} };`;
};

export const forDesktopDown = (content: string): string => {
    return isSupport ? '' : `@media screen and (max-width: ${Responsive.desktop}px) { ${content} };`;
};

export const forDesktopOnly = (content: string): string => {
    return isSupport ? '' : `@media screen and (min-width: ${Responsive.desktop}px) { ${content} };`;
};
