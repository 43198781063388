import styled from '@emotion/styled/macro';
import { Icon } from '@mdi/react';
import { Variables } from '../base';

interface IRecentProps {
    selected?: boolean;
}
export const selected = (props: IRecentProps): string => {
    return props.selected ? Variables.Colors.n15_24 : Variables.Colors.white;
};

export const Recent = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0 0 8px 0;
    margin: 0;
    box-shadow: ${Variables.Shadows.border};
`;
export const RecentTitle = styled.div`
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 500;
    color: ${Variables.Colors.n100};
    padding: 16px 16px;
`;

export const RecentCloseIcon = styled(Icon)`
    opacity: 0;
    cursor: pointer;
    & {
        fill: ${Variables.Colors.nx12};
    }
    &:hover {
        fill: ${Variables.Colors.nx24};
    }
`;
export const RecentItem = styled.div<IRecentProps>`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px 16px;
    cursor: pointer;

    background-color: ${selected};
    &:hover {
        background-color: ${Variables.Colors.n15_24};
    }
    &:hover {
        background-color: ${Variables.Colors.n15_48};
        ${RecentCloseIcon} {
            opacity: 1;
        }
    }
    ${props =>
        props.selected &&
        `
        ${RecentCloseIcon} {
            opacity: 1;
        }
    `}
`;
export const RecentIcon = styled(Icon)`
    margin-right: 16px;
    & {
        fill: ${Variables.Colors.nx24};
    }
`;
export const RecentText = styled.div`
    line-height: 16px;
    flex-grow: 1;
    color: ${Variables.Colors.n100};
`;

export const RecentClose = styled.div`
    display: flex;
    align-items: center;
`;
