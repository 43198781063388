import React from 'react';
import { ActionMenuBadge, IActionMenuBadgeItem } from './action-menu-badge';
import { assembleName, generateLabel } from './util';

export interface IPatientBadgeProps {
    className?: string;
    id?: string;
    first?: string;
    last?: string;
    suffix?: string;
    path?: string;
    tooltip?: string;
    items?: IActionMenuBadgeItem[];
}
export const PatientBadge: React.FunctionComponent<IPatientBadgeProps> = (
    props: IPatientBadgeProps,
): React.ReactElement => {
    const { className, id, first, last, suffix, path, tooltip, items } = props;
    const name = assembleName(first, last, suffix);
    return (
        <ActionMenuBadge
            className={className}
            label={generateLabel('Patient', id, name)}
            path={path || `/patients/${id}`}
            tooltip={tooltip}
            items={items}
            id={id}
        />
    );
};
