import {AuthApi, AuthSingleSignOnInfoResponse, CoreFetch, OAuthApi, ToastContext} from '@enter/core';
import { FormInput, PageHeader } from 'components';
import * as S from 'components/styles';
import { Form, Formik } from 'formik';
import React, {useContext, useEffect, useState} from 'react';
import { useLocation } from 'react-router-dom';
import * as Yup from 'yup';
import {delayedReturnRedirect, handleFetch} from '../util';
import { IAuthorizeParams, ITwoFactorParams } from './authorize.models';

const passwordAndEmailSchema = Yup.object().shape({
    email: Yup.string().required('Email is required'),
    password: Yup.string().required('Password is required'),
});

interface ILogin {
    email: string;
    password: string;
}

interface ILoginFormProps extends IAuthorizeParams {
    onTwoFactorAuth: (d: ITwoFactorParams) => void;
}

export const LoginForm: React.FunctionComponent<ILoginFormProps> = (props: ILoginFormProps): React.ReactElement => {
    const { client_id, code_challenge, redirect_uri, scope, onTwoFactorAuth, invitation_code, code, sso_return } = props;
    const location = useLocation();
    const toastContext = useContext(ToastContext);

    const isSSOFlow = ('true' === sso_return && !!code);

    let schema = Yup.object().shape({
        email: Yup.string().required('Email is required')
    });

    const [isSSOSubmitting, setSSOSubmitting] = useState(false);
    const [showPassword, setShowPassword] = useState(isSSOFlow);
    const [isLoading, setLoading] = useState(false);
    const [isShowLogin, setShowLogin] = useState(true);

    const initial: ILogin = {
        email: '',
        password: '',
    };

    // derived
    // const signupLink = '/signup_provider' + location.search;
    const forgotLink = '/forgot_password' + location.search;

    const showError = (msg: string): void => {
        toastContext.notify && toastContext.notify(msg, 'left');
    };

    const determineFlow = (): string => {
        switch (client_id) {
            case 'PROVIDER_DASHBOARD':
                return 'PROVIDER';
            case 'SUPPORT_TOOL':
                return 'SUPPORT';
            default:
                return 'OTHER';
        }
    };
    const getTitle = () => {
        const flow = determineFlow();
        return flow === 'SUPPORT' ? 'Support Sign In' : 'Sign In';
    };
    const getInstructionText = () => {
        const flow = determineFlow();
        return flow === 'SUPPORT'
            ? 'Sign in to the support tool.'
            : 'Sign in to see how your practice is performing and search for patients.';
    };

    const checkForSSOFlow = async (email: string, recaptchaToken: string): Promise<AuthSingleSignOnInfoResponse|undefined> => {
        return handleFetch(
        async () =>
            CoreFetch<AuthApi>(AuthApi).postSSOInfo({
                authSingleSignOnInfoRequest: {
                    email,
                    token: recaptchaToken,
                }
            }),
        (msg: string): void => {
            toastContext.notify && toastContext.notify(msg, 'left');
        });
    }

    useEffect(() => {
        if(isSSOFlow && !isSSOSubmitting) {
            const ssoEmail = window.localStorage.getItem('sso_email');
            if(ssoEmail) {
                setSSOSubmitting(true);
                setLoading(true);
                setShowLogin(false);
                // NB: wait for Google RECAPTCHA to load
                setTimeout(() => {
                    console.log("Inside SSO submission timeout");
                    onSubmit({email: ssoEmail, password: ''});
                    window.localStorage.removeItem('sso_email');
                }, 500);
            } else {
                console.log("No SSO email set");
                const code_index = location.search.indexOf('&code=');
                if(code_index > -1) {
                    const locationSearch = location.search.replace('&sso_return=true', '').substring(0, code_index - 1);
                    console.log("Refresh redirect params: " + locationSearch);
                    window.location.href = '/authorize' + locationSearch;
                }
            }
        }
    }, []);



    const onSubmit = (values: ILogin): void => {
        console.log("onSubmit fired");
        const { email, password } = values;
        window.grecaptcha.ready(async () => {
            console.log("onSubmit grecaptcha ready");
            setLoading(true);
            if(!showPassword && !isSSOFlow) {
                const recaptchaForSSO: string = await window.grecaptcha.execute(process.env.REACT_APP_RECAPTCHA_KEY, {
                    action: 'check_sso_requirement',
                });
                const resp: AuthSingleSignOnInfoResponse|undefined = await checkForSSOFlow(email, recaptchaForSSO);
                if(!resp) {
                    setLoading(false);
                    return;
                }
                if(resp.sso && resp.url) {
                    //store sso values and redirect
                    window.localStorage.setItem('sso_params', location.search);
                    window.localStorage.setItem('sso_email', email);
                    window.location.href = resp.url;
                    return;
                } else {
                    console.log("hasCheckedForSSOFlow set to true");
                    schema = passwordAndEmailSchema;
                    setShowPassword(true);
                    document.getElementById('password')?.focus();
                    setLoading(false);
                    return;
                }
            }

            const recaptcha: string = await window.grecaptcha.execute(process.env.REACT_APP_RECAPTCHA_KEY, {
                action: 'login',
            });
            const passwordPayload = isSSOFlow ? 'SSO;' + code : 'STANDARD;' + btoa(password!);
            const token = await handleFetch(
                async () =>
                    CoreFetch<OAuthApi>(OAuthApi).oauthLoginToken({
                        grantType: 'password',
                        clientId: client_id,
                        clientSecret: code_challenge + ';' + recaptcha,
                        username: email,
                        password: passwordPayload,
                        invitationCode: !!invitation_code ? invitation_code : undefined,
                        scope,
                        redirectUri: redirect_uri
                    }),
                (msg: string): void => {
                    console.log("Error handler");
                    console.log("isSSOFlow=" + isSSOFlow);
                    console.log("msg=" + msg);
                    if(isSSOFlow && "Invalid Credentials Entered" === msg) {
                        msg = 'Invalid Credentials. Please make sure your email has been invited to the organization and then close and retry again.';
                    }
                    toastContext.notify && toastContext.notify(msg, 'left');
                },
            );
            if (token) {
                const { accessToken, flow, twoFactorResponseId, twoFactorResponseType } = token;
                if (accessToken) {
                    delayedReturnRedirect(redirect_uri, flow, accessToken);
                } else if (email && twoFactorResponseId && twoFactorResponseType) {
                    onTwoFactorAuth({
                        email,
                        responseId: twoFactorResponseId,
                        responseType: twoFactorResponseType
                    });
                } else {
                    showError('Error retrieving access token');
                    // tslint:disable-next-line:no-console
                    console.warn(token);
                }
            }
            setLoading(false);
        });
    };
    const handleShowLogin = (): void => {
        setShowLogin(true);
    };
    const forgotPassword = (): React.ReactElement | undefined => <S.Link to={forgotLink}>Forgot password?</S.Link>;
    return (
        <Formik initialValues={initial} validationSchema={schema} onSubmit={onSubmit}>
            <Form>
                <PageHeader title={getTitle()} instructionText={getInstructionText()} />
                {isShowLogin && (
                    <>
                        <FormInput
                            label="Email"
                            id="email"
                            name="email"
                            type="text"
                            autoComplete="email"
                            placeholder="Enter your email address"
                        />
                        <S.Spacer />
                        {showPassword ? <FormInput
                            label="Password"
                            id="password"
                            name="password"
                            type="password"
                            autoComplete="current-password"
                            placeholder="Enter your password"
                            caption={forgotPassword()}
                        /> : <span/>}
                        <S.Spacer />
                        <S.Terms>
                            By logging in you accept these{' '}
                            <a
                                href="https://www.enter.health/legal/terms-of-service"
                                target="_blank"
                                rel="noopener noreferrer">
                                Terms of Service
                            </a>
                            .
                        </S.Terms>
                        <S.ButtonWrapper>
                            <S.Button variant="primary" disabled={isLoading} size="large" tabIndex={1} type="submit">
                                {isLoading ? <S.Progress size="24px" /> : (showPassword ? 'Log In' : 'Next')}
                            </S.Button>
                        </S.ButtonWrapper>
                    </>
                )}
                {!isShowLogin && (
                    <span>
                        <S.ButtonWrapper>
                            <S.Button type="submit" disabled={isLoading} variant="primary" size="large" onClick={handleShowLogin}>
                                {isLoading ? <S.Progress size="24px" /> : (showPassword ? 'Log In' : 'Next')}
                            </S.Button>
                        </S.ButtonWrapper>
                    </span>
                )}
                {/* <S.Footer>
                    <S.Link to={signupLink}>Don't have an account? Sign Up</S.Link>
                </S.Footer> */}
            </Form>
        </Formik>
    );
};
