import CalibreBold from './Calibre-Bold.woff';
import CalibreLight from './Calibre-Light.woff';
import CalibreMedium from './Calibre-Medium.woff';
import CalibreRegular from './Calibre-Regular.woff';
import CalibreSemibold from './Calibre-Semibold.woff';

export const Calibre = {
    Bold: CalibreBold,
    Light: CalibreLight,
    Medium: CalibreMedium,
    Regular: CalibreRegular,
    Semibold: CalibreSemibold,
};
