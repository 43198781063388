import { css } from '@emotion/react/macro';
import styled from '@emotion/styled/macro';
import { Icon } from '@mdi/react';
import { BaseButton, Tooltip, Variables } from '../base';
import { ClaimIdBadge } from './claim-id-badge';

export const ClaimBadge = styled(ClaimIdBadge)`
    margin-left: 8px;
    &:first-of-type {
        margin-left: 0;
    }
`;

export const Wrapper = styled.section`
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
`;

export const ExpandButton = styled(BaseButton)`
    display: inline-flex;
    overflow: hidden;
    color: ${Variables.Colors.b300_l62};
    background: transparent;
    font-size: 14px;
`;

export const ExpandIcon = styled(Icon)`
    height: 18px;
    width: 18px;
    fill: ${Variables.Colors.b300_l62};
`;

export const VisitTooltip = styled(Tooltip)`
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    padding: 6px;
    cursor: pointer;
`;

export const ActionIcon = styled(Icon)`
    height: 18px;
    width: 18px;
    fill: ${Variables.Colors.b300_l62};
`;

export const ActionButton = styled.div`
    display: inline-flex;
    border-left: 1px solid ${Variables.Colors.b300_l90};
    padding: 5px;
`;

export const ActionTooltip = styled(Tooltip)`
    padding: 4px;
    display: inline-flex;
    align-items: center;
    cursor: pointer;
`;

const BadgeHover = css`
    &:hover {
        cursor: pointer;
        border-color: ${Variables.Colors.b300_l76};

        ${ActionButton} {
            border-color: ${Variables.Colors.b300_l76};
        }
        ${ActionIcon} {
            fill: ${Variables.Colors.b300};
        }
    }
`;

const BadgeDisabled = css`
    cursor: default;
`;

export const Badge = styled.div<{ disabled: boolean }>`
    display: inline-flex;
    align-items: center;

    padding: 0;
    background-color: ${Variables.Colors.b300_l96};
    border: 1px solid ${Variables.Colors.b300_l90};
    border-radius: 3px;
    overflow: hidden;

    letter-spacing: 0.2px;
    color: ${Variables.Colors.p500};
    margin: 10px;

    ${props => (props.disabled ? BadgeDisabled : BadgeHover)}
`;
