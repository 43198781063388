import styled from '@emotion/styled/macro';
import { BaseButton, Variables } from '../base';

export const DialogBody = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0 24px 16px 24px;
    flex-grow: 1;
`;

export const DialogButton = styled(BaseButton)`
    &:not(:first-of-type) {
        margin-left: 24px;
    }
`;

export const DialogActions = styled.div`
    display: flex;
    flex-grow: 1;
    justify-content: flex-end;
    padding: 0px 24px;
`;

export const Row = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
`;
export const Col = styled.div`
    display: flex;
    flex-direction: column;
`;

export const Textarea = styled.textarea`
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    padding: 8px 8px;
    margin: 24px 0 0 0;

    border-radius: ${Variables.Rounded.medium};
    border: ${Variables.Borders.regular};
    background-color: ${Variables.Colors.nx4};

    font-family: monospace;
    white-space: pre-wrap;
    outline: none;
`;

export const Button = styled(BaseButton)`
    align-self: flex-end;
    margin: 0;
`;

export const Spacer = styled.span`
    flex-grow: 1;
`;
