import styled from '@emotion/styled/macro';
import { Icon } from '@mdi/react';
import { forPhoneOnly, forTabletPortraitOnly, Variables } from '../../base';
import { Card } from '../../card';
import { InfoIcon } from '../../info-icon';
import { IDataset } from './metrics-card';

type IDeltaProps = Pick<IDataset, 'sentiment'>;

export const MetricsCard = styled(Card)`
    padding: 24px 24px;
    align-self: stretch;

    ${forTabletPortraitOnly(`
        flex-basis: calc(50% - 16px);
        margin: 16px 0;
    `)};

    max-width: calc(25% - 24px);

    ${forPhoneOnly(`
        max-width: 100%;
    `)}
`;

export const MetricsLoader = styled(MetricsCard)`
    display: flex;
    justify-content: stretch;
`;

export const MetricsError = styled(MetricsCard)`
    display: flex;
    flex-direction: column;
    justify-content: stretch;
`;

export const Header = styled.div`
    display: flex;
    position: relative;
    align-items: top;
    margin-top: -4px;
`;

export const Title = styled.h3`
    color: ${Variables.Colors.p500};
    flex-grow: 1;
    font-size: 15px;
    font-weight: 500;
    letter-spacing: 0.2px;
    line-height: 24px;
    text-transform: uppercase;
    margin: 0;
`;

export const OptionsIcon = styled(Icon)`
    height: 20px;
    width: 20px;
    margin-top: 2px;
    fill: ${Variables.Colors.nx24};
    cursor: pointer;
    &:hover {
        fill: ${Variables.Colors.n50};
    }
    &:active {
        fill: ${Variables.Colors.n30};
    }
`;

export const Primary = styled.div`
    display: flex;
    padding: 8px 0 8px 0px;
    align-items: center;
`;

export const Metric = styled.div`
    display: inline-block;
    color: ${Variables.Colors.n100};
    font-size: 2rem;
    line-height: 2rem;
    letter-spacing: 0.1rem;
    overflow: hidden;
    text-overflow: ellipsis;
`;

export const StyledInfoIcon = styled(InfoIcon)`
    margin-left: 6px;
`;

export const TooltipHeader = styled.div`
    color: ${Variables.Colors.n70};
`;

export const TooltipFormula = styled.div`
    color: ${Variables.Colors.white};
    margin-top: 12px;
`;

export const Secondary = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    line-height: 18px;
    position: relative;
`;

export const Description = styled.div`
    position: absolute;
    bottom: 0;
    color: ${Variables.Colors.nx24};
    font-size: 13px;
    font-weight: 500;
    min-height: 13px;
    line-height: 13px;
    letter-spacing: 0px;
    margin-bottom: -16px;
`;

export const DescriptionTooltip = styled.span`
    font-weight: 500;
    font-size: 13px;
    color: ${Variables.Colors.n70};
`;

export const DescriptionDates = styled.span`
    color: ${Variables.Colors.white};
`;

export const Delta = styled.span`
    display: flex;
    align-items: center;
    white-space: nowrap;
`;

export const getSentimentColor = (props: IDeltaProps): string => {
    switch (props.sentiment) {
        case 'good':
            return Variables.Colors.successful;
        case 'bad':
            return Variables.Colors.warning;
        case 'neutral':
        default:
            return Variables.Colors.successful;
    }
};

export const DeltaIcon = styled(Icon)<IDeltaProps>`
    fill: ${(props: IDeltaProps): string => getSentimentColor(props)};
    height: 16px;
    width: 16px;
`;

export const DeltaValue = styled.span<IDeltaProps>`
    color: ${(props: IDeltaProps): string => getSentimentColor(props)};
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 1px;
    margin-left: 4px;
`;

export const Link = styled.a`
    display: inline-block;
    color: ${Variables.Colors.p200};
    font-size: 16px;
    line-height: 18px;
    letter-spacing: 0.2px;
    text-align: right;
    cursor: pointer;
    white-space: nowrap;
`;
export const LinkIcon = styled(Icon)`
    vertical-align: middle;
    fill: ${Variables.Colors.p200};
    height: 18px;
    width: 18px;
`;

export const Message = styled.span`
    display: flex;
    flex-grow: 1;
    justify-content: center;
    align-items: center;
`;
