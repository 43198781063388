import { mdiInformationOutline } from '@mdi/js';
import { Icon } from '@mdi/react';
import _ from 'lodash';
import React, { useState } from 'react';
import { Overlay, Spinner } from '../../../base';
import * as S from './async-list.styles';

interface IAsyncListCellProps {
    id: string;
    loadMethod: (id: string) => Promise<React.ReactElement>;
}

/**
 * This type of cell renders list of <key, value> items by click on the button
 */
export const AsyncListCell: React.FunctionComponent<IAsyncListCellProps> = (
    props: IAsyncListCellProps,
): React.ReactElement => {
    const [element, setElement] = useState<React.ReactElement>();
    const [loading, setLoading] = useState(false);

    const fetchItems = async (): Promise<void> => {
        if (!_.isEmpty(element)) {
            return Promise.resolve();
        }

        setLoading(true);
        return props
            .loadMethod(props.id)
            .then(res => {
                setElement(res);
            })
            .finally(() => setLoading(false));
    };

    const renderButton = (onClick: (target: EventTarget) => void): React.ReactElement => {
        const handleClick = async (e: React.MouseEvent): Promise<void> => {
            const target = e.currentTarget;
            e.stopPropagation();
            await fetchItems();
            onClick(target);
        };

        return (
            <S.Button onClick={handleClick} disabled={loading} variant="text">
                <S.IconWrapper>
                    {loading ? <Spinner size="18px" /> : <Icon path={mdiInformationOutline} size={0.75} />}
                </S.IconWrapper>
                <S.Text title={props.id}>{props.id}</S.Text>
            </S.Button>
        );
    };

    const handlePreventPropagation = (e: React.MouseEvent) => {
        e.stopPropagation();
    };

    return (
        <S.Container onClick={handlePreventPropagation}>
            <Overlay
                renderButton={renderButton}
                anchorOrigin={{ vertical: 'center', horizontal: 'left' }}
                transformOrigin={{ vertical: 'center', horizontal: 'left' }}>
                {element}
            </Overlay>
        </S.Container>
    );
};
