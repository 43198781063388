import styled from '@emotion/styled/macro';
import { Variables } from '../../base';

interface ISvgProps {
    selected?: boolean;
}

export const Wrapper = styled.div`
    display: block;
    height: 100%;
    width: 20px;
    margin: 0 auto;
`;

export const ScaledSvg = styled.svg<ISvgProps>`
    position: relative;
    left: 0;
    transition: all 0.2s;
`;

export const FixedSvg = styled.svg<ISvgProps>`
    position: relative;
    left: -20px;
    transition: all 0.2s;
`;

export const BgLine = styled.line<ISvgProps>`
    stroke: ${Variables.Colors.white};
    stroke-width: 3px;
`;

export const FgLine = styled.line<ISvgProps>`
    stroke-width: 1.5px;
    stroke: ${(props: ISvgProps): string => (props.selected ? Variables.Colors.b300 : Variables.Colors.platinum)};
`;

export const NoLine = styled.line<ISvgProps>`
    opacity: 0;
`;

export const Dot = styled.circle`
    fill: ${(props: ISvgProps): string => (props.selected ? Variables.Colors.b300 : Variables.Colors.platinum)};
`;

export const OpenDot = styled.circle`
    fill: ${Variables.Colors.white};
    stroke: ${(props: ISvgProps): string => (props.selected ? Variables.Colors.b300 : Variables.Colors.platinum)};
`;
