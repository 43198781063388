import { Hooks } from 'react-table';

// TODO(dmitry): Remove when issue in react-table is fixed
// https://github.com/tannerlinsley/react-table/pull/1924
export function useFlexLayout<D extends object = {}>(hooks: Hooks<D>) {
    const getTableBodyProps = (props: any, { instance }: any) => [
        props,
        {
            style: {
                minWidth: `${instance.totalColumnsWidth}px`,
            },
        },
    ];

    const getRowStyles = (props: any, { instance }: any) => [
        props,
        {
            style: {
                display: 'flex',
                flex: '1 0 auto',
                minWidth: `${instance.totalColumnsMinWidth}px`,
                maxWidth: `${instance.totalColumnsMaxWidth}px`,
            },
        },
    ];

    const getHeaderProps = (props: any, { column }: any) => [
        props,
        {
            style: {
                boxSizing: 'border-box',
                flex: column.totalFlexWidth ? `${column.totalFlexWidth} 0 auto` : undefined,
                minWidth: `${column.totalMinWidth}px`,
                maxWidth: `${column.totalMaxWidth}px`,
                width: `${column.totalWidth}px`,
            },
        },
    ];

    const getCellProps = (props: any, { cell }: any) => [
        props,
        {
            style: {
                boxSizing: 'border-box',
                flex: `${cell.column.totalFlexWidth} 0 auto`,
                minWidth: `${cell.column.totalMinWidth}px`,
                maxWidth: `${cell.column.totalMaxWidth}px`,
                width: `${cell.column.totalWidth}px`,
            },
        },
    ];

    hooks.getTableBodyProps.push(getTableBodyProps);
    hooks.getRowProps.push(getRowStyles);
    hooks.getHeaderGroupProps.push(getRowStyles);
    hooks.getHeaderProps.push(getHeaderProps);
    hooks.getCellProps.push(getCellProps);
}

useFlexLayout.pluginName = 'useFlexLayout';
