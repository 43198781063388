import styled from '@emotion/styled/macro';
import { Icon } from '@mdi/react';
import { Popper } from '@mui/material';
import { BaseImage, Tooltip, Variables } from '../base';

export const ActionMainItem = styled.div`
    display: flex;
    align-items: center;
    padding: 5px 8px;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

export const ActionLabel = styled.div`
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
`;

export const ActionSymbol = styled.span`
    display: inline-flex;
    height: 18px;
    width: 18px;
    align-items: center;
    margin-right: 4px;
    font-size: 18px;
    vertical-align: middle;
`;

export const ActionIcon = styled(Icon)`
    height: 18px;
    width: 18px;
    fill: ${Variables.Colors.b300_l62};
`;

export const ActionTooltip = styled(Tooltip)`
    display: flex;
    align-items: center;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    cursor: pointer;
    padding: 0;

    & ${ActionLabel} {
        flex-grow: 1;
        padding-right: 4px;
    }
    & ${ActionIcon} {
        flex-shrink: 0;
        fill: ${Variables.Colors.b300_l62};
    }
`;

export const ActionButton = styled.div`
    display: inline-flex;
    border-left: 1px solid ${Variables.Colors.b300_l90};

    & > ${ActionIcon} {
        margin: 4px;
        fill: ${Variables.Colors.b300_l62};
    }
`;

export const ActionMenuDivider = styled.div`
    height: 1px;
    border-bottom: 1px solid ${Variables.Colors.b300_l90};
`;

export const ActionMenuItem = styled.button`
    outline: none;
    user-select: none;
    background-color: unset;
    text-align: left;
    border: none;
    width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    text-overflow: ellipsis;

    padding: 0;
    font-size: 14px;

    color: ${Variables.Colors.b300_l62};
    background-color: ${Variables.Colors.b300_l96};

    & > ${ActionTooltip}, & > ${ActionLabel} {
        padding: 6px 4px;
    }

    &:hover {
        cursor: pointer;
        color: ${Variables.Colors.b300_l62};
        background-color: ${Variables.Colors.b300_l90};
    }

    &:disabled {
        color: ${Variables.Colors.n50};
        background-color: ${Variables.Colors.n15_48};
        cursor: default;
    }
`;

export const Badge = styled.div`
    display: inline-flex;
    align-items: center;

    padding: 0;
    font-size: 14px;
    letter-spacing: 0.2px;

    color: ${Variables.Colors.b300_l62};
    background-color: ${Variables.Colors.b300_l96};

    border: 1px solid ${Variables.Colors.b300_l90};
    border-radius: ${Variables.Rounded.small};
    overflow: hidden;
    justify-content: space-between;

    &:hover {
        cursor: pointer;
        color: ${Variables.Colors.b300_l76};
        border-color: ${Variables.Colors.b300_l76};

        ${ActionButton} {
            border-color: ${Variables.Colors.b300_l76};
        }
        ${ActionIcon} {
            fill: ${Variables.Colors.b300};
        }
    }
`;

export const WrappedPopper = styled(Popper)`
    position: absolute;
    z-index: 1301;
    ${({ anchorEl }) => {
        if (!anchorEl) {
            return;
        }
        const el = anchorEl as HTMLDivElement;
        const rect = el.getBoundingClientRect();

        const aw = rect.width;
        return `width: ${aw}px;`;
    }}
    margin-top: 1px;

    border: 1px solid ${Variables.Colors.b300_l90};
    border-radius: ${Variables.Rounded.small};
    box-shadow: ${Variables.Shadows.medium};
`;

export const ImageSymbol = styled(BaseImage)`
    height: 18px;
    width: 18px;
`;
