import _ from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import {
    ClientID,
    exchangeToken,
    IAuthFetchOptions,
    IFetchController,
    initCoreConfiguration,
    logoutAndRedirect,
    removeHashFromUrl,
    requestAccountAccess
} from './auth.service';
import { AuthStorage } from './auth.storage';
import { ErrorContext } from './error.provider';

export interface IAuthContextProps {
    clientId: ClientID | undefined;
    group: string | undefined;
    setGroup: (group: string, opts?: IAuthFetchOptions) => void;
    cancelGroup: (group: string) => void;
    logoutAndRedirect: (params?: Map<string, string>) => void;
    switchAccount: (accountId: string, href?: string) => void;
    getCurrentAccessToken: () => string | undefined;
    getCurrentRefreshToken: () => string | undefined;
}

export const AuthContext = React.createContext<IAuthContextProps>({
    group: undefined,
    clientId: undefined,
    logoutAndRedirect: () => undefined,
    switchAccount: () => undefined,
    setGroup: () => undefined,
    cancelGroup: () => undefined,
    getCurrentAccessToken: () => undefined,
    getCurrentRefreshToken: () => undefined
});

export const AuthProvider: React.FC<{ clientId: ClientID }> = ({ children, clientId }): React.ReactElement => {
    const errorContext = useContext(ErrorContext);
    const authStorage = new AuthStorage(clientId);
    const [fetchController] = useState<IFetchController>({
        group: '',
        opts: {},
        map: new Map<string, AbortController[]>(),
    });
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        setIsLoading(true);
        (async (): Promise<void> => {
            initCoreConfiguration(authStorage, fetchController, errorContext);
            await handleAuthRedirect();
            setIsLoading(false);
        })();
    }, []);

    /**
     * Handle redirect from the auth page, exchange and save tokens
     */
    const handleAuthRedirect = async (): Promise<void> => {
        const codeMatch = window.location.hash.match('#code=(.*)');
        if (codeMatch) {
            removeHashFromUrl();
            const code = codeMatch[1];
            await exchangeToken(code, authStorage);
        }
    };

    const handleLogoutAndRedirect = (params?: Map<string, string>): void => {
        logoutAndRedirect(authStorage, params);
    };

    const handleSwitchAccount = async (accountId: string, href: string = '/'): Promise<void> => {
        await requestAccountAccess(accountId, authStorage);
        authStorage.accountId = accountId;
        window.location.href = href;
    };

    const handleSetGroup = (grp: string, opts: IAuthFetchOptions): void => {
        fetchController.group = grp;
        fetchController.opts = opts;
    };

    const handleCancelGroup = (group: string): void => {
        const { map } = fetchController;
        const controllers = map.get(group);
        if (controllers) {
            _.forEach(controllers, controller => controller.abort());
        }
    };

    const getCurrentAccessToken = (): string | undefined => {
        return authStorage.accessToken;
    }

    const getCurrentRefreshToken = (): string | undefined => {
        return authStorage.refreshToken;
    }

    return (
        <AuthContext.Provider
            value={{
                clientId,
                group: fetchController.group,
                logoutAndRedirect: handleLogoutAndRedirect,
                switchAccount: handleSwitchAccount,
                setGroup: handleSetGroup,
                cancelGroup: handleCancelGroup,
                getCurrentAccessToken,
                getCurrentRefreshToken
            }}>
            {!isLoading && children}
        </AuthContext.Provider>
    );
};
