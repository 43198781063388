import { mdiCheck } from '@mdi/js';
import { Icon } from '@mdi/react';
import { Tooltip } from '@mui/material';
import React from 'react';
import { TooltipTransitionComponent } from '../base';
import * as S from './done-button.styles';

interface IDoneButtonProps {
    count?: number;
    disabled?: boolean;
    onClick: () => void;
}
export const DoneButton: React.FunctionComponent<IDoneButtonProps> = (props: IDoneButtonProps): React.ReactElement => {
    const { count, disabled, onClick } = props;
    const message = count ? `Move ${count} messages to Done` : 'Move to Done';
    return (
        <Tooltip title={<span>{message}</span>} TransitionComponent={TooltipTransitionComponent}>
            <div>
                <S.DoneButton variant="outlined" onClick={onClick} disabled={disabled}>
                    <Icon path={mdiCheck} size={1} />
                </S.DoneButton>
            </div>
        </Tooltip>
    );
};
