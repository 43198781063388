import styled from '@emotion/styled/macro';
import { Variables } from '../base';

export const Result = styled.div<{ selected?: boolean }>`
    flex: 0 1 auto;
    border-bottom: ${Variables.Borders.regular};

    background-color: ${({ selected }) => (selected ? Variables.Colors.n15_24 : Variables.Colors.white)};
`;

export const Payer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 8px 8px;
`;
export const PayerTop = styled.div`
    display: flex;
    flex-direction: row;
    font-size: 14px;
    color: ${Variables.Colors.n50};

    b {
        font-weight: 500;
        margin-right: 4px;
    }
    span {
        margin-right: 24px;
    }
`;
export const PayerBottom = styled.div`
    display: flex;
    flex-direction: row;
    margin-top: 4px;
    align-items: center;
`;
export const PayerName = styled.div<{ inactive?: boolean }>`
    font-size: 18px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    color: ${({ inactive }) => (inactive ? Variables.Colors.n50 : Variables.Colors.n100)};
`;
export const PayerType = styled.div`
    font-size: 14px;
    padding: 2px 4px;
    color: ${Variables.Colors.n50};
`;
export const Spacer = styled.span`
    flex-grow: 1;
    min-width: 12px;
`;
