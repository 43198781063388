import styled from '@emotion/styled/macro';
import { Variables } from '../base';

export const MissingId = styled.div`
    display: inline-flex;
    align-items: center;

    padding: 0;
    background-color: ${Variables.Colors.n15_48};
    border: 1px solid ${Variables.Colors.n15};
    border-radius: 3px;
    overflow: hidden;

    font-size: 14px;
    letter-spacing: 0.2px;
`;

export const Message = styled.div`
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    padding: 6px;
    color: ${Variables.Colors.n70};
`;
