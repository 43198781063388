import styled from '@emotion/styled/macro';
import { Icon } from '@mdi/react';
import { ClaimIdBadge } from '../../badge';
import { BaseButton, Toggle, Variables } from '../../base';
import { ComposeInput, IComposeInputProps } from './compose-input';
import { ComposeTextarea, IComposeTextareaProps } from './compose-textarea';

export const Header = styled.div`
    display: flex;
    align-items: center;
    height: 80px;
    box-shadow: ${Variables.Shadows.border};

    padding: 20px 20px 16px 32px;
`;

export const Title = styled.div`
    display: inline-flex;
    flex-grow: 1;
    font-size: 26px;
    font-weight: 600;
    line-height: 1.19;
    letter-spacing: 0.2px;
    color: ${Variables.Colors.n100};
`;

export const CloseButton = styled(BaseButton)`
    border: 0;
    min-width: 0;
    padding: 0 8px;
`;

export const CloseIcon = styled(Icon)`
    fill: ${Variables.Colors.n30};
`;

export const Body = styled.div`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    padding: 32px;
    overflow-y: auto;

    & > * {
        margin: 8px 0;
    }
`;

export const StyledClaimIdBadge = styled(ClaimIdBadge)`
    margin-bottom: 16px;
    align-self: start;
`;

export const StyledComposeInput = styled(ComposeInput)<IComposeInputProps>`
    margin-top: 16px;
`;

export const StyledComposeTextarea = styled(ComposeTextarea)<IComposeTextareaProps>`
    margin-top: 16px;
`;

export const NoteContainer = styled.div`
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    margin-top: 8px;
`;

export const Note = styled.div`
    margin-right: 16px;
    font-size: 16px;
    color: ${Variables.Colors.b200};
`;

export const Comment = styled.div`
    margin-right: 16px;
    font-size: 16px;
    color: ${Variables.Colors.nx48};
`;

export const NoteToggle = styled(Toggle)`
    --toggle-bgcolor-on: ${Variables.Colors.b200};
`;
