import { ISelectOption } from '../../../base';

export enum FilterMethod {
    GTE = 'GTE', // greater than or equal to
    GT = 'GT', // greater than
    LT = 'LT', // less than
    LTE = 'LTE', // less than or equal to
    EQ = 'EQ', // equal
    NQ = 'NQ', // not equal
    BT = 'BT', // between
    LK = 'LK', // like
    NLK = 'NLK', // Not like

    EMPTY = 'EMPTY', // Not empty
    NEMPTY = 'NEMPTY', // Not empty

    // FullText search
    OW = 'OW', // One of the words. 'apple banana'
    CT = 'CT', // Contains. '+apple'
    NCT = 'NCT', // Not contains. '-macintosh'
    EX = 'EX', // Exact. '"apple macintosh"'
    FT = 'FT', // Unformatted fulltext. '+apple +(>turnover <strudel)'
}

export type FilterType =
    | 'String'
    | 'FullText'
    | 'Phone'
    | 'Boolean'
    | 'Date'
    | 'DateTime'
    | 'DateRange'
    | 'Number'
    | 'Select'
    | 'AsyncSelect';

/**
 * Filter model
 */

interface ICustomFilterBase {
    filterId?: string;
    filterMethod?: FilterMethod;
    filterType: FilterType;
    // If filter locked, it is not possible to remove it, but still possible to change the value
    locked?: boolean;
    label?: string;
}

export interface ICustomFilterString extends ICustomFilterBase {
    filterType: 'String';
    value: string | undefined;
}

export interface ICustomFilterFullText extends ICustomFilterBase {
    filterType: 'FullText';
    value: string | undefined;
}

export interface ICustomFilterPhone extends ICustomFilterBase {
    filterType: 'Phone';
    value: string | undefined;
}

export interface ICustomFilterNumber extends ICustomFilterBase {
    filterType: 'Number';
    value: string | undefined;
}

export interface ICustomFilterSelect extends ICustomFilterBase {
    filterType: 'Select';
    value: ISelectOption | undefined;
}

export interface ICustomFilterBoolean extends ICustomFilterBase {
    filterType: 'Boolean';
    value: ISelectOption | undefined;
}

export interface ICustomFilterAsyncSelect extends ICustomFilterBase {
    filterType: 'AsyncSelect';
    value: ISelectOption | undefined;
}

export interface ICustomFilterDate extends ICustomFilterBase {
    filterType: 'Date';
    value: Date | undefined;
}

export interface ICustomFilterDateTime extends ICustomFilterBase {
    filterType: 'DateTime';
    value: Date | undefined;
}

export interface ICustomFilterDateRange extends ICustomFilterBase {
    filterType: 'DateRange';
    value: [Date, Date] | undefined; // [from, to]
}

export type ICustomFilter =
    | ICustomFilterString
    | ICustomFilterFullText
    | ICustomFilterPhone
    | ICustomFilterNumber
    | ICustomFilterBoolean
    | ICustomFilterAsyncSelect
    | ICustomFilterSelect
    | ICustomFilterDate
    | ICustomFilterDateTime
    | ICustomFilterDateRange;

export type CustomFilterFieldName = keyof ICustomFilter;

/**
 * Filter schema, that used for specify and build filter components
 */

interface IFilterSchemaBase<TFilterId> {
    label: string;
    placeholder?: string;
    filterId: TFilterId;
    filterType: FilterType;
    columnId: string;
}

interface IFilterSchemaDate<TFilterId> extends IFilterSchemaBase<TFilterId> {
    filterType: 'Date';
}

interface IFilterSchemaDateTime<TFilterId> extends IFilterSchemaBase<TFilterId> {
    filterType: 'DateTime';
}

interface IFilterSchemaDateRange<TFilterId> extends IFilterSchemaBase<TFilterId> {
    filterType: 'DateRange';
}

interface IFilterSchemaNumber<TFilterId> extends IFilterSchemaBase<TFilterId> {
    filterType: 'Number';
}

interface IFilterSchemaPhone<TFilterId> extends IFilterSchemaBase<TFilterId> {
    filterType: 'Phone';
}

interface IFilterSchemaString<TFilterId> extends IFilterSchemaBase<TFilterId> {
    filterType: 'String';
}

interface IFilterSchemaFullText<TFilterId> extends IFilterSchemaBase<TFilterId> {
    filterType: 'FullText';
}

interface IFilterSchemaBoolean<TFilterId> extends IFilterSchemaBase<TFilterId> {
    filterType: 'Boolean';
}

interface IFilterSchemaSelect<TFilterId> extends IFilterSchemaBase<TFilterId> {
    filterType: 'Select';
    options: ISelectOption[];
}

interface IFilterSchemaAsyncSelect<TFilterId> extends IFilterSchemaBase<TFilterId> {
    filterType: 'AsyncSelect';
    loadOptions: (req: string) => Promise<ISelectOption[]>;
}

export type IFilterSchema<TFilterId = string> =
    | IFilterSchemaDate<TFilterId>
    | IFilterSchemaDateTime<TFilterId>
    | IFilterSchemaDateRange<TFilterId>
    | IFilterSchemaNumber<TFilterId>
    | IFilterSchemaPhone<TFilterId>
    | IFilterSchemaString<TFilterId>
    | IFilterSchemaFullText<TFilterId>
    | IFilterSchemaBoolean<TFilterId>
    | IFilterSchemaSelect<TFilterId>
    | IFilterSchemaAsyncSelect<TFilterId>;
