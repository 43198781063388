import {
    mdiArrowRightBoldCircle,
    mdiBank,
    mdiCancel,
    mdiCheckCircle,
    mdiClockOutline,
    mdiFlag,
    mdiGavel,
    mdiSend,
} from '@mdi/js';
import _ from 'lodash';
import React from 'react';
import * as S from './transmission-status-badge.styles';
import { TransmissionStatusType } from './util';

export type BadgeSize = 'small' | 'large';

export interface ITransmissionStatusBadgeProps {
    className?: string;
    size: BadgeSize;
    status?: keyof typeof TransmissionStatusType;
}

export class TransmissionStatusBadge extends React.Component<ITransmissionStatusBadgeProps> {
    public render(): React.ReactElement {
        const { size, status, className } = this.props;
        const label = status ? _.startCase(TransmissionStatusType[status].toLowerCase()) : 'Preparing';

        return (
            <S.Badge size={size} claimStatus={status} className={className}>
                <S.BadgeLabel>{label}</S.BadgeLabel> {size === 'large' && this.renderBadgeIcon()}
            </S.Badge>
        );
    }
    private readonly renderBadgeIcon = (): React.ReactElement | string => {
        if (this.props.status) {
            switch (this.props.status) {
                case TransmissionStatusType.SUBMITTED:
                    return <S.BadgeIcon path={mdiSend} />;
                case TransmissionStatusType.ACKNOWLEDGED:
                    return <S.BadgeIcon path={mdiBank} />;
                case TransmissionStatusType.ADJUDICATED:
                    return <S.BadgeIcon path={mdiGavel} />;
                case TransmissionStatusType.COMPLETED:
                    return <S.BadgeIcon path={mdiCheckCircle} />;
                case TransmissionStatusType.REJECTED:
                    return <S.BadgeIcon path={mdiFlag} />;
                case TransmissionStatusType.DENIED:
                    return <S.BadgeIcon path={mdiCancel} />;
                case TransmissionStatusType.FORWARDED:
                    return <S.BadgeIcon path={mdiArrowRightBoldCircle} />;
                default:
                    return <S.BadgeIcon path={mdiClockOutline} />;
            }
        }

        return <S.BadgeIcon path={mdiClockOutline} />;
    };
}
