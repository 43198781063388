import { mdiClose } from '@mdi/js';
import { Formik, FormikProps } from 'formik';
import _ from 'lodash';
import React, { useContext, useState } from 'react';
import * as Yup from 'yup';
import { ComposeControls, ComposeError, ComposeSearch } from '.';
import { MessagingContext } from '../../../services/';
import { SidePanel } from '../side-panel';
import * as S from './compose-panel.styles';

const schema = Yup.object().shape({
    claimId: Yup.string(),
    subject: Yup.string().min(0).max(80).required('Subject is required.'),
    body: Yup.string().required('Message body is required'),
    attachments: Yup.array(),
});

interface IDraft {
    claimId: string;
    subject: string;
    body: string;
    note: boolean;
    attachments: File[];
}

export interface IComposePanelProps {
    className?: string;
    claimId?: string;
    // Make it not possible to change the claim. ClaimId is required if forceClaim === true
    forceClaim?: boolean;
    hideNote?: boolean;
    subject?: string;
    body?: string;
}

export const ComposePanel: React.FunctionComponent<IComposePanelProps> = (
    props: IComposePanelProps,
): React.ReactElement => {
    const { className, forceClaim, hideNote } = props;

    const context = useContext(MessagingContext);
    const [locked, setLocked] = useState(false);

    const initial: IDraft = {
        claimId: props.claimId || '',
        subject: props.subject || '',
        body: props.body || '',
        note: !hideNote,
        attachments: [],
    };

    const claimSelected = (formik: FormikProps<IDraft>): ((id: string) => void) => {
        return (id: string): void => {
            formik.setFieldValue('claimId', id);
        };
    };

    const handleRemoveClaim = (formik: FormikProps<IDraft>): (() => void) => {
        return (): void => {
            formik.setFieldValue('claimId', '');
        };
    };

    const handleInputChange = (formik: FormikProps<IDraft>): ((subject: string) => void) => {
        return (subject: string): void => {
            formik.setFieldValue('subject', subject);
        };
    };

    const handleTextareaChange = (formik: FormikProps<IDraft>): ((body: string, attachments: File[]) => void) => {
        return (body: string, attachments: File[]): void => {
            formik.setFieldValue('body', body);
            formik.setFieldValue('attachments', attachments);
        };
    };

    const onChangeNote = (formik: FormikProps<IDraft>): ((value: boolean) => void) => {
        return (value: boolean): void => {
            formik.setFieldValue('note', value);
        };
    };

    const handleTextareaSave = (formik: FormikProps<IDraft>): ((body: string, attachments: File[]) => void) => {
        return (body: string, attachments: File[]): void => {
            formik.setFieldValue('body', body);
            formik.setFieldValue('attachments', attachments);
            handleSave(formik)();
        };
    };

    const handleClose = async (): Promise<void> => {
        await (context.onMessageClose && context.onMessageClose());
    };
    const handleSave = (formik: FormikProps<IDraft>): (() => Promise<void>) => {
        // NB: avoid non-async formik.submitForm for now
        return async (): Promise<void> => {
            const errors = await formik.validateForm();
            if (_.isEmpty(errors)) {
                setLocked(true);
                await handleSubmit(formik.values);
            }
        };
    };
    const handleSubmit = async (values: IDraft): Promise<void> => {
        const { subject, body, note, claimId, attachments } = values;
        await (context.onComposeMessage &&
            context.onComposeMessage({
                subject,
                body,
                note,
                claimId,
                files: attachments,
            }));
        await (context.onMessageClose && context.onMessageClose());
    };

    return (
        <Formik
            enableReinitialize={true}
            initialValues={initial}
            validationSchema={schema}
            validateOnChange={false}
            onSubmit={handleSubmit}>
            {(formik: FormikProps<IDraft>): React.ReactElement => (
                <SidePanel className={className}>
                    <S.Header>
                        <S.Title>Start new message</S.Title>
                        <S.CloseButton variant="fab-light" onClick={handleClose}>
                            <S.CloseIcon path={mdiClose} size={1} />
                        </S.CloseButton>
                    </S.Header>
                    <S.Body>
                        {!formik.values.claimId && (
                            <ComposeSearch onClaimSelected={claimSelected(formik)} disabled={locked} />
                        )}
                        {formik.values.claimId && (
                            <S.StyledClaimIdBadge
                                claimId={formik.values.claimId}
                                forceClaim={forceClaim}
                                onRemove={handleRemoveClaim(formik)}
                            />
                        )}
                        <S.StyledComposeInput
                            isNote={formik.values.note}
                            disabled={locked}
                            subject={formik.values.subject}
                            onChange={handleInputChange(formik)}
                        />
                        {formik.errors && formik.errors.subject && <ComposeError>{formik.errors.subject}</ComposeError>}
                        <S.StyledComposeTextarea
                            isNote={formik.values.note}
                            disabled={locked}
                            body={formik.values.body}
                            onChange={handleTextareaChange(formik)}
                            onSave={handleTextareaSave(formik)}
                        />
                        {!hideNote && (
                            <S.NoteContainer>
                                <S.NoteToggle
                                    disabled={locked}
                                    value={formik.values.note}
                                    onChange={onChangeNote(formik)}
                                />
                                {formik.values.note ? (
                                    <S.Note>Sending as internal note</S.Note>
                                ) : (
                                    <S.Comment>Sending as provider message</S.Comment>
                                )}
                            </S.NoteContainer>
                        )}
                        {formik.errors && formik.errors.body && <ComposeError>{formik.errors.body}</ComposeError>}
                        <ComposeControls locked={locked} onClose={handleClose} onSave={handleSave(formik)} />
                    </S.Body>
                </SidePanel>
            )}
        </Formik>
    );
};
