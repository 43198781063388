import { css } from '@emotion/react/macro';
import styled from '@emotion/styled/macro';
import Icon from '@mdi/react';
import { transparentize } from 'polished';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import AsyncCreatableSelect from 'react-select/async-creatable';
import CreatableSelect from 'react-select/creatable';
import { forPhoneOnly, Variables } from '../variables';

export const DropdownIndicator = styled.div`
    padding: 0 8px;
    display: flex;
    path {
        fill: ${Variables.Colors.n70};
    }

    ${forPhoneOnly(`
        padding: 0 4px 0 0;
    `)};
`;

const successState = css`
    border-color: ${Variables.Colors.successful};
    &:not(:disabled) {
        &:hover,
        &:focus,
        &:active {
            border-color: ${Variables.Colors.successful};
        }
    }
`;

const errorState = css`
    border-color: ${Variables.Colors.warning};
    &:not(:disabled) {
        &:hover {
            border-color: ${Variables.Colors.warning};
        }

        &:focus,
        &:active {
            border-color: ${Variables.Colors.warning};
        }
    }
`;

export const SelectContainer = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    white-space: pre-wrap;
`;

interface IBaseSelectProps {
    showSuccess: boolean;
    showError: boolean;
}

export const BaseSelect = (props: IBaseSelectProps) => css`
    color: ${Variables.Colors.n70};

    .select {
        &__multi-value {
            background-color: unset;
            &__label {
                font-size: 14px;
                padding: 0;
            }

            &__remove {
                margin-left: 3px;
            }
        }

        &__control {
            border-radius: ${Variables.Rounded.small};
            border: ${Variables.Borders.regular};
            background-color: ${Variables.Colors.white};
            min-height: 40px;
            color: ${Variables.Colors.n100};
            cursor: pointer;

            &--is-focused {
                border-color: ${Variables.Colors.b500};
                box-shadow: 0 0 0 3px ${transparentize(0.82, Variables.Colors.b500)};
            }

            &--is-disabled {
                cursor: default;
                border-color: ${Variables.Colors.nx8};
                background-color: ${Variables.Colors.nx4};
                color: ${Variables.Colors.n15};
                & ${DropdownIndicator} {
                    path {
                        fill: ${Variables.Colors.n15};
                    }
                }
                .select__value-container {
                    &:not(.select__value-container--has-value) {
                        visibility: hidden;
                    }
                }
            }

            &:hover {
                border-color: ${Variables.Colors.b500};
            }
        }

        &__value-container {
            font-size: 100%;
            margin: 4px;
            padding: 0;
            overflow-y: auto;
        }

        &__single-value {
            top: 0;
            bottom: 0;
            margin: auto 2px;
            transform: none;
            display: flex;
            align-items: center;
        }

        &__placeholder {
            white-space: nowrap;
            color: ${Variables.Colors.n15};
            font-size: 16px;
        }

        &__menu {
            overflow: hidden;
            &-list {
                padding: 0;
            }
        }

        &__option {
            cursor: pointer;
            padding: 8px;
            &--is-focused {
                background-color: ${Variables.Colors.n15_24};
            }

            &--is-selected {
                color: ${Variables.Colors.b300};
                font-weight: 500;
                background-color: inherit;
                &:hover {
                    background-color: ${Variables.Colors.n15_24};
                }
            }

            &:active {
                background-color: ${Variables.Colors.n15_24};
            }
        }
    }

    ${forPhoneOnly(`
        .select__value-container {
            margin: 0 0 0 4px;
        }
    `)};
`;

export const StyledSelect = styled(Select)`
    ${BaseSelect}
`;

export const StyledCreatableSelect = styled(CreatableSelect)`
    ${BaseSelect}
`;

export const StyledAsyncCreatableSelect = styled(AsyncCreatableSelect)`
    ${BaseSelect}
`;

export const StyledAsyncSelect = styled(AsyncSelect)`
    ${BaseSelect}
`;

export const SelectBottom = styled.div`
    font-size: 13px;
    font-weight: 500;
    display: flex;
    align-items: center;
    margin-top: 8px;
`;

export const SelectBottomSuccess = styled(SelectBottom)`
    color: ${Variables.Colors.successful};
    fill: ${Variables.Colors.successful};
`;

export const SelectBottomError = styled(SelectBottom)`
    color: ${Variables.Colors.warning};
    fill: ${Variables.Colors.warning};
`;

export const SelectBottomIcon = styled(Icon)`
    width: 18px;
    height: 18px;
    vertical-align: middle;
`;

export const SelectBottomMessage = styled.div`
    display: inline-block;
    margin-left: 4px;
`;

export const MenuWrapper = styled.div<IBaseSelectProps>`
    ${BaseSelect}
`;
