import styled from '@emotion/styled/macro';
import { forPhoneOnly, forTabletLandscapeDown } from '@enter/core';
import { Grid } from '@mui/material';

export const SideImageGrid = styled(Grid)`
    min-height: 100vh;
    height: 100%;
    background-image: linear-gradient(to top, #fff3ed, #ffeadd);

    ${forTabletLandscapeDown(`
        min-height: 100vh;
        height: auto;
    `)}

    ${forPhoneOnly(`
        min-height: 100vh;
        height: auto;
        flex-direction: column;
    `)}
`;

export const SideImage = styled(Grid)``;

export const UpperLeftLogoHolder = styled.div`
    height: 68px;
    width: 100%;
    padding-left: 80px;
    padding-top: 26px;

    ${forPhoneOnly(`
        padding-top: 16px;
        padding-left: 16px;
        height: auto;
    `)}
`;

export const UpperLeftLogo = styled.img`
    width: 77px;
    height: 25px;
`;

export const HeaderLink = styled.a`
    display: inline-block;
    text-decoration: none;
`;

export const PaperCell = styled.div`
    display: flex;
    flex-direction: column;

    padding: 48px 48px 32px 48px;
    margin: 104px auto;

    border-radius: 3px;
    box-shadow: 0 8px 12px 0 rgba(15, 15, 15, 0.04), 0 0 0 1px rgba(15, 15, 15, 0.04);
    background-color: #ffffff;

    width: 460px;

    ${forPhoneOnly(`
        margin: 16px 16px 24px 16px;
        padding: 32px;
        width: auto;
    `)}
`;
