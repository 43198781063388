import dateFns from 'date-fns';
import * as React from 'react';
import DayPicker, {
    DayPickerInputProps,
    DayPickerProps,
    Modifier,
    Modifiers,
    NavbarElementProps,
} from 'react-day-picker';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import OutsideClickHandler from 'react-outside-click-handler';
import { IDatePickerComponentProps } from './datepicker';
import { DatePickerNavbar } from './datepicker-navbar';
import * as S from './datepicker.styles';

interface IDatePickerRangeProps {
    /**
     * If renderComponent is passed, then we render popover with datepicker
     * If not, then we just render datepicker
     */
    renderComponent?: (props: IDatePickerComponentProps) => React.ReactElement;
    onDateRangeSelected?: (from: Date, to: Date) => void;
    format?: string;
    from?: Date;
    to?: Date;
}

interface IDatePickerRangeState {
    from: Date | undefined;
    to: Date | undefined;
    enteredTo: Date | undefined;
}

const defaultProps = {
    format: 'ddd, MMM DD, YYYY',
};

export class DatePickerRange extends React.Component<IDatePickerRangeProps, IDatePickerRangeState> {
    private dayPickerInputRef: React.RefObject<DayPickerInput>;

    constructor(props: IDatePickerRangeProps) {
        super(props);
        this.state = this.getInitialState(props.from, props.to);
        this.dayPickerInputRef = React.createRef<DayPickerInput>();
    }

    public render(): React.ReactElement {
        const { from, enteredTo } = this.state;
        const { format, renderComponent } = this.props;

        const modifiers: Partial<Modifiers> = { start: from, end: enteredTo };
        const disabledDays: Modifier | Modifier[] = from && {
            before: from,
            // Forbid to select more than 1 year range
            after: dateFns.addYears(from, 1),
        };

        const selectedDays: Modifier | Modifier[] = from && [from, enteredTo && { from, to: enteredTo }];

        const dayPickerProps: DayPickerProps = {
            initialMonth: from,
            selectedDays,
            disabledDays,
            modifiers,
            onDayClick: this.handleDayClick,
            onDayMouseEnter: this.handleDayMouseEnter,
            showOutsideDays: true,
            navbarElement: this.renderNavbarElement,
        };

        const dayPickerInputProps: DayPickerInputProps & {
            ref: React.RefObject<DayPickerInput>;
        } = {
            ref: this.dayPickerInputRef,
            format: format || defaultProps.format,
            formatDate: this.formatDate,
            hideOnDayClick: false,
            component: this.props.renderComponent,
            inputProps: {
                readOnly: true,
            },
            dayPickerProps,
        };

        return (
            <S.DatePickerWrapper>
                <OutsideClickHandler onOutsideClick={this.hideDayPicker}>
                    {renderComponent ? <DayPickerInput {...dayPickerInputProps} /> : <DayPicker {...dayPickerProps} />}
                </OutsideClickHandler>
            </S.DatePickerWrapper>
        );
    }

    private renderNavbarElement = (props: NavbarElementProps): React.ReactElement => {
        const from = this.state.from ? dateFns.format(this.state.from, 'ddd, MMM DD, YYYY') : '';
        const to = this.state.to ? dateFns.format(this.state.to, 'ddd, MMM DD, YYYY') : '';
        const month = dateFns.format(props.month, 'MMMM YYYY');

        return (
            <DatePickerNavbar
                from={from}
                to={to}
                isShowRange={true}
                month={month}
                showPreviousButton={props.showPreviousButton}
                onPreviousClick={props.onPreviousClick}
                showNextButton={props.showNextButton}
                onNextClick={props.onNextClick}
            />
        );
    };

    private isSelectingFirstDay = (from: Date | undefined, to: Date | undefined, day: Date): boolean => {
        const isBeforeFirstDay: boolean | undefined = from && DayPicker.DateUtils.isDayBefore(day, from);
        const isRangeSelected: boolean = !!from && !!to;

        return !from || isBeforeFirstDay || isRangeSelected;
    };

    private isSelectingOutOfRange = (from: Date | undefined, to: Date | undefined, day: Date): boolean => {
        if (!from) {
            return false;
        }

        const maxDate = dateFns.addYears(from, 1).getTime();

        return day.getTime() > maxDate;
    };

    private handleDayClick = (day: Date): void => {
        const { from, to } = this.state;
        if (from && to && day >= from && day <= to) {
            this.resetState();
            return;
        }

        if (this.isSelectingFirstDay(from, to, day)) {
            this.setState({
                from: day,
                to: undefined,
                enteredTo: undefined,
            });
            return;
        }

        if (this.isSelectingOutOfRange(from, to, day)) {
            return;
        }

        this.hideDayPicker();

        this.setState({
            to: day,
            enteredTo: day,
        });

        if (this.state.from && this.props.onDateRangeSelected) {
            this.props.onDateRangeSelected(this.state.from, day);
        }
    };

    private handleDayMouseEnter = (day: Date): void => {
        const { from, to } = this.state;
        if (!this.isSelectingFirstDay(from, to, day)) {
            this.setState({
                enteredTo: day,
            });
        }
    };

    private hideDayPicker = (): void => {
        this.dayPickerInputRef.current?.hideDayPicker();
    };

    private formatDate = (date: Date, format: string): string => {
        if (!this.state.from) {
            return '';
        }

        const formattedFrom = dateFns.format(this.state.from, format);
        const formattedTo = dateFns.format(date, format);
        return `${formattedFrom} - ${formattedTo}`;
    };

    private resetState = (): void => {
        this.setState(this.getInitialState());
    };

    private getInitialState = (from?: Date, to?: Date): IDatePickerRangeState => {
        return {
            from,
            to,
            enteredTo: to, // Keep track of the last day for mouseEnter.
        };
    };
}
