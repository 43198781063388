import styled from '@emotion/styled/macro';
import { Icon } from '@mdi/react';
import { Variables } from '../base';
import { MessagingAuthorType } from './util';

interface IAuthorIndicator {
    type?: keyof typeof MessagingAuthorType;
}

export const AuthorIndicator = styled(Icon)<IAuthorIndicator>`
    height: 8px;
    width: 8px;

    fill: ${(props): string => {
        switch (props.type) {
            case MessagingAuthorType.PATIENT:
                return Variables.Colors.r30;
            case MessagingAuthorType.SUPPORT:
                return Variables.Colors.b300;
            case MessagingAuthorType.PROVIDER:
                return Variables.Colors.subG30;
            default:
                throw new Error(`Unknown type: ${props.type}`);
        }
    }};

    stroke: ${(props): string => {
        switch (props.type) {
            case MessagingAuthorType.PATIENT:
                return Variables.Colors.r30;
            case MessagingAuthorType.SUPPORT:
                return Variables.Colors.b300;
            case MessagingAuthorType.PROVIDER:
                return Variables.Colors.subG30;
            default:
                throw new Error(`Unknown type: ${props.type}`);
        }
    }};
`;
