import { mdiCalendarRange } from '@mdi/js';
import * as d3 from 'd3';
import { format } from 'date-fns';
import * as React from 'react';
import { DatePickerRange, Popover, PopoverDivider, PopoverItem, PopoverSubmenu } from '../base';
import * as S from './date-range.styles';

export type RangeOptionType =
    | '7days'
    | '30days'
    | '60days'
    | '90days'
    | '180days'
    | 'thisMonth'
    | 'thisYear'
    | 'custom';

interface IRangeOption {
    type: RangeOptionType;
    label: string;
    range: [Date, Date];
}

export interface IDateRangeProps {
    className?: string;
    open?: boolean;
    onChange?: (dateRange: [Date, Date], optionType: RangeOptionType) => void;
    selectedOptionType?: RangeOptionType;
    startDate?: Date;
    endDate?: Date;
}

interface IDateRangeState {
    selectedOptionType?: RangeOptionType;
    open?: boolean;
    startDate: Date | undefined;
    endDate: Date | undefined;
}

export const getDateRangeForOption = (option: RangeOptionType): [Date, Date] => {
    const today = new Date();
    const lastNDays = (n: number): [Date, Date] => [d3.timeDay.offset(today, -n + 1), today];
    const thisMonth = (): [Date, Date] => [d3.timeMonth.floor(today), today];
    const thisYear = (): [Date, Date] => [d3.timeYear.floor(today), today];

    switch (option) {
        case '7days':
            return lastNDays(7);
        case '30days':
            return lastNDays(30);
        case '60days':
            return lastNDays(60);
        case '90days':
            return lastNDays(90);
        case '180days':
            return lastNDays(180);
        case 'thisMonth':
            return thisMonth();
        case 'thisYear':
            return thisYear();
        default:
            return thisYear();
    }
};

export class DateRange extends React.PureComponent<IDateRangeProps, IDateRangeState> {
    constructor(props: IDateRangeProps) {
        super(props);

        const { open, selectedOptionType, startDate, endDate } = this.props;
        this.state = {
            open,
            selectedOptionType,
            startDate,
            endDate,
        };
    }

    public render(): React.ReactElement {
        return (
            <Popover open={this.props.open} renderButton={this.renderDateButton}>
                {this.renderFixedDates()}
                <PopoverDivider />
                <PopoverSubmenu label="Custom">
                    <DatePickerRange
                        onDateRangeSelected={this.handleCustomRangeSelected}
                        from={this.state.startDate}
                        to={this.state.endDate}
                    />
                </PopoverSubmenu>
            </Popover>
        );
    }

    private getDefaultOptions(): IRangeOption[] {
        return [
            {
                type: '7days',
                label: 'Last 7 days',
                range: getDateRangeForOption('7days'),
            },
            {
                type: '30days',
                label: 'Last 30 days',
                range: getDateRangeForOption('30days'),
            },
            {
                type: '60days',
                label: 'Last 60 days',
                range: getDateRangeForOption('60days'),
            },
            {
                type: '90days',
                label: 'Last 90 days',
                range: getDateRangeForOption('90days'),
            },
            {
                type: '180days',
                label: 'Last 180 days',
                range: getDateRangeForOption('180days'),
            },
            {
                type: 'thisMonth',
                label: 'This month',
                range: getDateRangeForOption('thisMonth'),
            },
            {
                type: 'thisYear',
                label: 'This year',
                range: getDateRangeForOption('thisYear'),
            },
        ];
    }

    private readonly renderDateButton = (): React.ReactElement => {
        const rangeText = this.getRangeText(this.state.startDate, this.state.endDate);
        return (
            <S.DateRangeCurrent>
                {rangeText}
                <S.DateRangeIcon path={mdiCalendarRange} />
            </S.DateRangeCurrent>
        );
    };

    private readonly getRangeText = (startDate: Date | undefined, endDate: Date | undefined): string => {
        const fmt = (date: Date) => format(date, 'ddd, MMM DD, YYYY');
        if (startDate && endDate) {
            return `${fmt(startDate)} - ${fmt(endDate)}`;
        } else {
            const { selectedOptionType } = this.state;
            if (selectedOptionType) {
                const range = getDateRangeForOption(selectedOptionType);
                return `${fmt(range[0])} - ${fmt(range[1])}`;
            }
        }
        return '';
    };

    private readonly renderFixedDates = (): React.ReactElement[] => {
        const defaultOptions = this.getDefaultOptions();

        return defaultOptions.map((rangeOption: IRangeOption) => {
            return (
                <PopoverItem
                    key={rangeOption.label}
                    selected={this.state.selectedOptionType === rangeOption.type}
                    onClick={this.handleRangeSelected(rangeOption.range, rangeOption.type)}>
                    {rangeOption.label}
                </PopoverItem>
            );
        });
    };

    private readonly handleCustomRangeSelected = (from: Date, to: Date): void => {
        return this.handleRangeSelected([from, to], 'custom')();
    };

    private readonly handleRangeSelected = (range: [Date, Date], type: RangeOptionType): (() => void) => {
        const [startDate, endDate] = range;
        return (): void => {
            this.setState({
                selectedOptionType: type,
                startDate,
                endDate,
            });

            this.props.onChange?.([startDate, endDate], type);
        };
    };
}
