import { Popover, PopoverOrigin } from '@mui/material';
import React from 'react';

export interface IOverlayProps {
    renderButton: (onOpen: (target: EventTarget | Element) => void, open: boolean) => React.ReactElement;
    anchorOrigin?: PopoverOrigin;
    transformOrigin?: PopoverOrigin;
}
/**
 * Simple overlay component to serve rendering any children inside with next features:
 * - prevent showing component outside the screen
 * - block body scrolling, when component is open
 * - component doesn't know anything about children passed
 */
export const Overlay: React.FunctionComponent<IOverlayProps> = (props): React.ReactElement => {
    const { renderButton, anchorOrigin, transformOrigin } = props;
    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

    function handlePopoverOpen(target: EventTarget): void {
        setAnchorEl(target as EventTarget & HTMLElement);
    }

    function handlePopoverClose(): void {
        setAnchorEl(null);
    }

    const open = Boolean(anchorEl);

    return (
        <>
            {renderButton(handlePopoverOpen, open)}
            <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={handlePopoverClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                    ...anchorOrigin,
                }}
                disableEnforceFocus={true}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                    ...transformOrigin,
                }}>
                {props.children}
            </Popover>
        </>
    );
};
