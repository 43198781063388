import React from 'react';
import {
    MeInfoResponse,
    ProviderClaim,
    ProviderComment,
    ProviderMessage,
    SupportClaim,
    SupportComment,
    SupportMessage,
} from '../../api';

async function noop(d?: any): Promise<void> {
    return undefined;
}

export interface IComment {
    messageId: string;
    body: string;
    note: boolean;
    files?: File[];
}

export interface IMessage {
    subject: string;
    body: string;
    note: boolean;
    claimId?: string;
    files?: File[];
}

export interface IDocument {
    id?: string;
    filename?: string;
    description?: string;
    width?: number;
    height?: number;
    filesize?: number;
    notes?: string;
    mimetype?: string;
    thumbnail?: string;
    claimId?: string;
    active?: boolean;
}

export interface IMessagingContextProps {
    isReadOnly?: boolean;
    isSubjectEditable?: boolean;
    isListLoading?: boolean;
    isDetailLoading?: boolean;
    enableMessageTypeValidation: boolean;

    account: MeInfoResponse | undefined;
    message: ProviderMessage | SupportMessage | undefined;
    comments: (ProviderComment | SupportComment)[] | undefined;
    documents: IDocument[] | undefined;
    claims: (ProviderClaim | SupportClaim)[] | undefined;

    onClaimSearch: (term: string) => Promise<(ProviderClaim | SupportClaim)[] | void>;
    onCommentToggle: (mid: string, cid: string, note: boolean) => Promise<void>;
    onComposeComment: (comment: IComment) => Promise<void>;
    onComposeMessage: (message: IMessage) => Promise<void>;
    onDocumentDownload: (documentId: string) => Promise<void>;
    onDocumentAssign: (document: IDocument) => Promise<void>;
    onMarkAsClosed: (ids: string | string[]) => Promise<void>;
    onMarkAsOpen: (ids: string | string[]) => Promise<void>;
    onMarkAsRead: (ids: string | string[]) => Promise<void>;
    onMessageClose: () => Promise<void>;
    onMessageOpen: (id: string) => Promise<void>;
    onSubjectUpdate: (id: string, subject: string) => Promise<void>;
    onClaimUpdate: (id: string, claimId: string | null) => Promise<void>;
}

export interface IMessageProviderProps {
    children: React.ReactElement | React.ReactElement[] | Element[];

    isReadOnly?: boolean;
    isSubjectEditable?: boolean;
    isListLoading?: boolean;
    isDetailLoading?: boolean;
    enableMessageTypeValidation: boolean;

    account: MeInfoResponse | undefined;
    message: ProviderMessage | SupportMessage | undefined;
    comments: (ProviderComment | SupportComment)[] | undefined;
    documents: IDocument[] | undefined;
    claims: (ProviderClaim | SupportClaim)[] | undefined;

    onClaimSearch: (term: string) => Promise<(ProviderClaim | SupportClaim)[] | void>;
    onCommentToggle?: (mid: string, cid: string, note: boolean) => Promise<void>;
    onComposeComment?: (comment: IComment) => Promise<void>;
    onComposeMessage?: (message: IMessage) => Promise<void>;
    onDocumentDownload: (documentId: string) => Promise<void>;
    onDocumentAssign?: (document: IDocument) => Promise<void>;
    onMarkAsClosed?: (ids: string | string[]) => Promise<void>;
    onMarkAsOpen?: (ids: string | string[]) => Promise<void>;
    onMarkAsRead?: (ids: string | string[]) => Promise<void>;
    onMessageClose: () => Promise<void>;
    onMessageOpen?: (id: string) => Promise<void>;
    onSubjectUpdate?: (id: string, subject: string) => Promise<void>;
    onClaimUpdate?: (id: string, claimId: string | null) => Promise<void>;
}

export const MessagingContext = React.createContext<IMessagingContextProps>({
    isReadOnly: true,
    isSubjectEditable: false,
    isListLoading: false,
    isDetailLoading: false,
    enableMessageTypeValidation: false,

    account: undefined,
    message: undefined,
    comments: undefined,
    documents: undefined,
    claims: undefined,

    onClaimSearch: noop,
    onCommentToggle: noop,
    onComposeComment: noop,
    onComposeMessage: noop,
    onDocumentDownload: noop,
    onDocumentAssign: noop,
    onMarkAsClosed: noop,
    onMarkAsOpen: noop,
    onMarkAsRead: noop,
    onMessageClose: noop,
    onMessageOpen: noop,
    onSubjectUpdate: noop,
    onClaimUpdate: noop,
});

export const MessagingProvider: React.FunctionComponent<IMessageProviderProps> = React.memo<IMessageProviderProps>(
    props => {
        const {
            children,
            onClaimSearch,
            onCommentToggle,
            onComposeComment,
            onComposeMessage,
            onDocumentDownload,
            onDocumentAssign,
            onMarkAsClosed,
            onMarkAsOpen,
            onMarkAsRead,
            onMessageClose,
            onMessageOpen,
            onSubjectUpdate,
            onClaimUpdate,
            ...providerProps
        } = props;

        // NB: Provider methods are optional, but context methods are not.
        const context = {
            onClaimSearch: onClaimSearch || noop,
            onCommentToggle: onCommentToggle || noop,
            onComposeComment: onComposeComment || noop,
            onComposeMessage: onComposeMessage || noop,
            onDocumentDownload: onDocumentDownload || noop,
            onDocumentAssign: onDocumentAssign || noop,
            onMarkAsClosed: onMarkAsClosed || noop,
            onMarkAsOpen: onMarkAsOpen || noop,
            onMarkAsRead: onMarkAsRead || noop,
            onMessageClose: onMessageClose || noop,
            onMessageOpen: onMessageOpen || noop,
            onSubjectUpdate: onSubjectUpdate || noop,
            onClaimUpdate: onClaimUpdate || noop,
            ...providerProps,
        };
        return <MessagingContext.Provider value={context}>{children}</MessagingContext.Provider>;
    },
);
