import styled from '@emotion/styled/macro';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import { forPhoneOnly, Variables } from '../../base';

export const AccordionPanelDetails = styled(AccordionDetails)`
    padding: 0px 8px 8px;
    display: block !important;
`;

export const AccordionPanelSummary = styled(AccordionSummary)`
    height: 40px;
    min-height: 40px;
    padding: 0px 8px;
    &.Mui-expanded {
        min-height: 40px;
    }
`;

export const AccordionPanel = styled(Accordion)``;

export const AccordionList = styled.section`
    margin-top: 10px;
    ${AccordionPanel}:first-of-type {
        &:before {
            display: none;
        }
    }
`;

export const Title = styled.div`
    font-weight: 500;
`;

export const Subtitle = styled.div`
    display: flex;
    font-weight: 500;
    flex: 1;
    justify-content: space-between;
`;

export const Warning = styled.span`
    color: ${Variables.Colors.r30};
`;

export const ServiceType = styled.div`
    margin-top: 8px;
    text-decoration: underline;
`;

export const Text = styled.div`
    text-align: left;
    font-weight: 400;
`;

export const TR = styled.tr``;

export const TD = styled.td<{ align?: 'right' }>`
    padding: 5px;
    border: 1px solid ${Variables.Colors.n15};
    text-align: ${({ align }) => (align === 'right' ? 'right' : undefined)};
`;

export const TH = styled.th`
    text-align: left;
    font-weight: 500;
    min-width: 150px;
    padding: 5px;
    border: 1px solid ${Variables.Colors.n15};
`;

export const THead = styled.thead``;

export const TBody = styled.tbody``;

export const Table = styled.table`
    border-collapse: collapse;
    border: 1px solid ${Variables.Colors.n15};
`;

export const ServiceTitleContainer = styled.section`
    display: flex;
    width: 100%;
    align-items: center;
    /* justify-content: space-between; */
`;

export const ServiceDetails = styled.div`
    display: flex;
`;

export const SummaryItem = styled.div`
    margin-left: 5px;
`;

export const ServiceBadge = styled.div`
    &:empty {
        display: none;
    }
`;

export const BadgesWrapper = styled.div`
    display: flex;

    ${ServiceBadge}:not(:first-of-type) {
        margin-left: 10px;
    }
`;

export const TreeViewWrapper = styled.section`
    margin-top: 20px;
    display: flex;
`;

export const TreeItem = styled.section``;
export const TreeItemGroup = styled.section`
    padding-left: 10px;
`;
export const TreeContainer = styled.section`
    border: 1px solid ${Variables.Colors.n15};
    padding: 10px;
    max-height: 800px;
    overflow-y: auto;
    > ${TreeItem} {
        &:not(:first-of-type) {
            margin-top: 10px;
        }
    }

    ${forPhoneOnly(`
        display: none;
    `)}
`;
export const TreeContent = styled.section``;
export const TreeContentAmount = styled.span`
    margin-left: 5px;
    font-size: 12px;
`;

export const TreeTitle = styled.span`
    font-weight: 500;
`;

export const ContentGroup = styled.section``;

export const ContentViewContainer = styled.section`
    padding: 0 10px;
    margin-left: 10px;
    scroll-behavior: smooth;

    max-height: 800px;
    overflow-y: auto;
    flex: 1;

    ${forPhoneOnly(`
        max-height: 100%;
        overflow-y: visible;
    `)}

    ${ContentGroup} {
        &:not(:first-of-type) {
            margin-top: 15px;
        }
    }
`;

export const ContentTitleBadges = styled.div`
    margin-left: 10px;
`;

export const ContentTitle = styled.section`
    font-weight: 500;
    font-size: 30px;
    display: flex;
    align-items: center;
`;
export const GroupDetails = styled.section`
    margin-top: 5px;
`;
export const GroupItem = styled.section`
    display: flex;
    width: 100%;
    justify-content: space-between;
`;
export const GroupItemDetails = styled.section``;
export const GroupItemTitle = styled.section`
    font-size: 20px;
`;

export const MessageItem = styled.span`
    font-size: 14px;
    word-break: break-all;
`;

export const GroupItemContainer = styled.div<{ stretch?: boolean }>`
    border: 1px solid ${Variables.Colors.n15};
    padding: 10px;
    grid-column: ${({ stretch }) => (stretch ? 'span 2' : undefined)};
    margin-top: 10px;
    min-width: 430px;
`;

export const BeneficiariesContainer = styled.div`
    margin-top: 10px;

    ${Table} {
        width: 100%;
    }
`;

export const GroupItems = styled.section`
    margin-top: 15px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 10px;
    row-gap: 10px;

    ${GroupItem} {
        &:not(:first-of-type) {
            margin-top: 5px;
        }
    }

    ${GroupItemContainer} {
        &:not(:first-of-type) {
            /* margin-top: 5px; */
        }
    }
`;

export const Label = styled.span`
    font-weight: 500;
`;
export const Value = styled.span`
    margin-left: 10px;
`;
export const LabelRow = styled.div`
    margin-top: 5px;
    font-size: 16px;
`;

export const GroupItemAmounts = styled.div``;
export const Number = styled.div`
    font-size: 25px;
    text-align: right;
`;

export const NumberDescription = styled.div`
    font-size: 14px;
    text-align: right;
`;

export const DetailsList = styled.ul`
    margin-top: 10px;
`;

export const EmptyLabel = styled.span`
    color: ${Variables.Colors.n30};
    text-decoration: none !important;
`;
