import * as React from 'react';
import * as S from './popover-divider.styles';

interface IPopoverDividerProps {
    className?: string;
}
export const PopoverDivider: React.FunctionComponent<IPopoverDividerProps> = ({ className }): React.ReactElement => (
    <S.PopoverDividerWrapper className={className}>
        <S.PopoverDivider />
    </S.PopoverDividerWrapper>
);
