import styled from '@emotion/styled/macro';
import { Tooltip } from '@mui/material';
import { BaseButton, Variables } from '../base';

export interface ILabelProperties {
    important?: boolean;
}

export const FormField = styled.div`
    margin-bottom: 8px;
    width: 100%;
`;

export const Label = styled.div<ILabelProperties>`
    margin: 0 0 6px 0;
    padding: 0;
    font-size: 16px;
    display: flex;
    align-items: center;
    font-weight: ${(props): string => (props.important ? 'bold' : 'normal')};
    color: ${(props): string => (props.important ? Variables.Colors.n100 : Variables.Colors.n70)} ;
`;

export const InfoWrapper = styled.div`
    margin-left: 6px;
    display: flex;
    align-items: center;
`;

export const FormFieldGroup = styled.div`
    margin-bottom: 8px;
    width: 100%;
    & ${FormField}:last-of-type {
        margin-bottom: 0;
    }
`;

export const AddFieldButton = styled(BaseButton)`
    height: 26px;
    min-width: 160px;
    border-radius: ${Variables.Rounded.small};
    padding: 5px;
    font-size: 16px;
    color: ${Variables.Colors.n70};
    margin: 5px 0;
    font-weight: 400;
    justify-content: flex-start;

    &:hover {
        background-color: ${Variables.Colors.n15_24};
    }
`;

export const StyledTooltip = styled(Tooltip)`
    // select zindex === 999
    /* z-index: 10000; */
`;
