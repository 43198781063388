import styled from '@emotion/styled/macro';
import { BaseButton, Variables } from '../base';

export const NoteIndicator = styled.div`
    display: flex;
    align-items: center;
`;

export const NoteLabel = styled.div`
    background-color: ${Variables.Colors.b100};
    color: ${Variables.Colors.white};
    font-size: smaller;
    text-transform: uppercase;
    padding: 0 4px;
`;

export const NoteButton = styled(BaseButton)`
    padding: 0 6px;
    min-width: unset;
    height: 20px;
    background-color: transparent;
    color: transparent;
`;
