import { mdiTrayArrowDown } from "@mdi/js";
import Icon from "@mdi/react";
import { Tooltip } from "@mui/material";
import _ from "lodash";
import React from "react";
import { CSVLink } from "react-csv";
import { Row } from "react-table";
import { TooltipTransitionComponent } from "../../../base";
import * as S from './table-export.styles';

export const TableExport: React.FC<{ rows: Row<any>[], prepareRow: (row: Row<any>) => void }> = ({ rows, prepareRow }) => {
    const [data, setData] = React.useState<object[]>([]);

    const getData = () => {
        const parsedData = rows.map(row => {
            prepareRow(row);
            return row.cells.reduce((acc, cell) => {
                if (!cell.column?.Header || !_.isString(cell.column.Header) || !cell.column.stringify) {
                    return acc;
                }

                if (cell.column.id === 'Selected' || cell.column.id === 'selection' || cell.column.id === 'Actions') {
                    return acc;
                }


                acc[cell.column.Header] = cell.column.stringify?.(cell);

                return acc;
            }, {})
        });

        setData(parsedData);
    }

    return <S.Container>
        <Tooltip
            placement="top-start"
            title={`Export the table to CSV. Only visible data will be exported.`}
            TransitionComponent={TooltipTransitionComponent}>
            <div>
                <CSVLink data={data} onClick={getData}>
                    <S.ActionButton variant="outlined" size="small" type="button">
                        <Icon path={mdiTrayArrowDown} size={1} />
                    </S.ActionButton>
                </CSVLink>
            </div>
        </Tooltip>
    </S.Container >
}