import styled from '@emotion/styled/macro';
import { transparentize } from 'polished';

/**
 * Sidebar Divider
 */
export const SidebarDivider = styled.div`
    width: 100%;
    height: 1px;
    background-color: ${transparentize(0.92, '#e4e8f0')};
`;
