import { AuthApi, CoreFetch, ToastContext } from '@enter/core';
import { FormInput, PageHeader, SideImageWrapper } from 'components';
import * as S from 'components/styles';
import { Form, Formik } from 'formik';
import _ from 'lodash';
import React, { useContext, useState } from 'react';
import { useLocation } from 'react-router-dom';
import * as Yup from 'yup';
import { handleFetch, parseQueryParams } from './util';

const schema = Yup.object().shape({
    password: Yup.string().min(8, 'Minimum length of 8').required('Password is required'),
    confirm: Yup.string().min(8, 'Minimum length of 8').required('Verification is required'),
});

interface IResetPassword {
    password: string;
    confirm: string;
}

export const ResetPassword: React.FunctionComponent = (): React.ReactElement => {
    document.title = 'Enter Login - Reset Password';

    const toastContext = useContext(ToastContext);
    const location = useLocation();
    const queryParams = parseQueryParams(location, {
        client_id: '',
        code: '',
    });

    const [isLoading, setLoading] = useState(false);

    const initial: IResetPassword = {
        password: '',
        confirm: '',
    };

    const forgotLink = '/forgot_password' + location.search;
    let successUrl = '/authorize' + location.search;
    if (queryParams.client_id === 'SUPPORT_TOOL') {
        successUrl = process.env.REACT_APP_SUPPORT_URL || successUrl;
    } else if (queryParams.client_id === 'PROVIDER_DASHBOARD') {
        successUrl = process.env.REACT_APP_PROVIDER_URL || successUrl;
    }

    const onValidate = (values: IResetPassword): any => {
        const errors = {};
        const { password, confirm } = values;
        if (password && confirm && password !== confirm) {
            _.set(errors, 'confirm', 'Password and verification do not match');
        }
        return errors;
    };

    const onSubmit = (values: IResetPassword): void => {
        window.grecaptcha.ready(async () => {
            await window.grecaptcha.execute(process.env.REACT_APP_RECAPTCHA_KEY, {
                action: 'reset_password',
            });
            setLoading(true);
            await handleFetch(
                async () =>
                    CoreFetch<AuthApi>(AuthApi).authResetPassword({
                        resetPasswordRequest: {
                            resetPasswordCode: queryParams.code,
                            password: values.password,
                        },
                    }),
                (msg: string): void => {
                    toastContext.notify && toastContext.notify(msg, 'left');
                },
            );
            setLoading(false);
            window.location.href = successUrl;
        });
    };

    return (
        <SideImageWrapper>
            <Formik initialValues={initial} validationSchema={schema} onSubmit={onSubmit} validate={onValidate}>
                <Form>
                    <PageHeader title={'Reset Password'} instructionText={'Passwords should have a minimum length of 8 and contain at least 1 upper case, lower case, and symbol.'}/>
                    <S.Spacer />
                    <FormInput
                        label="Password"
                        id="password"
                        name="password"
                        type="password"
                        autoComplete="new-password"
                        placeholder="Password (minimum length of 8)"
                    />
                    <S.Spacer />
                    <FormInput
                        label="Verify Password"
                        id="confirm"
                        name="confirm"
                        type="password"
                        autoComplete="new-password"
                        placeholder="Verify password (minimum length of 8)"
                    />
                    <S.Spacer />
                    <S.ButtonWrapper>
                        <S.Button variant="primary" disabled={isLoading} size="large" tabIndex={1} type="submit">
                            {isLoading ? <S.Progress size="24px" /> : 'Reset Password'}
                        </S.Button>
                    </S.ButtonWrapper>
                    <S.Footer>
                        <S.Link to={forgotLink}>Request password reset again</S.Link>
                    </S.Footer>
                </Form>
            </Formik>
        </SideImageWrapper>
    );
};
