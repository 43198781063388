import styled from '@emotion/styled/macro';
import { Variables } from '../../base';
import { LayoutRow } from '../../layout';

export const MetricsBar = styled(LayoutRow)`
    display: flex;
    justify-content: space-between;
    flex-grow: 1;
    min-height: 150px;
`;

export const MetricsWarning = styled(MetricsBar)`
    border-radius: ${Variables.Rounded.small};
    border: ${Variables.Borders.regular};
    border-style: dashed;
    justify-content: center;
    align-items: center;
`;

export const MetricsError = styled.div`
    border-radius: ${Variables.Rounded.small};
    border: ${Variables.Borders.regular};
    border-style: dashed;
    justify-content: center;
    align-items: center;
`;
