import styled from '@emotion/styled/macro';
import { Tooltip } from '@mui/material';
import _ from 'lodash';
import React, { useMemo } from 'react';
import {
    PaymentStatusType,
    ProviderClaimPaymentItem,
    ProviderPayer,
    SupportClaimPaymentItem,
    SupportPayer
} from '../../../api';
import { Variables } from '../../base';
import { TooltipTransitionComponent } from '../../base/tooltip';
import {
    DataTable,
    DataTableCell,
    DataTablePresenter,
    ITableColumn,
    renderEmpty,
    renderString
} from '../../data-table';
import { getPaymentIndex } from '../../services';


export const StyledTag = styled.span`
    display: inline-block;
    color: ${Variables.Colors.n70};
    background-color: ${Variables.Colors.platinum};
    border-radius: ${Variables.Rounded.medium};
    padding: 0 4px;
    &:not(:first-of-type) {
        margin-left: 4px;
    }
    white-space: nowrap;
`;

type PayerStatusMap = Map<number, SupportPayer | ProviderPayer>; // index: name

interface IPaymentItemsProps {
    items: (ProviderClaimPaymentItem | SupportClaimPaymentItem)[];
    payerStatusMap?: PayerStatusMap;
}

export const PaymentItems: React.FunctionComponent<IPaymentItemsProps> = React.memo(({ items, payerStatusMap }) => {
    const columns: ITableColumn<ProviderClaimPaymentItem | SupportClaimPaymentItem>[] = useMemo<
        ITableColumn<ProviderClaimPaymentItem | SupportClaimPaymentItem>[]
    >(
        () => [
            {
                id: 'ControlNumber',
                Header: 'Control Number',
                accessor: 'controlNumber',
                Cell: DataTableCell('String'),
                disableSortBy: true,
            },
            {
                id: 'ProcedureCode',
                Header: 'CPT/HCPCS',
                accessor: 'procedureCode',
                Cell: ({ cell, row }) => {
                    if (!cell.value) {
                        return renderEmpty();
                    }

                    const procedureCode = cell.value;
                    const modifiers = _.compact(row.original.modifierCodes).join(', ');
                    const value = _.compact([procedureCode, modifiers]).join('-');

                    return (
                        <div>
                            <StyledTag>{value}</StyledTag>{' '}
                            {(row.original.paidCount || 0) > 0 ? `x ${row.original.paidCount}` : null}
                        </div>
                    );
                },
                minWidth: 150,
                disableSortBy: true,
            },
            {
                id: 'Payer',
                Header: 'Payer',
                accessor: 'status',
                Cell: ({ cell }) => {
                    const paymentIndex = getPaymentIndex(cell.value as PaymentStatusType);
                    const status =
                        payerStatusMap && paymentIndex !== undefined ? payerStatusMap.get(paymentIndex) : undefined;

                    return cell.value ? (
                        <Tooltip
                            placement="top-start"
                            title={status?.name || ''}
                            open={status?.name ? undefined : false}
                            TransitionComponent={TooltipTransitionComponent}>
                            <div>{renderString(cell.value, { type: 'String', formatted: true, normalized: true })}</div>
                        </Tooltip>
                    ) : (
                        renderEmpty()
                    );
                },
                disableSortBy: true,
                minWidth: 200,
            },
            {
                id: 'ChargeAmount',
                Header: 'Charge amount',
                accessor: 'chargeAmount',
                Cell: DataTableCell('Amount'),
                disableSortBy: true,
            },
            {
                id: 'AllowedAmount',
                Header: 'Allowed amount',
                accessor: 'allowedAmount',
                Cell: DataTableCell('Amount'),
                disableSortBy: true,
            },
            {
                id: 'PaidAmount',
                Header: 'Paid amount',
                accessor: 'paidAmount',
                Cell: DataTableCell('Amount'),
                disableSortBy: true,
            },
            {
                id: 'RenderingProviderID',
                Header: 'Rendering Provider ID',
                accessor: 'renderingProviderId',
                Cell: DataTableCell('Amount'),
                disableSortBy: true,
                show: false,
            },
            {
                id: 'FromDOS',
                Header: 'From DOS',
                accessor: 'fromDos',
                Cell: DataTableCell('Date'),
                disableSortBy: true,
                width: 150,
            },
            {
                id: 'ThroughDOS',
                Header: 'Through DOS',
                accessor: 'throughDos',
                Cell: DataTableCell('Date'),
                disableSortBy: true,
                show: false,
                width: 150,
            },
            {
                id: 'PlaceOfServiceType',
                Header: 'POS Code',
                accessor: 'placeOfServiceCode',
                Cell: DataTableCell('String'),
                disableSortBy: true,
                show: false,
            },
        ],
        [],
    );

    const { current: presenter } = React.useRef(
        new DataTablePresenter({
            name: 'payment-items',
            data: items,
            columns,
            filterSchemas: [],
        }),
    );

    presenter.onDataUpdate(items);

    return <DataTable presenter={presenter} hidePagination={true} hideFiltering={true} isLoading={false} />;
});
