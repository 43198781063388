import * as d3 from 'd3';
import _ from 'lodash';

const formatNumberOrFail = (format: string): ((n: string | number | null | undefined) => string) => {
    return (n: string | number | null | undefined): string => {
        try {
            if (_.isString(n)) {
                n = Number.parseFloat(n);
            }
            if (_.isNumber(n)) {
                return d3.format(format)(n);
            }
        } catch (err) {
            // ignore error
        }
        return '–';
    };
};
type NumberType = 'Financial' | 'Currency' | 'Float' | 'Integer' | 'Pct' | 'Delta';
export function formatNumber(type: NumberType): (n: string | number | null | undefined) => string {
    switch (type) {
        case 'Financial':
            // 2725 => $2,725.00 or –
            return (n: string | number | null | undefined): string => formatNumberOrFail('$,.2f')(n).replace('$', '$ ');
        case 'Currency':
            // 2725 => $2,725.00
            return formatNumberOrFail('$,.2f');
        case 'Float':
            // 2725 => 2,725.00
            return formatNumberOrFail(',.2f');
        case 'Integer':
            // 2725 => 2,725
            return formatNumberOrFail(',.2d');
        case 'Pct':
            // 0.8411 => 84%
            return formatNumberOrFail(',.3p');
        case 'Delta':
            // 0.8411 => +84%
            return formatNumberOrFail('+.2p');
        default:
            throw new Error(`Unknown number type: ${type}`);
    }
}
export const formatBytes = (size: number | undefined): string => `${_.toUpper(d3.format('.2~s')(size || 0))}B`;

export const parseAmount = (amount?: { amount?: string }): number => {
    const f = parseFloat(_.get(amount, 'amount', '0'));
    return Number.isNaN(f) ? 0 : f;
};
