import styled from '@emotion/styled/macro';
import { forPhoneOnly } from '../base';

export const LayoutRow = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: auto;
    height: auto;
    margin: 0;
    padding: 0;
    justify-content: space-between;
    align-items: center;

    &:first-of-type {
        margin-top: 0px;
    }

    margin-top: 24px;
    ${forPhoneOnly(`
        margin-top: 16px;
    `)};
`;
