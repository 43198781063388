import styled from '@emotion/styled/macro';

export const SelectContainer = styled.div`
    display: flex;
    align-items: flex-start;
`;

export const PostfixContainer = styled.div`
    min-height: 100%;
`;
