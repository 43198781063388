import styled from '@emotion/styled/macro';
import { BaseButton } from '../../base';

export const Controls = styled.div`
    display: flex;
    flex-direction: row-reverse;
`;
export const UnderButton = styled(BaseButton)`
    height: 32px;
    min-width: auto;

    padding: 0 16px;
    margin-left: 16px;
`;
