import styled from '@emotion/styled/macro';
import { BaseButton, forPhoneOnly, Variables } from '../../../base';

export const SettingsContainer = styled.div`
    margin-left: 40px;

    ${forPhoneOnly(`
        margin-left: 20px;
    `)};
`;

export const ActionButton = styled(BaseButton)`
    min-width: auto;
    fill: ${Variables.Colors.n30};
    height: 30px;
    width: 40px;
    border-color: transparent;
    background: none;

    &:hover {
        fill: ${Variables.Colors.n70};
        background: white;
        border: solid 1px ${Variables.Colors.nx8};
    }

    padding: 0;
    z-index: 1;
`;

export const IconWrapper = styled.div`
    display: flex;

    svg {
        fill: ${Variables.Colors.n30};
    }
`;

export const ItemWrapper = styled.div`
    display: flex;
    gap: 10px;
    justify-content: space-between;
`;
