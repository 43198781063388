import _ from 'lodash';
import React from 'react';
import InputMask from 'react-input-mask';
import { PropsValue } from 'react-select';
import { AsyncSelect, BaseInput, BaseSelect, DatePicker, getBooleanOptions, ISelectOption } from '../../../base';
import { FormField } from '../../../form-field';
import {
    CustomFilterFieldName,
    ICustomFilter,
    ICustomFilterBoolean,
    ICustomFilterDate,
    ICustomFilterFullText,
    ICustomFilterNumber,
    ICustomFilterPhone,
    ICustomFilterSelect,
    ICustomFilterString,
    IFilterSchema,
} from './filters.models';
import { getFilterMethods, isEmptyValueMethod } from './filters.service';
import * as S from './filters.styles';

interface IFilterItemProps {
    index: number;
    filterSchemas: IFilterSchema[];
    filterId: ICustomFilter['filterId'];
    filterMethod: ICustomFilter['filterMethod'];
    filterValue: ICustomFilter['value'];
    onChange: (name: CustomFilterFieldName, value: any) => void;
}

export const FilterItem = React.memo<IFilterItemProps>(
    ({ index, filterSchemas, filterId, filterMethod, filterValue, onChange }) => {
        const filterSchema = filterSchemas.find(schema => schema.filterId === filterId);

        const filterColumns: ISelectOption<string>[] = filterSchemas
            .map(schema => {
                return {
                    value: schema.filterId,
                    label: schema.label,
                };
            })
            .sort((a, b) => a.label.localeCompare(b.label));

        const emptyValueMethod = filterMethod && isEmptyValueMethod(filterMethod);

        let filterMethods: ISelectOption[] = [];

        // We don't have filterSchema when filterId field is empty
        if (filterSchema) {
            filterMethods = getFilterMethods(filterSchema.filterType);
        }

        const selectedColumn = _.find(filterColumns, { value: filterId });
        const selectedMethod = _.find(filterMethods, { value: filterMethod });

        const handleSelectChange = (name: CustomFilterFieldName) => {
            return (option: PropsValue<ISelectOption>) => {
                if (!option) {
                    return;
                }

                const selectedOption = option as ISelectOption;

                onChange(name, selectedOption.value);
            };
        };

        const handleValueChange = (value: string) => {
            onChange('value', value);
        };

        return (
            <>
                <S.FilterItem>
                    <FormField>
                        <BaseSelect
                            tabIndex={index}
                            menuPortalTarget={document.body}
                            menuPosition="fixed"
                            onChange={handleSelectChange('filterId')}
                            options={filterColumns}
                            value={selectedColumn}
                        />
                    </FormField>
                </S.FilterItem>
                {filterMethods && (
                    <S.FilterItem>
                        <FormField>
                            <BaseSelect
                                tabIndex={index}
                                menuPortalTarget={document.body}
                                menuPosition="fixed"
                                onChange={handleSelectChange('filterMethod')}
                                options={filterMethods}
                                value={selectedMethod}
                            />
                        </FormField>
                    </S.FilterItem>
                )}
                {!filterMethods && (
                    <S.FilterItem>
                        <FormField>
                            <BaseSelect isDisabled={true} />
                        </FormField>
                    </S.FilterItem>
                )}
                {filterSchema && !emptyValueMethod && (
                    <S.FilterItem>
                        <FormField>
                            <FilterValue
                                index={index}
                                filterValue={filterValue}
                                filterSchema={filterSchema}
                                onChange={handleValueChange}
                            />
                        </FormField>
                    </S.FilterItem>
                )}
                {!filterSchema && (
                    <S.FilterItem>
                        <FormField>
                            <BaseSelect isDisabled={true} />
                        </FormField>
                    </S.FilterItem>
                )}
            </>
        );
    },
);

interface IFilterValueProps {
    index: number;
    filterSchema: IFilterSchema;
    filterValue: ICustomFilter['value'];
    onChange: (value: any) => void;
}

/**
 * We could have different type of value components depends on schema we selected
 */
export const FilterValue = React.memo<IFilterValueProps>(({ index, filterSchema, filterValue, onChange }) => {
    const handleSelectChange = (option: any): void => {
        const selectedOption = option as ISelectOption<string>;

        onChange(selectedOption);
    };

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        onChange(event.target.value);
    };

    switch (filterSchema.filterType) {
        case 'Select':
            const selectValue = filterValue as ICustomFilterSelect['value'];

            return (
                <BaseSelect
                    tabIndex={index}
                    menuPortalTarget={document.body}
                    menuPosition="fixed"
                    onChange={handleSelectChange}
                    options={filterSchema.options}
                    placeholder="Value"
                    value={selectValue}
                />
            );
        case 'Boolean':
            const booleanValue = filterValue as ICustomFilterBoolean['value'];

            return (
                <BaseSelect
                    menuPortalTarget={document.body}
                    menuPosition="fixed"
                    onChange={handleSelectChange}
                    options={getBooleanOptions()}
                    placeholder="Value"
                    value={booleanValue}
                />
            );
        case 'AsyncSelect':
            const asyncSelectValue = filterValue as ICustomFilterSelect['value'];

            return (
                <AsyncSelect
                    menuPortalTarget={document.body}
                    menuPosition="fixed"
                    onChange={handleSelectChange}
                    loadOptions={filterSchema.loadOptions}
                    placeholder={filterSchema.placeholder || 'Search'}
                    defaultOptions={true}
                    cacheOptions={true}
                    value={asyncSelectValue}
                />
            );
        case 'Number':
            const numberValue = filterValue as ICustomFilterNumber['value'];

            return <BaseInput placeholder="Value" type="number" onChange={handleInputChange} value={numberValue} />;
        case 'Phone':
            const phoneValue = filterValue as ICustomFilterPhone['value'];

            const handlePhoneChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
                onChange(event.target.value.replace(/[^0-9+]/g, ''));
            };

            return (
                <InputMask mask="+1 (999) 999-9999" value={phoneValue} onChange={handlePhoneChange} name={name}>
                    {(): React.ReactElement => <BaseInput placeholder="Value" type="string" value={phoneValue} />}
                </InputMask>
            );
        case 'String':
            const stringValue = filterValue as ICustomFilterString['value'];

            return <BaseInput placeholder="Value" onChange={handleInputChange} value={stringValue} />;
        case 'FullText':
            const fullTextValue = filterValue as ICustomFilterFullText['value'];

            return <BaseInput placeholder="Value" onChange={handleInputChange} value={fullTextValue} />;
        case 'Date':
        case 'DateTime':
            const dateValue = filterValue as ICustomFilterDate['value'];
            const selectedDate = _.isDate(dateValue) ? new Date(dateValue) : '';

            const handleDateChange = (val: Date | undefined): void => {
                onChange(val);
            };

            return (
                <DatePicker
                    onDateSelected={handleDateChange}
                    value={dateValue}
                    inputProps={{
                        placeholder: 'Choose date',
                    }}
                />
            );
        default:
            throw new Error('This filter type component is not implemented');
    }
});
