import { useField } from 'formik';
import React from 'react';
import * as S from './form-input.styles';

export type IFormInputProps = React.PropsWithoutRef<JSX.IntrinsicElements['input']> & {
    id: string;
    label: string;
    name: string;
    caption?: React.ReactNode;
};

export const FormInput: React.FC<IFormInputProps> = (props: IFormInputProps) => {
    const { id, label, name, caption, ...inputProps } = props;
    const [field, meta] = useField(name);
    return (
        <>
            <S.Label htmlFor={id}>{label}</S.Label>
            {caption && <S.Label align="right">{caption}</S.Label>}
            <S.Input id={id} {...field} {...inputProps} tabIndex={1} />
            {meta.touched && meta.error ? <S.Error>{meta.error}</S.Error> : null}
        </>
    );
};
