import { ToastProvider } from '@enter/core';
import {
    Authorize,
    ForgotPassword,
    NotFound,
    ResetPassword,
    SignupInvitation,
    SignupProvider,
    SignupVerification,
} from 'pages';
import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import {SingleSignOn} from "../pages/authorize/single-sign-on";

export function Router(): React.ReactElement {
    return (
        <ToastProvider variant="dark">
            <BrowserRouter>
                <Switch>
                    <Route path="/authorize" component={Authorize} exact={true} />
                    <Route path="/forgot_password" component={ForgotPassword} exact={true} />
                    <Route path="/reset_password" component={ResetPassword} exact={true} />
                    <Route path="/signup_provider" component={SignupProvider} exact={true} />
                    <Route path="/signup_verification" component={SignupVerification} exact={true} />
                    <Route path="/signup_invitation" component={SignupInvitation} exact={true} />
                    <Route path="/sso" component={SingleSignOn} exact={true}/>
                    <Route component={NotFound} />
                </Switch>
            </BrowserRouter>
        </ToastProvider>
    );
}
