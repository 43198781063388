import styled from '@emotion/styled/macro';
import { Icon } from '@mdi/react';
import { Variables } from '../base';

export const AttachmentCount = styled.div`
    color: ${Variables.Colors.n30};
`;

export const Paperclip = styled(Icon)`
    height: 18px;
    width: 18px;
    transform: rotate(45deg) translateY(5px);
    fill: ${Variables.Colors.n30};
`;
