import styled from '@emotion/styled/macro';
import { BaseButton, forPhoneOnly, Variables } from '../../base';
import { AuthorIndicator } from '../author-indicator';
import { NoteIndicator } from '../note-indicator';

interface IEntry {
    note?: boolean;
}

export const History = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
`;

export const HistoryHeader = styled.div`
    display: flex;
    align-items: center;
    padding: 16px 32px;
`;

export const HistoryHeaderLine = styled.div`
    flex-grow: 1;
    box-shadow: ${Variables.Shadows.border};
`;

export const HistoryHeaderLabel = styled.div`
    font-size: 15px;
    letter-spacing: 0.4px;
    color: ${Variables.Colors.n70};
    padding: 0 24px;
`;

export const HistoryBody = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
`;

export const HistoryNoteButton = styled(BaseButton)`
    padding: 0 6px;
    min-width: unset;
    height: 20px;
`;

const noteIndicator = styled(NoteIndicator);
const EntryComment = `
    padding: 8px 8px;
    color: ${Variables.Colors.n85};
    background-color: ${Variables.Colors.white};
    ${noteIndicator} {
        background-color: ${Variables.Colors.white};
        svg {
            fill: ${Variables.Colors.white};
        }
    }
    &:hover {
        background-color: ${Variables.Colors.n15_24};
        ${noteIndicator} {
            svg {
                fill: ${Variables.Colors.n85};
            }
        }
    }
`;
const EntryNote = `
    padding: 8px 0;
    margin: 8px;
    border: 1px solid ${Variables.Colors.b100};
    border-radius: 8px;
    background-color: ${Variables.Colors.b300_l96};
    ${noteIndicator} {
        transition: all 0s;
        background-color: ${Variables.Colors.b300_l96};
        svg {
            fill: ${Variables.Colors.b300_l96};
        }
    }
    &:hover {
        background-color: ${Variables.Colors.b300_l90};
        ${noteIndicator} {
            background-color: ${Variables.Colors.b300_l90};
            svg {
                fill: ${Variables.Colors.n70};
            }
        }
    }
`;

export const HistoryEntry = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;

    font-size: 16px;
    letter-spacing: 0.2px;

    ${(props: IEntry): string | undefined => (props.note ? EntryNote : EntryComment)}

    ${forPhoneOnly(`
        display: block;
    `)};
`;

export const HistoryAuthorIndicator = styled(AuthorIndicator)`
    margin-right: 6px;
`;

export const HistoryEntryTopic = styled.div`
    display: flex;
    align-items: center;
    padding: 0 8px 0 24px;
`;

export const HistoryEntryName = styled.div`
    font-weight: 500;
    color: ${Variables.Colors.n100};
`;

export const HistoryNoteSpacer = styled.span`
    flex-grow: 1;
`;

export const HistoryEntryTime = styled.div`
    margin-left: 8px;
    font-size: 14px;
    color: ${Variables.Colors.n70};
`;

export const HistoryEntryMessage = styled.div`
    display: inline-flex;
    padding: 8px 32px;

    overflow-x: hidden;

    * {
        white-space: pre-wrap;
        max-width: 100%;
    }
`;

export const HistoryEntryEdited = styled.div`
    margin-left: 8px;
    color: ${Variables.Colors.n50};
`;

export const HistoryEntryAttachment = styled.div`
    padding: 8px 32px 8px 32px;
`;
