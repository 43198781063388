import styled from '@emotion/styled/macro';
import { ISpinnerProps, Spinner, Variables } from '../../../base';

export const LoadingContainer = styled.section`
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    transition: all 0.3s ease;
    background-color: ${Variables.Colors.n15_24};
`;

export const LoadingSpinner = styled(Spinner)<ISpinnerProps>`
    background-color: transparent;
`;
