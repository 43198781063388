import styled from '@emotion/styled/macro';
import { Icon } from '@mdi/react';
import { Variables } from '../base';

export const InfoIcon = styled.span`
    display: flex;
`;

export const StyledIcon = styled(Icon)`
    display: flex;
    align-items: center;

    height: 24px;
    width: 24px;
    fill: ${Variables.Colors.n30};

    path {
        fill: ${Variables.Colors.nx24};
    }
`;
