import { AuthProvider } from '@enter/core';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './app';
import * as serviceWorker from './serviceWorker';

const ProvidedApp = (): React.ReactElement => (
    <AuthProvider clientId="LOGIN">
        <App />
    </AuthProvider>
);

Sentry.init({
    dsn: 'https://1f47a2c2f68648138449819022339cf5@o323927.ingest.sentry.io/6118404',
    integrations: [new Integrations.BrowserTracing(), new Sentry.Integrations.Breadcrumbs({
        console: false
    })],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
    release: `${process.env.REACT_APP_PACKAGE_NAME}-${process.env.NODE_ENV}-${process.env.SENTRY_RELEASE}`,
});

ReactDOM.render(<ProvidedApp />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
