import styled from '@emotion/styled/macro';
import { Variables } from '../base';
import Icon from '@mdi/react';

export const Select = styled.div`
    display: inline-block;
    padding: 7px 16px;

    line-height: 24px;
    background-color: ${Variables.Colors.white};

    border: ${Variables.Borders.regular};
    border-radius: ${Variables.Rounded.small};
    transition: all 0.4s ease-out;
    box-sizing: border-box;

    z-index: 2;
    user-select: none;
    outline: none;

    &:hover {
        cursor: pointer;
        border-color: ${Variables.Colors.n15};
    }
    &:active {
        border-color: ${Variables.Colors.n30};
    }
`;

export const SelectIcon = styled(Icon)`
    height: 24px;
    width: 24px;
    margin-top: -3px;
    margin-left: 8px;
    vertical-align: middle;
    path {
        fill: ${Variables.Colors.n30};
    }
    ${Select}:hover & {
        path {
            fill: ${Variables.Colors.n50};
        }
    }
`;
