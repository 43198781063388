import styled from '@emotion/styled/macro';
import { Variables } from '../../../base';

export const PaginationContainer = styled.section`
    display: flex;
    align-items: center;
`;

export const LabelsWrapper = styled.div`
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1;
    letter-spacing: 1px;
    text-align: center;
    color: ${Variables.Colors.p100};
    display: flex;
    align-items: center;
    white-space: nowrap;
`;

export const CurrentPageLabel = styled.span`
    font-weight: 500;
    padding: 4px 8px;
    border-radius: 12px;
    background-color: ${Variables.Colors.n5};
    text-align: center;
    cursor: pointer;
`;

export const TotalPagesLabel = styled.span`
    margin-left: 8px;
`;

export const ButtonsWrapper = styled.div`
    display: flex;
    margin-left: 40px;
    button:last-child {
        margin-left: 8px;
    }
`;

export const FirstPageContainer = styled.div`
    margin-right: 8px;
`;

export const LastPageContainer = styled.div`
    margin-left: 8px;
`;
