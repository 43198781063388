import styled from '@emotion/styled/macro';
import _ from 'lodash';
import { Variables } from '../variables';

interface IMenuProps {
    activatorRect: ClientRect;
    viewportRect: ClientRect;
    menuRect?: ClientRect;
    align?: 'left' | 'right';
    verticalAlign?: 'top' | 'bottom' | 'auto';
    isOpen?: boolean;
}

export const Menu = styled.div<IMenuProps>`
    position: absolute;
    display: block;
    padding: 8px 0px;

    user-select: none;
    z-index: 1301;

    border-radius: ${Variables.Rounded.small};
    background-color: ${Variables.Colors.white};

    ${(props: IMenuProps): string => {
        // NB: box-shadow renders even for 0-width elements
        if (props.isOpen !== false) {
            return `box-shadow: ${Variables.Shadows.large}, ${Variables.Shadows.border};`;
        }
        return '';
    }}

    ${(props: IMenuProps): string => {
        const vy = props.viewportRect.top;
        const vh = props.viewportRect.height;
        const ay = props.activatorRect.top;
        const ah = props.activatorRect.height;
        const mh = _.get(props, 'menuRect.height', vh);

        const hasRoomUnder = ay + ah + mh <= vh;
        const bottom = (): string => `
            top: ${-vy + ay + ah}px;
            margin-top: 8px;
        `;
        const top = (): string => {
            // On the first render `menuRect` is undefined, because we have not rendered menu items
            // After we rendered them, we know the reference and can calculate menu height
            if (!props.menuRect) {
                return bottom();
            }
            return `
                top: ${-vy + ay - mh}px;
                margin-top: -8px;
            `;
        };

        switch (props.verticalAlign) {
            case 'auto':
                return hasRoomUnder ? bottom() : top();
            case 'top':
                return top();
            default:
            case 'bottom':
                return bottom();
        }
    }}

    ${(props: IMenuProps): string => {
        const vx = props.viewportRect.left;
        const vw = props.viewportRect.width;
        const aw = props.activatorRect.width;
        const ax = props.activatorRect.left;
        switch (props.align) {
            case 'right':
                return `right: ${-vx + vw - ax - aw}px;`;
            case 'left':
            default:
                return `left: ${-vx + ax}px;`;
        }
    }}
`;
