import styled from '@emotion/styled/macro';
import { BaseButton, Tooltip } from '../base';

export const UndoneButton = styled(BaseButton)`
    width: auto;
    min-width: 0;
    padding: 0 7px;

    background-color: inherit;
`;

export const MessageTooltip = styled(Tooltip)`
    display: inline-flex;
    cursor: pointer;
`;
