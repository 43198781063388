import { mdiAutoFix, mdiFullscreen, mdiLoading, mdiRotateLeft, mdiRotateRight } from '@mdi/js';
import PSPDFKit from 'pspdfkit';
import React, { useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Prompt } from 'react-router-dom';
import { Spinner } from '../base';
import * as S from './pdf-viewer.styles';

type UnionToIntersection<T> = (T extends any ? (x: T) => any : never) extends (x: infer R) => any ? R : never;

type Instance = InstanceType<typeof PSPDFKit['Instance']>;
type ToolbarItem = Exclude<Parameters<typeof PSPDFKit.load>[0]['toolbarItems'], undefined>[0];
type InstantJSON = Exclude<UnionToIntersection<Parameters<typeof PSPDFKit.load>[0]>['instantJSON'], undefined>;

export type DocumentMetadata = InstantJSON;

export type OverlayMetadata = {
    height: number;
    left: number;
    top: number;
    width: number;
    pageIndex: number;
    key: string;
};

export type PDFViewerProps = {
    url: string;
    metadata?: string;
    enableEditing?: boolean;
    loading?: boolean;
    overlays?: OverlayMetadata[];
    onOverlayClick?: (key: string) => void;
    onMetadataUpdated?: (metadata: string) => Promise<void>;
    onDocumentUpdated?: (buffer: ArrayBuffer) => Promise<void>;
    onParseDocument?: () => Promise<void>;
};

// Make sure you followed the guide before using this component
// https://pspdfkit.com/getting-started/web/?frontend=react&project=existing-project
const PDFViewer: React.FunctionComponent<PDFViewerProps> = ({
    url,
    metadata,
    enableEditing,
    loading,
    overlays = [],
    onOverlayClick,
    onMetadataUpdated,
    onDocumentUpdated,
    onParseDocument,
}) => {
    const renderedOverlays = useRef<string[]>([]);
    const containerRef = useRef<HTMLElement>(null);
    const instanceRef = useRef<Instance>();

    const isPdf = checkIsPdf(url);

    function renderOverlays(instance: Instance, overlays: OverlayMetadata[]) {
        overlays.forEach(overlay => {
            const page = instance.pageInfoForIndex(overlay.pageIndex);
            if (!page) {
                return;
            }

            const space = 5;

            const x = page.width * overlay.left - space / 2;
            const y = page.height * overlay.top - space / 2;
            const width = page.width * overlay.width + space;
            const height = page.height * overlay.height + space;
            const item = new PSPDFKit.CustomOverlayItem({
                id: overlay.key,
                node: getHighlightElement({ width, height, overlay, onClick: onOverlayClick }),
                pageIndex: overlay.pageIndex,
                position: new PSPDFKit.Geometry.Point({ x, y }),
            });

            instance.setCustomOverlayItem(item);

            renderedOverlays.current.push(overlay.key);
        });
    }

    useEffect(() => {
        if (!isPdf) {
            return;
        }

        const container = containerRef.current;
        if (!container) {
            return;
        }

        (async function () {
            if (!enableEditing) {
                instanceRef.current = await initPdfViewer({ container, url, onParseDocument });
                renderOverlays(instanceRef.current, overlays);
                return;
            }

            const instantJSON = metadata ? JSON.parse(metadata) : [];
            const instance = await initPdfViewer({ container, url, instantJSON, enableEditing, onParseDocument });

            if (onMetadataUpdated) {
                instance.addEventListener('annotations.willSave', async () => {
                    const instantJson = await instance.exportInstantJSON();

                    onMetadataUpdated(JSON.stringify(instantJson));
                });
            }

            if (onDocumentUpdated) {
                instance.addEventListener('document.change', async (aaa: any) => {
                    const pdf = await instance.exportPDF();

                    onDocumentUpdated(pdf);
                });
            }

            instanceRef.current = instance;
        })();

        return () => {
            PSPDFKit.unload(container);
        };
    }, [enableEditing, url]);

    useEffect(() => {
        if (!instanceRef.current) {
            return;
        }

        renderOverlays(instanceRef.current, overlays);
    }, [overlays]);

    return (
        <S.Container>
            {!isPdf ? (
                <S.IFrame src={url} />
            ) : (
                <>
                    <Router>
                        <Prompt
                            when={loading}
                            message="The document is still loading. If you leave this page, your changes may not be saved. Do you still want to leave the page?"
                        />
                    </Router>
                    {!!loading && (
                        <S.LoadingContainer>
                            <Spinner />
                        </S.LoadingContainer>
                    )}
                    <S.Wrapper ref={containerRef} style={{ width: '100%', height: '100vh' }} />
                </>
            )}
        </S.Container>
    );
};

function checkIsPdf(url: string) {
    return url.match('.*.pdf');
}

const enabledToolbarItems: typeof PSPDFKit.defaultToolbarItems[number]['type'][] = [
    'pager',
    'pan',
    'zoom-out',
    'zoom-in',
    'zoom-mode',
    'spacer',
    // 'text',
    // 'annotate',
    // 'ink',
    // 'image',
    // 'line',
    // 'arrow',
    // 'rectangle',
    // 'ellipse',
    // 'polygon',
    // 'polyline',
    // 'note',
    'print',
    'search',
    'debug',
    'sidebar-thumbnails',
    // 'signature',
    // 'sidebar-document-outline',
    // 'sidebar-annotations',
    // 'sidebar-bookmarks',
    // 'highlighter',
    // 'text-highlighter',
    // 'ink-eraser',
    // 'stamp',
    // 'document-editor',
    // 'document-crop',
    'export-pdf',
];

const editingToolbarItems: typeof PSPDFKit.defaultToolbarItems[number]['type'][] = [
    'note',
    'text',
    'polyline',
    'line',
    'arrow',
    'rectangle',
    'sidebar-document-outline',
    'sidebar-annotations',
    'sidebar-bookmarks',
    'highlighter',
    'document-editor',
];

async function initPdfViewer({
    container,
    url,
    instantJSON,
    enableEditing,
    onParseDocument,
}: {
    container: HTMLElement;
    url: string;
    // { format: 'https://pspdfkit.com/instant-json/v1'; annotations: Record<string, any>[] | undefined; }
    instantJSON?: { format: 'https://pspdfkit.com/instant-json/v1'; annotations: Record<string, any>[] | undefined };
    enableEditing?: boolean;
    onParseDocument?: () => Promise<void>;
}) {
    let instance: Instance | undefined = undefined;

    // Create custom toolbar item
    const fullscreenItem: ToolbarItem = {
        type: 'custom',
        id: 'fullscreen',
        icon: `<svg style="opacity: 0.66;"><path d="${mdiFullscreen}" /></svg>`,
        title: 'Fullscreen',
        onPress: () => {
            if (!document.fullscreenElement) {
                container.requestFullscreen();
            } else {
                document.exitFullscreen();
            }
        },
    };

    const rotateLeftItem: ToolbarItem = {
        type: 'custom',
        id: 'fullscreen',
        icon: `<svg style="opacity: 0.66;"><path d="${mdiRotateLeft}" /></svg>`,
        title: 'Fullscreen',
        onPress: () => {
            if (!instance) {
                return;
            }

            instance.setViewState(viewState => viewState.rotateLeft());
        },
    };

    const rotateRightItem: ToolbarItem = {
        type: 'custom',
        id: 'fullscreen',
        icon: `<svg style="opacity: 0.66;"><path d="${mdiRotateRight}" /></svg>`,
        title: 'Fullscreen',
        onPress: () => {
            if (!instance) {
                return;
            }

            instance.setViewState(viewState => viewState.rotateRight());
        },
    };

    const parsingItem: ToolbarItem = {
        type: 'custom',
        id: 'parse',
        icon: `<svg style="opacity: 0.66;"><path d="${mdiAutoFix}" /></svg>`,
        title: 'Parse document',
        onPress: async () => {
            if (!instance) {
                return;
            }

            enableLoading(instance, 'parse');

            try {
                await onParseDocument?.();
            } finally {
                disableLoading(instance, parsingItem);
            }
        },
    };

    const toolbarItems: Array<ToolbarItem> = PSPDFKit.defaultToolbarItems.filter(el => {
        const enabledItems = [...enabledToolbarItems, ...(enableEditing ? editingToolbarItems : [])];

        return enabledItems.includes(el.type);
    });

    toolbarItems.push(rotateLeftItem, rotateRightItem);
    toolbarItems.push(fullscreenItem);

    if (!!onParseDocument) {
        toolbarItems.push(parsingItem);
    }

    instance = await PSPDFKit.load({
        // Container where PSPDFKit should be mounted.
        container,
        // The document to open.
        document: url,
        // Use the public directory URL as a base URL. PSPDFKit will download its library assets from here.
        baseUrl: `${window.location.protocol}//${window.location.host}/`,
        toolbarItems,
        licenseKey: isLocalhost ? process.env.REACT_APP_PDF_KEY_LOCALHOST : process.env.REACT_APP_PDF_KEY,
        styleSheets: ['/pdfstyles.css'],
        instantJSON:
            instantJSON?.format === 'https://pspdfkit.com/instant-json/v1'
                ? {
                      format: instantJSON.format,
                      annotations: instantJSON.annotations,
                  }
                : undefined,
    });

    return instance;
}

const loadingItem: ToolbarItem = {
    type: 'custom',
    id: 'loading',
    icon: `<svg style="opacity: 0.66; animation: spinner .6s linear infinite;"><path d="${mdiLoading}" /></svg>`,
    title: 'Loading',
    disabled: true,
};

function enableLoading(instance: Instance, id: string) {
    instance.setToolbarItems(items =>
        items.map(item => {
            if (item.id === id) {
                return loadingItem;
            }
            return item;
        }),
    );
}

function disableLoading(instance: Instance, currentItem: ToolbarItem) {
    instance.setToolbarItems(items =>
        items.map(item => {
            if (item.id === 'loading') {
                return currentItem;
            }
            return item;
        }),
    );
}

const getHighlightElement = ({
    width,
    height,
    overlay,
    onClick,
}: {
    width: number;
    height: number;
    overlay: OverlayMetadata;
    onClick?: (key: string) => void;
}): Node => {
    const overlayElement = document.createElement('div');
    const handleClick = () => {
        onClick?.(overlay.key);
    };
    overlayElement.addEventListener('click', handleClick);

    const block = <div className="overlay-container" style={{ width, height }} />;
    ReactDOM.render<Node>(block, overlayElement);

    return overlayElement;
};

const isLocalhost = Boolean(
    window.location.hostname === 'localhost' ||
        // [::1] is the IPv6 localhost address.
        window.location.hostname === '[::1]' ||
        // 127.0.0.1/8 is considered localhost for IPv4.
        window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/),
);

export default PDFViewer;
