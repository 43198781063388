import { mdiUndo } from '@mdi/js';
import { Icon } from '@mdi/react';
import { Tooltip } from '@mui/material';
import React from 'react';
import { TooltipTransitionComponent } from '../base';
import * as S from './undone-button.styles';

interface IUndoneButtonProps {
    count?: number;
    disabled?: boolean;
    onClick: () => void;
}
export const UndoneButton: React.FunctionComponent<IUndoneButtonProps> = (
    props: IUndoneButtonProps,
): React.ReactElement => {
    const { count, disabled, onClick } = props;
    const message = count ? `Move ${count} messages to Inbox` : 'Move to Inbox';
    return (
        <Tooltip title={<span>{message}</span>} TransitionComponent={TooltipTransitionComponent}>
            <div>
                <S.UndoneButton variant="outlined" onClick={onClick} disabled={disabled}>
                    <Icon path={mdiUndo} size={1} />
                </S.UndoneButton>
            </div>
        </Tooltip>
    );
};
