import { PageHeader, SideImageWrapper } from 'components';
import React from 'react';

export const NotFound: React.FunctionComponent = (): React.ReactElement => {
    return (
        <SideImageWrapper>
            <PageHeader title="404 Not found" instructionText="Sorry, the page you are looking for does not exist." />
        </SideImageWrapper>
    );
};
