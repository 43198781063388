import styled from '@emotion/styled/macro';
import { Icon } from '@mdi/react';
import { Popper } from '@mui/material';
import { forPhoneOnly, forTabletPortraitDown, Variables } from '../base';

export const WrappedPopper = styled(Popper)`
    position: absolute;
    z-index: 1301;
    min-height: 100px;
    ${({ anchorEl }) => {
        if (!anchorEl) {
            return;
        }
        const el = anchorEl as HTMLDivElement;
        const rect = el.getBoundingClientRect();

        const aw = rect.width;
        return `min-width: ${aw}px;`;
    }}

    box-shadow: ${Variables.Shadows.xlarge};
    background-color: ${Variables.Colors.white};

    --search-open-width: 600px;
    --search-closed-width: 480px;

    ${forTabletPortraitDown(`
        --search-open-width: 320px;
        --search-closed-width: 280px;
    `)}
    ${forPhoneOnly(`
        --search-open-width: 240px;
        --search-closed-width: 180px;
    `)}
`;

export const Body = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 0;
    overflow-y: auto;
    overflow-x: hidden;
    min-height: 220px;
    max-height: 520px;
    width: var(--search-open-width);
`;
export const Spacer = styled.div`
    flex-grow: 1;
`;
export const Related = styled.div`
    display: flex;
    align-items: center;
    padding: 20px 0;
    border-bottom: 1px solid ${Variables.Colors.nx4};
`;
export const RelatedIcon = styled(Icon)`
    margin: 0 12px;
    & {
        fill: ${Variables.Colors.nx24};
    }
`;
export const RelatedText = styled.div`
    line-height: 16px;
    flex-grow: 1;
    color: ${Variables.Colors.n100};
`;
export const Footer = styled.div`
    display: flex;
    align-items: center;
    height: 32px;
    padding: 0 8px;
    background-color: ${Variables.Colors.n5};
    border-radius: 0 0 ${Variables.Rounded.small} ${Variables.Rounded.small};
`;
export const FooterIcon = styled(Icon)`
    margin: 0 4px 0 8px;
    & {
        fill: ${Variables.Colors.nx24};
    }
`;

export const FooterText = styled.span`
    display: inline-flex;
    align-items: center;
    margin: 0 4px 0 0;
    color: ${Variables.Colors.nx24};

    & ${FooterIcon} {
        margin-left: 2px;
        margin-right: 0px;
    }
`;
