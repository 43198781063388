import {AuthApi, AuthSingleSignOnInfoResponse, CoreFetch, ToastContext} from '@enter/core';
import { PageHeader } from 'components';
import * as S from 'components/styles';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { handleFetch } from '../util';
import { IAuthorizeParams } from './authorize.models';

export const InvitationForm: React.FunctionComponent<IAuthorizeParams> = ({ invitation_code, sso_return, code}): React.ReactElement => {
    const [invitationEmail, setInvitationEmail] = useState<string>();
    const [invitationOrgName, setInvitationOrgName] = useState<string>();
    const [isLoading, setLoading] = useState(true);

    const [ssoUrl, setSsoUrl] = useState<string>();
    const [ssoEmail, setSsoEmail] = useState<string>();
    const [ssoRegistrationRequired, setSsoRegistrationRequired] = useState<boolean>(false);
    const [isSsoFlow, setSsoFlow] = useState<boolean>(false);
    const [hasLoadedSsoDetails, setHasLoadedSsoDetails] = useState<boolean>(false);

    const toastContext = useContext(ToastContext);
    const history = useHistory();
    const location = useLocation();

    const checkForSSOFlow = async (email: string, recaptchaToken: string): Promise<AuthSingleSignOnInfoResponse|undefined> => {
        return handleFetch(
            async () =>
                CoreFetch<AuthApi>(AuthApi).postSSOInfo({
                    authSingleSignOnInfoRequest: {
                        email,
                        token: recaptchaToken,
                    }
                }),
            (msg: string): void => {
                toastContext.notify && toastContext.notify(msg, 'left');
            });
    }

    useEffect(() => {
        // NB: wait for Google RECAPTCHA to load
        setTimeout(() => {
            const { grecaptcha } = window;
            if (grecaptcha && !hasLoadedSsoDetails) {
                grecaptcha.ready(async () => {
                    if (invitation_code) {
                        await grecaptcha.execute(process.env.REACT_APP_RECAPTCHA_KEY, {
                            action: 'get_invitation_info',
                        });
                        setLoading(true);
                        const details = await handleFetch(
                            async () =>
                                CoreFetch<AuthApi>(AuthApi).getAuthInvitationDetails({
                                    id: invitation_code,
                                }),
                            (msg: string): void => {
                                toastContext.notify && toastContext.notify(msg, 'left');
                            },
                        );
                        if (details) {
                            setInvitationOrgName(details.organizationName);
                            setInvitationEmail(details.email);

                            if(details.email) {
                                const recaptchaForSSO: string = await window.grecaptcha.execute(process.env.REACT_APP_RECAPTCHA_KEY, {
                                    action: 'check_sso_requirement',
                                });

                                const resp: AuthSingleSignOnInfoResponse|undefined = await checkForSSOFlow(details.email, recaptchaForSSO);
                                if(!resp) {
                                    setLoading(false);
                                    return;
                                }
                                setHasLoadedSsoDetails(true);
                                setSsoRegistrationRequired(!!resp.registrationRequired);
                                if(resp.sso && resp.url) {
                                    //store sso values and redirect
                                    setSsoEmail(details.email);
                                    setSsoUrl(resp.url);
                                    setSsoFlow(true);
                                } else {
                                    console.log("Org is not SSO");
                                    setSsoFlow(false);
                                    setSsoEmail("");
                                    setSsoUrl("");
                                }
                            }
                        }
                        setLoading(false);
                    }
                });
            }
        }, 500);
    }, [invitation_code, toastContext]);

    const getBoldInstructionText = () => (invitation_code && invitationEmail ? invitationEmail : undefined);
    const getInstructionText = () => {
        let instructionText = '';
        if (invitationOrgName) {
            if(isSsoFlow) {
                instructionText = `You've been invited to join ${invitationOrgName}. To accept the invitation, you can login through your organization's login provider below.`;
            } else {
                instructionText = `You've been invited to join ${invitationOrgName}. To accept the invitation, you can login to an existing account or create a new Enter account.`;
            }
        }
        return instructionText;
    };
    const handleInvitation = () => {
        history.push('/signup_invitation' + location.search);
    };
    const handleLogin = () => {
        let appendedLocationSearch = location.search;
        if (appendedLocationSearch.indexOf('skip_invitation_screen') === -1) {
            appendedLocationSearch = appendedLocationSearch + '&skip_invitation_screen=true';
        }
        history.push('/authorize' + appendedLocationSearch);
    };
    const handleSso = () => {
        window.localStorage.setItem('sso_params', location.search);
        if(ssoEmail) {
            window.localStorage.setItem('sso_email', ssoEmail);
        }
        window.localStorage.setItem('sso_registration_required', ssoRegistrationRequired.toString());
        if(ssoUrl) {
            window.location.href = ssoUrl;
        }
    }

    return (
        <div>
            <PageHeader
                title="Join Organization"
                boldInstructionText={getBoldInstructionText()}
                instructionText={getInstructionText()}
            />
            {isLoading && (
                <S.Centered>
                    <S.Progress />
                </S.Centered>
            )}
            {!isLoading && invitationEmail && invitationOrgName && !isSsoFlow && (
                <span>
                    <S.ButtonWrapper>
                        <S.Button variant="primary" disabled={isLoading} size="large" onClick={handleLogin}>
                            {isLoading ? <S.Progress size="24px" /> : 'Login to an existing account'}
                        </S.Button>
                    </S.ButtonWrapper>
                    <S.ButtonWrapper>
                        <S.Button variant="primary" disabled={isLoading} size="large" onClick={handleInvitation}>
                            {isLoading ? <S.Progress size="24px" /> : 'Register for a new account'}
                        </S.Button>
                    </S.ButtonWrapper>
                </span>
            )}
            {!isLoading && invitationEmail && invitationOrgName && isSsoFlow && (
                <span>
                    <S.ButtonWrapper>
                        <S.Button variant="primary" disabled={isLoading} size="large" onClick={handleSso}>
                            {isLoading ? <S.Progress size="24px" /> : 'Login to your organization'}
                        </S.Button>
                    </S.ButtonWrapper>
                </span>
            )}
        </div>
    );
};
