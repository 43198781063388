import React from 'react';
import * as S from './button-group.styles';

export interface IButtonGroupItemProps {
    label: string;
    disabled?: boolean;
}

export interface IButtonGroupProps {
    className?: string;
    buttons: IButtonGroupItemProps[];
    selected?: IButtonGroupItemProps;
    onSelect?: (selected: IButtonGroupItemProps) => void;
}

interface IButtonGroupState {
    selected: IButtonGroupItemProps;
}

export class ButtonGroup extends React.PureComponent<IButtonGroupProps, IButtonGroupState> {
    constructor(props: IButtonGroupProps) {
        super(props);
        this.state = {
            selected: this.props.selected || this.props.buttons[0],
        };
    }
    public componentDidUpdate(): void {
        if (this.props.selected && this.props.selected !== this.state.selected) {
            this.setState({
                selected: this.props.selected,
            });
        }
    }
    public render(): React.ReactElement {
        return <S.Container className={this.props.className}>{this.renderButtons()}</S.Container>;
    }
    private readonly renderButtons = (): JSX.Element[] => {
        return this.props.buttons.map((button: IButtonGroupItemProps) => (
            <S.Button
                key={button.label}
                onClick={this.changeSelected(button)}
                disabled={button.disabled}
                selected={this.state.selected.label === button.label}>
                {button.label}
            </S.Button>
        ));
    };

    private readonly changeSelected = (selected: IButtonGroupItemProps): (() => void) => {
        return (): void => {
            if (selected !== this.state.selected) {
                this.setState({
                    selected,
                });
                if (this.props.onSelect) {
                    this.props.onSelect(selected);
                }
            }
        };
    };
}
