import styled from '@emotion/styled/macro';
import { forPhoneOnly, forTabletLandscapeDown, Variables } from '../base';

export const sidePanelWidth = '445px';
export const SidePanel = styled.div`
    display: flex;
    flex-direction: column;

    position: absolute;
    top: 0;
    left: calc(100% - ${sidePanelWidth});
    right: 0;
    max-width: ${sidePanelWidth};
    width: ${sidePanelWidth};
    height: 100%;
    z-index: 10000;

    border: 0;
    background-color: ${Variables.Colors.white};
    box-shadow: ${Variables.Shadows.small}, ${Variables.Shadows.border};
    overflow: hidden;

    &.fixed {
        position: fixed;
    }

    ${forTabletLandscapeDown(`
        top: ${Variables.topnavSize};
        height: calc(100% - ${Variables.topnavSize});
        width: 100%;
    `)};

    ${forPhoneOnly(`
        position: fixed;
        left: 0;
        max-width: unset;
    `)}
`;
