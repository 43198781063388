import dateFns from 'date-fns';
import _ from 'lodash';
import React from 'react';
import {
    AuthorizationRequiredType,
    ClaimEligibilityItem,
    ClaimEligibilityItemBeneficiary,
    EligibilityItemType,
    InPlanNetworkType,
    ServiceType,
} from '../../../api';
import { Badge } from '../../badge';
import { BadgeType } from '../../badge/badge.styles';
import { BaseButton, isSupport } from '../../base';
import {
    renderAddressGroup,
    renderAmount,
    renderBoolean,
    renderEmpty,
    renderInt,
    renderPercentage,
    renderString,
} from '../../data-table';
import * as S from './eligibility.styles';
import { normalize } from './utils';

export const Beneficiaries: React.FunctionComponent<{ beneficiaries: ClaimEligibilityItemBeneficiary[] }> = React.memo(
    ({ beneficiaries }) => {
        return (
            <div>
                {beneficiaries.map((value, i) => (
                    <div key={i}>
                        <S.Table>
                            <S.TBody>
                                {value.type && (
                                    <S.TR>
                                        <S.TH>{normalize(value.type)}</S.TH>
                                        <S.TD>{_.compact([value.first, value.middle, value.last]).join(' ')}</S.TD>
                                    </S.TR>
                                )}
                                {value.email && (
                                    <S.TR>
                                        <S.TH>Email</S.TH>
                                        <S.TD>{value.email}</S.TD>
                                    </S.TR>
                                )}
                                {value.phone && (
                                    <S.TR>
                                        <S.TH>Phone</S.TH>
                                        <S.TD>{value.phone}</S.TD>
                                    </S.TR>
                                )}
                                {value.workPhone && (
                                    <S.TR>
                                        <S.TH>Work Phone</S.TH>
                                        <S.TD>{value.workPhone}</S.TD>
                                    </S.TR>
                                )}
                                {value.fax && (
                                    <S.TR>
                                        <S.TH>Fax</S.TH>
                                        <S.TD>{value.fax}</S.TD>
                                    </S.TR>
                                )}
                                {value.providerType && (
                                    <S.TR>
                                        <S.TH>Provider</S.TH>
                                        <S.TD>{value.providerType}</S.TD>
                                    </S.TR>
                                )}
                            </S.TBody>
                        </S.Table>
                        {!!value.address && renderAddressGroup([value.address])}
                    </div>
                ))}
            </div>
        );
    },
);

type ElibilityServiceType = ServiceType | typeof EMPTY_SERVICE_GROUP;

const EMPTY_SERVICE_GROUP = 'NO_SERVICE_CODE';

export const EligibilityItemsDetails: React.FunctionComponent<{ items: ClaimEligibilityItem[] }> = React.memo(
    ({ items }) => {
        const groups = new Map<ElibilityServiceType, ClaimEligibilityItem[]>();
        items.forEach(item => {
            if (!item.serviceTypeCodes?.length) {
                groups.set(EMPTY_SERVICE_GROUP, [...(groups.get(EMPTY_SERVICE_GROUP) || []), item]);
                return;
            }

            item.serviceTypeCodes.forEach(code => {
                groups.set(code, [...(groups.get(code) || []), item]);
            });
        });

        const entries = groups.entries();

        const sortedEntries = [...entries].sort(([key1], [key2]) => {
            // Put HEALTHBENEFITPLANCOVERAGE on the first place
            if (key1 === ServiceType.HEALTHBENEFITPLANCOVERAGE) {
                return -1;
            }

            if (key2 === ServiceType.HEALTHBENEFITPLANCOVERAGE) {
                return 1;
            }

            return key1 > key2 ? 1 : -1;
        });

        return <EligibilityTreeView groups={sortedEntries} />;
    },
);

export const TableDetails: React.FunctionComponent<{ values: ClaimEligibilityItem[] }> = ({ values }) => {
    return (
        <S.Table>
            <S.THead>
                <S.TR>
                    <S.TH>Type</S.TH>
                    <S.TH>Benefit Amount</S.TH>
                    <S.TH>Benefit Percentage</S.TH>
                    <S.TH>Auth required</S.TH>
                    <S.TH>Messages</S.TH>
                </S.TR>
            </S.THead>
            <S.TBody>
                {_.sortBy(values, 'type').map((value, i) => {
                    return (
                        <S.TR key={i}>
                            <S.TD>{normalize(value.type)}</S.TD>
                            {value.benefitAmount ? (
                                <S.TD align="right">{renderAmount(value.benefitAmount)}</S.TD>
                            ) : (
                                <S.TD>{renderEmpty()}</S.TD>
                            )}
                            {!!value.benefitPercentage ? (
                                <S.TD align="right">{renderPercentage(value.benefitPercentage)}</S.TD>
                            ) : (
                                <S.TD>{renderEmpty()}</S.TD>
                            )}
                            <S.TD>
                                {value.authorizationRequiredType === AuthorizationRequiredType.YES
                                    ? renderBoolean(true)
                                    : renderString(value.authorizationRequiredType)}
                            </S.TD>
                            <S.TD>
                                {!!value.messages ? <S.Text>{value.messages.join('\n')}</S.Text> : renderEmpty()}
                            </S.TD>
                        </S.TR>
                    );
                })}
            </S.TBody>
        </S.Table>
    );
};

const ServiceSummary: React.FunctionComponent<{ values: ClaimEligibilityItem[] }> = ({ values }) => {
    const renderStopLoss = () => {
        const stopLosses = values.filter(value => value.type === EligibilityItemType.STOPLOSS && value.benefitAmount);
        if (stopLosses.length === 0) {
            return;
        }

        const highest = _.last(_.sortBy(stopLosses, value => value.benefitAmount?.amount));
        return <S.SummaryItem>🛑 {renderAmount(highest?.benefitAmount)}</S.SummaryItem>;
    };
    const renderDeductible = () => {
        const deductibles = values.filter(
            value => value.type === EligibilityItemType.DEDUCTIBLE && value.benefitAmount,
        );
        if (deductibles.length === 0) {
            return;
        }

        const highest = _.last(_.sortBy(deductibles, value => value.benefitAmount?.amount));
        return <S.SummaryItem>⛔️ {renderAmount(highest?.benefitAmount)}</S.SummaryItem>;
    };
    const renderCopayment = () => {
        const copayments = values.filter(value => value.type === EligibilityItemType.COPAYMENT && value.benefitAmount);
        if (copayments.length === 0) {
            return;
        }

        const highest = _.last(_.sortBy(copayments, value => value.benefitAmount?.amount));
        return <S.SummaryItem>💰 {renderAmount(highest?.benefitAmount)}</S.SummaryItem>;
    };
    const renderCoinsurance = () => {
        const coinsurances = values.filter(
            value => value.type === EligibilityItemType.COINSURANCE && value.benefitAmount,
        );
        if (coinsurances.length === 0) {
            return;
        }

        const highest = _.last(_.sortBy(coinsurances, value => value.benefitAmount?.amount));
        return <S.SummaryItem>✂️ {renderAmount(highest?.benefitAmount)}</S.SummaryItem>;
    };

    const summary = _.compact([renderStopLoss(), renderDeductible(), renderCopayment(), renderCoinsurance()]);

    return <S.ServiceDetails>{summary}</S.ServiceDetails>;
};

const InNetworkMap: Record<InPlanNetworkType, string> = {
    [InPlanNetworkType.NA]: 'Network Not Applicable',
    [InPlanNetworkType.UNKNOWN]: 'Network Unknown',
    [InPlanNetworkType.YES]: 'In Network',
    [InPlanNetworkType.NO]: 'Out Of Network',
};

const InNetworkBadgeColors: Record<InPlanNetworkType, BadgeType | undefined> = {
    [InPlanNetworkType.NA]: undefined,
    [InPlanNetworkType.UNKNOWN]: BadgeType.ORANGE,
    [InPlanNetworkType.YES]: BadgeType.BLUE,
    [InPlanNetworkType.NO]: BadgeType.YELLOW,
};

const ServiceBadges: React.FunctionComponent<{ item: ClaimEligibilityItem }> = ({ item }) => {
    const renderCoverageLevel = () => {
        if (!item.coverageLevelType) {
            return;
        }

        return <Badge>{normalize(item.coverageLevelType)}</Badge>;
    };

    const renderInNetwork = () => {
        if (!item.inPlanNetworkType) {
            return;
        }

        const inNetworkLabel = InNetworkMap[item.inPlanNetworkType];

        return <Badge type={InNetworkBadgeColors[item.inPlanNetworkType]}>{normalize(inNetworkLabel)}</Badge>;
    };

    const renderActiveCoverage = () => {
        if (item.type !== EligibilityItemType.ACTIVECOVERAGE) {
            return;
        }

        return <Badge type={BadgeType.GREEN}>{normalize(item.type)}</Badge>;
    };

    const inNetwork = renderInNetwork();
    const coverageLevel = renderCoverageLevel();
    const activeCoverage = renderActiveCoverage();

    return (
        <S.BadgesWrapper>
            {!!activeCoverage && <S.ServiceBadge>{renderActiveCoverage()}</S.ServiceBadge>}
            {!!inNetwork && <S.ServiceBadge>{inNetwork}</S.ServiceBadge>}
            {!!coverageLevel && <S.ServiceBadge>{renderCoverageLevel()}</S.ServiceBadge>}
        </S.BadgesWrapper>
    );
};

const EligibilityTreeView = React.memo<{ groups: [ElibilityServiceType, ClaimEligibilityItem[]][] }>(({ groups }) => {
    // ElibilityServiceType: HTMLElement
    const refs = React.useRef<Record<string, HTMLElement>>({});
    const containerRef = React.useRef<HTMLElement | null>(null);

    const handleScrollTo = (key: string) => {
        const container = containerRef.current;
        if (!container) {
            return;
        }

        // We need to use different scrolling behaviour because of the difference betweeen header positioning
        isSupport
            ? window.scrollTo({ top: container.getBoundingClientRect().top + window.scrollY - 80, behavior: 'smooth' })
            : container.scrollIntoView();

        const item = refs.current[key];
        const topPos = item.offsetTop - (container.offsetTop || 0);
        container.scrollTop = topPos;
    };

    const renderSidebarView = () => {
        return (
            <S.TreeContainer>
                {groups.map(([key, values]) => {
                    const groupValues = _.groupBy(values, (value: ClaimEligibilityItem) =>
                        _.compact([value.type, value.coverageDescription]).join(' '),
                    );
                    const entries = _.filter(
                        _.sortBy(Object.entries(groupValues), ([key]) => key),
                        ([itemKey]) => !itemKey.match(EligibilityItemType.ACTIVECOVERAGE),
                    );
                    return (
                        <S.TreeItem key={key}>
                            <S.TreeContent>
                                <BaseButton variant="text" onClick={() => handleScrollTo(key)}>
                                    <S.TreeTitle>{normalize(key)}</S.TreeTitle>
                                </BaseButton>
                            </S.TreeContent>
                            <S.TreeItemGroup>
                                {entries.map(([itemKey, items]) => {
                                    const highestAmountItem = _.last(
                                        _.sortBy(items, el => _.toNumber(el.benefitAmount?.amount)),
                                    );
                                    const highestPercentItem = _.last(
                                        _.sortBy(items, el => _.toNumber(el.benefitPercentage)),
                                    );

                                    const amount = highestAmountItem?.benefitAmount
                                        ? renderAmount(highestAmountItem.benefitAmount)
                                        : undefined;
                                    const percentage = highestPercentItem?.benefitPercentage
                                        ? renderPercentage(highestPercentItem.benefitPercentage)
                                        : undefined;

                                    return (
                                        <S.TreeItem key={itemKey}>
                                            <S.TreeContent>
                                                <BaseButton
                                                    variant="text"
                                                    onClick={() => handleScrollTo(key + itemKey)}>
                                                    {normalize(itemKey)}
                                                    <S.TreeContentAmount>
                                                        {amount}
                                                        {percentage}
                                                    </S.TreeContentAmount>
                                                </BaseButton>
                                            </S.TreeContent>
                                        </S.TreeItem>
                                    );
                                })}
                            </S.TreeItemGroup>
                        </S.TreeItem>
                    );
                })}
            </S.TreeContainer>
        );
    };

    const renderGroupDetails = (item: ClaimEligibilityItem, isActiveCoverage: boolean = false) => {
        return (
            <S.GroupDetails>
                {!!item?.planType && (
                    <S.LabelRow>
                        <S.Label>Insurance Type</S.Label>{' '}
                        <S.Value>
                            {/* HMO, PPO, POS, etc */}
                            {item.planType.length > 3 ? normalize(item.planType) : item.planType}
                        </S.Value>
                    </S.LabelRow>
                )}
                <S.LabelRow>
                    <S.Label>{isActiveCoverage ? 'Active Coverage ' : null}Plan / Product</S.Label>{' '}
                    <S.Value>
                        {item.coverageDescription ? item.coverageDescription : <S.EmptyLabel>Empty</S.EmptyLabel>}
                    </S.Value>
                </S.LabelRow>
                {!!item?.eligibilityFromDate && (
                    <S.LabelRow>
                        <S.Label>From</S.Label>{' '}
                        <S.Value>{dateFns.format(item.eligibilityFromDate, 'MM/DD/YYYY')}</S.Value>
                    </S.LabelRow>
                )}
                {!!item?.eligibilityThroughDate && (
                    <S.LabelRow>
                        <S.Label>To</S.Label>{' '}
                        <S.Value>{dateFns.format(item.eligibilityThroughDate, 'MM/DD/YYYY')}</S.Value>
                    </S.LabelRow>
                )}
            </S.GroupDetails>
        );
    };

    const renderContentView = () => {
        return (
            <S.ContentViewContainer ref={containerRef}>
                {groups.map(([key, values]) => {
                    const groupValues = _.groupBy(values, (value: ClaimEligibilityItem) =>
                        _.compact([value.type, value.coverageDescription]).join(' '),
                    );
                    const entries = _.filter(
                        _.sortBy(Object.entries(groupValues), ([key]) => key),
                        ([itemKey]) => !itemKey.match(EligibilityItemType.ACTIVECOVERAGE),
                    );
                    const activeCoverage = _.first(
                        values.filter(value => value.type?.match(EligibilityItemType.ACTIVECOVERAGE)),
                    );

                    return (
                        <S.ContentGroup key={key}>
                            <S.ContentTitle ref={element => element && (refs.current[key] = element)}>
                                <div>{normalize(key)}</div>
                                {!!activeCoverage && (
                                    <S.ContentTitleBadges>
                                        <ServiceBadges item={activeCoverage} />
                                    </S.ContentTitleBadges>
                                )}
                            </S.ContentTitle>
                            {!!activeCoverage && renderGroupDetails(activeCoverage, true)}
                            <S.GroupItems>
                                {entries.map(([itemKey, items], i) => {
                                    const firstItem = _.first(items);

                                    let title = firstItem ? normalize(firstItem.type) : null;

                                    if (itemKey === EligibilityItemType.STOPLOSS) {
                                        title = 'Out of Pocket (Stop Loss)';
                                    }

                                    return (
                                        <div key={i}>
                                            {!!firstItem && renderGroupDetails(firstItem)}
                                            <S.BeneficiariesContainer>
                                                <Beneficiaries beneficiaries={firstItem?.beneficiaries || []} />
                                            </S.BeneficiariesContainer>
                                            <S.GroupItemContainer
                                                stretch={entries.length % 2 === 1 && i === entries.length - 1}>
                                                <S.GroupItemTitle
                                                    ref={element => element && (refs.current[key + itemKey] = element)}>
                                                    {title}
                                                </S.GroupItemTitle>
                                                <EligibilityGroupItems items={items} key={itemKey} />
                                            </S.GroupItemContainer>
                                        </div>
                                    );
                                })}
                            </S.GroupItems>
                        </S.ContentGroup>
                    );
                })}
            </S.ContentViewContainer>
        );
    };

    return (
        <S.TreeViewWrapper>
            {renderSidebarView()}
            {renderContentView()}
        </S.TreeViewWrapper>
    );
});

const EligibilityGroupItems: React.FunctionComponent<{ items: ClaimEligibilityItem[] }> = ({ items }) => {
    return (
        <>
            {_.orderBy(items, ['inPlanNetworkType'], ['desc']).map((item, i) => {
                const amount = item.benefitAmount ? renderAmount(item.benefitAmount) : undefined;
                const quantity = item.quantity ? renderInt(item.quantity) : undefined;
                const percentage = item.benefitPercentage ? renderPercentage(item.benefitPercentage) : undefined;

                const { quantityType } = item;

                return (
                    <S.GroupItem key={i}>
                        <S.GroupItemDetails>
                            <ServiceBadges item={item} />
                            {!!item.messages && (
                                <S.DetailsList>
                                    {item.messages.map((el, i) => (
                                        <li key={i}>
                                            <S.MessageItem>{el}</S.MessageItem>
                                        </li>
                                    ))}
                                    {item.deliveries &&
                                        item.deliveries?.length > 0 &&
                                        item.deliveries.map((el, i) => (
                                            <li key={i}>
                                                <S.MessageItem>{`${_.toNumber(el.quantity)} ${_.toLower(
                                                    normalize(el.quantityType),
                                                )} ${_.toLower(normalize(el.timePeriodType))}`}</S.MessageItem>
                                            </li>
                                        ))}
                                </S.DetailsList>
                            )}
                        </S.GroupItemDetails>

                        <S.GroupItemAmounts>
                            {!!quantity && quantityType && (
                                <S.Number>
                                    {quantity}{' '}
                                    {item.timePeriodType ? (
                                        <S.NumberDescription>{normalize(item.timePeriodType)}</S.NumberDescription>
                                    ) : undefined}
                                </S.Number>
                            )}
                            {!!amount && (
                                <S.Number>
                                    {amount}{' '}
                                    {item.timePeriodType ? (
                                        <S.NumberDescription>{normalize(item.timePeriodType)}</S.NumberDescription>
                                    ) : undefined}
                                </S.Number>
                            )}
                            {!!percentage && (
                                <S.Number>
                                    {percentage}{' '}
                                    {item.timePeriodType ? (
                                        <S.NumberDescription>{normalize(item.timePeriodType)}</S.NumberDescription>
                                    ) : undefined}
                                </S.Number>
                            )}
                        </S.GroupItemAmounts>
                    </S.GroupItem>
                );
            })}
        </>
    );
};
