import styled from '@emotion/styled/macro';
import { Icon } from '@mdi/react';
import { Tooltip, Variables } from '../base';

export const VisitLink = styled.a`
    display: inline-flex;
    overflow: hidden;
    color: ${Variables.Colors.b300_l62};
    flex-grow: 1;
`;

export const VisitTooltip = styled(Tooltip)`
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    padding: 6px;
    cursor: pointer;
`;

export const ActionSymbol = styled.span`
    display: inline-flex;
    height: 18px;
    width: 18px;
    align-items: center;
    margin-right: 4px;
    font-size: 18px;
    vertical-align: middle;
`;

export const ActionIcon = styled(Icon)`
    height: 18px;
    width: 18px;
    fill: ${Variables.Colors.b300_l62};
`;

export const ActionButton = styled.div`
    display: inline-flex;
    border-left: 1px solid ${Variables.Colors.b300_l90};
`;

export const ActionTooltip = styled(Tooltip)`
    padding: 4px;
    display: inline-flex;
    align-items: center;
    cursor: pointer;
`;

export const Badge = styled.div`
    display: inline-flex;
    align-items: center;
    align-self: baseline;

    padding: 0;
    background-color: ${Variables.Colors.b300_l96};
    border: 1px solid ${Variables.Colors.b300_l90};
    border-radius: 3px;
    overflow: hidden;

    font-size: 14px;
    letter-spacing: 0.2px;
    color: ${Variables.Colors.p500};

    &:hover {
        cursor: pointer;
        border-color: ${Variables.Colors.b300_l76};

        ${ActionButton} {
            border-color: ${Variables.Colors.b300_l76};
        }
        ${ActionIcon} {
            fill: ${Variables.Colors.b300};
        }
    }
`;
