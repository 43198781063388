import styled from '@emotion/styled/macro';
import { Variables } from '../base';

interface IClaimProps {
    selected?: boolean;
}
export const selectedClaim = (props: IClaimProps): string => {
    return props.selected ? Variables.Colors.n15_24 : Variables.Colors.white;
};

export const Claim = styled.tr<IClaimProps>`
    cursor: pointer;
    background-color: ${selectedClaim};

    td {
        vertical-align: middle;
    }

    &:hover td {
        background-color: ${Variables.Colors.n15_24};
    }
    &:active td {
        background-color: ${Variables.Colors.n15_48};
    }
`;
export const ClaimLink = styled.a`
    display: block;
    color: ${Variables.Colors.n50};
    line-height: 18px;
    padding: 11px 0;
`;
export const ClaimIcon = styled.td`
    width: 56px;
    padding: 6px 16px;
    svg {
        fill: ${Variables.Colors.n30};
    }

    ${ClaimLink} {
        padding: 0;
    }
`;
export const ClaimServiceDate = styled.td`
    font-size: 14px;
    border-top: ${Variables.Borders.regular};
    min-width: 15%;
`;
export const ClaimStatus = styled.td`
    font-size: 12px;
    border-top: ${Variables.Borders.regular};
`;
export const ClaimPayer = styled.td`
    font-size: 14px;
    border-top: ${Variables.Borders.regular};
    min-width: 30%;
`;
export const ClaimTotal = styled.td`
    font-size: 16px;
    border-top: ${Variables.Borders.regular};

    ${ClaimLink} {
        color: ${Variables.Colors.n100};
    }
`;
