import React from 'react';
import ContentLoader from 'react-content-loader';
import * as S from './placeholder.styles';

export const Placeholder = React.memo(() => {
    return (
        <S.PlaceholderContent>
            <ContentLoader speed={2} height={200} style={{ width: '100%' }} preserveAspectRatio="">
                <rect x="0" y="0" rx="0" ry="0" width="100%" height="20" />
                <rect x="0" y="40" rx="0" ry="0" width="80%" height="20" />
                <rect x="0" y="80" rx="0" ry="0" width="95%" height="20" />
                <rect x="0" y="120" rx="0" ry="0" width="75%" height="20" />
                <rect x="0" y="160" rx="0" ry="0" width="90%" height="20" />
            </ContentLoader>
        </S.PlaceholderContent>
    );
});
