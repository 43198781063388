import { Location, LocationDescriptor, LocationDescriptorObject } from 'history';
import * as React from 'react';
import { NavLink, NavLinkProps, Route, useRouteMatch } from 'react-router-dom';

interface IChildrenProps {
    active: boolean;
}

export interface IBaseNavLinkProps extends NavLinkProps {
    children: (props: IChildrenProps) => React.ReactNode;
    to: string | LocationDescriptorObject<any> | ((location: Location<any>) => LocationDescriptor<any>);
    // Property for the css style which will make the cursor unset for child elements;
    disabled?: boolean;
}

export const BaseNavLink: React.FunctionComponent<IBaseNavLinkProps> = navProps => {
    const { children, disabled, ...linkProps } = navProps;
    const match = useRouteMatch(String(linkProps.to));
    const hasMatch = !!match;
    const matchesExact = linkProps.exact && match?.isExact;
    const isNotExact = !linkProps.exact;
    return (
        <NavLink {...linkProps} style={{ pointerEvents: disabled ? 'none' : 'auto' }}>
            <Route>
                {children({
                    active: hasMatch && (matchesExact || isNotExact),
                })}
            </Route>
        </NavLink>
    );
};
