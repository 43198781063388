import styled from '@emotion/styled/macro';
import { DialogContent, DialogContentProps } from '@mui/material';
import { Variables } from '../variables';

export const DialogTitle = styled.p`
    margin: 0;
    margin: 0;
    font-size: 24px;
    font-weight: 600;
    color: ${Variables.Colors.n100};
    white-space: pre-wrap;
`;

export const ActionsContainer = styled.section`
    width: 100%;
    display: flex;
`;

export const StyledDialogContent = styled(DialogContent)`
    display: flex;
    overflow: hidden;
    min-width: 600px;
    /* Rewrite material style */
    padding: 0 !important;
` as React.ComponentType<DialogContentProps>;
