import { css } from '@emotion/react/macro';
import styled from '@emotion/styled/macro';
import { Variables } from '../variables';
import { TooltipDirection, TooltipSize, TooltipTheme } from './tooltip';

interface IEntityProps {
    direction: TooltipDirection;
    size?: TooltipSize;
    variant?: TooltipTheme;
    anchorRect: ClientRect;
    viewportRect: ClientRect;
}

const lgWidth = 600;
const mdWidth = 332;
const padding = 16;
const arrowOffset = 4;
const arrowSize = 10;
const arrowPosition = 20;
const arrowDiagonal = 14; // ~= sqrt(10^2 + 10^2)
const calcWidth = (props: IEntityProps): number => (props.size === 'large' ? lgWidth : mdWidth);

const tooltipBase = () => css`
    position: absolute;

    display: flex;
    opacity: 1;
    z-index: 1302;

    margin: 0;
    padding: ${padding}px;

    color: var(--tooltip-color);
    background-color: var(--tooltip-color-bg);

    font-size: 14px;
    letter-spacing: 0.2px;

    border-radius: var(--tooltip-border-radius);
    box-shadow: var(--tooltip-box-shadow);
`;

const lightTooltip = css`
    --tooltip-color: ${Variables.Colors.n100};
    --tooltip-color-bg: ${Variables.Colors.white};
    --tooltip-color-border: ${Variables.Colors.nx4};
    --tooltip-box-shadow: ${Variables.Shadows.medium}, ${Variables.Shadows.border};
    --tooltip-border-radius: ${Variables.Rounded.small};
    --tooltip-content: ' ';
`;

const darkTooltip = css`
    --tooltip-color: ${Variables.Colors.white};
    --tooltip-color-bg: ${Variables.Colors.nx100};
    --tooltip-color-border: ${Variables.Colors.white};
    --tooltip-border-radius: ${Variables.Rounded.small};
    --tooltip-box-shadow: ${Variables.Shadows.medium};
    --tooltip-content: ' ';
`;

const equationTooltip = css`
    --tooltip-color: ${Variables.Colors.white};
    --tooltip-color-bg: ${Variables.Colors.nx100};
    --tooltip-color-border: ${Variables.Colors.white};
    --tooltip-border-radius: ${Variables.Rounded.small};
    --tooltip-box-shadow: ${Variables.Shadows.medium};
    --tooltip-content: unset;
`;

const arrowTooltip = `
    position: absolute;
    height: ${arrowDiagonal}px;
    width: ${arrowDiagonal}px;
    content: var(--tooltip-content);
    box-shadow: 0 0 24px 0 ${Variables.Colors.nx8};
    border: 1px solid ${Variables.Colors.nx8};
    border-radius: ${Variables.Rounded.xsmall};
    background-color: var(--tooltip-color-bg);
    transform: rotate(45deg);
`;

const topTooltip = (props: IEntityProps) => css`
    transform: translate(-50%, calc(-100% - ${arrowSize + arrowOffset}px));
    &:before {
        ${arrowTooltip}
        clip-path: polygon(0 18px, 18px 18px, 18px 0);
        left: calc(50% - ${arrowDiagonal}px / 2);
        bottom: ${-arrowDiagonal / 2 + 1}px;
    }
`;
const topLeftTooltip = (props: IEntityProps) => {
    const maxWidth = calcWidth(props);
    return css`
        margin-left: -${maxWidth}px;
        transform: translate(
            calc(${maxWidth}px - 100% + ${2 * (arrowSize + arrowOffset)}px),
            calc(-100% - ${arrowSize}px)
        );
        &:before {
            ${arrowTooltip}
            clip-path: polygon(0 18px, 18px 18px, 18px 0);
            right: ${arrowPosition}px;
            bottom: ${-arrowDiagonal / 2 + 1}px;
        }
    `;
};

const topRightTooltip = () => css`
    transform: translate(${-2 * (arrowSize + arrowOffset)}px, calc(-100% - ${arrowSize + arrowOffset}px));
    &:before {
        ${arrowTooltip}
        clip-path: polygon(0 18px, 18px 18px, 18px 0);
        left: ${arrowPosition}px;
        bottom: ${-arrowDiagonal / 2 + 1}px;
    }
`;

const rightTooltip = (props: IEntityProps) => css`
    transform: translate(${arrowSize + arrowOffset * 2}px, ${-arrowSize - arrowOffset}px);
    &:before {
        ${arrowTooltip}
        clip-path: polygon(18px 18px, -3px -3px, -3px 18px);
        left: ${-arrowDiagonal / 2 - 1}px;
        top: 16px;
    }
`;
const bottomTooltip = (props: IEntityProps) => css`
    transform: translate(-50%, ${arrowSize + arrowOffset}px);
    &:before {
        ${arrowTooltip}
        clip-path: polygon(-3px -3px, -3px 18px, 18px -3px);
        left: calc(50% - ${arrowDiagonal / 2}px);
        top: ${-arrowDiagonal / 2}px;
    }
`;
const bottomLeftTooltip = (props: IEntityProps) => {
    const maxWidth = calcWidth(props);
    return css`
        margin-left: -${maxWidth}px;
        transform: translate(
            calc(${maxWidth}px - 100% + ${2 * (arrowSize + arrowOffset)}px),
            ${arrowSize + arrowOffset}px
        );
        &:before {
            ${arrowTooltip}
            clip-path: polygon(-3px -3px, -3px 18px, 18px -3px);
            right: ${arrowPosition}px;
            top: ${-arrowDiagonal / 2}px;
        }
    `;
};

const bottomRightTooltip = () => css`
    transform: translate(${-2 * (arrowSize + arrowOffset)}px, ${arrowSize + arrowOffset}px);
    &:before {
        ${arrowTooltip}
        clip-path: polygon(-3px -3px, -3px 18px, 18px -3px);
        left: ${arrowPosition}px;
        top: ${-arrowDiagonal / 2}px;
    }
`;

const leftTooltip = (props: IEntityProps) => {
    const maxWidth = calcWidth(props);
    return css`
        margin-left: -${maxWidth}px;
        transform: translate(
            calc(${maxWidth}px - 100% - ${arrowSize + arrowOffset * 2}px),
            ${-arrowSize - arrowOffset}px
        );
        &:before {
            ${arrowTooltip}
            clip-path: polygon(0 0, 18px 18px, 18px 0);
            right: ${-arrowDiagonal / 2 - 1}px;
            top: 16px;
        }
    `;
};

const noTooltip = css``;

export const Entity = styled.div<IEntityProps>`
    ${tooltipBase}

    ${(props: IEntityProps): string => {
        const vw = props.viewportRect.width;
        const aw = props.anchorRect.width;
        const ax = props.anchorRect.left;

        const maxWidth = calcWidth(props);
        let constrainedMax = maxWidth;
        switch (props.direction) {
            case 'left':
                constrainedMax = Math.min(maxWidth, ax - padding - arrowSize);
                break;
            case 'right':
                constrainedMax = Math.min(maxWidth, vw - aw - ax - padding - arrowSize);
                break;
            default:
                break;
        }
        return `max-width: ${constrainedMax}px;`;
    }}

    ${(props: IEntityProps): string => {
        const vx = props.viewportRect.left;
        const vy = props.viewportRect.top;
        const ah = props.anchorRect.height;
        const aw = props.anchorRect.width;
        const ax = props.anchorRect.left;
        const ay = props.anchorRect.top;
        switch (props.direction) {
            case 'top':
                return `
                    left: ${-vx + ax + aw / 2}px;
                    top: ${-vy + ay}px;
                `;
            case 'left':
                return `
                    left: ${-vx + ax}px;
                    top: ${-vy + ay}px;
                `;
            case 'bottom':
                return `
                    left: ${-vx + ax + aw / 2}px;
                    top: ${-vy + ay + ah}px;
                `;
            case 'right':
                return `
                    left: ${-vx + ax + aw}px;
                    top: ${-vy + ay}px;
                `;
            default:
                return '';
        }
    }}

    ${(props: IEntityProps) => {
        switch (props.variant) {
            case 'equation':
                return equationTooltip;
            case 'dark':
                return darkTooltip;
            default:
            case 'light':
                return lightTooltip;
        }
    }}

    ${(props: IEntityProps) => {
        const maxWidth = calcWidth(props);
        const halfWidth = maxWidth / 2;
        const vw = props.viewportRect.width;
        const ax = props.anchorRect.left;
        let position = 'center';
        if (ax - halfWidth < 0 && ax + maxWidth < vw) {
            position = 'right';
        } else if (ax + halfWidth > vw && ax - maxWidth > 0) {
            position = 'left';
        }
        switch (props.direction) {
            case 'left':
                return leftTooltip;
            case 'top':
                if (position === 'right') {
                    return topRightTooltip;
                } else if (position === 'left') {
                    return topLeftTooltip;
                } else {
                    return topTooltip;
                }
            case 'right':
                return rightTooltip;
            case 'bottom':
                if (position === 'right') {
                    return bottomRightTooltip;
                } else if (position === 'left') {
                    return bottomLeftTooltip;
                } else {
                    return bottomTooltip;
                }
            default:
                return noTooltip;
        }
    }}
`;
