import * as React from 'react';
import * as S from './radio.styles';

export interface IBaseRadioProps extends React.PropsWithoutRef<JSX.IntrinsicElements['input']> {
    label?: string;
    type?: 'radio';
}

export class BaseRadio extends React.Component<IBaseRadioProps> {
    public render(): React.ReactElement {
        const { className, checked, label, disabled, ...props } = this.props;

        return (
            <S.RadioContainer className={className} checked={checked} disabled={disabled}>
                <S.HiddenRadio checked={checked} disabled={disabled} {...props} />
                <S.StyledRadio>
                    <S.CheckIcon />
                </S.StyledRadio>
                {!!label && <S.Label>{label}</S.Label>}
            </S.RadioContainer>
        );
    }
}
