import { mdiAlertCircle, mdiCheckCircle } from '@mdi/js';
import * as React from 'react';
import * as S from './file-uploader.styles';

export type IBaseFileUploaderProps = React.PropsWithoutRef<JSX.IntrinsicElements['input']> & {
    successMessage?: string;
    errorMessage?: string;
    /**
     * If property is passed, then we show remove button
     * on the right
     */
    onDelete?: () => void;
    value: File | null;
};

export class BaseFileUploader extends React.Component<IBaseFileUploaderProps> {
    private realInputRef: React.RefObject<HTMLInputElement>;
    constructor(props: IBaseFileUploaderProps) {
        super(props);
        this.realInputRef = React.createRef<HTMLInputElement>();
    }

    public render(): React.ReactElement {
        const { successMessage, errorMessage, onDelete, required, ...props } = this.props;

        return (
            <S.FileUploaderContainer>
                <S.FileUploaderWrapper>
                    <S.Button variant="primary" type="button" onClick={this.handleClick}>
                        {props.placeholder}
                    </S.Button>
                    <S.FileUploader
                        ref={this.realInputRef}
                        {...props}
                        // Prevent set null or undefined
                        type="file"
                        value=""
                        showSuccess={!!successMessage}
                        showError={!!errorMessage}
                        showDelete={!!onDelete}
                    />
                    {props.value && (
                        <S.Label>
                            {/* Remove C:\fakepath\ from value */}
                            {props.value.name.split(/\\|\//).pop()}
                        </S.Label>
                    )}
                </S.FileUploaderWrapper>

                {successMessage && (
                    <S.FileUploaderBottomSuccess>
                        <S.FileUploaderBottomIcon path={mdiCheckCircle} />
                        <S.FileUploaderBottomMessage>{successMessage}</S.FileUploaderBottomMessage>
                    </S.FileUploaderBottomSuccess>
                )}

                {errorMessage && (
                    <S.FileUploaderBottomError>
                        <S.FileUploaderBottomIcon path={mdiAlertCircle} />
                        <S.FileUploaderBottomMessage>{errorMessage}</S.FileUploaderBottomMessage>
                    </S.FileUploaderBottomError>
                )}
            </S.FileUploaderContainer>
        );
    }

    private handleClick = (): void => {
        this.realInputRef.current?.click();
    };
}
