import dateFns from 'date-fns';
import * as React from 'react';
import { GuarantorBadge } from '../badge';
import * as S from './guarantor-search-result.styles';

export interface IGuarantorSearchResultProps {
    id?: string;
    first?: string;
    last?: string;
    suffix?: string;
    email?: string;
    dob?: string;
}

export class GuarantorSearchResult extends React.PureComponent<IGuarantorSearchResultProps> {
    public render(): React.ReactNode {
        const { children, id, first, last, suffix, email, dob } = this.props;
        return (
            <S.Result>
                <S.Guarantor>
                    {id && (
                        <S.GuarantorTop>
                            <b>Guarantor</b>
                            <S.Spacer />
                            <b>DOB</b>
                            <span>{dateFns.format(dob!, 'YYYY/MM/DD')}</span>
                            <b>{email}</b>
                        </S.GuarantorTop>
                    )}
                    <S.GuarantorBottom>
                        <S.GuarantorName>
                            {first} {last}
                        </S.GuarantorName>
                        <S.Spacer />
                        {id && <GuarantorBadge id={id} first={first} last={last} suffix={suffix} />}
                    </S.GuarantorBottom>
                </S.Guarantor>
                {!!children && (
                    <S.ClaimTable>
                        <tbody>{children}</tbody>
                    </S.ClaimTable>
                )}
            </S.Result>
        );
    }
}
