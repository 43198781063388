import { mdiPencil } from '@mdi/js';
import { Icon } from '@mdi/react';
import _ from 'lodash';
import React, { useContext } from 'react';
import { MessagingContext } from '../../services';
import { Tooltip } from '../base';
import * as S from './note-indicator.styles';
import { IMessagingComment } from './util';

interface INoteIndicator {
    className?: string;
    comment: IMessagingComment;
}

export const NoteIndicator: React.FunctionComponent<INoteIndicator> = (props: INoteIndicator): React.ReactElement => {
    const context = useContext(MessagingContext);
    const { message } = context;
    const { className, comment } = props;
    const hasNote = _.get(comment, 'note', false);
    const title = <span>Change to {hasNote ? 'normal comment' : 'internal note'}</span>;

    const toggleComment = async (): Promise<void> => {
        if (message && message.id && comment.id) {
            const phrase = hasNote ? 'a regular comment' : 'an internal note';
            const question = `Are you sure you want to change this to ${phrase}?`;
            const { id } = comment;
            if (window.confirm(question)) {
                await (context.onCommentToggle && context.onCommentToggle(message.id, id, !hasNote));
            }
        }
    };

    return (
        <S.NoteIndicator className={className}>
            {hasNote && <S.NoteLabel>Internal Note</S.NoteLabel>}
            <Tooltip title={title} direction="top" variant="dark">
                <S.NoteButton variant="text" onClick={toggleComment}>
                    <Icon path={mdiPencil} size={0.75} />
                </S.NoteButton>
            </Tooltip>
        </S.NoteIndicator>
    );
};
