import { FieldProps, FormikProps, getIn } from 'formik';
import _ from 'lodash';
import * as React from 'react';
import { OnChangeValue, PropsValue } from 'react-select';
import { BaseSelect, IBaseSelectProps, ISelectOption } from '../../';

type ISelectFieldProps = IBaseSelectProps &
    FieldProps & {
        onChange?: (option: OnChangeValue<ISelectOption, false>, form: FormikProps<any>) => Promise<void> | void;
    };

/**
 * Wrapper around BaseSelect to be able to work with Form components
 */
export class SelectField extends React.PureComponent<ISelectFieldProps> {
    public render(): React.ReactElement {
        const { field, form, ...props } = this.props;
        const error = getIn(form.errors, field.name);

        // TODO: add touched condition
        const errorMessage: string = error ? error : null;

        return (
            <BaseSelect
                {...props}
                {...field}
                errorMessage={errorMessage}
                menuPosition="fixed"
                value={this.getValue()}
                onChange={this.handleChange}
            />
        );
    }

    private readonly getValue = (): ISelectOption | ISelectOption[] | undefined => {
        const { field, options } = this.props;
        const optionsArr = options as ISelectOption[];

        if (!optionsArr) {
            return;
        }

        // In case of multiselect
        if (Array.isArray(field.value)) {
            return _.compact(field.value.map((el: any) => optionsArr.find(option => option.value === el)));
        } else {
            return optionsArr.find(option => option.value === field.value);
        }
    };

    private readonly handleChange = (option: PropsValue<ISelectOption>): void => {
        const {
            field,
            onChange,
            form: { setFieldValue },
        } = this.props;

        onChange?.(option as OnChangeValue<ISelectOption, false>, this.props.form);

        // In case of multiselect
        if (Array.isArray(option)) {
            setFieldValue(
                field.name,
                option.map(a => a.value),
            );
        } else {
            setFieldValue(field.name, _.get(option, 'value', null));
        }
    };
}
