import { mdiAlertCircle, mdiCheckCircle, mdiClose } from '@mdi/js';
import Icon from '@mdi/react';
import _ from 'lodash';
import * as React from 'react';
import * as S from './input.styles';

export type IBaseInputProps = React.PropsWithoutRef<JSX.IntrinsicElements['input']> & {
    successMessage?: string;
    errorMessage?: string;
    /**
     * If property is passed, then we show remove button on the right
     */
    onDelete?: () => void;
    /**
     * Custom postfix button with handler on the right.
     * optionPath is an SVG path string
     */
    onOption?: (e: React.MouseEvent) => void;
    optionPath?: string;
};

export class BaseInput extends React.Component<IBaseInputProps> {
    public render(): React.ReactElement {
        const { successMessage, errorMessage, onDelete, onOption, optionPath, required, ...props } = this.props;
        const { value, readOnly } = props;

        return (
            <S.InputContainer>
                <S.InputWrapper>
                    <S.Input
                        {...props}
                        // Prevent set null or undefined
                        value={_.isNil(value) ? '' : value}
                        showSuccess={!!successMessage}
                        showError={!!errorMessage}
                        showDelete={!!onDelete}
                    />
                    {onDelete && !readOnly && (
                        <S.InputPostfixButton onClick={onDelete} type="button" disabled={props.disabled}>
                            <Icon path={mdiClose} />
                        </S.InputPostfixButton>
                    )}
                    {onOption && optionPath && (
                        <S.InputInnerPostfixButton onClick={onOption} type="button" disabled={props.disabled}>
                            <Icon path={optionPath} />
                        </S.InputInnerPostfixButton>
                    )}
                </S.InputWrapper>

                {successMessage && (
                    <S.InputBottomSuccess>
                        <S.InputBottomIcon path={mdiCheckCircle} />
                        <S.InputBottomMessage>{successMessage}</S.InputBottomMessage>
                    </S.InputBottomSuccess>
                )}

                {errorMessage && (
                    <S.InputBottomError>
                        <S.InputBottomIcon path={mdiAlertCircle} />
                        <S.InputBottomMessage>{errorMessage}</S.InputBottomMessage>
                    </S.InputBottomError>
                )}
            </S.InputContainer>
        );
    }
}
