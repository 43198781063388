import { mdiCircleOutline, mdiLoading } from '@mdi/js';
import React from 'react';
import * as S from './spinner.styles';

export interface ISpinnerProps {
    className?: string;
    size?: number | string;
}

function renderSpinner(props: ISpinnerProps): React.ReactElement {
    const { className, size } = props;
    return (
        <S.Loading className={className} size={size}>
            <S.OutlineIcon path={mdiCircleOutline} />
            <S.LoadingIcon path={mdiLoading} />
        </S.Loading>
    );
}
export const Spinner = React.memo(renderSpinner);
