import styled from '@emotion/styled/macro';
import Icon from '@mdi/react';
import { Variables } from '../base/variables';

/**
 * DateRange
 */
export const DateRangeCurrent = styled.div`
    display: inline-block;
    padding: 7px 16px 7px 16px;

    flex-grow: 1;
    line-height: 24px;
    user-select: none;

    border: ${Variables.Borders.regular};
    border-radius: ${Variables.Rounded.small};
    transition: all 0.4s ease-out;
    box-sizing: border-box;

    z-index: 2;

    &:hover {
        cursor: pointer;
        border-color: ${Variables.Colors.n15};
    }

    &:active {
        border-color: ${Variables.Colors.n30};
    }
`;

export const DateRangeIcon = styled(Icon)`
    height: 24px;
    width: 24px;
    margin-top: -3px;
    margin-left: 8px;
    vertical-align: middle;
    path {
        fill: ${Variables.Colors.n30};
    }
    ${DateRangeCurrent}:hover & {
        path {
            fill: ${Variables.Colors.n50};
        }
    }
`;

export const CustomDatePicker = styled.div`
    display: flex;
    flex-direction: column;
    width: 200px;
    padding: 0px;
`;
export const CustomRow = styled.div`
    height: 24px;
    line-height: 24px;
    margin-bottom: 8px;
    display: flex;
    justify-content: space-between;
    text-align: right;
    font-weight: 500;
    padding: 0px 24px;
    color: ${Variables.Colors.n30};

    span {
        color: ${Variables.Colors.n100};
        text-align: center;
        width: 100px;
        padding: 0px 16px;
        border-radius: ${Variables.Rounded.large};
        background-color: ${Variables.Colors.n15_48};
    }
`;
export const CustomDivider = styled.div`
    border-bottom: ${Variables.Borders.regular};
    width: 100%;
`;
export const CustomCalendar = styled.div`
    height: 140px;
    margin: 16px 16px 8px 16px;
    background-color: ${Variables.Colors.n15_24};
`;
