import { Provider } from 'mobx-react';
import React, { useCallback, useLayoutEffect } from 'react';
import { GlobalStyles } from './app.styles';
import { Router } from './router';

declare global {
    interface Window {
        grecaptcha: any;
    }
}
window.grecaptcha = window.grecaptcha || {};

export interface IFbq extends Window {
    fbq?: any;
}

interface IDataWindow extends Window {
    dataLayer?: any;
}

const App: React.FunctionComponent = (): React.ReactElement => {
    const { dataLayer } = window as IDataWindow;

    const addFacebook = useCallback((): void => {
        if (process.env.REACT_APP_FACEBOOK_ID) {
            const fbq = (window as IFbq).fbq;
            const func = (f: any, b: any, e: any, v: any, n?: any, t?: any, s?: any) => {
                if (f.fbq) {
                    return;
                }
                n = f.fbq = (...args: any[]) => {
                    n.callMethod ? n.callMethod(...args) : n.queue.push(args);
                };
                if (!f._fbq) {
                    f._fbq = n;
                }
                n.push = n;
                n.loaded = !0;
                n.version = '2.0';
                n.queue = [];
                t = b.createElement(e);
                t.async = !0;
                t.src = v;
                s = b.getElementsByTagName(e)[0];
                s.parentNode.insertBefore(t, s);
            };
            func(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');
            fbq('init', process.env.REACT_APP_FACEBOOK_ID);
            fbq('track', 'PageView');
            // tslint:disable-next-line:no-console
            console.log('Facebook added');
        }
    }, []);

    const addGoogleAds = useCallback((): void => {
        if (process.env.REACT_APP_GOOGLE_TRACKER) {
            const gtag = (...args: any[]) => dataLayer.push(args);
            const script = document.createElement('script');
            const src = 'https://www.googletagmanager.com/gtag/js?id=' + process.env.REACT_APP_GOOGLE_TRACKER;
            script.setAttribute('src', src);
            document.body.appendChild(script);
            gtag('js', new Date());
            gtag('config', process.env.REACT_APP_GOOGLE_TRACKER, {
                linker: {
                    domains: ['enter.health', 'login.enter.health'],
                },
            });
            // tslint:disable-next-line:no-console
            console.log('Google Tags added');
        }
    }, [dataLayer]);

    const addRecaptcha = useCallback((): void => {
        const script = document.createElement('script');
        const src = 'https://www.google.com/recaptcha/api.js?render=' + process.env.REACT_APP_RECAPTCHA_KEY;
        script.setAttribute('src', src);
        document.body.appendChild(script);
        // tslint:disable-next-line:no-console
        console.log('Recaptcha added');
    }, []);

    useLayoutEffect(() => {
        addFacebook();
        addGoogleAds();
        addRecaptcha();
    }, [addFacebook, addGoogleAds, addRecaptcha]);

    return (
        <>
            <GlobalStyles />
            <Provider>
                <Router />
            </Provider>
        </>
    );
};

export default App;
