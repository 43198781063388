import styled from '@emotion/styled/macro';
import Icon from '@mdi/react';
import { Variables } from '../variables';

interface IWrapperProps {
    open?: boolean;
}

interface ISubmenuProps {
    activatorRect: ClientRect;
    viewportRect: ClientRect;
}

export const Wrapper = styled.div<IWrapperProps>`
    position: relative;
    display: flex;
    justify-content: space-between;

    padding: 8px 16px;
    line-height: 16px;
    font-size: 16px;
    line-height: 18px;

    color: ${Variables.Colors.n70};
    background-color: ${(props: IWrapperProps): string => (props.open ? Variables.Colors.n15_24 : 'inherit')};

    user-select: none;
    white-space: nowrap;
    cursor: pointer;

    &:hover {
        background-color: ${Variables.Colors.n15_24};
    }
`;

export const ExpandIcon = styled(Icon)`
    display: inline-block;
    background-size: 18px auto;
    height: 18px;
    width: 18px;
    path {
        fill: ${Variables.Colors.n70};
    }
`;

const minWidth = 50;
const marginTop = 8;
export const Submenu = styled.div<ISubmenuProps>`
    position: absolute;
    display: block;

    ${(props: ISubmenuProps): string => {
        const vy = props.viewportRect.top;
        const vx = props.viewportRect.left;
        const vw = props.viewportRect.width;
        const bw = props.activatorRect.width;
        const bx = props.activatorRect.left;
        const by = props.activatorRect.top;

        if (vw - bx - bw - minWidth > 0) {
            return `
                top: ${-vy + by - marginTop}px;
                left: ${-vx + bx + bw}px
                `;
        } else {
            return `
                top: ${-vy + by - marginTop}px;
                right: ${-vx + vw - bx}px
                `;
        }
    }}

    min-width: ${minWidth}px;
    margin-top: ${marginTop}px;
    padding: 8px 0px;

    z-index: 2;
    border-radius: ${Variables.Rounded.small};
    box-shadow: ${Variables.Shadows.large}, ${Variables.Shadows.border};
    background-color: ${Variables.Colors.white};
`;
