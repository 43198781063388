import * as React from 'react';

export interface IBaseImageProps {
    src: string;
    srcSet?: string;
    alt?: string;
    title?: string;
    className?: string;
}

export class BaseImage extends React.Component<IBaseImageProps> {
    public render(): React.ReactElement {
        const { src, srcSet, alt, title, className } = this.props;
        return <img src={src} srcSet={srcSet} alt={alt} title={title} className={className} />;
    }
}
