import styled from '@emotion/styled/macro';
import Icon from '@mdi/react';
import { forPhoneOnly, Variables } from '../../base';
import { Card } from '../../card';

export const chartColors = ({ ord }: { ord: number }): string => {
    switch (ord) {
        case 0:
            return Variables.Colors.b300;
        case 1:
            return Variables.Colors.subG10;
        case 2:
            return Variables.Colors.sunset200;
        case 3:
            return Variables.Colors.p200;
        default:
            return '';
    }
};

export const FinancialOverviewCard = styled(Card)`
    display: flex;
    flex-direction: column;
    padding: 0;
`;

export const Loader = styled.div`
    display: flex;
    flex-grow: 1;
    padding: 24px;
`;

export const Body = styled.div`
    display: flex;
    flex-direction: row;
    padding: 24px 16px 0 16px;

    ${forPhoneOnly(`
        flex-direction: column;
    `)}
`;

export const Feature = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    border-radius: 12px;
    border: 1px solid ${Variables.Colors.n15};
    background-color: ${Variables.Colors.n15};
    padding: 16px;
    min-width: 10em;

    &:first-of-type {
        margin: 0 16px 0 0;
    }
    &:last-of-type {
        margin: 0 0 0 16px;
    }

    ${forPhoneOnly(`
        min-width: unset;
        &:first-of-type {
            margin: 0 0 16px 0;
        }
        &:last-of-type {
            margin: 16px 0 0 0;
        }
    `)}
`;
export const FeatureAmount = styled.span`
    font-size: 24px;
    font-weight: 500;
`;
export const FeatureName = styled.span`
    text-transform: uppercase;
    font-size: 18px;
`;

export const Segments = styled.div`
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: stretch;
    flex-grow: 1;
    position: relative;
    border: 1px solid ${Variables.Colors.n15};
    background-color: ${Variables.Colors.n15};
`;

export const Segment = styled.div<{ pct: number; ord: number }>`
    display: flex;
    flex-basis: ${({ pct }) => `${pct}%`};
    min-height: 80px;
    overflow: hidden;
    align-items: flex-end;
    justify-content: center;

    cursor: pointer;
    background-color: ${chartColors};

    &:hover {
        opacity: 0.6;
        box-shadow: inset 0 0 1px 1px ${Variables.Colors.p500};
    }
`;

export const Goal = styled.div<{ pct: number }>`
    position: absolute;
    width: 100px;

    padding: 2px;
    margin-top: -18px;
    margin-left: ${({ pct }) => `calc(${pct}% - 50px);`};
    border-radius: 12px;

    font-weight: 100;
    text-align: center;
    color: ${Variables.Colors.white};
    background-color: ${Variables.Colors.subG30};
    z-index: 1;
`;

export const GoalAmount = styled.span`
    font-weight: 500;
`;

export const GoalLine = styled.div<{ pct: number }>`
    display: flex;
    position: absolute;
    margin-left: ${({ pct }) => `${pct}%`};
    min-height: 80px;
    border-left: 2px solid ${Variables.Colors.subG30};
`;

export const Footer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 12px 0;
    font-size: 16px;
    font-weight: 500;
    color: ${Variables.Colors.n100};
    background-color: ${Variables.Colors.white};
    cursor: pointer;
    &:hover {
        color: ${Variables.Colors.n50};
    }
`;

export const Details = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;
    border-radius: 12px;
    margin: 16px;
`;

export const InfoIcon = styled(Icon)`
    margin-left: 5px;
`;

export const Detail = styled.div<{ warning?: boolean }>`
    display: flex;
    align-items: center;
    white-space: nowrap;
    font-size: 18px;

    ${({ warning }) =>
        warning &&
        `
        color: ${Variables.Colors.warning};
        font-weight: 500;
    `})}


    ${forPhoneOnly(`
        flex-grow: 1;
    `)}
`;

export const Dot = styled(Icon)<{ ord: number }>`
    fill: ${chartColors};
    stroke: ${Variables.Colors.n85};
    height: 12px;
    width: 12px;
    margin: 0 8px;
`;

export const Spacer = styled.div`
    flex-grow: 1;
    min-width: 12px;
`;

export const DetailAmount = styled.span``;

export const WarningIcon = styled(Icon)`
    fill: ${Variables.Colors.warning};
    margin-right: 4px;
`;
