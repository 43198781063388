import { mdiAlertCircle, mdiCheckCircle, mdiChevronDown } from '@mdi/js';
import Icon from '@mdi/react';
import { Tooltip } from '@mui/material';
import _ from 'lodash';
import * as React from 'react';
import { components, MenuProps, OptionProps, Props } from 'react-select';
import { TooltipTransitionComponent } from '../tooltip';
import * as S from './select.styles';

const normalizeStr = (str: string) => _.chain(str).lowerCase().upperFirst().value();

export interface ISelectOption<T = string | boolean> {
    label: string | React.ReactElement;
    value: T | undefined;
    info?: string | React.ReactElement;
    title?: React.ReactElement | string;
}

export type IBaseSelectProps = Props<ISelectOption> & {
    className?: string;
    successMessage?: string;
    errorMessage?: string;
    isCreatable?: string;
    required?: boolean;
    disabled?: boolean;
    important?: boolean;
};

/**
 * Method to convert enums to Select Options
 */
export function getOptions<T>(
    type: T,
    opts: {
        allowEmpty?: boolean;
        normalize?: boolean;
    } = {},
): ISelectOption[] {
    const { allowEmpty, normalize } = opts;
    const res: ISelectOption[] = [];

    for (const n in type) {
        if (isNaN(Number(n))) {
            const label = normalize ? normalizeStr(String(type[n])) : n;
            res.push({ value: String(type[n]), label });
        }
    }
    if (allowEmpty === true) {
        res.unshift({ value: undefined, label: '' });
    }

    return res;
}

export function getBooleanOptions(): ISelectOption[] {
    return [
        {
            label: 'Yes',
            value: true,
        },
        {
            label: 'No',
            value: false,
        },
    ];
}

/**
 * Convert any object to ISelectOption by passing value and label keys.
 */
export function parseOptions(arr: object[], valueKey: string, labelKey: string): ISelectOption<string>[] {
    return arr.map(el => ({
        value: _.get(el, valueKey, ''),
        label: _.get(el, labelKey, ''),
    }));
}

export const DropdownIndicator = (): React.ReactElement => {
    return (
        <S.DropdownIndicator>
            <Icon path={mdiChevronDown} size={0.75} />
        </S.DropdownIndicator>
    );
};

export const BaseSelect: React.FunctionComponent<IBaseSelectProps> = (props: IBaseSelectProps): React.ReactElement => {
    const { successMessage, errorMessage, className, isCreatable, required, disabled, ...otherProps } = props;

    const showSuccess = !!successMessage;
    const showError = !!errorMessage;
    const selectProps: Props<ISelectOption> = {
        ...otherProps,
        styles: {
            menuPortal: base => ({
                ...base,
                zIndex: 9999,
            }),
            ...otherProps.styles,
        },
        components: {
            ...otherProps.components,
            DropdownIndicator,
            Menu: (menuProps: MenuProps<ISelectOption, false>) => (
                <S.MenuWrapper showSuccess={showSuccess} showError={showError}>
                    <components.Menu {...menuProps} />
                </S.MenuWrapper>
            ),
            Option: (optionProps: OptionProps<ISelectOption>) => {
                if (optionProps.data.title) {
                    return (
                        <components.Option {...(optionProps as any)}>
                            <Tooltip
                                placement="top-start"
                                title={optionProps.data.title}
                                TransitionComponent={TooltipTransitionComponent}>
                                <div>
                                    {optionProps.data.label}
                                    {optionProps.data.info}
                                </div>
                            </Tooltip>
                        </components.Option>
                    );
                }
                return (
                    <components.Option {...(optionProps as any)}>
                        {optionProps.data.label}
                        {optionProps.data.info}
                    </components.Option>
                );
            },
        },
        classNamePrefix: 'select',
        openMenuOnFocus: true,
        isClearable: !required,
        isDisabled: disabled,
    };

    return (
        <S.SelectContainer className={className}>
            {isCreatable ? (
                <S.StyledCreatableSelect {...(selectProps as any)} showSuccess={showSuccess} showError={showError} />
            ) : (
                <S.StyledSelect {...(selectProps as any)} showSuccess={showSuccess} showError={showError} />
            )}
            {successMessage && (
                <S.SelectBottomSuccess>
                    <S.SelectBottomIcon path={mdiCheckCircle} />
                    <S.SelectBottomMessage>{successMessage}</S.SelectBottomMessage>
                </S.SelectBottomSuccess>
            )}

            {errorMessage && (
                <S.SelectBottomError>
                    <S.SelectBottomIcon path={mdiAlertCircle} />
                    <S.SelectBottomMessage>{errorMessage}</S.SelectBottomMessage>
                </S.SelectBottomError>
            )}
        </S.SelectContainer>
    );
};
