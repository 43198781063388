import styled from '@emotion/styled/macro';
import { forPhoneOnly, Variables } from '../base';

interface ICard {
    size: 'small' | 'medium' | 'large';
}

export const Card = styled.div<ICard>`
    color: ${Variables.Colors.n100};
    background-color: ${Variables.Colors.white};
    display: inline-block;
    padding: 24px 24px;

    ${(props): string => {
        const { size } = props;
        switch (size) {
            case 'small':
                return `
                    flex: 0 0 calc(25% - 16px);
                    ${forPhoneOnly(`
                        flex-basis: 100%;
                        &:not(:last-of-type) {
                            margin-bottom: 16px;
                        }
                    `)};
                `;
            case 'medium':
                return `
                    flex: 0 0 calc(50% - 12px);
                    ${forPhoneOnly(`
                        flex-basis: 100%;
                        &:not(:last-of-type) {
                            margin-bottom: 16px;
                        }
                    `)};
                `;
            case 'large':
                return `
                    flex: 0 0 100%;
                `;
            default:
                return '';
        }
    }}

    border-radius: ${Variables.Rounded.small};
    box-shadow: ${Variables.Shadows.border}, ${Variables.Shadows.medium};
`;
