import styled from '@emotion/styled/macro';
import { BaseButton, BaseInput, BaseSelect, forPhoneOnly, PopoverItem, Variables } from '../../../base';

export const FiltersContainer = styled.section`
    display: flex;
    align-items: center;
`;

export const FilterItem = styled.div`
    flex: 1;
    margin-left: 12px;
`;

export const ActionsWrapper = styled.div`
    display: flex;
`;

export const FilterItemInput = styled(BaseInput)``;

export const FilterItemSelect = styled(BaseSelect)``;

export const FilterGroup = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    ${FilterItem}:first-of-type {
        margin-left: 0;
        flex: 1;
    }
`;

export const DeleteButton = styled(BaseButton)`
    width: 18px;
    height: 18px;
    padding: 0;
    min-width: auto;
    margin-left: 10px;
`;

export const AddRowButton = styled(BaseButton)`
    margin-top: 10px;
`;

export const FilterWrapper = styled(PopoverItem)`
    width: calc(800px - 2 * 20px);
    cursor: default;
    padding: 16px 20px;
    margin: 0;
    color: ${Variables.Colors.n70};
    &:hover {
        cursor: default;
        background: none;
    }

    ${FilterGroup} {
        margin-top: 10px;
    }

    ${FilterGroup}:first-of-type {
        margin-top: 0;
    }

    ${forPhoneOnly(`
        width: calc(90vw - 48px);
        & input {
            font-size: 12px;
        }
    `)};
`;

export const ActionButton = styled(BaseButton)`
    min-width: 100px; /* ensure room for icon */
    margin-right: 8px;
`;

export const ActiveFilters = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin-left: 18px;
`;

export const FilterButton = styled(BaseButton)`
    white-space: nowrap;
    margin: 4px 16px 4px 0;
    & > svg {
        margin-left: 8px;
    }
`;

export const FilterText = styled.span`
    line-height: 18px;
    font-size: 16px;
`;

export const FilterOperator = styled.span`
    line-height: 18px;
    font-size: 18px;
`;

export const FilterInput = styled(BaseInput)`
    &:focus {
        transition: none;
        width: 300px;
    }
`;
