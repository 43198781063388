import * as React from 'react';
import * as S from './button.styles';

type ButtonType = 'primary' | 'outlined' | 'fab-dark' | 'fab-light' | 'text' | 'unstyled' | 'warning' | 'success';

export interface IBaseButtonProps {
    onClick?: (e: React.MouseEvent) => void;
    onMouseEnter?: (e: React.MouseEvent) => void;
    variant?: ButtonType;
    className?: string;
    disabled?: boolean;
    type?: 'submit' | 'reset' | 'button';
    size?: 'small' | 'medium' | 'large';
    tabIndex?: number;
    noBorder?: boolean;
}

export class BaseButton extends React.Component<IBaseButtonProps> {
    public render(): React.ReactElement {
        const { variant } = this.props;

        switch (variant) {
            case 'primary':
                return this.renderPrimaryButton();
            case 'warning':
                return this.renderWarningButton();
            case 'success':
                return this.renderSuccessButton();
            case 'outlined':
                return this.renderOutlinedButton();
            case 'text':
                return this.renderTextButton();
            case 'fab-dark':
                return this.renderFabDarkButton();
            case 'fab-light':
                return this.renderFabLightButton();
            case 'unstyled':
                return this.renderUnstyledButton();
            // return this.renderFabIconLightButton();
            default:
                return this.renderPrimaryButton();
        }
    }

    private renderPrimaryButton(): React.ReactElement {
        return <S.Primary {...this.props} />;
    }

    private renderWarningButton(): React.ReactElement {
        return <S.Warning {...this.props} />;
    }

    private renderSuccessButton(): React.ReactElement {
        return <S.Success {...this.props} />;
    }

    private renderOutlinedButton(): React.ReactElement {
        return <S.Outlined {...this.props} />;
    }

    private renderTextButton(): React.ReactElement {
        return <S.Text {...this.props} />;
    }

    private renderFabDarkButton(): React.ReactElement {
        return <S.FabDark {...this.props} />;
    }

    private renderFabLightButton(): React.ReactElement {
        return <S.FabLight {...this.props} />;
    }

    private renderUnstyledButton(): React.ReactElement {
        return <S.Unstyled {...this.props} />;
    }

    // private renderFabIconLightButton(): React.ReactElement {
    //     const materialProviderProps: IconButtonProps = {
    //         children: this.props.children,
    //         onClick: this.props.onClick,
    //         onMouseEnter: this.props.onMouseEnter,
    //         className: this.props.className,
    //         disabled: this.props.disabled,
    //         type: this.props.type,
    //         tabIndex: this.props.tabIndex,
    //         size: 'medium',
    //     };

    //     return <S.FabIconLight {...materialProviderProps} />;
    // }

    // private renderFabIconDarkButton(): React.ReactElement {
    //     const materialProviderProps: IconButtonProps = {
    //         children: this.props.children,
    //         onClick: this.props.onClick,
    //         onMouseEnter: this.props.onMouseEnter,
    //         className: this.props.className,
    //         disabled: this.props.disabled,
    //         type: this.props.type,
    //         tabIndex: this.props.tabIndex,
    //         size: 'medium',
    //     };

    //     return <S.FabIconDark {...materialProviderProps} />;
    // }
}
