import styled from '@emotion/styled/macro';
import { BaseButton, Spinner, Variables } from '@enter/core';
import { Link as RouterLink } from 'react-router-dom';

export const Spacer = styled.div`
    height: 20px;
`;

export const Button = styled(BaseButton)`
    width: 100%;
    height: auto;
    padding: 10px 20px;
    text-transform: uppercase;

    &:disabled {
        color: ${Variables.Colors.white};
        background-color: ${Variables.Colors.b300_l90};
    }
`;

export const ButtonWrapper = styled.div`
    margin: 8px;
    position: relative;

    ${Button}:not(:first-of-type) {
        margin-top: 16px;
    }
`;

export const Progress = styled(Spinner)`
    svg:last-of-type {
        stroke: white;
    }
`;

export const Centered = styled.div`
    text-align: center;
`;

export const Footer = styled(Centered)`
    padding-top: 24px;
`;

export const Link = styled(RouterLink)`
    color: #3f51b5;
    font-size: 0.875rem;
`;

export const A = styled.a`
    color: #3f51b5;
    font-size: 0.875rem;
`;

export const Terms = styled.p`
    margin-bottom: 10px;
    margin-top: 0;
    font-size: 10px;
    color: ${Variables.Colors.n50};
    a {
        color: ${Variables.Colors.n50};
        text-decoration: underline;
    }
`;
