import { mdiChevronRight } from '@mdi/js';
import { Portal } from '@mui/material';
import * as React from 'react';
import * as S from './popover-submenu.styles';

export interface IPopoverSubmenuProps {
    label: string;
    open?: boolean;
    pinned?: boolean;
    selectable?: boolean;
    openSubmenu?: () => void;
    closeSubmenu?: () => void;
    closeMenu?: () => void;
}
export interface IPopoverSubmenuState {
    activatorRect?: ClientRect;
    viewportRect?: ClientRect;
    open: boolean;
}

export class PopoverSubmenu extends React.PureComponent<IPopoverSubmenuProps, IPopoverSubmenuState> {
    private activatorRef: React.RefObject<HTMLDivElement>;
    constructor(props: IPopoverSubmenuProps) {
        super(props);
        this.state = {
            activatorRect: undefined,
            viewportRect: undefined,
            open: !!this.props.open,
        };
        this.activatorRef = React.createRef();
    }
    public componentDidMount(): void {
        this.updateRect();
        window.addEventListener('resize', this.updateRect);
    }
    public componentWillUnmount(): void {
        window.removeEventListener('resize', this.updateRect);
    }
    public componentDidUpdate(): void {
        this.updateRect();
    }
    public render(): React.ReactElement {
        return (
            <>
                <S.Wrapper ref={this.activatorRef} open={this.state.open} onClick={this.toggleSubmenu}>
                    {this.props.label}
                    <S.ExpandIcon path={mdiChevronRight} />
                </S.Wrapper>
                <Portal container={document.body}>{this.renderMenu()}</Portal>
            </>
        );
    }
    private readonly updateRect = (): void => {
        if (this.activatorRef && this.activatorRef.current) {
            const viewportRect = document.body.getBoundingClientRect();
            const activatorRect = this.activatorRef.current.getBoundingClientRect();
            if (
                !this.state.activatorRect ||
                this.state.activatorRect.left !== activatorRect.left ||
                this.state.activatorRect.top !== activatorRect.top
            ) {
                this.setState({
                    activatorRect,
                    viewportRect,
                });
            }
        }
    };
    private readonly toggleSubmenu = (): void => {
        if (!this.state.open) {
            if (this.props.openSubmenu) {
                this.props.openSubmenu();
            }
        } else {
            if (this.props.closeSubmenu) {
                this.props.closeSubmenu();
            }
        }
        this.setState({
            open: !this.state.open,
        });
    };
    private readonly renderMenu = (): React.ReactElement => {
        if (this.state.open && this.state.activatorRect && this.state.viewportRect) {
            return (
                <S.Submenu activatorRect={this.state.activatorRect} viewportRect={this.state.viewportRect}>
                    {this.renderChildren()}
                </S.Submenu>
            );
        }
        return <></>;
    };
    private readonly renderChildren = () => {
        const { children } = this.props;
        if (children) {
            return React.Children.map(
                children as React.ReactElement,
                (child: React.ReactElement, i: number): React.ReactElement =>
                    React.cloneElement(child, {
                        closeMenu: this.props.closeMenu,
                        pinned: this.props.pinned,
                        selectable: this.props.selectable,
                        key: i,
                    }),
            );
        }
        return null;
    };
}
