import { css } from '@emotion/react/macro';
import styled from '@emotion/styled/macro';
import { Icon } from '@mdi/react';
import { Tooltip, Variables } from '../../base';
import { Card } from '../../card';
import { InsuranceCard } from './insurance-card';

interface ITab {
    active: boolean;
}

export const InsuranceOverviewCard = styled(Card)`
    display: flex;
    flex-direction: column;
    flex: 1 0 auto;
    padding: 0;
`;

export const Header = styled.div`
    display: flex;
    align-items: stretch;
    border-bottom: 1px solid ${Variables.Colors.platinum};
`;

export const Title = styled.div`
    flex-grow: 1;
    padding: 16px 24px;

    font-size: 15px;
    font-weight: 500;
    text-transform: uppercase;
    color: ${Variables.Colors.p500};
`;

export const Tabs = styled.div`
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    padding: 0;
    border-bottom: none;
`;

const ActiveTab = css`
    color: ${Variables.Colors.b300};
    border-bottom-color: ${Variables.Colors.b300};

    /* &:hover {
        color: ${Variables.Colors.n85};
    } */
`;

const InactiveTab = css`
    color: ${Variables.Colors.n50};
    cursor: pointer;

    &:hover {
        color: ${Variables.Colors.n15};
    }
`;

export const Tab = styled.div<ITab>`
    display: flex;
    align-items: center;
    padding: 16px;

    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0.2px;
    line-height: 1;

    background-color: ${Variables.Colors.white};
    border-bottom: 2px solid transparent;

    ${props => {
        return props.active ? ActiveTab : InactiveTab;
    }};
`;

export const TabLabel = styled.span``;

export const TabIcon = styled(Icon)`
    height: 18px;
    width: 18px;
    border-radius: 18px;
    margin-left: 8px;

    fill: ${Variables.Colors.white};
    stroke: ${Variables.Colors.n100};
    background-color: ${Variables.Colors.n100};
`;

export const ActiveIcon = styled(TabIcon)`
    stroke: ${Variables.Colors.b300};
    background-color: ${Variables.Colors.b300};
`;

export const TabTooltip = styled(Tooltip)`
    height: 18px;
`;

export const Loader = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    align-items: center;
    justify-content: center;
    padding: 24px 16px;
    flex: 1;
`;

export const Footer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 12px 0;
    font-size: 16px;
    font-weight: 500;
    color: ${Variables.Colors.n100};
    background-color: ${Variables.Colors.ghostWhite};
    cursor: pointer;
    &:hover {
        color: ${Variables.Colors.n50};
    }
`;

export const Link = styled.div``;

export const StyledCard = styled(InsuranceCard)`
    padding: 16px;
`;

export const Divider = styled.div`
    border-bottom: ${Variables.Borders.regular};
    /* position: absolute; */
    left: 0;
    width: 100%;
    margin: 2px 0;
`;

export const Actions = styled.div`
    /* max-width: 100px; */
    background-color: ${Variables.Colors.b300_l96};
    height: 100%;
    padding: 20px 10px;
    border-left: 1px solid ${Variables.Colors.platinum};
    display: flex;
    flex-direction: column;
    width: 200px;
`;

export const Container = styled.div`
    display: flex;
    height: 100%;
`;

export const Content = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
`;
