import React from 'react';
import * as S from './loader.styles';

export const Loader = React.memo<{ loading: boolean }>(({ loading }) => {
    return loading ? (
        <S.LoadingContainer>
            <S.LoadingSpinner />
        </S.LoadingContainer>
    ) : null;
});
