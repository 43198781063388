import styled from '@emotion/styled/macro';
import { Icon } from '@mdi/react';
import { transparentize } from 'polished';
import { BaseButton, forPhoneOnly, Variables } from '../../base';
import { TitleCard } from '../../card';
import { DataTable } from '../../data-table';

export const StyledTitleCard = styled(TitleCard)`
    justify-content: space-between;
    max-width: 100%;
`;

export const CardBody = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1 1 100%;
    overflow-x: auto;
`;

export const StyledDataTable = styled(DataTable)`
    display: flex;
    flex-direction: column;
    margin: 16px 0;
    flex: 1 1 100%;
    box-shadow: 0 0.5px 0 0 ${Variables.Colors.n15};

    [role='columnheader'] {
        padding: 8px 12px 6px 12px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    [role='cell'] {
        display: inline-block;
        padding: 8px 12px 6px 12px;
        line-height: 24px;
        font-size: 15px;
        color: ${Variables.Colors.n70};

        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        &:first-of-type {
            text-align: center;
            padding: 8px;
        }
    }

    ${forPhoneOnly(`
        [role='cell'] {
            &:first-of-type {
                padding-left: 8px;
            }
        }
    `)}
`;

export const StyledTag = styled.span`
    display: inline-block;
    color: ${Variables.Colors.n70};
    background-color: ${Variables.Colors.platinum};
    border-radius: ${Variables.Rounded.medium};
    padding: 0 4px;
    &:not(:first-of-type) {
        margin-left: 4px;
    }
    white-space: nowrap;
`;

export const StyledDescription = styled.span`
    line-height: 1.5;
    color: ${Variables.Colors.n100};
`;

export const StyledCharges = styled.span`
    display: block;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;

export const StyledIcon = styled(Icon)`
    height: 24px;
    width: 24px;
    vertical-align: middle;
    fill: ${Variables.Colors.nx24};
`;

export const ExpandableDiv = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    flex-grow: 1;

    cursor: pointer;
    padding: 0;
`;

export const ExpandIcon = styled(Icon)`
    margin-top: 4px;
    margin-right: 16px;
    height: 16px;
    width: 16px;
    fill: ${Variables.Colors.b300};
    align-self: flex-end;
`;

export const AlignCell = styled.div`
    display: flex;
    justify-content: center;
`;

export const SuccessIcon = styled(Icon)`
    margin-top: 4px;
    height: 16px;
    width: 16px;
    fill: ${Variables.Colors.successful};
    align-self: flex-end;
`;

export const WarningIcon = styled(Icon)`
    margin-top: 4px;
    height: 16px;
    width: 16px;
    fill: ${Variables.Colors.warning};
    align-self: flex-end;
`;

export const Expand = styled.div`
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: space-around;
    flex-grow: 1;
    padding: 0 8px;
`;

export const DetailsTable = styled.table`
    flex-grow: 0;
    padding: 12px 24px;
    & th {
        white-space: nowrap;
        text-align: left;
        font-weight: 500;
    }
    & td span {
        display: inline-block;
        font-size: 16px;
        text-decoration: underline;
    }
`;

export const ProviderTable = styled.table`
    display: inline-flex;
    flex-grow: 0;
    max-width: 400px;
    margin-left: 24px;
`;
export const ProviderType = styled.th`
    text-align: right;
    font-size: 15px;
    font-weight: 500;
`;
export const ProviderName = styled.td`
    text-align: left;
    font-size: 15px;
    padding-left: 12px;
`;

export const FooterLink = styled.a`
    display: block;
    text-align: right;
    padding: 0 24px;
    flex-grow: 1;
    text-align: right;
    font-size: 15px;
    font-weight: 500;
    letter-spacing: normal;
    line-height: 1;
    cursor: pointer;
    color: ${Variables.Colors.p300};
`;

export const RowWrapper = styled.div<{ warning: boolean }>`
    > div:first-of-type {
        background-color: ${({ warning }) => (warning ? transparentize(0.75, Variables.Colors.r20) : undefined)};
    }
`;

export const SummaryRow = styled.div`
    background-color: ${Variables.Colors.b300_l96};
    [role='cell'] {
        font-size: 16px;
        font-weight: 500;
    }
`;

export const ResourceTable = styled.section`
    width: 100%;
    padding: 10px;
    box-shadow: ${Variables.Shadows.border};
    margin: 16px 0;
`;

export const EmptyLabel = styled.span`
    color: ${Variables.Colors.n30};
    text-decoration: none !important;
`;

export const ActionButton = styled(BaseButton)`
    min-width: auto;
    fill: ${Variables.Colors.n30};
    height: 30px;
    width: 40px;
    border-color: transparent;
    background: none;

    &:hover {
        fill: ${Variables.Colors.n70};
        /* background: white; */
        border: solid 1px ${Variables.Colors.nx8};
    }

    padding: 0;
    z-index: 1;
`;
