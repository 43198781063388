import styled from '@emotion/styled/macro';
import { Icon } from '@mdi/react';
import { forDesktopDown, forPhoneOnly, forTabletPortraitDown, Variables } from '../base';

interface IInputProps {
    open: boolean;
}

export const Container = styled.div`
    display: flex;
    flex-grow: 1;
    align-items: center;
`;

export const InputWrapper = styled.div<IInputProps>`
    display: flex;
    flex-grow: 1;
    align-items: center;

    padding: 11px 0px;
    height: 40px;
    line-height: 16px;

    color: ${Variables.Colors.n100};
    background-color: ${Variables.Colors.n5};
    border-radius: ${Variables.Rounded.small};

    ${forDesktopDown(`
        --search-closed-width: 480px;
        --search-open-width: 600px;
    `)};
    ${forTabletPortraitDown(`
        --search-closed-width: 380px;
        --search-open-width: 480px;
    `)}
    ${forPhoneOnly(`
        --search-closed-width: 0px;
        --search-open-width: 0px;
    `)}
    min-width: ${(props: IInputProps) => (props.open ? 'var(--search-open-width)' : 'var(--search-closed-width)')};
`;

export const PrefixIcon = styled(Icon)`
    margin: 0 12px;
    outline: none;
    & {
        fill: ${Variables.Colors.nx24};
    }
`;

export const Input = styled.input`
    flex-grow: 1;
    font-size: 18px;

    color: ${Variables.Colors.n100};
    background-color: ${Variables.Colors.n5};
    border: none;

    &:focus {
        outline: none;
    }
`;
export const Suffix = styled.button`
    display: flex;
    align-items: center;
    outline: none;

    padding: 0;
    margin: 0 8px 0 16px;
    line-height: 1;
    border: none;
    background: transparent;
`;

export const SuffixIcon = styled(Icon)`
    cursor: pointer;
    & {
        fill: ${Variables.Colors.nx24};
    }
    &:hover,
    ${Suffix}:focus & {
        fill: ${Variables.Colors.n100};
    }
`;
