import styled from '@emotion/styled/macro';
import { BaseButton } from '../../../base';

export const Text = styled.span`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

export const Button = styled(BaseButton)`
    cursor: help;
    padding: 0 8px 0 0;
    display: flex;
    justify-content: center;
`;

export const Container = styled.section`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

export const IconWrapper = styled.div`
    display: flex;
    margin-right: 3px;
`;

export const ButtonContainer = styled.div`
    display: flex;
    min-width: 60px;
`;
