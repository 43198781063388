import styled from '@emotion/styled/macro';
import { Icon } from '@mdi/react';
import { forPhoneOnly, Tooltip, Variables } from '../../base';
import { Card } from '../../card';
import { DataTable } from '../../data-table';

export const StyledCard = styled(Card)`
    justify-content: space-between;
    padding: 24px 0 16px 0;
`;

export const CardHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 24px;
`;

export const CardTitle = styled.span`
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-transform: uppercase;
    font-size: 15px;
    font-weight: 500;
    line-height: 1;
    letter-spacing: 0.5px;
    color: ${Variables.Colors.p300};
`;

export const CardDate = styled.span`
    color: ${Variables.Colors.n70};
    font-weight: 500;
    padding: 2px 12px;
    background-color: ${Variables.Colors.n15};
    border-radius: ${Variables.Rounded.large};
`;

export const CardBody = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1 1 100%;
    overflow-x: auto;
    padding: 0 24px;
`;

export const StyledDataTable = styled(DataTable)`
    display: flex;
    flex-direction: column;
    margin: 16px 0 0 0;
    flex: 1 1 100%;

    & > section {
        min-height: 0;
    }
    & > section > section {
        & div {
            min-height: 0;
        }
    }

    [role='columnheader'] {
        padding: 8px 12px 6px 12px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    [role='cell'] {
        display: inline-block;
        padding: 8px 12px 6px 12px;
        line-height: 24px;
        font-size: 15px;
        color: ${Variables.Colors.n70};

        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    [role='row']:last-of-type {
        [role='cell'] {
            font-weight: 600;
        }
    }

    ${forPhoneOnly(`
        [role='cell'] {
            &:first-of-type {
                padding-left: 8px;
            }
        }
    `)}
`;

export const StyledTag = styled.span`
    display: inline-block;
    color: ${Variables.Colors.n70};
    background-color: ${Variables.Colors.platinum};
    border-radius: ${Variables.Rounded.medium};
    padding: 0 4px;
    &:not(:first-of-type) {
        margin-left: 4px;
    }
    white-space: nowrap;
`;

export const StyledDescription = styled.span`
    line-height: 1.5;
    color: ${Variables.Colors.n100};
`;

export const StyledCharges = styled.span`
    display: block;
    text-align: right;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;

export const StyledIcon = styled(Icon)`
    height: 24px;
    width: 24px;
    vertical-align: middle;
    fill: ${Variables.Colors.nx24};
`;

export const Cell = styled.div<{ align?: 'left' | 'right' }>`
    padding: 0;
    color: ${Variables.Colors.n70};
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    text-align: ${({ align }) => (align === 'right' ? 'right' : 'left')};
`;

export const Reasons = styled(Cell)`
    color: ${Variables.Colors.warning};
    white-space: pre-line;
`;
export const ReasonTooltip = styled(Tooltip)`
    &:not(:first-of-type) {
        margin-left: 4px;
    }
`;
export const Reason = styled.div`
    display: flex;
    flex-direction: column;
`;
export const ReasonCode = styled.div``;

export const Header = styled.div<{ align?: 'right' }>`
    text-transform: uppercase;
    font-weight: 600;
    font-size: 12px;
    color: ${Variables.Colors.n70};

    text-align: ${({ align }) => (align === 'right' ? 'right' : 'left')};
`;

export const ExpandableDiv = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    flex-grow: 1;

    cursor: pointer;
    padding: 0;
`;

export const ExpandIcon = styled(Icon)`
    margin-top: 4px;
    margin-right: 16px;
    height: 16px;
    width: 16px;
    fill: ${Variables.Colors.b300};
    align-self: flex-end;
`;

export const Expand = styled.div`
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: space-around;
    flex-grow: 1;
    padding: 0 8px;
`;

export const Spacer = styled.div`
    flex-grow: 1;
`;
