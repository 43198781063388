import { mdiInformation } from '@mdi/js';
import React, { useState } from 'react';
import { Tooltip, TooltipDirection, TooltipSize, TooltipTheme } from '../base';
import * as S from './info-icon.styles';

export interface IInfoIconProps {
    className?: string;
    direction: TooltipDirection;
    tooltip?: React.ReactElement;
    size?: TooltipSize;
    variant?: TooltipTheme;
    open?: boolean;
}

export const InfoIcon: React.FunctionComponent<IInfoIconProps> = (props: IInfoIconProps): React.ReactElement => {
    const { className, direction, tooltip, size, variant, open } = props;
    const [isClicked, setClicked] = useState(false);
    const handleClick = (): void => {
        setClicked(!isClicked);
    };
    return (
        <S.InfoIcon className={className} onClick={handleClick}>
            <Tooltip direction={direction} open={isClicked || open} size={size} variant={variant} title={tooltip}>
                <S.StyledIcon path={mdiInformation} />
            </Tooltip>
        </S.InfoIcon>
    );
};
