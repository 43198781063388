import _ from 'lodash';
import React from 'react';
import { ClaimResponsibilityType, SupportClaimResponsibilityCategoryType } from '../../api';
import { Emoji } from '../base/emoji/emoji';
import * as S from './responsibility-badge.styles';
import { BadgeSize } from './transmission-status-badge';

export interface IResponsibilityBadgeProps {
    className?: string;
    size: BadgeSize;
    responsibility?: keyof typeof ClaimResponsibilityType;
}

export class ResponsibilityBadge extends React.Component<IResponsibilityBadgeProps> {
    public render(): React.ReactElement {
        const { size, responsibility, className } = this.props;
        const label = responsibility
            ? _.startCase(ClaimResponsibilityType[responsibility].toLowerCase())
            : ClaimResponsibilityType.UNKNOWN;

        return (
            <S.Badge size={size} responsibility={responsibility} className={className} title="Responsibility">
                <S.BadgeLabel>{label}</S.BadgeLabel> {size === 'large' && this.renderBadgeIcon()}
            </S.Badge>
        );
    }
    private readonly renderBadgeIcon = (): React.ReactElement | string => {
        if (this.props.responsibility) {
            switch (this.props.responsibility) {
                case ClaimResponsibilityType.ENTER:
                    return <Emoji type="lightning" />;
                case ClaimResponsibilityType.GUARANTOR:
                    return <Emoji type="faceWithThermometer" />;
                case ClaimResponsibilityType.NONE:
                    return <Emoji type="thumbsUp" />;
                case ClaimResponsibilityType.PAYER:
                    return <Emoji type="hospital" />;
                case ClaimResponsibilityType.PROVIDER:
                    return <Emoji type="healthWorker" />;
                case ClaimResponsibilityType.UNKNOWN:
                    return <Emoji type="faceWithMonocle" />;
                default:
                    return <Emoji type="faceWithMonocle" />;
            }
        }

        return <Emoji type="faceWithMonocle" />;
    };
}

export function getResponsibilityDescription(type: ClaimResponsibilityType) {
    const dict = {
        [ClaimResponsibilityType.ENTER]: "This claim is on Enter's to-do list.",
        [ClaimResponsibilityType.PAYER]: "We're waiting on the Payer.",
        [ClaimResponsibilityType.PROVIDER]: "We're waiting on the Provider's response.",
        [ClaimResponsibilityType.GUARANTOR]: "We're waiting on the Guarantor to pay.",
        [ClaimResponsibilityType.NONE]: 'This claim is done! 🎉',
        [ClaimResponsibilityType.UNKNOWN]: "Something went wrong. We're looking into it.",
    };

    return dict[type];
}

export function getResponsibilityCategoryDescription(type: SupportClaimResponsibilityCategoryType) {
    const dict = {
        [SupportClaimResponsibilityCategoryType.CLAIMCLOSEOUT]: 'This claim is closed.',
        [SupportClaimResponsibilityCategoryType.CLAIMPAID]: 'The claim was fully paid.',
        [SupportClaimResponsibilityCategoryType.FAXRECEIVED]: 'We received a fax.',
        [SupportClaimResponsibilityCategoryType.MAILRECEIVED]: 'We received a letter.',
        [SupportClaimResponsibilityCategoryType.PAYMENTRECEIVED]: 'We received a payment.',
        [SupportClaimResponsibilityCategoryType.HL7RECEIVED]: 'We received an HL7 message. ',
        [SupportClaimResponsibilityCategoryType.PROFESSIONALCREATED]: 'The professional claim (CMS1500) was created.',
        [SupportClaimResponsibilityCategoryType.TRANSMISSIONWITHIN30DAYS]:
            'This claim was sent to the Payer in the last 30 days. ',
        [SupportClaimResponsibilityCategoryType.TRANSMISSIONBEYOND30DAYS]:
            'This claim has been with the Payer for over 30 days.',
        [SupportClaimResponsibilityCategoryType.TRANSMISSIONREJECTEDORDENIED]:
            'This claim was rejected or denied by the Payer.',
        [SupportClaimResponsibilityCategoryType.FAXFAILED]: 'The fax attempt failed. ',
        [SupportClaimResponsibilityCategoryType.LETTERPAYERADDRESSNOTVALID]:
            "The letter did not send because the Payer's address was not valid.",
        [SupportClaimResponsibilityCategoryType.LETTERPAYERWITHIN30DAYS]:
            'The appeal (mail) was sent to the Payer in the last 30 days.',
        [SupportClaimResponsibilityCategoryType.LETTERPAYERBEYOND30DAYS]:
            'This appeal (mail) has been with the Payer for over 30 days.',
        [SupportClaimResponsibilityCategoryType.LETTERGUARANTORWITHIN30DAYS]:
            'The bill was sent to the Patient in the last 30 days.',
        [SupportClaimResponsibilityCategoryType.LETTERGUARANTORBEYOND30DAYS]:
            'This bill has been with the Patient for over 30 days.',
        [SupportClaimResponsibilityCategoryType.FAXPAYERNUMBERNOTVALID]:
            "The fax did not send because the Payer's fax number was not valid.",
        [SupportClaimResponsibilityCategoryType.FAXWITHIN30DAYS]:
            'The appeal (fax) was sent to the Payer in the last 30 days.',
        [SupportClaimResponsibilityCategoryType.FAXBEYOND30DAYS]:
            'This appeal (fax) has been with the Payer for over 30 days.',
        [SupportClaimResponsibilityCategoryType.SUPPORTCOMMENTWITHIN7DAYS]:
            'This support ticket has been addressed in the last 7 days.',
        [SupportClaimResponsibilityCategoryType.SUPPORTCOMMENTBEYOND7DAYS]:
            'This support ticket has been outstanding for over 7 days.',
        [SupportClaimResponsibilityCategoryType.PROVIDERCOMMENT]:
            'This provider initiated support ticket is pending a response.',
        [SupportClaimResponsibilityCategoryType.CLAIMEMPTY]: 'This claim has no usable information in it at this time.',
        [SupportClaimResponsibilityCategoryType.CROSSOVERTRANSMISSIONWITHIN30DAYS]:
            'This claim was transmitted to the next payer by the previous payer in the last 30 days.',
        [SupportClaimResponsibilityCategoryType.CROSSOVERTRANSMISSIONBEYOND30DAYS]:
            'This claim was transmitted to the next payer by the previous payer over 30 days ago with no response.',
        [SupportClaimResponsibilityCategoryType.PAYERRESPONSIBILITYTAGWITHIN30DAYS]:
            'This claim responsibility was changed to provider responsibility by system tag in the last 30 days.',
        [SupportClaimResponsibilityCategoryType.PAYERRESPONSIBILITYTAGBEYOND30DAYS]:
            'This claim responsibility was changed to provider responsibility by system tag over 30 days ago.',
        [SupportClaimResponsibilityCategoryType.GUARANTOROVERPAYMENT]:
            'There is a guarantor overpayment on this claim and a refund needs to be issued and recorded.',
        [SupportClaimResponsibilityCategoryType.INTERNALESCALATION]:
            'This claim has been escalated within Enter for further review and processing.',
        [SupportClaimResponsibilityCategoryType.UNKNOWN]: 'The responsibiltiy category for this claim is unknown.',
    };
    return dict[type];
}
