import { mdiClose } from '@mdi/js';
import { Slide, Snackbar, SnackbarOrigin } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import * as React from 'react';
import { ToastTheme } from './toast.models';
import * as S from './toast.styles';

export type ToastAlign = 'left' | 'right';

export interface IToastProps {
    align?: ToastAlign;
    className?: string;
    message: string | React.ReactElement;
    variant?: ToastTheme;
    timeout?: number;
    onClose?: () => void;
}
export class Toast extends React.PureComponent<IToastProps> {
    private get isOpen(): boolean {
        return !!this.props.message;
    }

    public render(): React.ReactElement {
        const { align, className, message, variant } = this.props;
        let anchorOrigin: SnackbarOrigin;
        switch (align) {
            case 'left':
                anchorOrigin = { vertical: 'bottom', horizontal: 'left' };
                break;
            case 'right':
            default:
                anchorOrigin = { vertical: 'bottom', horizontal: 'right' };
        }

        return (
            <Snackbar
                open={this.isOpen}
                anchorOrigin={anchorOrigin}
                onClose={this.handleClose}
                TransitionComponent={this.renderSlide}>
                <S.Toast className={className} variant={variant}>
                    {message}
                    <S.Close onClick={this.handleClose}>
                        <S.CloseIcon path={mdiClose} />
                    </S.Close>
                </S.Toast>
            </Snackbar>
        );
    }

    private readonly renderSlide = (props: TransitionProps & { children: React.ReactElement }): React.ReactElement => {
        return <Slide {...props} direction="up" />;
    };

    private readonly handleClose = (): void => {
        if (this.props.onClose) {
            this.props.onClose();
        }
    };
}
