import styled from '@emotion/styled/macro';
import { Variables } from '../base/variables';

export const TopNav = styled.section`
    width: 100%;
    height: ${Variables.topnavSize};
    display: flex;
    justify-content: space-between;
    border-bottom: ${Variables.Borders.regular};
    border-bottom-color: ${Variables.Colors.platinum};
`;

export const TopNavLeft = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: flex-start;
    margin-left: 48px;
`;

export const TopNavRight = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: flex-end;
`;

export const TopNavItem = styled.div`
    display: flex;
    ${TopNavLeft} > &:not(:first-of-type) {
        display: flex;
        margin-left: 28px;
    }
    ${TopNavRight} > & {
        margin-right: 16px;
        display: flex;
    }
`;
