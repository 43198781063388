import { css, Global } from '@emotion/react/macro';
import { Styles } from '@enter/core';
import '@mdi/font/css/materialdesignicons.min.css';
import React from 'react';

const styles = css`
    ${Styles.Normalize}
    ${Styles.Core}
`;

export const GlobalStyles = () => <Global styles={styles} />;
